import React, {
  Fragment, useState, useEffect, useContext,
} from 'react';
import {
  Form, Col, Button, InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';

import Icon from 'components/Icon';
import User from 'entities/User';
import AddRoleModal from './AddRoleModal';
import { AppContext } from 'App';
import { useModel } from 'models';

const AccountRole = ({ account }) => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(User.ROLE_ADMIN);
  const [rolePerms, setRolePerms] = useState([]);

  const { currentUser, dialog } = useContext(AppContext);

  const [updateState, { updateRole }] = useModel('auth', state => ({
    updateState: state.updateRole,
  }));

  useEffect(() => {
    if (selectedRole) {
      setRolePerms(selectedRole === User.ROLE_ADMIN ? [] : account.roles[selectedRole].perms);
    }
  }, [selectedRole]);

  const onUpdateRole = () => {
    dialog.warning({
      title: 'Update permissions',
      content: "Are you sure want to update this role's permissions?",
      onOK: (d) => {
        updateRole({
          roles: [{ id: selectedRole, name: account.roles[selectedRole].name, perms: rolePerms }],
        });
        d.dismiss();
      },
    });
  };

  const onAddSuccess = ({ name }) => {
    let id = null;
    Object.keys(account.roles).forEach((roleKey) => {
      if (account.roles[roleKey].name === name) {
        id = roleKey;
      }
    });
    setSelectedRole(id);
    setAddModalVisible(false);
  };

  const roleOptions = [{ value: User.ROLE_ADMIN, label: 'Admin' }].concat(
    Object.keys(account.roles).map(rk => ({
      value: rk,
      label: account.roles[rk].name,
    })),
  );

  const perms = [
    {
      group: 'Account',
      perms: {
        [User.PermAccountTeamView]: 'View team members',
        [User.PermAccountTeamEdit]: 'Invite, edit team member',
        [User.PermAccountRoleView]: 'View roles & permissions',
        [User.PermAccountRoleEdit]: 'Edit roles & permissions',
        [User.PermSystemSettingView]: 'View system settings',
        [User.PermSystemSettingEdit]: 'Edit system settings',
      },
    },
    {
      perms: {
        [User.PermAppView]: 'View list applications, application detail',
        [User.PermAppEdit]: 'Create, edit application',
        [User.PermAppTagView]: "View application's tags",
        [User.PermAppTagEdit]: "Edit, create application's tags",
      },
    },
    {
      perms: {
        [User.PermReportView]: 'View reports',
      },
    },
    {
      perms: {
        [User.PermPubAll]: 'Manage all publishers',
        [User.PermPubInfoView]: 'View list publishers, publisher info',
        [User.PermPubInfoEdit]: 'Edit publisher info',
        [User.PermPubPaymentView]: "View publisher's payment settings",
        [User.PermPubPaymentEdit]: "Edit publisher's payment settings",
        [User.PermPubSharingView]: "View publisher's sharing configs",
        [User.PermPubSharingEdit]: "Edit publisher's sharing configs",
        [User.PermPubContractView]: "View publisher's contracts",
        [User.PermPubContractEdit]: "Generate publisher's contract",
        [User.PermPubInvoiceView]: "View publisher's invoices",
        [User.PermPubInvoiceEdit]: "Create/update publisher's invoices",
      },
    },
    {
      perms: {
        [User.PermMonitorView]: 'View list monitors',
        [User.PermMonitorEdit]: 'Create/update monitors',
        [User.PermMonitorAlertView]: 'View monitor alerts',
        [User.PermMonitorAlertEdit]: 'Edit/update monitor alerts',
      },
    },
  ];

  return (
    <div>
      <Form.Row className="mb-3">
        <Form.Label column sm={4} className="text-sm-right">
          Role
        </Form.Label>
        <Col sm={8}>
          <InputGroup>
            <Select
              className="Select"
              classNamePrefix="Select"
              options={roleOptions}
              value={selectedRole ? roleOptions.find(r => r.value === selectedRole) : null}
              onChange={({ value }) => setSelectedRole(value)}
            />
            {currentUser.hasPermission(User.PermAccountRoleEdit) && (
              <InputGroup.Append>
                <Button className="addProfile" onClick={() => setAddModalVisible(true)}>
                  <Icon size={20} name="plus" />
                </Button>
              </InputGroup.Append>
            )}
          </InputGroup>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Label column sm={4} className="text-sm-right">
          Permission
        </Form.Label>
        <Col sm={8}>
          {perms.map((pg, index) => {
            const keys = Object.keys(pg.perms);
            return (
              <Fragment key={index}>
                {keys.map(k => (
                  <Form.Check
                    key={k}
                    disabled={
                      selectedRole === User.ROLE_ADMIN
                      || !currentUser.hasPermission(User.PermAccountRoleEdit)
                    }
                    className="py-2"
                    type="checkbox"
                    id={k}
                    label={pg.perms[k]}
                    checked={selectedRole === User.ROLE_ADMIN || rolePerms.includes(k)}
                    onChange={e => setRolePerms(
                      e.target.checked ? rolePerms.concat(k) : rolePerms.filter(rk => rk !== k),
                    )
                    }
                  />
                ))}
                <hr className="my-3" />
              </Fragment>
            );
          })}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={4} />
        <Col sm={8}>
          <Button disabled={updateState.loading} onClick={onUpdateRole}>
            Save
          </Button>
        </Col>
      </Form.Row>
      {currentUser.hasPermission(User.PermAccountRoleEdit) && (
        <AddRoleModal
          show={addModalVisible}
          onSuccess={onAddSuccess}
          onHide={() => setAddModalVisible(false)}
        />
      )}
    </div>
  );
};

export default AccountRole;
