import React, { useState } from 'react';
import {
  Modal, Form, InputGroup, Button,
} from 'react-bootstrap';
import Select from 'react-select';

import Page from 'entities/Page';
import Icon from 'components/Icon';
import PublisherInput from 'components/PublisherInput';

const FilterPageModal = ({
  filter: initFilter, onApply, onHide, ...rest
}) => {
  const [filter, setFilter] = useState(initFilter);
  const statusOptions = Page.getStatusOptions();

  return (
    <Modal centered onHide={onHide} {...rest} onEnter={() => setFilter(initFilter)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Icon size={20} className="mr-1" name="filter">
            Filter Page
          </Icon>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Publisher</Form.Label>
          <InputGroup size="sm">
            <PublisherInput
              small
              placeholder="Search publisher"
              onChange={p => setFilter({ ...filter, accountId: p ? p.id : undefined })}
              emptyOption={{ id: '0', name: '-- Not set --' }}
              value={filter.accountId}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>Status</Form.Label>
          <InputGroup size="sm">
            <Select
              isClearable
              className="Select"
              classNamePrefix="Select"
              options={statusOptions}
              onChange={s => setFilter({ ...filter, status: s.value })}
              value={filter.status ? statusOptions.find(s => s.value === filter.status) : null}
            />
          </InputGroup>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onApply({ status: '', accountId: '' })}>
          Reset
        </Button>
        <Button size="sm" variant="primary" onClick={() => onApply(filter)}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterPageModal;
