import api from './api';

export default {
  getReports(params) {
    return api.GET('/query/report', params);
  },
  getPageReports(params) {
    return api.GET('/query/report/adbreak', params);
  },
  getAdmobReports(params) {
    return api.GET('/query/report/admob', params);
  },
};
