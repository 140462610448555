import React, { useContext } from 'react';
import {
  Row, Col, Form, Button, Badge,
} from 'react-bootstrap';
import Collapse, { Panel } from 'rc-collapse';
import get from 'lodash.get';

import Icon from 'components/Icon';
import User from 'entities/User';
import { AppContext } from 'App';

import payoneerIcon from 'static/images/payoneer.png';

// TODO: Refactor payment method collapse

const PaymentSettingInfo = ({ account = {}, onEdit, onSetDefault }) => {
  const { currentUser } = useContext(AppContext);
  const canEdit = currentUser.hasPermission(User.PermPubPaymentEdit);
  const { payment = {} } = account;
  const getBankInfo = bankTransfer => [
    { label: 'Currency', value: bankTransfer.currency },
    { label: 'Account Name', value: bankTransfer.accName },
    { label: 'Account Number', value: bankTransfer.accNumber },
    { label: 'Bank Name', value: bankTransfer.bank },
    { label: 'Bank Address', value: bankTransfer.bankAddr },
    { label: 'Swift Code', value: bankTransfer.swiftCode },
  ];

  const renderInfoRow = (label, value) => (
    <Form.Group key={label} as={Row}>
      <Form.Label column xs={5} lg={3} className="text-right pt-0">
        {label}
      </Form.Label>
      <Col xs={7} lg={9}>
        <div>{value}</div>
      </Col>
    </Form.Group>
  );

  return (
    <div>
      <Form.Group as={Row}>
        <Form.Label column xs={4} md={3} className="text-md-right pt-0">
          Schedule
        </Form.Label>
        <Col xs={8} md={9}>
          <div>Paid monthly</div>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column xs={4} md={3} className="text-md-right pt-0">
          Threshold
        </Form.Label>
        <Col xs={8} md={9}>
          <div>{payment.threshold}</div>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column lg={3} className="text-lg-right pt-0">
          Payment Methods
        </Form.Label>
        <Col lg={9}>
          {account.hasPaymentMethod() && (
            <Collapse accordion>
              {(payment.bankTransfers || []).map(bt => (
                <Panel
                  key={bt.id}
                  showArrow={false}
                  header={(
                    <span className="d-flex align-items-center">
                      <Icon className="mr-2" size={20} type="fa" name="credit-card" />
                      {`${bt.accNumber} - ${bt.currency}`}
                    </span>
)}
                  extra={
                    get(payment, 'defaultMethod') === bt.id && canEdit ? (
                      <Badge pill variant="primary">
                      default
                      </Badge>
                    ) : (
                      <Badge
                        className="defaultButton"
                        variant="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          onSetDefault(bt.id);
                        }}
                      >
                      set default
                      </Badge>
                    )
                }
                  headerClass="collapseHeader"
                >
                  <div className="collapseContent">
                    {getBankInfo(bt).map(info => renderInfoRow(info.label, info.value))}
                  </div>
                </Panel>
              ))}
              {(payment.paypals || []).map(pp => (
                <Panel
                  key={pp.id}
                  showArrow={false}
                  header={(
                    <span className="d-flex align-items-center">
                      <Icon className="mr-2" size={20} name="paypal" type="fa" />
                      {`${pp.account} - ${pp.currency}`}
                    </span>
)}
                  extra={
                  payment.defaultMethod === pp.id ? (
                    <Badge pill variant="primary">
                      default
                    </Badge>
                  ) : (
                    <Badge
                      className="defaultButton"
                      variant="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        onSetDefault(pp.id);
                      }}
                    >
                      set default
                    </Badge>
                  )
                }
                  headerClass="collapseHeader"
                >
                  {renderInfoRow('Currency', pp.currency)}
                  {renderInfoRow('Account', pp.account)}
                </Panel>
              ))}
              {payment.payoneer && (
                <Panel
                  showArrow={false}
                  header={(
                    <span className="d-flex align-items-center">
                      <img src={payoneerIcon} alt="payoneer" style={{ width: 20, height: 16, marginRight: 8 }} />
                      Payoneer
                    </span>
)}
                  extra={
                  payment.defaultMethod === payment.payoneer.id ? (
                    <Badge pill variant="primary">
                      default
                    </Badge>
                  ) : (
                    <Badge
                      className="defaultButton"
                      variant="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        onSetDefault(payment.payoneer.id);
                      }}
                    >
                      set default
                    </Badge>
                  )
                }
                  headerClass="collapseHeader"
                >
                  <div className="collapseContent">
                    {renderInfoRow('Method', payment.payoneer.method)}
                    {renderInfoRow('Currency', payment.payoneer.currency)}
                    {renderInfoRow('Status', payment.payoneer.status)}
                  </div>
                </Panel>
              )}
            </Collapse>
          )}
        </Col>
      </Form.Group>
      {canEdit && (
      <Row className="mt-5">
        <Col />
        <Col xs={8} md={9}>
          <Button onClick={onEdit}>
            <Icon className="mr-2" name="edit" />
            Edit
          </Button>
        </Col>
      </Row>
      )}
    </div>
  );
};

export default PaymentSettingInfo;
