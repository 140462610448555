import React from 'react';

import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';

const AssignUserTable = ({ users, ...rest }) => {
  const columns = [
    {
      key: 'name',
      title: 'Name',
      width: 200,
    },
    {
      key: 'email',
      title: 'Email',
    },
    { key: 'role', title: 'Role' },
  ];

  const rows = (users || []).map(u => ({
    key: u.id,
    name: u.name,
    email: u.email,
    role: u.role,
  }));

  return (
    <DataTable
      className="mt-3"
      columns={columns}
      rows={rows}
      {...rest}
      emptyText={<EmptyState desc="No data" />}
    />
  );
};

export default AssignUserTable;
