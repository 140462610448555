import React, { useEffect, useContext } from 'react';
import { Form, Col } from 'react-bootstrap';
import qs from 'query-string';
import pickBy from 'lodash.pickby';
import moment from 'moment';

import { AppContext } from 'App';
import { useModel } from 'models';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import ListInvoiceCard from 'components/ListInvoiceCard';
import PublisherInput from 'components/PublisherInput';
import InvoiceStatusIput from 'components/InvoiceStatusIput';
import DateRangePicker from 'components/DateRangePicker';
import Notification from 'components/Notification';
import QuickSearchInput from 'components/QuickSearchInput';

import useActionEffect from 'utils/useActionEffect';

import styles from './ListInvoice.module.scss';

const invoiceSelector = ({
  getInvoices, payInvoice, deleteInvoice, invoices,
}) => ({
  invoices: (getInvoices.ids || []).map(i => invoices[i]),
  total: getInvoices.total,
  totalAmount: getInvoices.totalAmount,
  payState: payInvoice,
  deleteState: deleteInvoice,
});

const ListInvoices = ({ history, location }) => {
  const { currentAccount, dialog } = useContext(AppContext);
  const [
    {
      total, totalAmount, invoices, payState, deleteState,
    },
    { getInvoices, payInvoice, deleteInvoice },
  ] = useModel('invoice', invoiceSelector);
  const params = qs.parse(location.search);
  const page = parseInt(params.p, 10) || 1;
  const perpage = parseInt(params.pp, 10) || 30;
  const startDate = params.from ? moment(params.from, 'YYYY-MM-DD') : null;
  const endDate = params.to ? moment(params.to, 'YYYY-MM-DD') : null;

  const quickSearchOptions = [
    { keyword: 'hasRef:1', label: 'Invoices included referral Rev', value: { hasRef: 1 } },
    { keyword: 'hasAdmob:1', label: 'Invoices included Admob Rev', value: { hasAdmob: 1 } },
  ];

  useEffect(() => {
    const quickSearch = quickSearchOptions.find(o => o.keyword === params.keyword);
    getInvoices(
      pickBy({
        limit: perpage,
        skip: `${(page - 1) * perpage}`,
        accountId: params.pid,
        hasRef: params.hasRef,
        id: params.id,
        status: params.status,
        from: params.from ? moment(params.from).format('YYYY-MM-DD') : null,
        to: params.to ? moment(params.to).format('YYYY-MM-DD') : null,
        ...(quickSearch ? quickSearch.value : {}),
      }),
    );
  }, [
    page,
    perpage,
    params.pid,
    params.hasRef,
    params.id,
    params.keyword,
    params.status,
    params.from,
    params.to,
  ]);

  useActionEffect({
    loading: deleteState.loading,
    error: deleteState.error,
    onError: () => Notification.error(
      deleteState.error.message || deleteState.error.error || 'Something went wrong.',
      1,
    ),
  });

  useActionEffect({
    loading: payState.loading,
    error: payState.error,
    onError: () => Notification.error(
      payState.error.message || payState.error.error || 'Something went wrong.',
      1,
    ),
  });

  const onPay = id => {
    dialog.warning({
      title: 'Pay invoice',
      content: 'Are you sure want to pay this invoice?',
      onOK: d => {
        payInvoice(id);
        d.dismiss();
      },
    });
  };

  const onDelete = id => {
    dialog.warning({
      title: 'Delete invoice',
      content: 'Are you sure want to delete this invoice?',
      onOK: d => {
        deleteInvoice(id);
        d.dismiss();
      },
    });
  };

  const onQueryParamsChange = p => history.push({
    ...location,
    search: qs.stringify(pickBy({ ...params, ...p, p: 1 })),
  });

  const onPaginate = e => {
    if (e.page !== page || e.perpage !== perpage) {
      history.push({
        ...location,
        search: qs.stringify({
          ...params,
          p: e.page !== page ? e.page : 1,
          pp: e.perpage,
        }),
      });
    }
  };

  return (
    <MainLayout>
      <PageHeader title="List Invoices" subTitle="Invoices management" />
      <div className={`${styles.listInvoice} mt-3`}>
        <Form.Row>
          <Col sm={6} md={4} lg={3}>
            <QuickSearchInput
              className="w-100"
              placeholder="Invoice ID"
              options={quickSearchOptions}
              value={params.id || params.keyword}
              searchOnEnter
              onSearch={(id, quickSearch) => onQueryParamsChange(quickSearch ? { keyword: id } : { id, keyword: null })}
            />
          </Col>
          <Col sm={6} md={3} lg={2}>
            <InvoiceStatusIput
              value={params.status}
              placeholder="Status"
              onChange={status => onQueryParamsChange({ status })}
            />
          </Col>
          {currentAccount.isAdmin() && (
            <Col sm={6} md={3} xl={2}>
              <PublisherInput
                placeholder="All publishers"
                onChange={p => onQueryParamsChange({ pid: p ? p.id : '' })}
                value={params.pid}
              />
            </Col>
          )}
          <Col sm={6} md={4} lg={3}>
            <DateRangePicker
              showClearDates
              anchorDirection="right"
              isOutsideRange={d => false}
              startDate={startDate}
              endDate={endDate}
              maxDate={moment()}
              onChange={(start, end) => onQueryParamsChange({
                from: start.format('YYYY-MM-DD'),
                to: end.format('YYYY-MM-DD'),
              })}
              onClearDate={() => onQueryParamsChange({ from: '', to: '' })}
            />
          </Col>
        </Form.Row>
      </div>
      <ListInvoiceCard
        total={total}
        totalAmount={totalAmount}
        invoices={invoices}
        pagination={{ current: page, perpage, onChange: onPaginate }}
        onPay={onPay}
        paying={payState.loading && payState.id}
        onDelete={onDelete}
        showPubId={currentAccount.isAdmin()}
      />
    </MainLayout>
  );
};
export default ListInvoices;
