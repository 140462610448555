import React from 'react';
import {
  Modal, Button, Form, InputGroup,
} from 'react-bootstrap';

import Icon from 'components/Icon';
import ClipBoardButton from 'components/ClipBoardButton';

const ReferralUrlModal = ({ accountId, show, onHide }) => {
  const referrerURL = `${window.location.origin}/register?ref=${accountId}`;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Copy Referral URL</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <Form.Control readOnly type="text" value={referrerURL} />
          <InputGroup.Append>
            <ClipBoardButton tooltip={{ placement: 'top' }} value={referrerURL}>
              <Icon name="copy" className="mr-1" />
              Copy
            </ClipBoardButton>
          </InputGroup.Append>
        </InputGroup>
        <div className="mt-3">
          <Button
            className="p-0 mr-1"
            variant="link"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referrerURL)}`}
            target="_blank"
          >
            <Icon name="facebook" type="fa" size={46} style={{ color: '#3b5998' }} />
          </Button>
          <Button
            className="p-0"
            variant="link"
            href={`https://twitter.com/home?status=${encodeURIComponent(referrerURL)}`}
            target="_blank"
          >
            <Icon name="twitter-square" type="fa" size={46} style={{ color: '#55acee' }} />
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferralUrlModal;
