import React, { useEffect } from 'react';
import qs from 'query-string';
import moment from 'moment';
import pickBy from 'lodash.pickby';
import Select from 'react-select';
import { Form, Col, Dropdown } from 'react-bootstrap';

import Account from 'entities/Account';
import { useModel } from 'models';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import ListPublishersCard from 'components/ListPublishersCard';
import DateRangePicker from 'components/DateRangePicker';
import SearchInput from 'components/SearchInput';
import AccountTypeSelect from 'components/AccountTypeSelect';
import AccountManagerSelect from 'components/AccountManagerSelect';
import CountryInput from 'components/CountryInput';

import configs from 'configs/configs';
import styles from './ListPublishers.module.scss';

const pubsSelector = ({ listPublishers, publishers }) => ({
  publishers: listPublishers.ids.map(i => publishers[i]),
  total: listPublishers.total,
});

/**
 * @param {import('react-router-dom').RouteComponentProps} props
 */
const ListPublishers = ({ history, location }) => {
  const [{ total, publishers }, { getPublishers }] = useModel('publisher', pubsSelector);
  const params = qs.parse(location.search);
  const page = parseInt(params.p, 10) || 1;
  const perpage = parseInt(params.pp, 10) || 10;

  const quickSearchOptions = [
    { key: 'no-dfp-adunit', label: 'Publisher has no DFP Adunit', query: { dfpAdUnitID: 0 } },
    {
      key: 'no-contract',
      label: 'Publisher has no contract',
      query: { contractId: 0, pubType: 'personal' },
    },
    {
      key: 'need-updated',
      label: 'Contract may need to be updated',
      query: { contractInfoChanged: 1, pubType: 'personal' },
    },
    {
      key: 'integratedAdmob',
      label: 'Integrated Admob',
      query: { admobConfig: '1' },
    },
    { key: 'has-ref', label: 'Publisher has referrals', query: { hasRef: 1 } },
    { key: 'referee', label: 'Referee publishers', query: { refAccountId: '*' } },
  ];
  let selectedQuickSearchOption = quickSearchOptions.find(o => o.key === params.quickSearch) || {};
  if (configs.platform === 'FAN') {
    selectedQuickSearchOption = {};
  }

  useEffect(() => {
    getPublishers({
      limit: perpage,
      skip: (page - 1) * perpage,
      status: params.status,
      AM: params.AM,
      pubType: params.pubType,
      country: params.country,
      from: params.from ? moment(params.from).format('YYYY-MM-DD') : null,
      to: params.to ? moment(params.to).format('YYYY-MM-DD') : null,
      keyword: !selectedQuickSearchOption.query && params.keyword ? params.keyword : null,
      ...(selectedQuickSearchOption.query || {}),
    });
  }, [
    page,
    perpage,
    selectedQuickSearchOption.key,
    params.from,
    params.to,
    params.status,
    params.AM,
    params.pubType,
    params.keyword,
    params.country,
    getPublishers,
  ]);

  const onQueryParamsChange = p => history.push({
    ...location,
    search: qs.stringify(pickBy({ ...params, ...p, p: 1 })),
  });

  const onPaginate = e => {
    if (e.page !== page || e.perpage !== perpage) {
      history.push({
        ...location,
        search: qs.stringify({
          ...params,
          p: e.page !== page ? e.page : 1,
          pp: e.perpage,
        }),
      });
    }
  };

  return (
    <MainLayout>
      <PageHeader title="List Publishers" subTitle="Publishers management" />
      <div className={`${styles.listPublisher} mt-3`}>
        <Form.Row>
          <Col sm={6} md={3} lg={4} xl={2}>
            {configs.platform === 'ADX' && (
              <Dropdown className="w-100">
                <Dropdown.Toggle
                  as={SearchInput}
                  id="togger"
                  value={selectedQuickSearchOption.label || params.keyword || ''}
                  placeholder="Search"
                  searchOnEnter
                  autoComplete="off"
                  onSearch={keyword => onQueryParamsChange({ keyword })}
                  onClear={() => onQueryParamsChange({ quickSearch: '', keyword: '' })}
                />
                <Dropdown.Menu>
                  <Dropdown.Header>Quick Search</Dropdown.Header>
                  {quickSearchOptions.map(o => (
                    <Dropdown.Item
                      key={o.key}
                      onClick={() => onQueryParamsChange({ quickSearch: o.key })}
                    >
                      {o.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {configs.platform === 'FAN' && (
              <SearchInput
                searchOnEnter
                value={selectedQuickSearchOption.label || params.keyword || ''}
                placeholder="Search"
                autoComplete="off"
                onSearch={keyword => onQueryParamsChange({ keyword })}
                onClear={() => onQueryParamsChange({ quickSearch: '', keyword: '' })}
              />
            )}
          </Col>

          <Col sm={6} md={3} lg={2}>
            <Select
              isClearable
              className="Select"
              classNamePrefix="Select"
              placeholder="Status"
              options={Account.getStatusOptions()}
              onChange={c => onQueryParamsChange({ status: c ? c.value : '' })}
              value={Account.getStatusOptions().find(s => `${s.value}` === params.status)}
              noOptionsMessage={() => 'No result'}
            />
          </Col>

          <Col sm={6} md={3} lg={2}>
            <AccountTypeSelect
              placeholder="Account Type"
              value={params.pubType}
              onChange={pt => onQueryParamsChange({ pubType: pt })}
            />
          </Col>

          <Col sm={6} md={3} lg={2}>
            <AccountManagerSelect
              placeholder="Account Manager"
              value={params.AM}
              onChange={amId => onQueryParamsChange({ AM: amId })}
            />
          </Col>

          <Col sm={6} md={3} lg={2}>
            <CountryInput
              value={params.country}
              onChange={c => onQueryParamsChange({ country: c ? c.code : '' })}
              placeholder="Filter by country"
            />
          </Col>

          <Col sm={6} md={3} lg={4} xl={2}>
            <DateRangePicker
              showClearDates
              anchorDirection="right"
              isOutsideRange={d => false}
              startDate={params.from ? moment(params.from, 'YYYY-MM-DD') : null}
              endDate={params.to ? moment(params.to, 'YYYY-MM-DD') : null}
              maxDate={moment()}
              onChange={(start, end) => onQueryParamsChange({
                from: start.format('YYYY-MM-DD'),
                to: end.format('YYYY-MM-DD'),
              })}
              onClearDate={() => onQueryParamsChange({ from: '', to: '' })}
            />
          </Col>
        </Form.Row>
      </div>
      <ListPublishersCard
        total={total}
        publishers={publishers}
        pagination={{ current: page, perpage, onChange: onPaginate }}
        additionColumns={selectedQuickSearchOption.key === 'integratedAdmob' ? ['admobStatus'] : []}
      />
    </MainLayout>
  );
};
export default ListPublishers;
