import React from 'react';
import { Link } from 'react-router-dom';
import {
  OverlayTrigger, Button, Tooltip, Badge,
} from 'react-bootstrap';
import moment from 'moment';
import Icon from 'components/Icon';

import styles from './MonitorAlert.module.scss';

const alertTypes = ['', 'success', 'warning', 'urgent'];
const alertIcons = ['', 'calendar-check ', 'exclamation-triangle', 'exclamation-triangle'];

const renderAlertHeadingLink = (alert) => {
  let href;
  let name;
  if (alert.pubId) {
    href = `/app-reports?pid=${alert.pubId}`;
    name = `Publisher #${alert.pubId}`;
  }
  if (alert.appId) {
    href = `/app-reports?aid=${alert.appId}`;
    name = alert.appName;
  }
  if (alert.tagCode) {
    href = `/app-reports?aid=${alert.appId}&breakdown=tag`;
    name = `${name ? `${name} > ` : ''}${alert.tagName}`;
  }

  return <Link to={href}>{name}</Link>;
};


const MonitorAlert = ({
  alert, showActions, onResolve, showSimilarLink, showMonitorLink,
}) => (
  <div className={styles.monitorAlert}>
    <div className={`media monitor-alert-${alertTypes[alert.type]}`}>
      <div className="media-left">
        <Icon name={alertIcons[alert.type]} type="fa" />
        <div className="meta-date text-white">{moment(alert.date, 'YYYYMMDD').format('DD-MM')}</div>
      </div>
      <div className="media-body">
        <div className="media-heading">
          {renderAlertHeadingLink(alert)}
          {alert.status === 'new' && (
          <Badge variant="info" pill className="ml-2">
            new
          </Badge>
          )}
        </div>
        <div className="media-content">
          <p>{alert.message}</p>
          {/* <code>{alert.detail}</code> */}
          {showMonitorLink && <Link to={`/monitors?kw=${alert.monitorId}`}>View monitor</Link>}
          {showMonitorLink && showSimilarLink && ' | '}
          {showSimilarLink && <Link to={`/alerts?mid=${alert.monitorId}`}>Find similar</Link>}
        </div>

        <small className="meta-timestamp text-muted">
          {moment(alert.lstUpdate).fromNow()}
        </small>
      </div>
      {showActions && (
      <div className="media-right">
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="left"
          overlay={<Tooltip id={`dismiss-${alert.id}`}>Snooze, dismiss for now</Tooltip>}
        >
          <Button variant="warning" size="sm" block onClick={() => onResolve('dismissed')}>
            <Icon name="clock" type="fa" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="left"
          overlay={<Tooltip id={`done-${alert.id}`}>Mark as resolved, not show again</Tooltip>}
        >
          <Button variant="success" size="sm" block onClick={() => onResolve('done')}>
            <Icon name="check-circle" type="fa" />
          </Button>
        </OverlayTrigger>
      </div>
      )}
    </div>
  </div>
);

export default MonitorAlert;
