import api from './api';

export default {
  me() {
    return api.GET('/profile');
  },
  login(params) {
    return api.POST('/login', params);
  },
  logout() {
    return api.POST('/logout');
  },
  getAccounts(params) {
    return api.GET('/accounts', params);
  },
  getAccountDetail(id) {
    return api.GET(`/accounts/${id}`);
  },
  updateAccount(id, data) {
    return api.POST(`/accounts/${id}`, data);
  },
  getContracts({ accountId }) {
    return api.GET(`/accounts/${accountId}/contracts`);
  },
  getContract({ accountId, contractId }) {
    return api.GET(`/accounts/${accountId}/contracts/${contractId}`);
  },
  generateContract({ accountId, ...params }) {
    return api.POST(`/accounts/${accountId}/contracts`, params);
  },
  getProfiles() {
    return api.GET('/contract/profiles');
  },
  saveProfile(params) {
    return api.POST(`/contract/profiles/${params.id}`, params);
  },
  getInvitations() {
    return api.GET('/invitations');
  },
  sendInvite(data) {
    return api.POST('/invitations', data);
  },
  register(data) {
    return api.POST('/register', data);
  },
  addRole(data) {
    return api.POST('/accounts/admin', data);
  },
  updateRole(data) {
    return api.POST('/accounts/admin', data);
  },
  updateMember(accountId, memberId, data) {
    return api.POST(`/accounts/${accountId}/users/${memberId}`, data);
  },
  sendFeedback(data) {
    return api.POST('/feedback', data);
  },
  getPayoneerLinks(id, redirectUrl) {
    return api.GET(`/accounts/${id}/payoneer`, { redirect_url: redirectUrl });
  },
  generateApiKey(id) {
    return api.POST(`/accounts/${id}/apiKey`);
  },
};
