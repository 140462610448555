import React, { useContext } from 'react';
import { Alert, Button } from 'react-bootstrap';
import classNames from 'classnames';

import Icon from 'components/Icon';

import { AppContext } from 'App';

import styles from './SystemAlert.module.scss';

const icons = {
  success: 'check',
  danger: 'alert-triangle',
  warning: 'alert-triangle',
  info: 'info',
};

const alertFunc = {
  success: 'success',
  danger: 'warning',
  warning: 'warning',
  info: 'info',
};

const SystemAlert = ({
  alert, dismissible, onClose, ...rest
}) => {
  const { dialog } = useContext(AppContext);
  const { title, content, type } = alert;

  const doAlert = () => {
    const showDialog = dialog[alertFunc[type]];
    if (!showDialog) {
      return;
    }
    showDialog({
      title: title || 'Alert',
      content,
      cancelable: false,
      className: classNames('alert-dialog', styles.alertDialogLg),
    });
  };

  const doClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Alert className={classNames('mt-3', styles.systemAlert, { 'pr-5': dismissible })} variant={type} onClick={doAlert} {...rest}>
      <Icon size={24} className="mr-2 font-weight-bold" name={icons[type]} providers="fi" />
      {title || content}
      {dismissible && (
        <Button variant="link" className="closeButton" onClick={doClose}>
          <Icon name="x" />
        </Button>
      )}
    </Alert>
  );
};

export default SystemAlert;
