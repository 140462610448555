import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Dimmer } from 'tabler-react';
import { Row, Col, Alert } from 'react-bootstrap';
import Chart from 'react-google-charts';
import moment from 'moment';
import pickBy from 'lodash.pickby';
import { parse, stringify } from 'query-string';
import get from 'lodash.get';

import { useModel } from 'models';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import EmptyState from 'components/EmptyState';
import DataTable from 'components/DataTable';
import DateRangePicker from 'components/DateRangePicker';
import { formatNumber } from 'utils/intl';
import { getDimensionName, getMetricName } from 'entities/Report';
import useTempState from 'utils/useTempState';

import styles from './ReferralsReport.module.scss';

const fontName = 'Google Sans';
const opts = {
  theme: 'material',
  chartArea: {
    left: 50,
    width: '95%',
  },
  colors: ['#467fcf', '#e74c3c'],
  focusTarget: 'category',
  pointsVisible: false,
  pointSize: 4,
  vAxis: {
    textStyle: { fontName },
    format: 'currency',
    minorGridlines: { count: 0 },
  },
  hAxis: { textStyle: { fontName } },
  tooltip: {
    textStyle: { fontName },
    isHtml: true,
  },
  legend: { position: 'top' },
};

const defaultConfig = {
  dims: ['DATE', 'ADX_REFERRER_ID', 'ADX_REFERRER_NAME'],
  metrics: ['REFERRER_EARNINGS', 'REFERRAL_EARNINGS'],
  total: [
    { key: 'REFERRER_TOTAL' },
    { key: 'REFERRER_EARNINGS', type: 'currency' },
    { key: 'REFERRAL_EARNINGS', type: 'currency' },
    { key: 'REFERRAL_PAID', type: 'currency' },
  ],
};

const byReferrerConfig = {
  dims: ['DATE', 'ADX_REFEREE_ID', 'ADX_REFEREE_NAME'],
  metrics: ['REFEREE_EARNINGS', 'REFERRAL_EARNINGS'],
  total: [
    { key: 'REFEREE_TOTAL' },
    { key: 'REFEREE_EARNINGS', type: 'currency' },
    { key: 'REFERRAL_EARNINGS', type: 'currency' },
    { key: 'REFERRAL_PAID', type: 'currency' },
  ],
};

const byRefereeConfig = {
  dims: ['DATE', 'ADX_REFEREE_ID', 'ADX_REFEREE_NAME'],
  metrics: ['REFEREE_EARNINGS', 'REFERRAL_EARNINGS'],
  total: [
    { key: 'REFEREE_EARNINGS', type: 'currency' },
    { key: 'REFERRAL_EARNINGS', type: 'currency' },
  ],
};

const ReferralsReport = ({ history, location, match }) => {
  const params = parse(location.search);
  let startDate = moment(params.from, 'YYYY-MM-DD');
  let endDate = moment(params.to, 'YYYY-MM-DD');
  const { accountId, refereeId } = match.params;
  let config = defaultConfig;
  if (accountId) {
    config = byReferrerConfig;
  }
  if (refereeId) {
    config = byRefereeConfig;
  }

  if (!endDate.isValid()) {
    endDate = moment();
  }
  if (!startDate.isValid()) {
    startDate = endDate.clone().subtract(6, 'd');
  }

  const [byDatePagination, setByDatePagination] = useTempState({
    perpage: 10,
    current: 1,
  }, [accountId, refereeId]);
  const [byAccountPagination, setByAccountPagination] = useTempState({
    perpage: 10,
    current: 1,
  }, [accountId, refereeId]);

  const [{
    rows, totalPaid, loading, error,
  }, { getReferralReport }] = useModel(
    'referral',
    state => ({
      ...state.getReferralReport,
    }),
  );

  useEffect(() => {
    getReferralReport({
      dimensions: config.dims,
      metrics: config.metrics,
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      'filter.ADX_REFERRER_ID': accountId,
      'filter.ADX_REFEREE_ID': refereeId,
    });
  }, [accountId, refereeId, params.from, params.to]);

  const colsByDate = [
    { key: 'DATE', title: getDimensionName('DATE') },
    ...config.metrics.map(m => ({
      key: m,
      title: getMetricName(m),
      render: r => formatNumber(r, { style: 'currency', currency: 'USD' }),
    })),
  ];

  const colsByAccount = [
    { key: config.dims[1], title: getDimensionName(config.dims[1]) },
    { key: config.dims[2], title: getDimensionName(config.dims[2]) },
    ...config.metrics.map(m => ({
      key: m,
      title: getMetricName(m),
      render: r => formatNumber(r, { style: 'currency', currency: 'USD' }),
    })),
  ];

  const data = {
    byDate: {
      rows: [],
      tableRows: [],
    },
    byAccount: {
      rows: [],
      tableRows: [],
    },
    total: {
      REFERRER_EARNINGS: 0,
      REFEREE_EARNINGS: 0,
      REFEREE_TOTAL: 0,
      REFERRER_TOTAL: 0,
      REFERRAL_EARNINGS: 0,
      REFERRAL_PAID: 0,
    },
  };

  const rowsByAccount = {};
  (rows || []).forEach((r) => {
    const dateRow = [r[0], r[3], r[4]];
    data.byDate.rows.push(dateRow);
    data.byDate.tableRows.push(
      colsByDate.reduce((prev, col, i) => ({ ...prev, [col.key]: dateRow[i] }), {}),
    );

    const accountRow = [r[1], r[2], r[3], r[4]];
    data.byAccount.rows.push(accountRow);
    rowsByAccount[r[1]] = colsByAccount.reduce((prev, col, i) => {
      if (config.metrics.includes(col.key)) {
        return { ...prev, [col.key]: get(rowsByAccount, [r[1], col.key], 0) + accountRow[i] };
      }
      return { ...prev, [col.key]: accountRow[i] };
    }, {});

    data.total.REFEREE_EARNINGS += r[3];
    data.total.REFERRER_EARNINGS += r[3];
    data.total.REFERRAL_EARNINGS += r[4];
  });

  data.total.REFERRAL_PAID = totalPaid;
  data.total.REFEREE_TOTAL = data.byAccount.tableRows.length;
  data.total.REFERRER_TOTAL = data.byAccount.tableRows.length;
  data.byAccount.tableRows = Object.keys(rowsByAccount).map(k => ({ ...rowsByAccount[k] }));

  const byAccountTableRows = data.byAccount.tableRows.map((r) => {
    const rr = { ...r };
    const idKey = colsByAccount[0].key;
    const nameKey = colsByAccount[1].key;
    if (rr[idKey]) {
      const path = { ...location, pathname: `${location.pathname}/${rr[idKey] || '_unknown_'}` };
      rr[nameKey] = (
        <Link to={path} title={rr[nameKey]}>
          {rr[nameKey]}
        </Link>
      );
    }
    return rr;
  });

  const chartData = [colsByDate.map(col => col.title), ...data.byDate.rows];

  const onQueryParamsChange = p => history.push({
    ...location,
    search: stringify(pickBy({ ...params, ...p })),
  });

  const getBreadcrumb = () => {
    if (!accountId) {
      return [{ title: 'Referrals Report', active: true }];
    }
    if (!refereeId) {
      return [
        { title: 'Referrals Report', href: { ...location, pathname: '/referrals-report' } },
        { title: accountId, active: true },
      ];
    }
    return [
      { title: 'Referrals Report', href: { ...location, pathname: '/referrals-report' } },
      { title: accountId, href: { ...location, pathname: `/referrals-report/${accountId}` } },
      { title: refereeId, active: true },
    ];
  };

  const action = (
    <DateRangePicker
      anchorDirection="right"
      isOutsideRange={() => false}
      startDate={startDate}
      endDate={endDate}
      maxDate={moment()}
      onChange={(start, end) => onQueryParamsChange({ from: start.format('YYYY-MM-DD'), to: end.format('YYYY-MM-DD') })
      }
      onClearDate={() => onQueryParamsChange({ from: '', to: '' })}
    />
  );

  return (
    <MainLayout>
      <PageHeader title="Referrals Report" extraContent={action} breadcrumb={getBreadcrumb()} />
      {error && (
        <Alert variant="danger" className="mt-3">
          {error.message || error.error || 'Something went wrong'}
        </Alert>
      )}
      <div className={styles.ReferralsReport}>
        <Card className="mt-3">
          <Card.Header>Line Chart</Card.Header>
          <Card.Body>
            <Dimmer active={!rows || loading} loader>
              <Row className="border-bottom">
                {config.total.map(({ key, type }) => (
                  <Col key={key} xs={6} lg={12 / config.total.length} className="text-center mb-2">
                    <div>{getMetricName(key)}</div>
                    <h4>
                      {type === 'currency'
                        ? formatNumber(data.total[key], { style: 'currency', currency: 'USD' })
                        : data.total[key]}
                    </h4>
                  </Col>
                ))}
              </Row>
              {rows && rows.length > 0 && (
                <Chart
                  height="300px"
                  width="100%"
                  chartType="LineChart"
                  loader={<div>Loading...</div>}
                  data={chartData}
                  options={opts}
                />
              )}
              {rows && !rows.length && (
                <EmptyState className="mt-3" icon="trending-up" desc="No data" />
              )}
            </Dimmer>
          </Card.Body>
        </Card>
        <Row>
          <Col lg={!refereeId ? 6 : 12}>
            <Card>
              <Card.Header>Report By Date</Card.Header>
              <Card.Body>
                <Dimmer active={!rows || loading} loader>
                  <DataTable
                    rowKey={config.dims[0]}
                    scroll={{ x: true }}
                    emptyText={<EmptyState desc="No data" />}
                    columns={colsByDate}
                    rows={data.byDate.tableRows.slice(
                      byDatePagination.perpage * (byDatePagination.current - 1),
                      byDatePagination.perpage * byDatePagination.current,
                    )}
                    pagination={{
                      total: Math.ceil(data.byDate.tableRows.length / byDatePagination.perpage),
                      current: Math.min(data.byDate.tableRows.length, byDatePagination.current),
                      onChange: p => setByDatePagination({ ...byDatePagination, current: p }),
                    }}
                  />
                </Dimmer>
              </Card.Body>
            </Card>
          </Col>
          {!refereeId && (
            <Col lg={6}>
              <Card>
                <Card.Header>{!accountId ? 'Report By Referrer' : 'Report By Referee'}</Card.Header>
                <Card.Body>
                  <Dimmer active={!rows || loading} loader>
                    <DataTable
                      rowKey={config.dims[1]}
                      scroll={{ x: true }}
                      emptyText={<EmptyState desc="No data" />}
                      columns={colsByAccount.slice(1)}
                      rows={byAccountTableRows.slice(
                        byAccountPagination.perpage * (byAccountPagination.current - 1),
                        byAccountPagination.perpage * byAccountPagination.current,
                      )}
                      pagination={{
                        total: Math.ceil(byAccountTableRows.length / byAccountPagination.perpage),
                        current: Math.min(byAccountTableRows.length, byAccountPagination.current),
                        onChange: p => setByAccountPagination({ ...byAccountPagination, current: p }),
                      }}
                    />
                  </Dimmer>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </MainLayout>
  );
};

export default ReferralsReport;
