import api from './api';

export default {
  getInvoices(params) {
    return api.GET('/invoices', params);
  },
  getInvoice(id) {
    return api.GET(`/invoices/${id}`);
  },
  addInvoice({ accountId, ...params }) {
    return api.POST(`/accounts/${accountId}/invoices`, params);
  },
  updateInvoice({ id, ...params }) {
    return api.POST(`/invoices/${id}`, params);
  },
  sendInvoice(id, params) {
    return api.POST(`/invoices/${id}/send`, params);
  },
  confirmInvoice({ id, ...params }) {
    return api.POST(`/invoices/${id}/confirm`, params);
  },
  payInvoice(id) {
    return api.POST(`/invoices/${id}/pay`);
  },
  deleteInvoice(id) {
    return api.DELETE(`/invoices/${id}`);
  },
};
