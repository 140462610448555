import Account from 'entities/Account';
import User from 'entities/User';
import configs from './configs';

export default {};
export const menus = [
  { icon: 'home', title: 'Dashboard', href: '/' },
  { icon: 'box', title: 'Apps', href: '/apps' },
  {
    icon: 'pie-chart',
    title: 'Reports',
    only: currentAccount => configs.platform === 'ADX'
      && (currentAccount.isAdmin() || (currentAccount.admobConfig || {}).enabled),
    childs: [
      { title: 'ADX Reports', href: '/app-reports' },
      { title: 'Admob Reports', href: '/admob-reports' },
    ],
  },
  {
    icon: 'pie-chart',
    title: 'Reports',
    href: '/app-reports',
    only: currentAccount => (configs.platform === 'ADX'
        && currentAccount.isPublisher()
        && !(currentAccount.admobConfig || {}).enabled)
      || configs.platform === 'FAN',
  },
  {
    icon: 'layout',
    title: 'Ad Break',
    only: currentAccount => configs.platform === 'FAN' && (currentAccount.isAdmin() || currentAccount.adbreakEnabled),
    childs: [{ title: 'Pages', href: '/pages' }, { title: 'Reports', href: '/page-reports' }],
  },
  {
    icon: 'bell',
    title: 'Alerts',
    href: '/alerts',
    only: currentAccount => configs.platform === 'ADX' && currentAccount.isAdmin(),
  },
  {
    icon: 'users',
    title: 'Publishers',
    href: '/publishers',
    only: Account.TYPE_ADMIN,
  },
  {
    icon: 'grid',
    title: 'Integration',
    href: '/integration',
    only: () => configs.platform === 'ADX',
  },
  {
    icon: 'link',
    title: 'Referrals',
    href: '/referrals',
    only: currentAccount => configs.platform === 'ADX' && currentAccount.isPublisher(),
  },
  {
    icon: 'link',
    title: 'Referrals',
    href: '/referrals-report',
    only: currentAccount => configs.platform === 'ADX' && currentAccount.isAdmin(),
  },
  {
    icon: 'dollar-sign',
    title: 'Invoices',
    href: '/invoices',
    only: (currentAccount, currentUser) => currentUser.hasPermission(User.PermPubInvoiceView, User.PermPubInvoiceEdit),
  },
  { icon: 'settings', title: 'Settings', href: '/settings' },
];
