/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import { NavLink, withRouter, matchPath } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import { Page } from 'tabler-react';
import classNames from 'classnames';
import Slideout from 'slideout';
import enquire from 'enquire.js';
import { Container, Nav, Collapse } from 'react-bootstrap';

import { menus } from 'configs/sidebar';
import Icon from 'components/Icon';
import styles from './layout.module.scss';
import { useModel } from '../models';

import Header from './Header';

const breakpointMap = {
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  // md: '(min-width: 768px)',
  // lg: '(min-width: 992px)',
  // xl: '(min-width: 1200px)',
};

/** @type {Slideout} */
let slideout;

const Main = ({ children, className, location }) => {
  const sidebar = useRef();
  const main = useRef();
  const wrapper = useRef();
  const [{ user, account }, { logout }] = useModel('auth', state => ({
    user: state.user,
    account: state.account,
  }));

  useEffect(() => {
    slideout = new Slideout({
      panel: main.current,
      menu: sidebar.current,
      padding: 250,
      tolerance: 80,
      duration: 200,
    });
    /* eslint-disable react/no-find-dom-node */
    slideout.on('beforeopen', () => {
      const elem = findDOMNode(wrapper.current);
      if (elem) {
        elem.classList.add('sidebar-open');
      }
    });
    slideout.on('beforeclose', () => {
      const elem = findDOMNode(wrapper.current);
      if (elem) {
        elem.classList.remove('sidebar-open');
      }
    });
    Object.keys(breakpointMap).forEach(bp => {
      enquire.register(breakpointMap[bp], {
        match: () => {
          if (bp === 'xs') {
            if (slideout.isOpen()) {
              slideout.close();
            }
          } else if (!slideout.isOpen()) {
            slideout.open();
          }
        },
      });
    });

    return () => {
      slideout.off('close');
      slideout.destroy();
      Object.keys(breakpointMap).forEach(bp => enquire.unregister(breakpointMap[bp]));
    };
  }, []);
  return (
    <Page className={`${styles.main} ${className || ''}`}>
      <Page.Main>
        <Header
          currentUser={user}
          onLogout={() => logout()}
          onToggleSidebar={() => slideout.toggle()}
        />
        <div ref={wrapper} className="main-wrapper d-flex flex-column flex-grow-1 sidebar-open">
          <Sidebar ref={sidebar} currentUser={user} currentAccount={account} location={location} />
          <main
            ref={main}
            className="d-flex flex-column align-items-start main-content flex-grow-1"
          >
            <Container fluid className="content-wrapper d-flex flex-column flex-grow-1">
              {children}
            </Container>
            <Footer />
          </main>
        </div>
      </Page.Main>
    </Page>
  );
};
export default withRouter(Main);

/**
 *
 * @param {Object} props
 * @param {import("entities/Account").default} props.currentAccount
 */
function _Sidebar({
  className, currentAccount, currentUser, location,
}, ref) {
  return (
    <div ref={ref} className={classNames(styles.sidebar, className)}>
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <Nav as="ul" className="flex-column">
          {menus
            .filter(m => {
              if (typeof m.only === 'function') {
                return m.only(currentAccount, currentUser);
              }
              return m.only == null || currentAccount.type === m.only;
            })
            .map((m, i) => {
              const expanded = (m.childs || []).some(mm => matchPath(location.pathname, { path: mm.href }));
              return (
                <MenuItem key={i} icon={m.icon} title={m.title} href={m.href} expanded={expanded}>
                  {m.childs
                    && m.childs.map((mm, ii) => (
                      <MenuItem key={ii} icon={mm.icon} title={mm.title} href={mm.href} />
                    ))}
                </MenuItem>
              );
            })}
        </Nav>
      </Scrollbar>
    </div>
  );
}
const Sidebar = React.forwardRef(_Sidebar);

const MenuItem = ({
  icon, title, href = '', children, expanded,
}) => {
  const [collapsed, setCollapsed] = useState(expanded);
  if (!children) {
    return (
      <Nav.Item as="li" className="p-0">
        <NavLink exact={href === '/'} className="flex-grow-1" to={href}>
          {icon && <Icon size={20} name={icon} className="item-icon" />}
          <span>{title}</span>
        </NavLink>
      </Nav.Item>
    );
  }
  const rightIcon = collapsed ? 'chevron-down' : 'chevron-right';
  // TODO: highlight menu item when it's child is active
  return (
    <Nav.Item as="li" className="p-0 flex-column align-items-stretch">
      <a
        className="d-flex align-items-center"
        onClick={() => setCollapsed(!collapsed)}
        role="menuitem"
        tabIndex={-1}
      >
        {icon && <Icon size={22} name={icon} className="item-icon" />}
        <span className="flex-grow-1">{title}</span>
        <Icon name={rightIcon} />
      </a>
      <Collapse in={collapsed}>
        <Nav as="ul">{children}</Nav>
      </Collapse>
    </Nav.Item>
  );
};

const Footer = () => (
  <footer className={classNames('align-self-stretch p-3', styles.footer)}>
    Copyright &copy; 2019 Appota
  </footer>
);
