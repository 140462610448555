import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

let count = 0;

const DatePicker = ({
  size, value, maxDate, minDate, onChange, onFocus, ...rest
}) => {
  const [show, setShow] = useState(false);
  /**
   * @param {import("moment").Moment} d
   */
  const isOutsideRange = d => (minDate && d.isBefore(minDate, 'd')) || (maxDate && d.isAfter(maxDate, 'd'));
  const onFocusChange = ({ focused }) => {
    setShow(focused);
    // eslint-disable-next-line no-unused-expressions
    focused && onFocus && onFocus();
  };

  return (
    <SingleDatePicker
      numberOfMonths={1}
      small={size === 'sm'}
      focused={show}
      onFocusChange={onFocusChange}
      date={value ? moment(value) : null}
      id={`DatePicker-${++count}`}
      onDateChange={d => d && onChange && onChange(d)}
      firstDayOfWeek={1}
      isOutsideRange={isOutsideRange}
      hideKeyboardShortcutsPanel
      {...rest}
    />
  );
};

export default DatePicker;
