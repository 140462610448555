import React from 'react';
import {
  Modal, Button, Form, Alert,
} from 'react-bootstrap';

import useTempState from 'utils/useTempState';

import styles from './PaymentSetting.module.scss';

const UpdatePaymentConfirmModal = ({
  show, email, submitting, submitError, onSubmit, onCancel,
}) => {
  const [code, setCode] = useTempState('', [show]);

  return (
    <Modal dialogClassName={styles.modal} show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm update payment settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="info">
          {`A verify code has been sent to your email ${email}. Please check your inbox and input verify code here to continue:`}
        </Alert>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Verify Code"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
        </Form.Group>
        {submitError && (
          <Alert variant="danger">
            {submitError.message || submitError.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
        <Button variant="primary" disabled={submitting} onClick={() => onSubmit(code)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePaymentConfirmModal;
