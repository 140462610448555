
/* eslint-disable lines-between-class-members */
export default class User {
    static TYPE_UNREGISTERED = 0;
    static TYPE_ADMIN = 1;
    static TYPE_PUBLISHER = 2;

    static STATUS_PENDING = 1;
    static STATUS_ACTIVATED = 2;
    static STATUS_DEACTIVATED = 3;

    static ROLE_ADMIN = 'admin';

    static PermSystemSettingView = 'system.settings.view'
    static PermSystemSettingEdit = 'system.settings.edit'
    static PermAccountTeamView = 'account.team.view'
    static PermAccountTeamEdit = 'account.team.edit'
    static PermAccountRoleView = 'account.role.view'
    static PermAccountRoleEdit = 'account.role.edit'
    static PermAppView = 'app.view'
    static PermAppEdit = 'app.edit'
    static PermAppTagView = 'app.tag.view'
    static PermAppTagEdit = 'app.tag.edit'
    static PermReportView = 'report.view'
    static PermPubAll = 'pub.all'
    static PermPubInfoView = 'pub.info.view'
    static PermPubInfoEdit = 'pub.info.edit'
    static PermPubPaymentView = 'pub.payment.view'
    static PermPubPaymentEdit = 'pub.payment.edit'
    static PermPubSharingView = 'pub.sharing.view'
    static PermPubSharingEdit = 'pub.sharing.edit'
    static PermPubContractView = 'pub.contract.view'
    static PermPubContractEdit = 'pub.contract.edit'
    static PermPubInvoiceView = 'pub.invoice.view'
    static PermPubInvoiceEdit = 'pub.invoice.edit'
    static PermMonitorView = 'monitor.view';
    static PermMonitorEdit = 'monitor.edit';
    static PermMonitorAlertView = 'monitor.alert.view';
    static PermMonitorAlertEdit = 'monitor.alert.edit';

    constructor(user = {}) {
      Object.assign(this, user);
    }

    getAbbrName() {
      const parts = this.name.split(' ');
      if (parts.length > 1) {
        return parts[0][0] + parts[1][0];
      }
      return this.name.slice(0, 2);
    }

    isPending() {
      return this.status === User.STATUS_PENDING;
    }

    isActivated() {
      return this.status === User.STATUS_ACTIVATED;
    }

    isDeactivated() {
      return this.status === User.STATUS_DEACTIVATED;
    }

    isAdmin() {
      return this.role === User.ROLE_ADMIN;
    }
    static pubRoles = {
      admin: {
        name: 'Admin',
        desc: 'Full permissions.',
      },
      developer: {
        name: 'Developer',
        desc: 'Can create/edit applications and tags, view information of account, reports.',
        perms: [
          User.PermAppTagView, User.PermAppTagEdit, User.PermAppView, User.PermAppEdit,
          User.PermPubPaymentView, User.PermReportView,
          User.PermPubSharingView, User.PermPubContractView,
        ],
      },
      viewer: {
        name: 'Viewer',
        desc: 'Can view information of account, applications, invoices, and reports.',
        perms: [
          User.PermPubInvoiceView, User.PermPubContractView, User.PermPubSharingView,
          User.PermPubPaymentView, User.PermAppTagView, User.PermAppView, User.PermReportView,
        ],
      },
    }
    /**
     *  perm is array means all perm is required
     * @param  {...(string||[string])} perms
     */
    hasPermission(...perms) {
      if (this.role === User.ROLE_ADMIN) {
        return true;
      }
      if (!this.account || ((!this.account.roles || !this.account.roles[this.role]) && this.account.isAdmin())) {
        return false;
      }
      let userPerms = [];
      if (this.account.isPublisher()) {
        userPerms = User.pubRoles[this.role].perms;
      } else {
        userPerms = this.account.roles[this.role].perms;
      }
      for (let i = 0; i < perms.length; i++) {
        const p = perms[i];
        for (let j = 0; j < userPerms.length; j++) {
          if (typeof p === 'string' && p === userPerms[j]) {
            return true;
          }
          if (typeof p === 'object') {
            if (p.length === 0) {
              return true;
            }
            const pp = p.shift();
            if (!this.hasPermission(pp)) {
              return false;
            }
            return this.hasPermission(p);
          }
        }
      }

      return false;
    }
}
