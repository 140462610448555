import React from 'react';

import classNames from 'classnames';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Media } from 'tabler-react';
import { Link } from 'react-router-dom';
import SystemAlert from 'components/SystemAlert';

import { useModel } from 'models';

import styles from './PageHeader.module.scss';

/**
 * @param {Object} props
 * @param {BreadcrumbObject[]} props.breadcrumb
 */
const PageHeader = ({
  title, subTitle, logo, extraContent, breadcrumb,
}) => {
  const [{ systemAlerts }] = useModel('notification', state => ({
    systemAlerts: state.systemAlerts || [],
  }));

  return (
    <Row className={classNames(styles.wrapper, 'py-3')}>
      <Col>
        {systemAlerts.filter(a => a.positions.includes('global')).map(a => (
          <SystemAlert key={a.id} alert={a} />
        ))}
        {breadcrumb && (
        <Breadcrumb>
          {breadcrumb.map((b, i) => (
            <BreadcrumbItem key={i} href={b.href} active={b.active}>
              {b.title}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        )}
        <Media className="d-flex flex-column flex-sm-row">
          {logo}
          <Media.Body>
            <h1 className="m-0 page-title">{title}</h1>
            {subTitle && <p>{subTitle}</p>}
          </Media.Body>
          {extraContent}
        </Media>
      </Col>
    </Row>
  );
};

export default PageHeader;

const BreadcrumbItem = ({ href, children, active }) => (
  <li className={classNames('breadcrumb-item', { active })}>
    {href && <Link to={href}>{children}</Link>}
    {!href && <span>{children}</span>}
  </li>
);
