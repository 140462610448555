import React from 'react';
import {
  Card, Row, Col, Button,
} from 'react-bootstrap';
import classNames from 'classnames';

import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import Icon from 'components/Icon';

import unityIcon from 'static/images/unity.svg';
import styles from './Integration.module.scss';

const colProps = {
  // xs: 12,
  // sm: 6,
  // md: 4,
  // lg: 3,
  className: 'pb-5',
};

const Integration = () => (
  <MainLayout className={styles.wrapper}>
    <PageHeader title="Integration" subTitle="SDK integration" />
    <h3 className="mt-4 mb-2">Google Mobile Ads SDK</h3>
    <Row>
      <Col {...colProps}>
        <SDKCard
          platform="android"
          name="Google Ads Android SDK"
          desc="This guide shows you how to integrate the GMA SDK into a new Android app and use it to display a simple banner ad."
          docUrl="https://developers.google.com/ad-manager/mobile-ads-sdk/android/quick-start"
        />
      </Col>
      <Col {...colProps}>
        <SDKCard
          platform="ios"
          name="Google Ads iOS SDK"
          desc="This guide shows you how to integrate the GMA SDK into a new iOS app and use it to display a simple banner ad."
          docUrl="https://developers.google.com/ad-manager/mobile-ads-sdk/ios/quick-start"
        />
      </Col>
      <Col {...colProps}>
        <SDKCard
          platform="unity"
          name="Google Ads Unity Plugin"
          desc="While using Unity game engine, it is now supported plugin to serve ads to Android & iOS by GMA SDK."
          docUrl="https://developers.google.com/admob/unity/start"
        />
      </Col>
    </Row>
    <h3 className="mt-4 mb-2">Google Interactive Media Ads SDK</h3>
    <Row>
      <Col {...colProps}>
        <SDKCard
          platform="android"
          name="IMA Android SDK"
          desc="This guide shows you how to integrate the IMA SDK into a sample video player app."
          docUrl="https://developers.google.com/interactive-media-ads/docs/sdks/android/"
        />
      </Col>
      <Col {...colProps}>
        <SDKCard
          platform="ios"
          name="IMA iOS SDK"
          desc="The Google IMA SDK allows developers to request and track VAST ads in a cast-enabled video environment."
          docUrl="https://developers.google.com/interactive-media-ads/docs/sdks/ios/quickstart"
        />
      </Col>
      <Col {...colProps}>
        <SDKCard
          platform="tvos"
          name="IMA tvOS SDK"
          desc="The IMA Client-Side tvOS SDK allows native application developers to request and track VAST ads in the tvOS environment."
          docUrl="https://developers.google.com/interactive-media-ads/docs/sdks/tvos-client-side/"
        />
      </Col>
      <Col {...colProps}>
        <SDKCard
          platform="html5"
          name="IMA HTML 5 SDK"
          desc="This guide shows you how to integrate the IMA SDK into a web page with HTML5 video player."
          docUrl="https://developers.google.com/interactive-media-ads/docs/sdks/html5/quickstart"
        />
      </Col>
      <Col {...colProps}>
        <SDKCard
          platform="cast"
          name="IMA SDK and Google Cast"
          desc="The Google IMA SDK allows developers to request and track VAST ads in a cast-enabled video environment."
          docUrl="https://developers.google.com/interactive-media-ads/docs/sdks/cast/client-side/"
        />
      </Col>
    </Row>
  </MainLayout>
);

export default Integration;

const configs = {
  android: {
    icon: 'android',
  },
  ios: {
    icon: 'apple',
  },
  tvos: {
    icon: 'tv',
  },
  html5: {
    icon: 'html5',
  },
  cast: {
    icon: 'Chromecast',
  },
  unity: {
    icon: <img className="icon" src={unityIcon} alt="" />,
  },
};

const SDKCard = ({
  platform, name, desc, docUrl,
}) => (
  <Card className={classNames(styles.sdkCard, platform)}>
    <Card.Header className="justify-content-center">
      {typeof configs[platform].icon === 'string' ? <Icon className="icon" size={64} type="fa" name={configs[platform].icon} /> : configs[platform].icon }
    </Card.Header>
    <Card.Body className="d-flex flex-column">
      <h4>{name}</h4>
      <p>{desc}</p>
      <Button className="mt-auto" block href={docUrl} target="_blank" variant="outline-primary">Get Started</Button>
    </Card.Body>
  </Card>
);
