import React from 'react';
import { Redirect } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import pickBy from 'lodash.pickby';

const Invitation = ({ match, location }) => {
  const { code } = match.params;
  const { email } = parse(location.search);
  const query = stringify(pickBy({ code, email }));

  return <Redirect to={{ pathname: '/login', search: query }} />;
};

export default Invitation;
