/* eslint-disable max-classes-per-file */
import { formatNumber } from 'utils/intl';

const metricNames = {
  // common
  PROFIT: 'Profit',

  // fan
  FAN_REVENUE: 'Estimated revenue',
  FAN_PUB_REVENUE: "Estimated pub's revenue",
  FAN_CLICK: 'Clicks',
  FAN_REQUEST: 'Requests',
  FAN_FILLED_REQUEST: 'Filled requests',
  FAN_IMPRESSION: 'Impressions',
  FAN_ECPM: 'eCPM',
  FAN_FILL_RATE: 'Fill rate',
  FAN_SHOW_RATE: 'Show rate',
  FAN_CTR: 'CTR',

  // fan page
  IMPRESSIONS: 'Impressions',
  IMPRESSIONS_CROSSPOSTED: 'Impressions crossposted',
  GROSS_CPM: 'Gross CPM',
  GROSS_CPM_CROSSPOSTED: 'Gross CPM crossposted',
  EARNINGS_CROSSPOSTED: 'Estimated revenue crossposted',
  PUB_EARNINGS: "Estimated pub's revenue",

  // adx
  AD_REQUESTS: 'Requests',
  MATCHED_AD_REQUESTS: 'Matched requests',
  AD_IMPRESSIONS: 'Impressions',
  CLICKS: 'Clicks',
  EARNINGS: 'Estimated revenue',
  ADX_PUBLISHER_EARNINGS: "Estimated pub's revenue",
  ADX_ECPM: 'eCPM',
  ADX_REQUEST_CTR: 'CTR',
  REFERRER_EARNINGS: 'Referrer Rev',
  REFERRAL_EARNINGS: 'Referral Rev',
  REFEREE_EARNINGS: 'Referee Rev',
  REFERRER_TOTAL: 'Total Referrers',
  REFEREE_TOTAL: 'Total Referees',
  REFERRAL_PAID: 'Paid Referral Rev',
  ADX_COVERAGE: 'Coverage',
  ADX_SHOW_RATE: 'Show rate',
  // deduction
  _AD_IMPRESSIONS: 'Deducted Impressions',
  _CLICKS: 'Deducted Clicks',
  _EARNINGS: 'Deducted Rev',

  // Admob
  ESTIMATED_EARNINGS: 'Estimated revenue',
  MATCHED_REQUESTS: 'Matched requests',
  IMPRESSION_CTR: 'Impression CTR',
  IMPRESSION_RPM: 'eCPM',
  MATCH_RATE: 'Match rate',
  SHOW_RATE: 'Show Rate',
};

const dimensionNames = {
  DATE: 'date',
  WEEK: 'week',
  MONTH: 'month',
  ADX_PUBLISHER_ID: 'Publisher ID',
  ADX_PUBLISHER_NAME: 'Publisher name',
  ADX_APP_ID: 'App ID',
  ADX_APP_NAME: 'App name',
  AD_TAG_CODE: 'Tag code',
  AD_TAG_NAME: 'Tag name',
  COUNTRY_CODE: 'Country',
  ADX_REFERRER_ID: 'Referrer ID',
  ADX_REFERRER_NAME: 'Referrer Name',
  ADX_REFEREE_ID: 'Referee ID',
  ADX_REFEREE_NAME: 'Referee Name',
  FAN_PAGE_ID: 'Page ID',
  FAN_PAGE_NAME: 'Page name',
  FAN_PUB_ID: 'Publisher ID',
  FAN_PUB_NAME: 'Publisher name',
  FAN_APP_ID: 'App ID',
  FAN_APP_NAME: 'App name',
  APP_NAME: 'App name',
  APP_ID: 'App ID',
};

export class FANMetric {
  FAN_REQUEST = 0;
  FAN_FILLED_REQUEST = 0;
  FAN_IMPRESSION = 0;
  FAN_CLICK = 0;
  FAN_REVENUE = 0;
  FAN_PUB_REVENUE = 0;

  static METRIC_TYPES = [
    'FAN_REQUEST',
    'FAN_FILLED_REQUEST',
    'FAN_FILL_RATE',
    'FAN_IMPRESSION',
    'FAN_CLICK',
    'FAN_CTR',
    'FAN_ECPM',
    'FAN_REVENUE',
    'FAN_PUB_REVENUE',
  ];

  constructor(init = {}) {
    this.add(init);
  }

  add(data) {
    Object.keys(data).forEach(k => {
      if (this[k] !== undefined) {
        this[k] += data[k];
      }
    });
  }

  get FAN_FILL_RATE() {
    return this.FAN_FILLED_REQUEST / this.FAN_REQUEST;
  }

  get FAN_SHOW_RATE() {
    return this.FAN_IMPRESSION / this.FAN_FILLED_REQUEST;
  }

  get FAN_ECPM() {
    return this.FAN_REVENUE === 0 ? 0 : (this.FAN_REVENUE / this.FAN_IMPRESSION) * 1000;
  }

  get FAN_CTR() {
    return this.FAN_CLICK / this.FAN_IMPRESSION;
  }

  get PROFIT() {
    return this.FAN_REVENUE - this.FAN_PUB_REVENUE;
  }

  /**
   * @param {FANMetric} prev
   */
  getChange(prev) {
    return {
      FAN_REQUEST: (this.FAN_REQUEST - prev.FAN_REQUEST) / prev.FAN_REQUEST,
      FAN_FILLED_REQUEST:
        (this.FAN_FILLED_REQUEST - prev.FAN_FILLED_REQUEST) / prev.FAN_FILLED_REQUEST,
      FAN_IMPRESSION: (this.FAN_IMPRESSION - prev.FAN_IMPRESSION) / prev.FAN_IMPRESSION,
      FAN_CLICK: (this.FAN_CLICK - prev.FAN_CLICK) / prev.FAN_CLICK,
      FAN_REVENUE: (this.FAN_REVENUE - prev.FAN_REVENUE) / prev.FAN_REVENUE,
      FAN_PUB_REVENUE: (this.FAN_PUB_REVENUE - prev.FAN_PUB_REVENUE) / prev.FAN_PUB_REVENUE,
      FAN_FILL_RATE: (this.FAN_FILL_RATE - prev.FAN_FILL_RATE) / prev.FAN_FILL_RATE,
      FAN_SHOW_RATE: (this.FAN_SHOW_RATE - prev.FAN_SHOW_RATE) / prev.FAN_SHOW_RATE,
      FAN_ECPM: (this.FAN_ECPM - prev.FAN_ECPM) / prev.FAN_ECPM,
      FAN_CTR: (this.FAN_CTR - prev.FAN_CTR) / prev.FAN_CTR,
      PROFIT: (this.PROFIT - prev.PROFIT) / prev.PROFIT,
    };
  }

  /**
   * @param {FANMetricType} metric
   * @param {Intl.NumberFormatOptions} opts
   */
  format(metric, opts) {
    return formatMetric(metric, this[metric], opts);
  }
}

export class ADXMetric {
  AD_REQUESTS = 0;
  MATCHED_AD_REQUESTS = 0;
  AD_IMPRESSIONS = 0;
  CLICKS = 0;
  EARNINGS = 0;
  ADX_PUBLISHER_EARNINGS = 0;
  // deduction
  _AD_IMPRESSIONS = 0;
  _CLICKS = 0;
  _EARNINGS = 0;

  static METRIC_TYPES = [
    'AD_REQUESTS',
    'MATCHED_AD_REQUESTS',
    'AD_IMPRESSIONS',
    'CLICKS',
    'EARNINGS',
    'ADX_REQUEST_CTR',
    'ADX_ECPM',
    'ADX_PUBLISHER_EARNINGS',
  ];

  get ADX_REQUEST_CTR() {
    return this.CLICKS / this.MATCHED_AD_REQUESTS;
  }

  get ADX_IMPRESSION_CTR() {
    return this.CLICKS / this.AD_IMPRESSIONS;
  }

  get ADX_CPC() {
    return this.EARNINGS / this.CLICKS;
  }

  get ADX_ECPM() {
    return this.EARNINGS === 0 ? 0 : (this.EARNINGS / this.AD_IMPRESSIONS) * 1000;
  }

  get PROFIT() {
    return this.EARNINGS - this.ADX_PUBLISHER_EARNINGS;
  }

  get ADX_COVERAGE() {
    return this.MATCHED_AD_REQUESTS / this.AD_REQUESTS;
  }

  get ADX_SHOW_RATE() {
    return this.AD_IMPRESSIONS / this.MATCHED_AD_REQUESTS;
  }

  constructor(init = {}) {
    this.add(init);
  }

  add(data) {
    Object.keys(data).forEach(k => {
      let kk = k;
      if (k.substr(0, 1) === '-') {
        kk = `_${k.substr(1)}`;
      }
      // temporary fix: ADX_PUBLISHER_EAERNINGS can be < 0
      if (data[k] < 0) {
        data[k] = Math.abs(data[k]);
      }
      if (this[kk] !== undefined) {
        this[kk] += data[k];
      }
    });
  }

  /**
   * @param {ADXMetricType} metric
   * @param {Intl.NumberFormatOptions} opts
   */
  format(metric, opts) {
    return formatMetric(metric, this[metric], opts);
  }

  /**
   * @param {ADXMetric} prev
   */
  getChange(prev) {
    return {
      AD_REQUESTS: (this.AD_REQUESTS - prev.AD_REQUESTS) / prev.AD_REQUESTS,
      AD_IMPRESSIONS: (this.AD_IMPRESSIONS - prev.AD_IMPRESSIONS) / prev.AD_IMPRESSIONS,
      CLICKS: (this.CLICKS - prev.CLICKS) / prev.CLICKS,
      EARNINGS: (this.EARNINGS - prev.EARNINGS) / prev.EARNINGS,
      MATCHED_AD_REQUESTS:
        (this.MATCHED_AD_REQUESTS - prev.MATCHED_AD_REQUESTS) / prev.MATCHED_AD_REQUESTS,
      FAN_SHOW_RATE: (this.FAN_SHOW_RATE - prev.FAN_SHOW_RATE) / prev.FAN_SHOW_RATE,
      ADX_ECPM: (this.ADX_ECPM - prev.ADX_ECPM) / prev.ADX_ECPM,
      ADX_REQUEST_CTR: (this.ADX_REQUEST_CTR - prev.ADX_REQUEST_CTR) / prev.ADX_REQUEST_CTR,
      ADX_IMPRESSION_CTR:
        (this.ADX_IMPRESSION_CTR - prev.ADX_IMPRESSION_CTR) / prev.ADX_IMPRESSION_CTR,
      ADX_CPC: (this.ADX_CPC - prev.ADX_CPC) / prev.ADX_CPC,
      ADX_PUBLISHER_EARNINGS:
        (this.ADX_PUBLISHER_EARNINGS - prev.ADX_PUBLISHER_EARNINGS) / prev.ADX_PUBLISHER_EARNINGS,
      PROFIT: (this.PROFIT - prev.PROFIT) / prev.PROFIT,
      ADX_COVERAGE: (this.ADX_COVERAGE - prev.ADX_COVERAGE) / prev.ADX_COVERAGE,
      ADX_SHOW_RATE: (this.ADX_SHOW_RATE - prev.ADX_SHOW_RATE) / prev.ADX_SHOW_RATE,
    };
  }
}

export class AdmobMetric {
  AD_REQUESTS = 0;
  MATCHED_REQUESTS = 0;
  IMPRESSIONS = 0;
  CLICKS = 0;
  ESTIMATED_EARNINGS = 0;
  ADX_PUBLISHER_EARNINGS = 0;

  static METRIC_TYPES = [
    'AD_REQUESTS',
    'MATCHED_REQUESTS',
    'IMPRESSIONS',
    'CLICKS',
    'ESTIMATED_EARNINGS',
    'IMPRESSION_CTR',
    'IMPRESSION_RPM',
    'MATCH_RATE',
    'SHOW_RATE',
    'ADX_PUBLISHER_EARNINGS',
  ];

  get IMPRESSION_CTR() {
    return this.CLICKS / this.IMPRESSIONS;
  }

  get IMPRESSION_RPM() {
    return this.ESTIMATED_EARNINGS === 0 ? 0 : (this.ESTIMATED_EARNINGS / this.IMPRESSIONS) * 1000;
  }

  get PROFIT() {
    return this.ESTIMATED_EARNINGS - this.ADX_PUBLISHER_EARNINGS;
  }

  get MATCH_RATE() {
    return this.MATCHED_REQUESTS / this.AD_REQUESTS;
  }

  get SHOW_RATE() {
    return this.IMPRESSIONS / this.MATCHED_REQUESTS;
  }

  constructor(init = {}) {
    this.add(init);
  }

  add(data) {
    Object.keys(data).forEach(k => {
      let kk = k;
      if (k.substr(0, 1) === '-') {
        kk = `_${k.substr(1)}`;
      }
      if (this[kk] !== undefined) {
        this[kk] += data[k];
      }
    });
  }

  format(metric, opts) {
    return formatMetric(metric, this[metric], opts);
  }

  /**
   * @param {AdmobMetric} prev
   */
  getChange(prev) {
    return AdmobMetric.METRIC_TYPES.reduce(
      (c, v) => ({ ...c, [v]: (this[v] - prev[v]) / prev[v] }),
      {},
    );
  }
}

export class FANPageMetric {
  IMPRESSIONS = 0;
  IMPRESSIONS_CROSSPOSTED = 0;
  GROSS_CPM_CROSSPOSTED = 0;
  GROSS_CPM = 0;
  EARNINGS = 0;
  PUB_EARNINGS = 0;

  static METRIC_TYPES = [
    'IMPRESSIONS',
    'IMPRESSIONS_CROSSPOSTED',
    'GROSS_CPM_CROSSPOSTED',
    'GROSS_CPM',
    'EARNINGS',
    'PUB_EARNINGS',
  ];

  constructor(init = {}) {
    this.add(init);
  }

  add(data) {
    Object.keys(data).forEach(k => {
      if (this[k] !== undefined) {
        this[k] += data[k];
      }
      if (k === 'EARNINGS_CROSSPOSTED') {
        this.EARNINGS += data[k];
      }
    });
  }

  get PROFIT() {
    return this.EARNINGS - this.PUB_EARNINGS;
  }

  /**
   * @param {ADXMetricType} metric
   * @param {Intl.NumberFormatOptions} opts
   */
  format(metric, opts) {
    return formatMetric(metric, this[metric], opts);
  }

  /**
   * @param {ADXMetric} prev
   */
  getChange(prev) {
    return {
      IMPRESSIONS: (this.IMPRESSIONS - prev.IMPRESSIONS) / prev.IMPRESSIONS,
      IMPRESSIONS_CROSSPOSTED:
        (this.IMPRESSIONS_CROSSPOSTED - prev.IMPRESSIONS_CROSSPOSTED)
        / prev.IMPRESSIONS_CROSSPOSTED,
      GROSS_CPM: (this.GROSS_CPM - prev.GROSS_CPM) / prev.GROSS_CPM,
      GROSS_CPM_CROSSPOSTED:
        (this.GROSS_CPM_CROSSPOSTED - prev.GROSS_CPM_CROSSPOSTED) / prev.GROSS_CPM_CROSSPOSTED,
      EARNINGS: (this.EARNINGS - prev.EARNINGS) / prev.EARNINGS,
      EARNINGS_CROSSPOSTED:
        (this.EARNINGS_CROSSPOSTED - prev.EARNINGS_CROSSPOSTED) / prev.EARNINGS_CROSSPOSTED,
      PUB_EARNINGS: (this.PUB_EARNINGS - prev.PUB_EARNINGS) / prev.PUB_EARNINGS,
      PROFIT: (this.PROFIT - prev.PROFIT) / prev.PROFIT,
    };
  }
}

/**
 * @param {string} metric
 * @param {Number} value
 * @param {Intl.NumberFormatOptions} opts
 */
export function formatMetric(metric, value, opts) {
  switch (metric) {
    case 'FAN_FILL_RATE':
    case 'FAN_SHOW_RATE':
    case 'FAN_CTR':
    case 'ADX_REQUEST_CTR':
    case 'ADX_IMPRESSION_CTR':
    case 'ADX_COVERAGE':
    case 'ADX_SHOW_RATE':
    case 'MATCH_RATE':
    case 'SHOW_RATE':
    case 'IMPRESSION_CTR':
      opts = { style: 'percent', maximumFractionDigits: 2, ...opts };
      break;
    case 'FAN_ECPM':
    case 'FAN_REVENUE':
    case 'FAN_PUB_REVENUE':
    case 'ADX_ECPM':
    case 'EARNINGS':
    case '_EARNINGS':
    case 'ADX_PUBLISHER_EARNINGS':
    case 'GROSS_CPM':
    case 'GROSS_CPM_CROSSPOSTED':
    case 'EARNINGS_CROSSPOSTED':
    case 'PUB_EARNINGS':
    case 'PROFIT':
    case 'ESTIMATED_EARNINGS':
    case 'IMPRESSION_RPM':
      opts = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        ...opts,
      };
      break;
    default:
      opts = { style: 'decimal', maximumFractionDigits: 0, ...opts };
  }

  if (opts.style === 'percent' && value > 1e12) {
    value = +Infinity;
    opts.round = false;
  }

  let subfix = '';
  if (opts.round) {
    if (value > 1e9) {
      value /= 1e9;
      subfix = ' B';
    } else if (value > 1e6) {
      value /= 1e6;
      subfix = ' M';
    } else if (value > 1000) {
      value /= 1000;
      subfix = ' K';
    }
    delete opts.round;
  }
  if (value > 1e9 && opts.maximumFractionDigits) {
    opts.maximumFractionDigits = 0;
    opts.minimumFractionDigits = 0;
  }

  let formatted = formatNumber(value, opts) + subfix;
  if (subfix && opts.style === 'percent') {
    formatted = formatted.replace(`%${subfix}`, `${subfix}%`);
  }

  return formatted;
}

const metaDimensions = {
  FAN_APP_ID: 'FAN_APP_NAME',
  FAN_PUB_ID: 'FAN_PUB_NAME',
  ADX_APP_ID: 'ADX_APP_NAME',
  ADX_PUBLISHER_ID: 'ADX_PUBLISHER_NAME',
  AD_TAG_CODE: ['ADX_AD_TAG_NAME', 'AD_TAG_NAME', 'ADX_APP_ID'],
  FAN_PAGE_ID: 'FAN_PAGE_NAME',
  APP_ID: ['APP_NAME'],
};

export const asyncAggregateReports = async (
  reports,
  MetricClass,
  group,
  partitionFunc,
  threshold = 1000,
) => {
  const recursiveAggregate = (prev, rows) => new Promise(resolve => {
    const r = { ...reports, rows: rows.slice(0, threshold) };
    const next = aggregateReports(prev, r, MetricClass, group, partitionFunc);
    if (rows.length === 0) {
      resolve(next);
      return;
    }
    setTimeout(() => {
      recursiveAggregate(next, rows.slice(threshold)).then(resolve);
    });
  });
  return recursiveAggregate({}, reports.rows || []);
};

/**
 * @param {{headers: String[], rows: any[]}} reports
 * @param {(row:{}) => string} partitionFunc return partition name
 * @param {Object.<string, string>} group
 * @returns {Object}
 */
export function aggregateReports(prevData = {}, reports = {}, MetricClass, group, partitionFunc) {
  if (!reports.headers || !group) return null;
  const data = { ...prevData };

  Object.keys(group).forEach(k => {
    if (!data[k] && (group[k] === '*' || reports.headers.find(h => h === group[k]))) {
      data[k] = {};
    }
  });

  reports.rows.forEach(r => {
    r = r.reduce((c, v, i) => ({ ...c, [reports.headers[i]]: v }), {});

    let pkey = 'metrics';
    if (partitionFunc) {
      pkey = partitionFunc(r);
      if (!pkey) return;
    }

    Object.keys(data).forEach(k => {
      if (group[k] === '*') {
        if (!data[k][pkey]) {
          data[k][pkey] = new MetricClass();
        }
        data[k][pkey].add(r);
        return;
      }

      let groupId = r[group[k]];
      if (!groupId) {
        groupId = '_empty_'; // TODO: use Symbol
      }
      if (!data[k][groupId]) {
        data[k][groupId] = {
          [group[k]]: groupId,
        };
      }
      if (!data[k][groupId][pkey]) {
        data[k][groupId][pkey] = new MetricClass();
      }
      data[k][groupId][pkey].add(r);
      let metaDim = metaDimensions[group[k]];
      if (metaDim) {
        if (typeof metaDim === 'string') {
          metaDim = [metaDim];
        }
        metaDim.forEach(d => {
          if (r[d]) {
            data[k][groupId][d] = r[d];
          }
        });
      }
    });
  });
  return data;
}

export function getMetricName(metric) {
  return metricNames[metric];
}

export function getDimensionName(dim) {
  return dimensionNames[dim];
}
