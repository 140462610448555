import React from 'react';
import { Card, Loader } from 'tabler-react';
import {
  // InputGroup,
  // FormControl,
  Form,
  Media,
  ListGroup,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import classNames from 'classnames';
import Scrollbar from 'react-scrollbars-custom';

import defaultIcon from 'static/images/app-icon-default.png';
import Icon from '../Icon';
import styles from './FlexLayoutCard.module.scss';

const FlexLayoutCard = ({
  className,
  total,
  loading,
  data,
  onBack,
  onFetchMore,
  showDetail,
  sidebar,
  main,
  extraContent,
  headerExtra,
}) => (
  <Card className={classNames(styles.card, className, { 'with-detail': showDetail })}>
    <Card.Header>
      <Card.Title>
        <Button className="back-btn" variant="link" onClick={onBack}>
          <Icon name="arrow-left">Back</Icon>
        </Button>
        <span className="total">{`Total: ${total}`}</span>
        {headerExtra}
      </Card.Title>
      <Card.Options>{extraContent}</Card.Options>
    </Card.Header>
    <Card.Body className="d-flex p-0">
      <Row noGutters className="w-100 flex-grow-1">
        <Col className={styles.list} sm={12} md={5} lg={4} xl={3}>
          <Scrollbar
            data-slideout-ignore
            noScrollX
            style={{ width: '100%', height: '100%' }}
            onScroll={({ scrollTop, scrollHeight, clientHeight }) => {
              const bottomReached = scrollTop >= scrollHeight - clientHeight - 200;
              if (bottomReached && total > data.length && !loading) {
                onFetchMore();
              }
            }}
          >
            <ListGroup variant="flush">{sidebar}</ListGroup>
          </Scrollbar>
        </Col>
        <Col className="card-body-main" sm={12} md={7} lg={8} xl={9}>
          {main}
        </Col>
      </Row>
      {data.length === 0 && loading && <Loader className="card-loading" />}
    </Card.Body>
  </Card>
);

/* eslint-disable jsx-a11y/anchor-is-valid */
FlexLayoutCard.RowItem = ({
  icon,
  name,
  active,
  onClick,
  onSelectedChange,
  selected,
  selectable,
}) => (
  <ListGroup.Item onClick={onClick} action className="pl-3 pr-5 py-2" active={active}>
    <Media className={classNames(styles.rowItem, 'align-items-center')}>
      {selectable && (
        <Form.Check
          id={name}
          type="checkbox"
          checked={selected}
          onClick={e => e.stopPropagation()}
          onChange={e => onSelectedChange(e.target.checked)}
        />
      )}
      <img className="icon mr-2 rounded" src={icon || defaultIcon} alt="" />
      <Media.Body>
        <a className="text-decoration-none" title={typeof name === 'string' ? name : null}>
          {name}
        </a>
      </Media.Body>
    </Media>
  </ListGroup.Item>
);

export default FlexLayoutCard;
