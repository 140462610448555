import update from 'immutability-helper';

import userService from 'services/user';
import User from 'entities/User';
import Account from 'entities/Account';
import configs from 'configs/configs';

export default {
  name: 'auth',
  state: {
    user: null,
    account: null,
    authorized: false,
    error: null,
    login: {},
    invitations: [],
    update: { loading: false, error: null },
    getInvitations: {},
    sendInvite: {},
    register: {},
    addRole: {},
    updateRole: {},
    updateMember: {},
    generateApiKey: {
      loading: false,
      error: null,
    },
  },
  reducers: {
    getCurrentUser: (state, payload, effectStatus) => {
      switch (effectStatus) {
        case 'success': {
          const { account, user } = payload;
          const acc = new Account(account);
          return {
            user: new User({ ...user, account: acc }),
            account: acc,
            authorized: true,
            error: null,
          };
        }
        case 'error':
          return {
            error: payload,
          };
        default:
          return state;
      }
    },
    login: (state, payload, effectStatus) => {
      switch (effectStatus) {
        case 'success': {
          const { account, user } = payload;
          const acc = new Account(account);
          return {
            account: acc,
            user: new User(user),
            authorized: !acc.isUnregistered(),
            login: { loading: false, error: null },
            register: { ...state.register, inprogress: acc.isUnregistered() },
          };
        }
        case 'error':
          return {
            login: { loading: false, error: payload },
          };
        default:
          return {
            login: { loading: true, error: null },
          };
      }
    },
    update: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            account: new Account(payload),
            update: { loading: false, error: null },
          };
        case 'error':
          return {
            update: { loading: false, error: payload },
          };
        default:
          return {
            update: { loading: true, error: null },
          };
      }
    },
    getInvitations: (state, payload, status) => {
      switch (status) {
        case 'success':
          return { invitations: [...payload.invitations] };
        default:
          return state;
      }
    },
    sendInvite: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            invitations: [...state.invitations, payload],
            sendInvite: { loading: false, error: null },
          };
        case 'error':
          return {
            sendInvite: { loading: false, error: payload },
          };
        default:
          return {
            sendInvite: { loading: true, error: null },
          };
      }
    },
    register: (state, payload, status) => {
      switch (status) {
        case 'success': {
          const { request, response } = payload;
          return {
            register: {
              ...state.register,
              loading: false,
              inprogress: !!request.resume || !!request.checkAppotaId,
              error: null,
            },
            account: response.id ? new Account(response) : null,
            user: request.resume || request.checkAppotaId ? new User(response.appotaUser) : null,
          };
        }
        case 'error': {
          const { request, error } = payload;
          return {
            register: {
              loading: false,
              inprogress: false,
              error: request.resume ? null : error,
            },
          };
        }
        default:
          return {
            register: {
              ...state.register,
              loading: !payload.resume ? true : state.register.loading,
              error: null,
            },
          };
      }
    },
    updateRole: (_, payload, status) => {
      switch (status) {
        case 'success': {
          return {
            account: new Account(payload),
            updateRole: {
              loading: false,
              error: null,
            },
          };
        }
        case 'error': {
          return {
            updateRole: {
              loading: false,
              error: payload,
            },
          };
        }
        default:
          return {
            updateRole: {
              loading: true,
              error: null,
            },
          };
      }
    },
    addRole: (_, payload, status) => {
      switch (status) {
        case 'success': {
          return {
            account: new Account(payload),
            addRole: {
              loading: false,
              error: null,
            },
          };
        }
        case 'error': {
          return {
            addRole: {
              loading: false,
              error: payload,
            },
          };
        }
        default:
          return {
            addRole: {
              loading: true,
              error: null,
            },
          };
      }
    },
    updateMember: (state, payload, status) => {
      switch (status) {
        case 'success': {
          const memberIndex = state.account.team.findIndex((m) => m.id === payload.id);
          return {
            account: new Account(
              update(state.account, { team: { [memberIndex]: { $set: payload } } }),
            ),
            updateMember: {
              ...state.updateMember,
              [payload.memberId]: {
                loading: false,
                error: null,
              },
            },
          };
        }
        case 'error': {
          return {
            updateMember: {
              ...state.updateMember,
              [payload.memberId]: {
                loading: false,
                error: payload.error,
              },
            },
          };
        }
        default:
          return {
            updateMember: {
              ...state.updateMember,
              [payload.memberId]: {
                loading: true,
                error: null,
              },
            },
          };
      }
    },
    generateApiKey: (state, payload, status) => {
      switch (status) {
        case 'success':
          const { apiKey } = payload;
          return {
            ...state,
            account: new Account({ ...state.account, apiKey }),
            generateApiKey: { loading: false, error: null },
          };
        case 'error':
          return {
            ...state,
            generateApiKey: {
              loading: false,
              error: payload,
            },
          };

        default:
          return {
            ...state,
            generateApiKey: {
              loading: true,
              error: null,
            },
          };
      }
    },
  },
  effects: {
    async getCurrentUser(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.me());
      } catch (e) {
        onError(e);
      }
    },
    async login(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.login(payload));
      } catch (e) {
        onError(e);
      }
    },
    async logout() {
      try {
        await userService.logout();
        window.location.replace('/');
      } catch (e) {
        console.log('logout failed: ', e);
      }
    },
    async update(payload, onSuccess, onError) {
      const id = this.getState((state) => state.account.id);
      try {
        onSuccess(await userService.updateAccount(id, payload));
      } catch (e) {
        onError(e);
      }
    },
    async sendInvite(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.sendInvite(payload));
      } catch (e) {
        onError(e);
      }
    },
    async getInvitations(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.getInvitations(payload));
      } catch (e) {
        onError(e);
      }
    },
    async register(payload, onSuccess, onError) {
      try {
        let captcha = '';
        if (!payload.resume) {
          captcha = await window.grecaptcha.execute(configs.RECAPTCHA_SITE_KEY, {
            action: 'register',
          });
        }

        const response = await userService.register({ ...payload, captcha });
        onSuccess({ request: payload, response });
      } catch (e) {
        onError({ request: payload, error: e });
      }
    },
    async updateRole(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.updateRole(payload));
      } catch (e) {
        onError(e);
      }
    },
    async addRole(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.addRole(payload));
      } catch (e) {
        onError(e);
      }
    },
    async updateMember({ accountId, memberId, data }, onSuccess, onError) {
      try {
        const response = await userService.updateMember(accountId, memberId, data);
        onSuccess({ ...response, memberId });
      } catch (e) {
        onError({ memberId, error: e });
      }
    },
    async generateApiKey({ id }, onSuccess, onError) {
      try {
        const res = await userService.generateApiKey(id);
        onSuccess({ ...res, id });
      } catch (e) {
        onError(e);
      }
    },
  },
  actions: {
    getCurrentUser: () => {},
    login: (params) => params,
    logout: () => {},
    update: (data) => data,
    sendInvite: (data) => data,
    getInvitations: () => {},
    register: (params) => params,
    updateRole: (params) => params,
    addRole: (params) => params,
    updateMember: (accountId, memberId, data) => ({ accountId, memberId, data }),
    generateApiKey: (id) => ({ id }),
  },
};
