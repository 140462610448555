import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import { Dimmer } from 'tabler-react';
import { ListGroup, Media, Button } from 'react-bootstrap';
import classNames from 'classnames';

import EmptyState from 'components/EmptyState';
import Icon from 'components/Icon';

import { useModel } from 'models';

import styles from './PublisherPages.module.scss';
import defaultIcon from 'static/images/app-icon-default.png';

const pagesSelector = ({ listPages, pages }) => ({
  pages: listPages.ids.map(i => pages[i]),
  total: listPages.total,
  loading: listPages.loading,
});

const PublisherPages = ({ account }) => {
  const [{ total, pages, loading }, { getListPages }] = useModel('page', pagesSelector);

  useEffect(() => {
    getListPages({ limit: 30, skip: 0, accountId: account.id });
  }, []);

  /* eslint-disable jsx-a11y/anchor-is-valid */
  const renderPage = page => (
    <ListGroup.Item key={page.id}>
      <Media className={classNames(styles.rowItem, 'align-items-center')}>
        <img className="icon mr-2 rounded" src={page.icon || defaultIcon} alt="" />
        <Media.Body>
          <div>
            <Link className="text-decoration-none" title={page.name} to={`/pages/${page.id}`}>
              {page.name}
            </Link>
            <div>{page.getStatusBadge()}</div>
          </div>
          <Link to={`/page-reports?breakdown=page&pgid=${page.id}`}>
            <Button size="sm">
              <Icon name="chart-bar" type="fa" size={16} />
            </Button>
          </Link>
        </Media.Body>
      </Media>
    </ListGroup.Item>
  );

  return (
    <div className="h-100">
      <Scrollbar
        noScrollX
        style={{ width: '100%', height: '100%' }}
        onScroll={({ scrollTop, scrollHeight, clientHeight }) => {
          const bottomReached = scrollTop >= scrollHeight - clientHeight - 200;
          if (bottomReached && total > pages.length && !loading) {
            getListPages({ limit: 30, skip: pages.length, accountId: account.id });
          }
        }}
      >
        <Dimmer active={loading} loader>
          <ListGroup variant="flush">
            <>
              {pages.map(renderPage)}
              {pages.length === 0 && (
                <EmptyState className="mt-5" icon="search" title="No data" desc="No page to show" />
              )}
            </>
          </ListGroup>
        </Dimmer>
      </Scrollbar>
    </div>
  );
};

export default PublisherPages;
