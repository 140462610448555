import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { Nav } from 'react-bootstrap';
import moment from 'moment';

const DRP = ({
  className, startDate, endDate, onChange, onClearDate, maxDate, minDate, maxRange, ...rest
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [range, setRange] = useState([startDate, endDate]);
  const onDateChange = (v, quickSelect) => {
    setRange([v.startDate, v.endDate]);
    if (quickSelect) {
      setFocusedInput(null);
    }
    if ((quickSelect || focusedInput === 'endDate') && v.startDate && v.endDate && onChange) {
      onChange(v.startDate, v.endDate);
    }
    if (!v.startDate && !v.endDate && onClearDate) {
      onClearDate();
    }
  };

  /**
   * @param {import("moment").Moment} d
   */
  const isOutsideRange = (d) => {
    const [sDate] = range;
    return (maxRange && sDate && d.diff(sDate, 'days') > maxRange) || (minDate && d.isBefore(minDate, 'd')) || (maxDate && d.isAfter(maxDate, 'd'));
  };

  const quickSelect = () => {
    const today = moment();
    const quickRange = [
      {
        key: 'today',
        title: 'Today',
        range: { startDate: today.clone(), endDate: today.clone() },
      },
      {
        key: 'yesterday',
        title: 'Yesterday',
        range: { startDate: today.clone().subtract(1, 'd'), endDate: today.clone().subtract(1, 'd') },
      },
      {
        key: '7days',
        title: 'Last 7 days',
        range: { startDate: today.clone().subtract(6, 'd'), endDate: today.clone() },
      },
      {
        key: '14day',
        title: 'Last 14 days',
        range: { startDate: today.clone().subtract(13, 'd'), endDate: today.clone() },
      },
      {
        key: '30days',
        title: 'Last 30 days',
        range: { startDate: today.clone().subtract(29, 'd'), endDate: today.clone() },
      },
      {
        key: 'week',
        title: 'This week',
        range: { startDate: today.clone().startOf('week'), endDate: today.clone() },
      },
      {
        key: 'month',
        title: 'This month',
        range: { startDate: today.clone().startOf('month'), endDate: today.clone() },
      },
      {
        key: 'lastWeek',
        title: 'Last week',
        range: {
          startDate: today
            .clone()
            .startOf('week')
            .subtract(7, 'd'),
          endDate: today
            .clone()
            .startOf('week')
            .subtract(1, 'd'),
        },
      },
      {
        key: 'lastMonth',
        title: 'Last month',
        range: {
          startDate: today
            .clone()
            .startOf('month')
            .subtract(1, 'd')
            .startOf('month'),
          endDate: today
            .clone()
            .startOf('month')
            .subtract(1, 'd'),
        },
      },
    ];
    const active = quickRange.find(
      r => r.range.startDate.diff(range[0], 'd') === 0 && r.range.endDate.diff(range[1], 'd') === 0,
    ) || {};
    return (
      <Nav
        activeKey={active.key}
        onSelect={k => onDateChange(quickRange.find(r => r.key === k).range, true)}
        className="flex-column py-3"
      >
        {quickRange.map(r => (
          <Nav.Link key={r.key} eventKey={r.key}>
            {r.title}
          </Nav.Link>
        ))}
      </Nav>
    );
  };

  return (
    <div className={className}>
      <DateRangePicker
        small
        startDate={range[0]}
        endDate={range[1]}
        startDateId="start-date"
        endDateId="end-date"
        focusedInput={focusedInput}
        onFocusChange={f => setFocusedInput(f)}
        onDatesChange={onDateChange}
        minimumNights={0}
        isOutsideRange={isOutsideRange}
        firstDayOfWeek={1}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        calendarInfoPosition="before"
        renderCalendarInfo={quickSelect}
        {...rest}
      />
    </div>
  );
};

export default DRP;
