import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Select from 'react-select';

import { useModel } from 'models';

/** @type {import("lodash").Cancelable} */
let searchDebounced = null;

const appSelector = ({ searchApps, apps }) => ({
  apps: searchApps.ids.map(i => apps[i]),
  total: searchApps.total,
  loading: searchApps.loading,
});

const AppInput = ({
  filter = {}, fixedOptions = [], emptyOption, onChange, value, ...rest
}) => {
  const [keyword, setKeyword] = useState(value);
  const [{ loading, apps }, { searchApps }] = useModel('app', appSelector);

  useEffect(() => {
    setKeyword(value);
  }, [value]);

  useEffect(() => {
    if (searchDebounced) {
      searchDebounced.cancel();
    }
    if (keyword) {
      searchDebounced = debounce(
        () => searchApps({
          ...filter,
          kw: keyword,
        }),
        300,
      );
      searchDebounced();
    }
  }, [keyword]);

  let options = emptyOption ? [...fixedOptions, emptyOption] : fixedOptions;
  if (keyword && !loading) {
    options = [...options, ...apps];
  }

  if (emptyOption || fixedOptions.length > 0) {
    rest.openMenuOnFocus = true;
  } else {
    rest.menuIsOpen = !!keyword && keyword !== value;
  }

  return (
    <Select
      isClearable
      isLoading={loading}
      className="Select"
      classNamePrefix="Select"
      getOptionValue={v => v.id}
      getOptionLabel={v => v.name}
      options={options}
      onInputChange={kw => setKeyword(kw)}
      onChange={onChange}
      value={value ? options.find(o => o.id === value) : null}
      filterOption={opts => opts} // show all search results
      // menuIsOpen={!!keyword && keyword !== value}
      noOptionsMessage={() => 'No result'}
      {...rest}
    />
  );
};

export default AppInput;
