import notificationService from 'services/notification';

export default {
  name: 'notification',
  state: {
    getNotifications: {},
    notifications: {},
    readNotification: {},
    systemAlerts: [],
    addSystemAlert: {},
    deleteSystemAlert: {},
  },
  reducers: {
    getNotifications: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            systemAlerts: (payload.customNotis || []),
            notifications: (payload.messages || []).reduce(
              (v, n) => ({ ...v, [n.id]: { ...v[n.id], ...n } }),
              {
                ...state.notifications,
              },
            ),
            getNotifications: {
              totalUnread: payload.totalUnread,
              ids: (state.getNotifications.ids || []).concat((payload.messages || []).map(m => m.id)),
              loading: false,
              error: null,
              hasMore: payload.messages && payload.messages.length > 0,
            },
          };
        case 'error':
          return {
            getNotifications: {
              ...state.getNotifications,
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            getNotifications: {
              ...state.getNotifications,
              loading: true,
              error: null,
            },
          };
      }
    },
    readNotification: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            notifications: payload.id
              ? {
                ...state.notifications,
                [payload.id]: {
                  ...state.notifications[payload.id],
                  read: true,
                },
              }
              : Object.keys(state.notifications).reduce(
                (result, key) => ({
                  ...result,
                  [key]: {
                    ...state.notifications[key],
                    read: true,
                  },
                }),
                {},
              ),
            getNotifications: {
              ...state.getNotifications,
              totalUnread: payload.id ? state.getNotifications.totalUnread - 1 : 0,
            },
            readNotification: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            readNotification: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            readNotification: {
              loading: true,
              error: null,
            },
          };
      }
    },
    newNotification(state, noti) {
      return {
        getNotifications: {
          totalUnread: state.getNotifications.totalUnread + 1,
          ids: [noti.id].concat(state.getNotifications.ids || []),
        },
        notifications: {
          ...state.notifications,
          [noti.id]: noti,
        },
      };
    },
    addSystemAlert: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            systemAlerts: [payload].concat(state.systemAlerts),
            addSystemAlert: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            addSystemAlert: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            addSystemAlert: {
              loading: true,
              error: null,
            },
          };
      }
    },
    deleteSystemAlert: (state, { id, error }, status) => {
      switch (status) {
        case 'success':
          return {
            systemAlerts: state.systemAlerts.filter(a => a.id !== id),
            deleteSystemAlert: {
              [id]: {
                loading: false,
                error: null,
              },
            },
          };
        case 'error':
          return {
            deleteSystemAlert: {
              [id]: {
                loading: false,
                error,
              },
            },
          };
        default:
          return {
            deleteSystemAlert: {
              [id]: {
                loading: true,
                error: null,
              },
            },
          };
      }
    },
  },
  effects: {
    async getNotifications(payload, onSuccess, onError) {
      try {
        onSuccess(await notificationService.getNotifications(payload));
      } catch (e) {
        onError(e);
      }
    },
    async readNotification(payload, onSuccess, onError) {
      try {
        await notificationService.readNotification(payload);
        onSuccess({ id: payload.msgId });
      } catch (e) {
        onError(e);
      }
    },
    async addSystemAlert(payload, onSuccess, onError) {
      try {
        onSuccess(await notificationService.addSystemAlert(payload));
      } catch (e) {
        onError(e);
      }
    },
    async deleteSystemAlert({ id }, onSuccess, onError) {
      try {
        await notificationService.deleteSystemAlert(id);
        onSuccess({ id });
      } catch (e) {
        onError({ id, error: e });
      }
    },
  },
  actions: {
    getNotifications: params => params,
    readNotification: params => params,
    newNotification: params => params,
    addSystemAlert: params => params,
    deleteSystemAlert: id => ({ id }),
  },
};
