import React, { useState, useContext } from 'react';
import {
  Form, Col, Button, Row, Popover, OverlayTrigger,
} from 'react-bootstrap';
import Select from 'react-select';
import { Formik } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';
import get from 'lodash.get';
import moment from 'moment';

import Notification from 'components/Notification';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import SystemAlert from 'components/SystemAlert';
import { AppContext } from 'App';

import styles from './Settings.module.scss';

const typeOptions = [
  { value: 'success', label: 'Success' },
  { value: 'info', label: 'Info' },
  { value: 'warning', label: 'Warning' },
  { value: 'danger', label: 'Danger' },
];

const positionOptions = [{ value: 'global', label: 'Global' }];

const AlertSetting = ({ currentUser }) => {
  const [useHtml, setUseHtml] = useState(false);
  const [{ systemAlerts, loading, error }, { addSystemAlert, deleteSystemAlert }] = useModel(
    'notification',
    state => ({ ...(state.addSystemAlert || {}), systemAlerts: state.systemAlerts || [] }),
  );

  const hasPermission = currentUser.isAdmin();

  const { dialog } = useContext(AppContext);

  const schema = yup.object({
    type: yup.string().required('This field is required'),
    positions: yup
      .array()
      .min(1, 'This field is required')
      .required('This field is required'),
    ...(useHtml
      ? { contentHtml: yup.string().required('This field is required') }
      : { content: yup.string().required('This field is required') }),
  });

  useActionEffect({
    loading,
    error,
    onError: () => Notification.error(error.message || error.error || 'Something went wrong.'),
    onSuccess: () => Notification.success('Add system alert successfully'),
  });

  const onSubmit = (formState) => {
    addSystemAlert({
      ...formState,
      ...(useHtml ? { content: null } : { contentHtml: null }),
      expireAt: formState.expireAt && moment(formState.expireAt, 'DD/MM/YYYY').startOf('day').toISOString(),
    });
  };

  const onDeleteSystemAlert = (id) => {
    dialog.warning({
      title: 'Delete alert',
      content: 'Are you sure want to delete this alert?',
      onOK: (d) => {
        deleteSystemAlert(id);
        d.dismiss();
      },
    });
  };

  const expireAtInfo = (
    <Popover id="expire-date-info">Alert will expire at the beginning of selected date</Popover>
  );

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        title: '',
        content: '',
        contentHtml: '',
        type: '',
        positions: [],
        expireAt: '',
      }}
    >
      {(formProps) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleBlur,
          setFieldTouched,
        } = formProps;
        const isError = name => errors[name] && touched[name];
        return (
          <div className={styles.alertSetting}>
            {systemAlerts.map(a => (
              <SystemAlert
                key={a.id}
                alert={a}
                dismissible={hasPermission}
                onClose={() => onDeleteSystemAlert(a.id)}
              />
            ))}
            <Form.Group as={Row}>
              <Form.Label column sm={4} className="text-right">
                Alert Title
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  disabled={!hasPermission}
                  type="text"
                  name="title"
                  placeholder="Alert title"
                  value={values.title || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={isError('title')}
                />
                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={4} className="text-right">
                Alert Content
              </Form.Label>
              <Col sm={8}>
                <Form.Check
                  disabled={!hasPermission}
                  id="personal"
                  inline
                  className="pt-2 mb-2"
                  type="checkbox"
                  label="Use HTML content"
                  checked={useHtml}
                  onChange={e => setUseHtml(e.target.checked)}
                />
                {useHtml ? (
                  <>
                    <Form.Control
                      disabled={!hasPermission}
                      as="textarea"
                      rows="4"
                      style={{ fontFamily: 'monospace' }}
                      name="contentHtml"
                      placeholder="Alert HTML content"
                      value={values.contentHtml || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={isError('contentHtml')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contentHtml}
                    </Form.Control.Feedback>
                  </>
                ) : (
                  <>
                    <Form.Control
                      disabled={!hasPermission}
                      as="textarea"
                      rows="4"
                      name="content"
                      placeholder="Alert content"
                      value={values.content || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={isError('content')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>
                  </>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={4} className="text-right">
                Type
              </Form.Label>
              <Col sm={8}>
                <Select
                  isDisabled={!hasPermission}
                  className={classNames('Select', { 'is-invalid': isError('type') })}
                  classNamePrefix="Select"
                  placeholder="Select alert type"
                  options={typeOptions}
                  value={typeOptions.find(c => c.value === values.type)}
                  onChange={({ value }) => setFieldValue('type', value)}
                  onBlur={() => setFieldTouched('type', true)}
                />
                {isError('type') && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {get(errors, 'type')}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={4} className="text-right">
                Positions
              </Form.Label>
              <Col sm={8}>
                <Select
                  isMulti
                  isDisabled={!hasPermission}
                  className={classNames('Select', { 'is-invalid': isError('positions') })}
                  classNamePrefix="Select"
                  placeholder="Select alert positions"
                  options={positionOptions}
                  value={positionOptions.filter(p => (values.positions || []).includes(p.value))}
                  onChange={ps => setFieldValue('positions', ps.map(p => p.value))}
                  onBlur={() => setFieldTouched('positions', true)}
                />
                {isError('positions') && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {get(errors, 'positions')}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={4} className="text-right">
                Expire date
                <OverlayTrigger rootClose trigger="click" placement="top" overlay={expireAtInfo}>
                  <Button size="sm" className="infoButton">
                    <Icon name="question" type="fa" size={8} />
                  </Button>
                </OverlayTrigger>
              </Form.Label>
              <Col sm={8}>
                <DatePicker
                  disabled={!hasPermission}
                  size="lg"
                  openDirection="up"
                  placeholder="MM/DD/YYYY"
                  value={values.expireAt ? moment(values.expireAt, 'DD/MM/YYYY') : null}
                  onChange={d => setFieldValue('expireAt', d.format('DD/MM/YYYY'))}
                />
                {isError('expireAt') && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.expireAt}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Form.Group>
            <Form.Row className="mt-6">
              <Col sm={4} />
              <Col sm={8}>
                <Button disabled={loading || !hasPermission} onClick={handleSubmit}>
                  Save
                </Button>
              </Col>
            </Form.Row>
          </div>
        );
      }}
    </Formik>
  );
};

export default AlertSetting;
