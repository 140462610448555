import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { Site, Avatar } from 'tabler-react';
import {
  Container, Dropdown, ListGroup, Media, Badge, Button,
} from 'react-bootstrap';
import moment from 'moment';
import Scrollbar from 'react-scrollbars-custom';
import Notification from 'react-web-notification';
import get from 'lodash.get';
import { useCookies } from 'react-cookie';

// import { useModel } from '../models';

import Api from 'services/api';
import Icon from 'components/Icon';
import EmptyState from 'components/EmptyState';


import logoFAN from 'static/images/adblue2-white.png';
import logoADX from 'static/images/appotax.png';
import appIcon from 'static/images/app-icon-default.png';
import configs from 'configs/configs';
import { useModel } from 'models';
import { AppContext } from 'App';
import SupportInfoModal from 'components/SupportInfoModal';
import FeedbackModal from 'components/FeedbackModal';
import FeedbackSuccessModal from 'components/FeedbackSuccessModal';
import WhatsNewModal from 'components/WhatsNewModal';

const today = moment().format('YYYYMMDD');

/**
 * @param {Object} props
 * @param {import('entities/User').default} props.currentUser
 */
const Header = ({ currentUser, onLogout, onToggleSidebar }) => {
  const wsRetry = useRef(0);
  const [push, setPush] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackSuccessModal, setShowFeedbackSuccessModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showWhatsNewModal, setShowWhatsNewModal] = useState(false);
  const [cookies, setCookie] = useCookies([]);
  const [
    {
      notifications, totalUnread, loading, hasMore,
    },
    { getNotifications, readNotification, newNotification },
    // eslint-disable-next-line no-shadow
  ] = useModel('notification', ({ notifications, getNotifications }) => ({
    totalUnread: getNotifications.totalUnread,
    notifications: (getNotifications.ids || []).map((id) => notifications[id]),
    loading: getNotifications.loading,
    hasMore: getNotifications.hasMore,
  }));

  const { dialog, currentAccount } = useContext(AppContext);
  useEffect(() => {
    if (loading === undefined) {
      getNotifications({ limit: 50 });
      try {
        initWebsocket();
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const whatsnewCookieName = `whatsnew_read_${currentUser.id}`;
  const whatsnew = currentAccount.isPublisher() ? configs.whatsNew.pub : configs.whatsNew.admin;
  const hasNews = parseInt(today, 10) - whatsnew.latest < 3
    && whatsnew.latest > (parseInt(cookies[whatsnewCookieName], 10) || 0);

  const initWebsocket = () => {
    const ws = Api.Websocket('/ws');
    ws.onopen = () => {
      wsRetry.current = 0;
    };
    ws.onmessage = (m) => {
      const noti = JSON.parse(m.data);
      newNotification(noti);
      setPush(noti);
    };
    ws.onclose = (e) => setTimeout(() => {
      if (e.code === 1000) {
        return;
      }
      wsRetry.current += 1;
      initWebsocket();
    }, wsRetry.current * 1000);
  };

  const onClickNoti = (noti) => {
    if (noti.read) {
      return;
    }
    readNotification({ msgId: noti.id, topic: noti.topicId });
  };

  const onReadAllNoti = () => {
    dialog.warning({
      title: 'Mark all as read',
      content: 'Are you sure want to mark all notification as read?',
      onOK: (d) => {
        readNotification({ topic: `noti:${currentAccount.id}` });
        d.dismiss();
      },
    });
  };

  const confirmLogout = () => dialog.warning({
    title: 'Logout',
    content: 'Are you sure want to logout?',
    onOK: (d) => {
      onLogout();
      d.dismiss();
    },
  });

  const onScrollNoti = ({ scrollTop, scrollHeight, clientHeight }) => {
    const bottomReached = scrollTop >= scrollHeight - clientHeight;
    const lastNoti = notifications[notifications.length - 1];
    if (lastNoti && bottomReached && !loading && hasMore) {
      getNotifications({ limit: 20, before: lastNoti.id });
    }
  };

  return (
    <header className="header py-2 fixed-top">
      <Container fluid className="d-flex align-items-center">
        <Button variant="link" className="sidebar-toggler-btn mr-3" onClick={onToggleSidebar}>
          <Icon name="menu" size={24} />
        </Button>
        <Site.Logo alt="AppotaX" src={configs.platform === 'FAN' ? logoFAN : logoADX} />
        <div className="header-action ml-auto d-flex">
          <Dropdown className="noti-dropdown" alignRight>
            <Dropdown.Toggle variant="link">
              <Icon name="bell" size={18} />
              {totalUnread > 0 && (
                <Badge pill className="noti-unread" variant="danger">
                  {totalUnread > 99 ? '99+' : totalUnread}
                </Badge>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-arrow p-0">
              <Scrollbar noScrollX style={{ width: 320, height: 320 }} onScroll={onScrollNoti}>
                <ListGroup variant="flush">
                  {notifications.map((noti) => (
                    <ListGroup.Item action key={noti.id} onClick={() => onClickNoti(noti)}>
                      <Media key={noti.id} className="noti-item px-2 pt-3 pb-2">
                        <img className="app-icon mr-2 rounded" src={noti.src.iconUrl || appIcon} alt="" />
                        <Media.Body>
                          {noti.src.link && (
                            <Link className="text-decoration-none noti-header" to={noti.src.link}>
                              {noti.src.name}
                            </Link>
                          )}
                          {!noti.src.link && noti.src.name}
                          {!noti.read && <span className="nav-unread" />}
                          <p className="noti-content">{noti.content}</p>
                          <small className="noti-ts text-muted">{moment(noti.ts).fromNow()}</small>
                        </Media.Body>
                      </Media>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                {notifications.length === 0 && (
                  <EmptyState className="mt-5" icon="bell-off" desc="No notification" />
                )}
              </Scrollbar>
              {totalUnread > 0 && (
                <Dropdown.Item onClick={onReadAllNoti} className="text-center border-top py-2">
                  Mark all as read
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown alignRight className="account-dropdown">
            <Dropdown.Toggle
              id="account-dropdown"
              variant="link"
              className="nav-link leading-none border-0"
            >
              <Avatar color="blue" imageURL={currentUser.avatar} size="md">
                {!currentUser.avatar && currentUser.getAbbrName()}
              </Avatar>
              <div className="name ml-2 d-block">
                <span className="text-default">{currentUser.name}</span>
                <small className="d-block text-left text-muted mt-1">{currentUser.role}</small>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-arrow">
              <Dropdown.Item className="px-4" as={Link} to="/settings">
                <Icon size={18} name="settings" className="mr-1">Profile</Icon>
              </Dropdown.Item>
              <Dropdown.Item className="px-4" onClick={() => setShowWhatsNewModal(true)}>
                <Icon size={18} name="gift" className="mr-1">What&apos;s new</Icon>
                {hasNews && <span className="float-right status-icon bg-danger mt-2" />}
              </Dropdown.Item>
              {currentAccount.isPublisher() && currentAccount.amInfo && (
                <Dropdown.Item className="px-4" onClick={() => setShowSupportModal(true)}>
                  <Icon size={18} name="life-buoy" className="mr-1">Support</Icon>
                </Dropdown.Item>
              )}
              {currentAccount.isPublisher() && (
                <Dropdown.Item className="px-4" onClick={() => setShowFeedbackModal(true)}>
                  <Icon size={18} name="message-square" className="mr-1">Send feedback</Icon>
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item className="px-4" icon="user" onClick={confirmLogout}>
                <Icon size={18} name="log-out" className="mr-1">Logout</Icon>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
      <Notification
        timeout={3000}
        onClick={() => window.parent.focus()}
        onShow={() => setPush(null)}
        ignore={!push}
        title={get(push, 'title') || get(push, 'src.name') || ''}
        options={{
          icon: get(push, 'src.iconUrl', appIcon),
          body: get(push, 'content'),
          tag: get(push, 'id'),
        }}
      />
      {currentAccount.isPublisher() && currentAccount.amInfo && (
        <SupportInfoModal show={showSupportModal} onHide={() => setShowSupportModal(false)} />
      )}
      {currentAccount.isPublisher() && (
        <>
          <FeedbackModal
            show={showFeedbackModal}
            onHide={() => setShowFeedbackModal(false)}
            onSuccess={() => {
              setShowFeedbackModal(false);
              setShowFeedbackSuccessModal(true);
            }}
          />
          <FeedbackSuccessModal
            show={showFeedbackSuccessModal}
            onHide={() => setShowFeedbackSuccessModal(false)}
          />
        </>
      )}
      <WhatsNewModal
        show={showWhatsNewModal}
        onHide={() => setShowWhatsNewModal(false)}
        onRead={() => setCookie(whatsnewCookieName, today, { expires: moment().add(365, 'd')._d })}
      />
    </header>
  );
};

export default Header;
