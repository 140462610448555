import React, { useEffect } from 'react';
import {
  Modal, Button, Form, ButtonGroup, Row, Col, Alert,
} from 'react-bootstrap';

import { useModel } from 'models';
import useTempState from 'utils/useTempState';
import useActionEffect from 'utils/useActionEffect';

import faceSmile from 'static/images/face-smile.svg';
import faceSad from 'static/images/face-sad.svg';

const FeedbackModal = ({ show, onSuccess, onHide }) => {
  const [formState, setFormState] = useTempState({ feeling: 'good', subject: 'I want to send a feedback' }, [show]);
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);

  const [{ loading, error }, { sendFeedback }] = useModel('publisher', state => ({
    ...state.sendFeedback,
  }));

  useEffect(() => {
    setSubmitStatus({ loading, error });
  }, [loading, error]);

  useActionEffect({ loading, error, onSuccess });

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send us your Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Form.Label column xs={4} className="text-right">
            Your experiences
          </Form.Label>
          <Col xs={8}>
            <ButtonGroup size="sm" className="mr-2" aria-label="First group">
              <Button
                variant={formState.feeling === 'good' ? 'primary' : 'secondary'}
                onClick={() => setFormState({ ...formState, feeling: 'good' })}
              >
                <img src={faceSmile} alt="😃" />
              </Button>
              <Button
                variant={formState.feeling === 'bad' ? 'primary' : 'secondary'}
                onClick={() => setFormState({ ...formState, feeling: 'bad' })}
              >
                <img src={faceSad} alt="☹️" />
              </Button>
            </ButtonGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column xs={4} className="text-right">
            I want to
          </Form.Label>
          <Col xs={8}>
            <Form.Check
              className="d-block mt-2"
              id="feedback"
              inline
              type="checkbox"
              label="Send a feedback"
              checked={formState.subject === 'I want to send a feedback'}
              onChange={() => setFormState({ ...formState, subject: 'I want to send a feedback' })}
            />
            <Form.Check
              className="d-block mt-2"
              id="submit-bug"
              inline
              type="checkbox"
              label="Submit a bug"
              checked={formState.subject === 'I want to submit a bug'}
              onChange={() => setFormState({ ...formState, subject: 'I want to submit a bug' })}
            />
            <Form.Check
              className="d-block mt-2"
              id="request-feature"
              inline
              type="checkbox"
              label="Request a missing feature"
              checked={formState.subject === 'I want to request a missing feature'}
              onChange={() => setFormState({ ...formState, subject: 'I want to request a missing feature' })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column xs={4} className="text-right">
            Message
          </Form.Label>
          <Col xs={8}>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Write something to send"
              value={formState.content || ''}
              onChange={e => setFormState({ ...formState, content: e.target.value })}
            />
          </Col>
        </Form.Group>
        {submitStatus.error && <Alert variant="danger">{submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button disabled={!formState.content || submitStatus.loading} variant="primary" onClick={() => sendFeedback(formState)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
