import { useRef, useEffect } from 'react';

export default ({
  loading, error, onSuccess = () => {}, onError = () => {},
}) => {
  const prevLoading = useRef();
  const prevError = useRef();

  useEffect(() => {
    if (prevLoading.current === loading && prevError.current === error) {
      return;
    }

    if (prevLoading.current && !loading && !error) {
      onSuccess();
    }

    if (prevLoading.current && !prevLoading.error && error) {
      onError();
    }

    prevLoading.current = loading;
    prevError.current = error;
  });
};
