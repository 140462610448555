import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import styles from './FeedbackSuccessModal.module.scss';

const FeedbackSuccessModal = ({ show, onHide }) => (
  <Modal className={styles.feedbackSuccessModal} show={show} onHide={onHide}>
    <Modal.Body className="text-center">
      <h3 className="mt-4">Thank you for your feedback!</h3>
      <div className="w-100 text-center mt-5">
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default FeedbackSuccessModal;
