import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import {
  InputGroup,
  Button,
  Media,
  Row,
  Col,
  Container,
  Alert,
  Modal,
  Form,
} from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import defaultIcon from 'static/images/app-icon-default.png';
import PublisherInput from 'components/PublisherInput';
import Notification from 'components/Notification';
import CopyableText from 'components/CopyableText';
import { AppContext } from 'App';

import { useModel } from 'models';
import User from 'entities/User';
import { usePrevious } from 'react-use';
import EmptyState from '../EmptyState';
import Icon from '../Icon';
import styles from './AppDetail.module.scss';

const AppDetail = ({ appId }) => {
  // if (!app) app = {};
  const [update, setUpdate] = useState({});
  const [showEditModal, setShowEditModal] = useState();
  const [{ app, loading, error }, { updateApp, getAppDetail }] = useModel(
    'app',
    state => ({
      ...(state.updateApp[appId] || {}),
      app: state.apps[appId] || {},
    }),
    appId,
  );
  const prevLoading = useRef(loading);
  useEffect(() => {
    if (prevLoading.current && !loading) {
      // update finish
      if (error) {
        Notification.error(error.message || error.error || 'Something went wrong', 1);
      } else {
        Notification.success(`App ${app.name} has been updated`);
        setUpdate({});
      }
    }
    prevLoading.current = loading;
  }, [loading]);

  useEffect(() => {
    if (!appId) {
      return;
    }
    getAppDetail(appId);
  }, [appId]);

  /** @type {{currentUser:import('entities/User').default, currentAccount: import('entities/Account').default}} */
  const { currentAccount, currentUser, dialog } = useContext(AppContext);

  if (!appId) {
    return <EmptyState className="my-5" icon="box" desc="Select an app to view detail" />;
  }
  if (loading !== undefined && !app.id) {
    return (
      <Alert className="my-5 mx-3" variant="danger">
        {`Application with ID '${appId}' not exists`}
      </Alert>
    );
  }
  const assignPublisher = () => {
    dialog.warning({
      title: 'Are you sure?',
      content: 'You cannot re-assign publisher after submit.',
      onOK: (d) => {
        if (!update.accountId) {
          return;
        }
        updateApp(app.id, { accountId: update.accountId });
        d.dismiss();
      },
    });
  };
  const colProps = {
    xs: 12,
    sm: 4,
    md: 3,
    lg: 2,
  };
  return (
    <div className={classNames(styles.appDetail)}>
      <Media className="p-2 align-items-center">
        <img className="app-icon mr-2 rounded" src={app.icon || defaultIcon} alt="" />
        <Media.Body>
          <h5 className="m-0 app-name">
            {app.name}
            {app.link && (
              <a
                className="ml-2"
                href={getFbAppLink(app.link)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon className="align-top" name="external-link" />
              </a>
            )}
          </h5>
          <small className="text-muted">
            ID:
            {' '}
            <CopyableText>{appId}</CopyableText>
          </small>
        </Media.Body>
        {currentAccount.isAdmin() && (
          <Button
            size="sm"
            variant="secondary"
            className="mr-1"
            onClick={() => setShowEditModal(true)}
          >
            Edit
          </Button>
        )}
        <Button
          size="sm"
          variant="secondary"
          className="mr-1"
          as={Link}
          to={{ pathname: '/app-reports', search: qs.stringify({ aid: app.id }) }}
        >
          View reports
        </Button>
      </Media>
      <Container fluid className="main-info py-3">
        <Row className="mb-1">
          <Col {...colProps}>
            <strong>Status:</strong>
          </Col>
          <Col className="app-status">{app.status}</Col>
        </Row>
        <Row className="mb-1">
          <Col {...colProps}>
            <strong>Category:</strong>
          </Col>
          <Col>{app.category}</Col>
        </Row>
        {app.gpUrl && (
          <Row className="mb-1">
            <Col {...colProps}>
              <strong>Google Play URL:</strong>
            </Col>
            <Col><a href={app.gpUrl} target="_blank" rel="noopener noreferrer">{app.gpUrl}</a></Col>
          </Row>
        )}
        {app.itunesUrl && (
          <Row className="mb-1">
            <Col {...colProps}>
              <strong>iTunes URL:</strong>
            </Col>
            <Col><a href={app.itunesUrl} target="_blank" rel="noopener noreferrer">{app.itunesUrl}</a></Col>
          </Row>
        )}
        {currentAccount.isAdmin() && (
          <Row className="mb-1">
            <Col {...colProps}>
              <strong>Publisher:</strong>
            </Col>
            <Col>
              {app.accountId && (
                <Link to={`/publishers/${app.accountId}`}>
                  {app.publisher ? app.publisher.name : app.accountId}
                </Link>
              )}
              {!app.accountId && currentUser.hasPermission(User.PermAppEdit) && (
                <InputGroup className="assign-pub-input" size="sm">
                  <PublisherInput
                    placeholder="Search publisher"
                    onChange={p => setUpdate({ ...update, accountId: p ? p.id : undefined })}
                    value={update.accountId}
                  />
                  <InputGroup.Append>
                    <Button size="sm" onClick={assignPublisher}>
                      Assign
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              )}
            </Col>
          </Row>
        )}
      </Container>
      {showEditModal && app && (
        <EditModal
          loading={loading}
          error={error ? error.message || error.error : null}
          onExited={() => setShowEditModal(false)}
          app={app}
          onSubmit={data => updateApp(app.id, data)}
        />
      )}
    </div>
  );
};

function getFbAppLink(link) {
  if (!link || /https?:\/\/.+/i.test(link)) {
    return link;
  }
  return `https://facebook.com${link}`;
}

export default AppDetail;

const EditModal = ({
  app, onSubmit, loading, onHide, error, ...rest
}) => {
  const [show, setShow] = useState(true);
  const [state, setState] = useState({
    gpUrl: app.gpUrl || '',
    itunesUrl: app.itunesUrl || '',
  });
  const [errors, setErrors] = useState({});
  const prevLoading = usePrevious(loading);
  useEffect(() => {
    if (!loading && prevLoading && !error) {
      setShow(false);
    }
  }, [loading]);

  const submit = () => {
    const errs = {};
    if (state.gpUrl && !/https?:\/\//i.test(state.gpUrl)) {
      errs.gpUrl = 'invalid gp url';
    }
    if (state.itunesUrl && !/https?:\/\//i.test(state.itunesUrl)) {
      errs.itunesUrl = 'invalid itunes url';
    }
    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      onSubmit(state);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} {...rest}>
      <Modal.Header>
        <Modal.Title>{`Update app: ${app.name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group>
            <Form.Label>Google Play URL</Form.Label>
            <Form.Control
              isInvalid={errors.gpUrl}
              type="url"
              value={state.gpUrl}
              onChange={e => setState({ ...state, gpUrl: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>iTunes URL</Form.Label>
            <Form.Control
              isInvalid={errors.itunesUrl}
              type="url"
              value={state.itunesUrl}
              onChange={e => setState({ ...state, itunesUrl: e.target.value })}
            />
          </Form.Group>
          {!loading && prevLoading && error && <Alert variant="danger">{error}</Alert>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)} variant="secondary">
          Cancel
        </Button>
        <Button disabled={loading} onClick={submit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
