import React, { forwardRef } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import RoleSelect from 'components/RoleSelect';

const schema = yup.object({
  name: yup.string().required('This field is required'),
});

/**
 * @param {object} props
 * @param {import('entities/Account').default} props.account
 */
const MemberForm = ({
  account, infoOnly, className, initialValues, onSubmit,
}, ref) => (
  <Formik
    ref={ref}
    enableReinitialize
    validationSchema={schema}
    onSubmit={onSubmit}
    initialValues={
      initialValues || {
        name: '',
        avatar: '',
        phone: '',
        skype: '',
        role: '',
        platforms: { ADX: true, FAN: true },
      }
    }
  >
    {(formProps) => {
      const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
      } = formProps;
      const isError = (name) => errors[name] && touched[name];

      let roles;
      if (account.isAdmin()) {
        roles = [{ value: 'admin', label: 'Admin' }].concat(
          Object.keys(account.roles).map((k) => ({
            value: k,
            label: account.roles[k].name,
          })),
        );
      }

      return (
        <Form className={className} onSubmit={handleSubmit}>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              Name
            </Form.Label>
            <Col xs={10}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Tag Name"
                value={values.name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={isError('name')}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              Avatar
            </Form.Label>
            <Col xs={10}>
              <Form.Control
                type="text"
                name="avatar"
                placeholder="Avatar"
                value={values.avatar || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Form.Group>
          {(account.isAdmin() || infoOnly) && (
            <>
              <Form.Group as={Row}>
                <Form.Label column xs={2}>
                  Phone
                </Form.Label>
                <Col xs={10}>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={values.phone || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column xs={2}>
                  Skype
                </Form.Label>
                <Col xs={10}>
                  <Form.Control
                    type="text"
                    name="skype"
                    placeholder="Skype"
                    value={values.skype || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
              </Form.Group>
              {!infoOnly && (
                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    Platform
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Check
                      checked={!!values.platforms.ADX}
                      id="p-adx"
                      label="ADX"
                      value="ADX"
                      onChange={(e) => setFieldValue('platforms.ADX', e.target.checked)}
                    />
                    <Form.Check
                      checked={!!values.platforms.FAN}
                      id="p-fan"
                      label="FAN"
                      value="FAN"
                      onChange={(e) => setFieldValue('platforms.FAN', e.target.checked)}
                    />
                  </Col>
                </Form.Group>
              )}
            </>
          )}
          {!infoOnly && (
            <Form.Group as={Row}>
              <Form.Label column xs={2}>
                Role
              </Form.Label>
              <Col xs={10}>
                <RoleSelect
                  roles={roles}
                  value={values.role}
                  onChange={(role) => setFieldValue('role', role)}
                />
              </Col>
            </Form.Group>
          )}
        </Form>
      );
    }}
  </Formik>
);

export default forwardRef(MemberForm);
