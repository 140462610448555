import React, { useState } from 'react';
import {
  Modal, Form, InputGroup, Button,
} from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';

import configs from 'configs/configs';
import App from 'entities/App';
import Icon from 'components/Icon';
import PublisherInput from 'components/PublisherInput';
import DatePicker from 'components/DatePicker';

import styles from './FilterAppModal.module.scss';

const FilterAppModal = ({
  filter: initFilter, onApply, onHide, ...rest
}) => {
  const [filter, setFilter] = useState(initFilter);
  const statusOptions = App.getStatusOptions();
  const storeStatusOptions = App.getStoreStatusOptions();

  const platformOptions = [{ value: 'ios', label: 'iOS' }, { value: 'android', label: 'Android' }];

  const violationStatuses = [
    { value: 'UNKNOWN', label: 'NOT SUBMIT' },
    { value: 'SUBMITTED', label: 'SUBMITTED' },
    { value: 'REJECTED', label: 'REJECTED' },
  ];

  return (
    <Modal
      className={styles.modal}
      centered
      onHide={onHide}
      {...rest}
      onEnter={() => setFilter(initFilter)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Icon size={20} className="mr-1" name="filter">
            Filter App
          </Icon>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Publisher</Form.Label>
          <InputGroup size="sm">
            <PublisherInput
              small
              placeholder="Search publisher"
              onChange={p => setFilter({ ...filter, aid: p ? p.id : undefined })}
              emptyOption={{ id: '0', name: '-- Not set --' }}
              value={filter.aid}
            />
          </InputGroup>
        </Form.Group>
        {configs.platform === 'ADX' && (
          <>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <InputGroup size="sm">
                <Select
                  isClearable
                  className="Select"
                  classNamePrefix="Select"
                  options={statusOptions}
                  placeholder="Select status"
                  onChange={s => setFilter({ ...filter, status: s ? s.value : null })}
                  value={filter.status ? statusOptions.find(s => s.value === filter.status) : null}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Platform</Form.Label>
              <InputGroup size="sm">
                <Select
                  isClearable
                  className="Select"
                  classNamePrefix="Select"
                  options={platformOptions}
                  placeholder="Select platform"
                  onChange={s => setFilter({ ...filter, platform: s ? s.value : null })}
                  value={
                    filter.platform ? platformOptions.find(s => s.value === filter.platform) : null
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Store Status</Form.Label>
              <InputGroup size="sm">
                <Select
                  isClearable
                  className="Select"
                  classNamePrefix="Select"
                  options={storeStatusOptions}
                  placeholder="Select store status"
                  onChange={s => setFilter({ ...filter, storeStatus: s ? s.value : null })}
                  value={
                    filter.storeStatus
                      ? storeStatusOptions.find(s => s.value === filter.storeStatus)
                      : null
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="filter-created-date">
              <Form.Label>Created From</Form.Label>
              <DatePicker
                size="sm"
                placeholder="Ex: 14/07/2005"
                value={filter.from ? moment(filter.from, 'YYYY-MM-DD') : null}
                onChange={d => setFilter({ ...filter, from: d.format('YYYY-MM-DD') })}
                maxDate={moment()}
              />
            </Form.Group>
            {filter.violation && (
              <Form.Group>
                <Form.Label>Violation Appeal Status</Form.Label>
                <InputGroup size="sm">
                  <Select
                    isClearable
                    className="Select"
                    classNamePrefix="Select"
                    options={violationStatuses}
                    placeholder="Select appeal status"
                    onChange={s => setFilter({ ...filter, violation: s ? s.value : null })}
                    value={
                      filter.violation && filter.violation !== 1
                        ? violationStatuses.find(s => s.value === filter.violation)
                        : null
                    }
                  />
                </InputGroup>
              </Form.Group>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => onApply({
            status: '',
            aid: '',
            platform: '',
            storeStatus: '',
            violation: '',
            from: '',
          })
          }
        >
          Reset
        </Button>
        <Button size="sm" variant="primary" onClick={() => onApply(filter)}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterAppModal;
