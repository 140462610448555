import React, {
  useEffect, useRef, useState, useContext,
} from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import TagForm from 'components/TagForm';

import { AppContext } from 'App';
import User from 'entities/User';
import styles from './UpdateTagModal.module.scss';

const UpdateTagModal = ({
  show, appId, tag, onSuccess, onHide,
}) => {
  const [submitStatus, setSubmitStatus] = useState({ loading: false, error: null });
  const [deleteStatus, setDeleteStatus] = useState({ loading: false, error: null });
  const form = useRef(null);
  const [{ updateState, deleteState }, { updateTag, deleteTag }] = useModel('app', state => ({
    updateState: state.updateTag,
    deleteState: state.deleteTag,
  }));

  useEffect(
    () => {
      setSubmitStatus({ ...updateState });
    },
    [updateState.loading, updateState.error],
  );

  useActionEffect({
    loading: updateState.loading,
    error: updateState.error,
    onSuccess,
  });

  useEffect(
    () => {
      setDeleteStatus({ ...deleteState });
    },
    [deleteState.loading, deleteState.error],
  );

  useActionEffect({
    loading: deleteState.loading,
    error: deleteState.error,
    onSuccess: onHide,
  });

  /** @type {{currentUser: User, currentAccount: Account}} */
  const { currentUser, currentAccount, dialog } = useContext(AppContext);

  const onDelete = () => {
    dialog.warning({
      title: `Delete tag "${tag.name}"`,
      content: 'This action cannot be undo, are you sure to continue?',
      onOK: (d) => {
        deleteTag(appId, tag.id);
        d.dismiss();
      },
    });
  };

  if (!tag) {
    return null;
  }

  const canDelete = currentUser.hasPermission(User.PermAppTagEdit)
    && (currentAccount.isAdmin() || (currentAccount.isPublisher() && !tag.sdkCode));

  return (
    <Modal dialogClassName={styles.modal} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit in-app tag</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TagForm
          ref={form}
          initialValues={{
            name: tag.name,
            sizes: tag.sizes,
            type: tag.type,
            code: tag.code,
            sdkCode: tag.sdkCode,
            isDFP: true,
          }}
          onSubmit={values => updateTag(appId, tag.id, values)}
          submitError={submitStatus.error}
          submitting={submitStatus.loading}
        />
        {submitStatus.error && (
          <Alert variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
        {deleteStatus.error && (
          <Alert variant="danger">
            {deleteStatus.error.message || deleteStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {canDelete && (
        <Button
          className="mr-auto"
          disabled={deleteStatus.loading}
          variant="danger"
          onClick={onDelete}
        >
          Delete
        </Button>
        )}
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => form.current.submitForm()}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateTagModal;
