import api from './api';

export default {
  getMonitors(params) {
    return api.GET('/monitors', params);
  },
  addMonitor(params) {
    return api.POST('/monitors', params);
  },
  updateMonitor(id, params) {
    return api.POST(`/monitors/${id}`, params);
  },
};
