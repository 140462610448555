import React, { useState } from 'react';
import {
  Row, Col, Form, Button, Dropdown, Alert,
} from 'react-bootstrap';
import { Loader } from 'tabler-react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Collapse, { Panel } from 'rc-collapse';
import get from 'lodash.get';

import Icon from 'components/Icon';
import payoneerIcon from 'static/images/payoneer.png';
import PayoneerAuthModal from './PayoneerAuthModal';

const requireMessage = 'This field is required';

const schema = yup.object({
  threshold: yup
    .number()
    .min(100, 'Threshold cannot less than 100')
    .test('threshold', 'Threshold must be multiply of 100', value => value % 100 === 0),
  paypals: yup.array().of(
    yup.object({
      account: yup
        .string()
        .email('Invalid account')
        .required(requireMessage),
    }),
  ),
  bankTransfers: yup.array().of(
    yup.object({
      bank: yup.string().required(requireMessage),
      bankAddr: yup.string().required(requireMessage),
      swiftCode: yup.string().required(requireMessage),
      accName: yup.string().required(requireMessage),
      accNumber: yup.string().required(requireMessage),
    }),
  ),
  payoneer: yup.object({
    currency: yup.string(),
  }),
});

const PaymentSettingForm = ({
  publisherId,
  initialValues,
  publisherCountry,
  submitting,
  submitError,
  onSubmit,
  onCancel,
  payoneerLoading,
  payoneerError,
}) => {
  const [showPayoneerModal, setShowPayoneerModal] = useState(false);
  const [panelActiveKey, setPanelActiveKey] = useState(null);
  const getBankFields = index => [
    { label: 'Account Name', fieldName: `bankTransfers[${index}].accName` },
    { label: 'Account Number', fieldName: `bankTransfers[${index}].accNumber` },
    { label: 'Bank Name', fieldName: `bankTransfers[${index}].bank` },
    { label: 'Bank Address', fieldName: `bankTransfers[${index}].bankAddr` },
    { label: 'Swift Code', fieldName: `bankTransfers[${index}].swiftCode` },
  ];

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          threshold: 100,
          paypals: [],
          bankTransfers: [],
        }
      }
    >
      {(formProps) => {
        const {
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          submitCount,
        } = formProps;
        const isError = fieldName => get(errors, fieldName) && (get(touched, fieldName) || submitCount > 0);
        const isBankMethodError = index => !!get(errors, ['bankTransfers', index]) && submitCount > 0;
        const isPaypalMethodError = index => !!get(errors, ['paypals', index]) && submitCount > 0;

        const renderInfoRow = (label, value) => (
          <Form.Group as={Row}>
            <Form.Label column xs={5} lg={3} className="text-right pt-0">
              {label}
            </Form.Label>
            <Col xs={7} lg={9}>
              <div>{value}</div>
            </Col>
          </Form.Group>
        );
        const renderFormInput = (label, fieldName) => (
          <Form.Group key={fieldName} as={Row}>
            <Form.Label column xs={5} lg={3} className="text-right pt-0">
              {label}
            </Form.Label>
            <Col xs={7} lg={9}>
              <Form.Control
                type="text"
                name={fieldName}
                value={get(values, fieldName, '')}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={isError(fieldName)}
              />
              <Form.Control.Feedback type="invalid">{get(errors, fieldName)}</Form.Control.Feedback>
            </Col>
          </Form.Group>
        );

        const renderAddMethodDropdown = () => {
          const methods = [
            {
              method: 'bankTransfers',
              currency: 'USD',
              label: (
                <span>
                  <Icon className="mr-2" size={20} type="fa" name="credit-card" />
                  Bank Transfer - USD
                </span>
              ),
            },
            {
              method: 'bankTransfers',
              currency: 'VND',
              label: (
                <span>
                  <Icon className="mr-2" size={20} type="fa" name="credit-card" />
                  Bank Transfer - VND
                </span>
              ),
            },
            {
              method: 'paypals',
              currency: 'USD',
              label: (
                <span>
                  <Icon className="mr-2" size={20} type="fa" name="paypal" />
                  Paypal - USD
                </span>
              ),
            },
          ].filter((m) => {
            if (publisherCountry !== 'VN' && m.currency === 'VND') {
              return false;
            }
            return (values[m.method] || []).every(mm => mm.currency !== m.currency);
          });

          if (methods.length === 0 && values.payoneer) {
            return null;
          }

          return (
            <Dropdown>
              <Dropdown.Toggle variant="secondary" block id="toggler">
                <Icon name="plus" className="mr-2" />
                Add new
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {methods.map((m, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      const key = `${new Date().getTime()}`;
                      setPanelActiveKey(key);
                      setFieldValue(`${m.method}[${get(values, `${m.method}.length`, 0)}]`, {
                        key,
                        currency: m.currency,
                      });
                    }}
                  >
                    {m.label}
                  </Dropdown.Item>
                ))}
                {!payoneerError && !values.payoneer && (
                  <Dropdown.Item
                    disabled={payoneerLoading}
                    onClick={() => setShowPayoneerModal(true)}
                    className="payoneer-option"
                  >
                    <img
                      src={payoneerIcon}
                      alt="payoneer"
                      style={{ width: 20, height: 16, marginRight: 8 }}
                    />
                    Payoneer
                    {payoneerLoading && <Loader />}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          );
        };

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label column xs={4} md={3} className="text-md-right pt-0">
                Schedule
              </Form.Label>
              <Col xs={8} md={9}>
                <div>Paid monthly</div>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column xs={4} md={3} className="text-md-right pt-0">
                Threshold
              </Form.Label>
              <Col xs={8} md={9}>
                <Form.Control
                  type="number"
                  step={100}
                  min={100}
                  name="threshold"
                  value={values.threshold}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={isError('threshold')}
                />
                <Form.Control.Feedback type="invalid">{errors.threshold}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column lg={3} className="text-lg-right pt-0">
                Payment Methods
              </Form.Label>
              <Col lg={9}>
                {(get(values, 'bankTransfers.length') > 0 || get(values, 'paypals.length') > 0 || values.payoneer) && (
                  <Collapse
                    accordion
                    className="mb-3"
                    activeKey={panelActiveKey}
                    onChange={key => setPanelActiveKey(key)}
                  >
                    {(values.bankTransfers || []).map((bt, index) => (
                      <Panel
                        className={isBankMethodError(index) ? 'methodError' : ''}
                        key={bt.id || bt.key}
                        showArrow={false}
                        extra={(
                          <Icon
                            className="delete"
                            type="fa"
                            name="times"
                            onClick={() => setFieldValue(
                              'bankTransfers',
                              values.bankTransfers.filter((_, i) => index !== i),
                            )}
                          />
)}
                        header={(
                          <span className="d-flex align-items-center">
                            <Icon className="mr-2" size={20} type="fa" name="credit-card" />
                            {bt.id ? `${bt.accNumber} - ${bt.currency}` : 'New payment method'}
                          </span>
)}
                        headerClass="collapseHeader"
                      >
                        <div className="collapseContent">
                          {renderInfoRow('Currency', bt.currency)}
                          {getBankFields(index).map(bf => renderFormInput(bf.label, bf.fieldName))}
                        </div>
                      </Panel>
                    ))}
                    {(values.paypals || []).map((pp, index) => (
                      <Panel
                        className={isPaypalMethodError(index) ? 'methodError' : ''}
                        key={pp.id || pp.key}
                        showArrow={false}
                        header={(
                          <span className="d-flex align-items-center">
                            <Icon className="mr-2" size={20} name="paypal" type="fa" />
                            {pp.id ? `${pp.account} - ${pp.currency}` : 'New payment method'}
                          </span>
)}
                        extra={(
                          <Icon
                            className="delete"
                            type="fa"
                            name="times"
                            onClick={() => setFieldValue('paypals', values.paypals.filter((_, i) => index !== i))
                            }
                          />
)}
                        headerClass="collapseHeader"
                      >
                        <div className="collapseContent">
                          {renderInfoRow('Currency', pp.currency)}
                          {renderFormInput('Account', `paypals[${index}].account`)}
                        </div>
                      </Panel>
                    ))}
                    {values.payoneer && (
                      <Panel
                        showArrow={false}
                        header={(
                          <span className="d-flex align-items-center">
                            <img
                              src={payoneerIcon}
                              alt="payoneer"
                              style={{ width: 20, height: 16, marginRight: 8 }}
                            />
                            Payoneer
                          </span>
)}
                        headerClass="collapseHeader"
                      >
                        <div className="collapseContent">
                          <Form.Group as={Row}>
                            <Form.Label column xs={5} lg={3} className="text-right pt-0">
                              Currency
                            </Form.Label>
                            <Col xs={7} lg={9}>
                              <Form.Control
                                as="select"
                                value={values.payoneer.currency}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="payoneer.currency"
                              >
                                <option value="USD">USD</option>
                                <option value="VND">VND</option>
                              </Form.Control>
                            </Col>
                          </Form.Group>
                        </div>
                      </Panel>
                    )}
                  </Collapse>
                )}
                {renderAddMethodDropdown()}
              </Col>
            </Form.Group>
            {submitError && (
              <Row className="mt-5">
                <Col />
                <Col xs={8} md={9}>
                  <Alert variant="danger">
                    {submitError.message || submitError.error || 'Something went wrong.'}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="mt-5">
              <Col />
              <Col xs={8} md={9}>
                <Button
                  key="cancel"
                  className="mr-3"
                  variant="outline-secondary"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={
                    submitting
                    || !dirty
                    || (get(values, 'bankTransfers.length', 0) === 0
                      && get(values, 'paypals.length', 0) === 0)
                  }
                >
                  <Icon className="mr-2" name="save" />
                  Save
                </Button>
              </Col>
            </Row>
            <PayoneerAuthModal
              show={showPayoneerModal}
              onCancel={() => setShowPayoneerModal(false)}
              publisherId={publisherId}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentSettingForm;
