import monitorService from 'services/monitor';

export default {
  name: 'monitor',
  state: {
    monitors: {},
    getMonitors: {},
    addMonitor: {},
    updateMonitor: {},
    searchMonitors: {},
  },
  reducers: {
    getMonitors: (state, { total, monitors, error }, status) => {
      switch (status) {
        case 'success':
          return {
            monitors: (monitors || []).reduce(
              (v, i) => ({ ...v, [i.id]: { ...v[i.id], ...i } }),
              { ...state.monitors },
            ),
            getMonitors: {
              total,
              ids: (monitors || []).map(p => p.id),
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            getMonitors: {
              ...state.getMonitors,
              loading: false,
              error,
            },
          };
        default:
          return {
            getMonitors: {
              ...state.getMonitors,
              loading: true,
              error: null,
            },
          };
      }
    },
    addMonitor: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            monitors: {
              ...state.monitors,
              [payload.id]: payload,
            },
            getMonitors: {
              ...state.getMonitors,
              ids: [payload.id].concat(state.getMonitors.ids),
            },
            addMonitor: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            addMonitor: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            addMonitor: {
              loading: true,
              error: null,
            },
          };
      }
    },
    updateMonitor: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            monitors: {
              ...state.monitors,
              [payload.id]: { ...state.monitors[payload.id], ...payload },
            },
            updateMonitor: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            updateMonitor: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            updateMonitor: {
              loading: true,
              error: null,
            },
          };
      }
    },
    searchMonitors: (state, { total, monitors, error }, status) => {
      switch (status) {
        case 'success':
          return {
            monitors: (monitors || []).reduce(
              (v, i) => ({ ...v, [i.id]: { ...v[i.id], ...i } }),
              { ...state.monitors },
            ),
            searchMonitors: {
              total,
              ids: (monitors || []).map(p => p.id),
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            searchMonitors: {
              ...state.searchMonitors,
              loading: false,
              error,
            },
          };
        default:
          return {
            searchMonitors: {
              ...state.searchMonitors,
              loading: true,
              error: null,
            },
          };
      }
    },
  },
  effects: {
    async getMonitors(payload, onSuccess, onError) {
      try {
        onSuccess(await monitorService.getMonitors(payload));
      } catch (e) {
        onError(e);
      }
    },
    async addMonitor(payload, onSuccess, onError) {
      try {
        onSuccess(await monitorService.addMonitor(payload));
      } catch (e) {
        onError(e);
      }
    },
    async updateMonitor({ id, data }, onSuccess, onError) {
      try {
        onSuccess(await monitorService.updateMonitor(id, data));
      } catch (e) {
        onError(e);
      }
    },
    async searchMonitors(payload, onSuccess, onError) {
      try {
        onSuccess(await monitorService.getMonitors(payload));
      } catch (e) {
        onError(e);
      }
    },
  },
  actions: {
    getMonitors: params => params,
    addMonitor: params => params,
    updateMonitor: (id, data) => ({ id, data }),
    searchMonitors: params => params,
  },
};
