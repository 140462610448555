import React from 'react';
import Select from 'react-select';

const pubRoles = [
  { value: 'admin', label: 'Admin' },
  { value: 'developer', label: 'Developer' },
  { value: 'viewer', label: 'Viewer' },
];

const RoleSelect = ({
  roles, onChange, value, className, ...rest
}) => {
  const roleOptions = roles || pubRoles;

  return (
    <Select
      className={`${className || ''} Select`}
      classNamePrefix="Select"
      options={roleOptions}
      onChange={(o) => onChange(o && o.value)}
      value={value ? roleOptions.find((s) => s.value === value) : null}
      {...rest}
    />
  );
};

export default RoleSelect;
