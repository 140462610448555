import React, { useState, useEffect } from 'react';
import {
  Row, Col, Button, Form, InputGroup, Alert,
} from 'react-bootstrap';
import { Form as TRForm } from 'tabler-react';
import { useSetState, usePrevious } from 'react-use';

import useAppContext from 'hooks/useAppContext';
import User from 'entities/User';
import { useModel } from 'models';

const AdmobSettings = ({ accountId, config = {} }) => {
  const [editing, setEditing] = useState(false);
  const initState = { enabled: false, sharingPercent: 0, pubId: '' };
  const [state, setState] = useSetState({ ...initState, ...config });
  const [errors, setErrors] = useState({});
  const [{ loading, error }, { updatePublisher }] = useModel(
    'publisher',
    s => s.updatePublisher[accountId] || {},
  );
  const prevLoading = usePrevious(loading);
  useEffect(() => {
    if (prevLoading && !loading) {
      if (error) {
        setErrors({ submit: error.message || error.error });
      } else {
        setEditing(false);
      }
    }
  }, [loading]);
  useEffect(() => {
    setState({ ...initState, ...config });
  }, [config]);
  const { currentAccount, currentUser } = useAppContext();

  const colProps = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
    className: 'text-right',
  };
  const canEdit = currentAccount.isAdmin() && currentUser.hasPermission(User.PermPubInfoEdit);

  const onEdit = () => {
    if (editing) {
      setErrors({});
      setState({ ...initState, ...config });
    }
    setEditing(!editing);
  };

  const onSubmit = () => {
    const errs = {};
    if (!state.pubId) {
      errs.pubId = 'Admob Publisher ID is required';
    }
    if (state.sharingPercent < 0 || state.sharingPercent > 100) {
      errs.sharingPercent = 'Invalid percent';
    }
    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return;
    }
    if (
      state.enabled === config.enabled
      && state.pubId === config.pubId
      && state.sharingPercent === config.sharingPercent
      && state.enabled === config.enabled
    ) {
      setEditing(false);
      return; // nothing change
    }
    updatePublisher(accountId, {
      admobConfig: {
        ...config,
        pubId: state.pubId,
        sharingPercent: state.sharingPercent,
        enabled: state.enabled,
      },
    });
  };

  if (!accountId) return '';

  return (
    <Form>
      <Form.Group>
        {canEdit && !config.pubId && (
          <TRForm.Switch
            className="p-0 mt-2"
            name="toggler"
            type="checkbox"
            disabled={!canEdit}
            label="Enable Admob for this publisher"
            checked={state.enabled}
            onChange={e => setEditing(true) || setState({ enabled: !state.enabled })}
          />
        )}
        {!canEdit && !config.enabled && <Alert variant="warning">Admob is not enabled</Alert>}
      </Form.Group>
      {(state.enabled || config.pubId) && (
        <>
          <Form.Group as={Row}>
            <Form.Label column {...colProps}>
              Admob Publisher ID:
            </Form.Label>
            <Col>
              <Form.Control
                plaintext={!editing}
                readOnly={!editing}
                value={state.pubId}
                onChange={e => setState({ pubId: e.target.value })}
                isInvalid={errors.pubId}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column {...colProps}>
              Sharing Percent:
            </Form.Label>
            <Col>
              {editing ? (
                <InputGroup>
                  <Form.Control
                    type="number"
                    min="0"
                    max="100"
                    value={state.sharingPercent}
                    onChange={e => setState({ sharingPercent: parseInt(e.target.value, 10) })}
                    isInvalid={errors.sharingPercent}
                  >
                  </Form.Control>
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              ) : (
                <Form.Control plaintext readOnly defaultValue={`${state.sharingPercent}%`} />
              )}
            </Col>
          </Form.Group>
          {config.pubId && (
            <Form.Group as={Row}>
              <Form.Label column {...colProps}>
                {editing ? 'Enabled:' : 'Status:'}
              </Form.Label>
              <Col>
                {!editing && (
                  <Form.Control plaintext readOnly value={state.enabled ? 'Enabled' : 'Disabled'} />
                )}
                {editing && (
                  <TRForm.Checkbox
                    className="border-0 mb-0 form-control"
                    checked={state.enabled}
                    onChange={e => setState({ enabled: e.target.checked })}
                    isInline
                    label=" "
                  />
                )}
              </Col>
            </Form.Group>
          )}
          {canEdit && (
            <Form.Group className="text-center border-top pt-3">
              {!!errors.submit && <Alert variant="danger">{errors.submit}</Alert>}
              <Button onClick={onEdit} variant="secondary">
                {editing ? 'Cancel' : 'Edit'}
              </Button>
              {editing && (
                <Button disabled={loading} className="ml-2" onClick={onSubmit}>
                  Save
                </Button>
              )}
            </Form.Group>
          )}
        </>
      )}
    </Form>
  );
};

export default AdmobSettings;
