import React, { useContext, useEffect } from 'react';
import {
  Modal, Button, Form, Alert,
} from 'react-bootstrap';
import Select from 'react-select';

import { AppContext } from 'App';
import User from 'entities/User';
import useTempState from 'utils/useTempState';

const AssignPublisherModal = ({
  show, publisherAM, loading, error, onSubmit, onHide,
}) => {
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);
  const [AM, setAM] = useTempState(publisherAM || [], [show]);
  const { currentAccount } = useContext(AppContext);
  const optionAMs = currentAccount.listAMs().filter(u => u.status === User.STATUS_ACTIVATED);
  const optionOthers = currentAccount.team.filter(
    u => u.status === User.STATUS_ACTIVATED && u.id !== AM[0],
  );

  useEffect(() => {
    setSubmitStatus({ loading, error });
  }, [loading, error, setSubmitStatus]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Assign managers for publisher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>AM:</Form.Label>
          <Select
            className="Select"
            classNamePrefix="Select"
            placeholder="Select AM"
            options={optionAMs}
            getOptionLabel={u => u.name}
            getOptionValue={u => u.id}
            value={AM[0] ? optionAMs.find(a => a.id === AM[0]) : null}
            onChange={({ id }) => setAM([id].concat(AM.slice(1).filter(otherId => otherId !== id)))}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Others:</Form.Label>
          <Select
            isMulti
            isDisabled={AM.length === 0}
            className="Select"
            classNamePrefix="Select"
            options={optionOthers}
            getOptionLabel={u => u.name}
            getOptionValue={u => u.id}
            value={AM.length > 1 ? optionOthers.filter(a => AM.includes(a.id)) : null}
            onChange={(as) => {
              setAM([AM[0]].concat(as.map(a => a.id)));
            }}
          />
        </Form.Group>
        {submitStatus.error && (
          <Alert className="mt-3" dismissible variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button disabled={loading || AM.length === 0} variant="primary" onClick={() => onSubmit(AM)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignPublisherModal;
