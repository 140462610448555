import React, { useState, useRef, useEffect } from 'react';
import { Form, InputGroup, Dropdown } from 'react-bootstrap';
import { Button } from 'tabler-react';
import debounce from 'lodash.debounce';

import DatePicker from '../DatePicker';
import styles from './Settings.module.scss';

const SharingConfigInput = ({
  value = {}, onSubmit, loading, minDate,
}) => {
  const [showSlider, setShowSlider] = useState(false);
  const [date, setDate] = useState();
  const [percent, setPercent] = useState(value.percent || '');
  // const [error, setError] = useState();
  /** @type {{current: import('lodash').Cancelable}} */
  const hideSlider = useRef();
  useEffect(
    () => () => {
      if (hideSlider.current) {
        hideSlider.current.cancel();
      }
    },
    [],
  );
  const onOK = () => {
    setShowSlider(false);
    if (!date || (!percent && percent !== 0)) {
      // setError('All field is required');
      return;
    }
    if (!date.isValid()) {
      // setError('Invalid date');
      setDate();
      return;
    }
    if (percent < 0 || percent > 100) {
      // setError('Invalid percent');
      setPercent('');
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    onSubmit && onSubmit({ date, percent });
  };

  return (
    <Dropdown show={showSlider} className={styles.sharingConfigInput} onToggle={() => {}}>
      <InputGroup>
        <DatePicker
          size="sm"
          onChange={d => setDate(d)}
          value={date}
          onFocus={() => setShowSlider(false)}
          minDate={minDate}
          placeholder="Start date"
        />
        <Form.Control
          type="number"
          min={0}
          max={100}
          size="sm"
          value={percent}
          onChange={e => setPercent(parseInt(e.target.value, 10))}
          placeholder="%"
          onFocus={() => {
            if (hideSlider.current) {
              hideSlider.current.cancel();
            }
            setShowSlider(true);
          }}
          onBlur={() => {
            if (hideSlider.current) {
              hideSlider.current.cancel();
            }
            hideSlider.current = debounce(() => setShowSlider(false), 1000);
            hideSlider.current();
          }}
        />
        <InputGroup.Append>
          <Button size="sm" color="primary" onClick={onOK} disabled={loading} loading={loading}>
            OK
          </Button>
        </InputGroup.Append>
      </InputGroup>
      <Dropdown.Menu className="py-1 px-2 w-100" data-slideout-ignore>
        <Form.Control
          className="custom-range"
          type="range"
          min={0}
          max={100}
          size="sm"
          value={percent || 0}
          onChange={(e) => {
            if (hideSlider.current) {
              hideSlider.current.cancel();
              setPercent(parseInt(e.target.value, 10));
            }
          }}
          onBlur={() => setShowSlider(false)}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SharingConfigInput;
