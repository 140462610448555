import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import { useModel } from 'models';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import AccountDetailCard from 'components/AccountDetailCard';

/**
 * @param {import('react-router-dom').RouteComponentProps} props
 */
const AccountDetail = ({
  location,
  history,
  match: {
    params: { accountId },
  },
}) => {
  const [{ account, error }, { getDetail }] = useModel(
    'publisher',
    state => ({
      ...state.getDetail[accountId],
      account: state.publishers[accountId] || {},
    }),
    accountId,
  );

  useEffect(() => {
    getDetail(accountId);
  }, [accountId]);

  return (
    <MainLayout>
      <PageHeader
        title="Account Detail"
        breadcrumb={[
          { title: 'List Publishers', href: '/publishers' },
          { title: account.name || accountId, active: true },
        ]}
        subTitle={account.name || accountId}
      />
      {error && (
        <Alert className="mt-3" variant="danger">
          {error.message || error.error}
        </Alert>
      )}
      {!error && (
        <AccountDetailCard
          className="mt-3 flex-grow-1"
          account={account}
          accountId={accountId}
          activeTab={location.hash.replace('#', '')}
          onChangeTab={tab => history.push({ ...location, hash: `#${tab}` })}
        />
      )}
    </MainLayout>
  );
};

export default AccountDetail;
