import React from 'react';
import update from 'immutability-helper';
import { InputGroup, Form, Button } from 'react-bootstrap';
import Select from 'react-select';

import Icon from 'components/Icon';

// import styles from './AdjustmentList.module.scss';

const AdjustmentList = ({ adjustments = [], onChange }) => {
  const units = [
    { value: '%', label: '%' },
    { value: 'USD', label: 'USD' },
    { value: 'VND', label: 'VND' },
  ];

  const onUpdate = (index, key, value) => {
    onChange(update(adjustments, { [index]: { [key]: { $set: value } } }));
  };
  const onAdd = () => {
    onChange(adjustments.concat({ name: '', value: '', unit: 'USD' }));
  };
  const onRemove = index => onChange(update(adjustments, { $splice: [[index, 1]] }));

  return (
    <div>
      {adjustments.map((a, index) => (
        <InputGroup key={index} className="mb-2">
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={a.name}
            onChange={e => onUpdate(index, 'name', e.target.value)}
          />
          <Form.Control
            type="number"
            placeholder="Enter value"
            value={a.value}
            onChange={e => onUpdate(index, 'value', parseFloat(e.target.value))}
          />
          <Select
            className="Select"
            classNamePrefix="Select"
            placeholder=""
            options={units}
            value={units.find(u => u.value === a.unit)}
            onChange={({ value }) => onUpdate(index, 'unit', value)}
          />
          <InputGroup.Prepend>
            <Button variant="danger" onClick={() => onRemove(index)}>
              <Icon name="minus" />
            </Button>
          </InputGroup.Prepend>
        </InputGroup>
      ))}
      <Button block onClick={onAdd}>
        <Icon name="plus" />
      </Button>
    </div>
  );
};

export default AdjustmentList;
