const subdomain = window.location.hostname.replace(/^www\./i, '').split('.')[0];
const platform = subdomain.match(/adx/) ? 'ADX' : 'FAN';

const whatsnews = {
  ADX: {
    admin: {
      latest: 20190829,
      gistId: '9e5943a6ef54996db5b1e106d546ffc3',
      file: 'adx_admin.md',
    },
    pub: {
      latest: 20190812,
      gistId: '9e5943a6ef54996db5b1e106d546ffc3',
      file: 'adx_pub.md',
    },
  },
  FAN: {
    admin: {
      latest: 20190812,
      gistId: '9e5943a6ef54996db5b1e106d546ffc3',
      file: 'fan_admin.md',
    },
    pub: {
      latest: 20190812,
      gistId: '9e5943a6ef54996db5b1e106d546ffc3',
      file: 'fan_pub.md',
    },
  },
};

const configs = {
  APPOTA_CLIENT_ID: 'e594378a091f1c7c33794e7441b4b3a005874b7a4',
  apiBaseURL: '/api/v1.2',
  wsBaseURL: `${/https/.test(window.location.protocol) ? 'wss' : 'ws'}://${window.location.host}/api/v1.2`,
  platform: process.env.REACT_APP_PLATFORM || platform,
  baseURL: '/-',
  absURL(path) {
    return `${window.location.protocol}//${window.location.host}${this.baseURL}${path}`;
  },
  RECAPTCHA_SITE_KEY: '6LcEopIUAAAAAJEEF3tUR91bkN5Rhv6YEFoMrr8B',
  gmapAPIKey: 'AIzaSyDDxoTUT58Off7lNGnQTbWVhEj5WqBIwk8',
  whatsNew: whatsnews[platform],
  GA: 'UA-92284709-1',
};

if (process.env.REACT_APP_BASE_URL) {
  configs.baseURL = process.env.REACT_APP_BASE_URL.replace(/\/$/, '');
}

export default configs;
