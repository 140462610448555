import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button as TRButton } from 'tabler-react';
import classNames from 'classnames';
import moment from 'moment';
import get from 'lodash.get';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';

import DataTable from 'components/DataTable';
import Icon from 'components/Icon';
import { AppContext } from 'App';

import { formatNumber } from 'utils/intl';
import Invoice from 'entities/Invoice';
import payoneerIcon from 'static/images/payoneer.png';
import EmptyState from '../EmptyState';

import styles from './ListInvoiceCard.module.scss';

/**
 * @param {Object} props
 * @param {Account[]} props.invocies
 */
const ListInvoiceCard = ({
  className,
  total = 0,
  totalAmount,
  invoices = [],
  pagination = {
    current: 0,
    perpage: 30,
  },
  onPay,
  paying,
  onDelete,
  showPubId,
}) => {
  const totalPage = Math.ceil(total / pagination.perpage);
  const { currentAccount } = useContext(AppContext);

  /** @type {[DataTableColumProps]} */
  let columns = [
    {
      key: 'id',
      title: 'ID',
      render: id => <Link to={`/invoices/${id}`}>{id}</Link>,
    },
    { key: 'pubId', title: 'Pub ID' },
    { key: 'dateRange', title: 'Date Range' },
    currentAccount.isAdmin()
      ? { key: 'dueDate', title: 'Due Date' }
      : { key: 'confirmDueDate', title: 'Confirm Due Date' },
    { key: 'status', title: 'Status' },
    { key: 'amount', title: 'Amount' },
    { key: 'action', title: 'Action' },
  ];

  if (!showPubId) {
    columns = columns.filter(c => c.key !== 'pubId');
  }

  const renderPaymentMethodIcon = paymentMethod => {
    switch (paymentMethod) {
      case Invoice.PAYMENT_METHOD_PAYPAL: {
        return <Icon size={18} type="fa" name="paypal" />;
      }
      case Invoice.PAYMENT_METHOD_BANK: {
        return <Icon size={18} type="fa" name="credit-card" />;
      }
      case Invoice.PAYMENT_METHOD_PAYONEER: {
        return (
          <img
            src={payoneerIcon}
            alt="payoneer"
            style={{ height: 14, width: 18, borderRadius: 2 }}
          />
        );
      }
      default:
        return null;
    }
  };

  const DueDate = ({ ignoreWarning, date }) => {
    const md = moment(date, 'YYYY-MM-DD');
    if (ignoreWarning) {
      return md.format('L');
    }
    return (
      <span className={classNames({ 'text-danger': moment().diff(md, 'd') > 0 })}>
        {md.format('L')}
      </span>
    );
  };

  const rows = invoices.map(i => {
    const actions = [
      {
        visible: currentAccount.isAdmin() && i.isAccepted() && !i.isPayoneerPending(),
        render: () => (
          <TRButton
            key="pay"
            className="action"
            size="small"
            color="success"
            loading={i.id === paying}
            disabled={paying && i.id !== paying}
            onClick={() => onPay(i.id)}
          >
            Pay
          </TRButton>
        ),
      },
      {
        visible: currentAccount.isAdmin() && i.isDraft(),
        render: () => (
          <Button
            key="delete"
            className="action"
            size="small"
            variant="danger"
            onClick={() => onDelete(i.id)}
          >
            <Icon name="trash-2" />
          </Button>
        ),
      },
    ];
    return {
      id: i.id,
      pubId: <Link to={`/publishers/${i.accountId}`}>{get(i, 'pubInfo.name', 'Unknown')}</Link>,
      confirmDueDate: (
        <DueDate ignoreWarning={i.status > Invoice.STATUS_SENT} date={i.confirmDueDate} />
      ),
      dueDate: <DueDate ignoreWarning={i.status === Invoice.STATUS_PAID} date={i.dueDate} />,
      status: i.getStatusBadge(),
      dateRange: `${moment(i.startDate, 'YYYYMMDD').format('L')} - ${moment(
        i.endDate,
        'YYYYMMDD',
      ).format('L')}`,
      amount: (
        <span>
          {renderPaymentMethodIcon(i.paymentMethod)}
          <span className="ml-1">{i.formattedAmount}</span>
        </span>
      ),
      action: actions.map(a => a.visible && a.render()),
    };
  });

  const onPageChange = p => pagination.onChange
    && pagination.onChange({
      page: p,
      perpage: pagination.perpage,
    });
  const onPerPageChange = p => pagination.onChange
    && pagination.onChange({
      page: pagination.current,
      perpage: p,
    });
  const perpages = [10, 30, 50, 100];
  const showPerpage = total > Math.min(pagination.perpage, perpages[0]);

  return (
    <Card className={classNames('mt-3', styles.card, className)}>
      <Card.Header>
        <Card.Title>{`Total: ${total}`}</Card.Title>
        <Card.Options>
          <Card.Title className="text-body">
            {formatNumber(get(totalAmount, 'USD'), { style: 'currency', currency: 'USD' })}
            <span className="mx-2">|</span>
            {formatNumber(get(totalAmount, 'VND'), { style: 'currency', currency: 'VND' })}
          </Card.Title>
          {showPerpage && (
            <DropdownButton
              className="perpage-btn"
              size="sm"
              title={pagination.perpage}
              alignRight
              variant="outline-primary"
              onSelect={key => onPerPageChange(parseInt(key, 10))}
            >
              {perpages.map(p => (
                <Dropdown.Item key={p} eventKey={p}>
                  {p}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <DataTable
          rowKey="id"
          columns={columns}
          rows={rows}
          scroll={{ x: true }}
          emptyText={<EmptyState desc="No data" />}
          pagination={{
            total: totalPage,
            current: Math.min(totalPage, pagination.current),
            onChange: onPageChange,
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default ListInvoiceCard;
