import React, { forwardRef, useState } from 'react';
import {
  Row, Col, Form, Button, Alert,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import get from 'lodash.get';
import Select from 'react-select';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import classNames from 'classnames';
import merge from 'lodash.merge';

import SocialList from 'components/SocialList';
import Icon from 'components/Icon';

import configs from 'configs/configs';
import countries from 'utils/countries';

import styles from './AccountInfo.module.scss';

const AccountInfoForm = forwardRef(
  ({
    initialValues, submitting, submitError, onSubmit, onCancel, currentAccount,
  }, ref) => {
    const [pubType, setPubType] = useState(initialValues.pubType || 'personal');
    const schema = yup.object({
      name: yup.string().required('This field is required'),
      contactInfo: yup.object({
        country: yup.string().required('This field is required'),
        city: yup.string().required('This field is required'),
        address: yup.string().required('This field is required'),
        phone: yup.string().required('This field is required'),
      }),
      ...(pubType === 'company'
        ? {
          company: yup.object({
            name: yup.string().required('This field is required'),
            taxCode: yup.string().required('This field is required'),
          }),
        }
        : {
          PIN: yup.object({
            number: yup.string().required('This field is required'),
            issPlace: yup.string().required('This field is required'),
            issDate: yup.string().required('This field is required'),
          }),
        }),
    });

    return (
      <Formik
        ref={ref}
        enableReinitialize
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={merge(
          {
            pubType: 'personal',
            referralAllowed: false,
            adbreakEnabled: false,
            lockInventory: false,
            contactInfo: {
              name: '',
              country: '',
              city: '',
              address: '',
              phone: '',
            },
            company: {
              name: '',
              taxCode: '',
            },
            PIN: {
              number: '',
              issDate: '',
              issPlace: '',
            },
          },
          initialValues,
        )}
      >
        {formProps => {
          const {
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          } = formProps;
          const isError = fieldName => get(errors, fieldName) && get(touched, fieldName);
          const countryOptions = countries.map(c => ({ value: c.code, label: c.name }));
          const socials = {
            facebook: values.contactInfo.facebook,
            skype: values.contactInfo.skype,
            wechat: values.contactInfo.wechat,
            qq: values.contactInfo.qq,
            telegram: values.contactInfo.telegram,
            twitter: values.contactInfo.twitter,
            whatsapp: values.contactInfo.whatsapp,
          };

          const onSocialsChange = newSocials => {
            Object.keys(newSocials).forEach(s => {
              setFieldValue(`contactInfo.${s}`, newSocials[s]);
            });
          };

          return (
            <Form className={styles.accountInfoForm} onSubmit={handleSubmit}>
              {currentAccount.isAdmin() && (
                <div>
                  <h5>Account Settings:</h5>
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      Account Type
                    </Form.Label>
                    <Col xs={7} lg={8} className="pt-2">
                      <Form.Check
                        id="personal"
                        inline
                        type="checkbox"
                        label="Personal"
                        checked={values.pubType === 'personal'}
                        onChange={() => {
                          setFieldValue('pubType', 'personal');
                          setPubType('personal');
                        }}
                      />
                      <Form.Check
                        id="company"
                        inline
                        type="checkbox"
                        label="Company"
                        checked={values.pubType === 'company'}
                        onChange={() => {
                          setFieldValue('pubType', 'company');
                          setPubType('company');
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      Referral
                    </Form.Label>
                    <Col xs={7} lg={8} className="pt-2">
                      <Form.Check
                        id="referral"
                        inline
                        type="checkbox"
                        label="Allow to run referral"
                        checked={values.referralAllowed}
                        onChange={e => setFieldValue('referralAllowed', e.target.checked)}
                      />
                    </Col>
                  </Form.Group>
                  {configs.platform === 'FAN' && (
                    <Form.Group as={Row}>
                      <Form.Label column xs={5} lg={4} className="text-right">
                        Ad break:
                      </Form.Label>
                      <Col xs={7} lg={8} className="pt-2">
                        <Form.Check
                          id="adbreak"
                          inline
                          type="checkbox"
                          label="Enable ad break"
                          checked={values.adbreakEnabled}
                          onChange={e => setFieldValue('adbreakEnabled', e.target.checked)}
                        />
                      </Col>
                    </Form.Group>
                  )}
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      Platform
                    </Form.Label>
                    <Col xs={7} lg={8} className="pt-2">
                      <Form.Check
                        id="enableADX"
                        inline
                        type="checkbox"
                        label="ADX"
                        checked={values.enableADX}
                        onChange={e => setFieldValue('enableADX', e.target.checked)}
                      />
                      <Form.Check
                        id="enableFAN"
                        inline
                        type="checkbox"
                        label="FAN"
                        checked={values.enableFAN}
                        onChange={e => setFieldValue('enableFAN', e.target.checked)}
                      />
                    </Col>
                  </Form.Group>
                  {configs.platform === 'ADX' && (
                    <Form.Group as={Row}>
                      <Form.Label column xs={5} lg={4} className="text-right">
                        Lock inventory
                      </Form.Label>
                      <Col xs={7} lg={8} className="pt-2">
                        <Form.Check
                          id="lock-inventory"
                          inline
                          type="checkbox"
                          label="Lock publisher's inventory"
                          checked={values.lockInventory}
                          onChange={e => setFieldValue('lockInventory', e.target.checked)}
                        />
                      </Col>
                    </Form.Group>
                  )}
                </div>
              )}
              <div>
                <h5>Contact Information:</h5>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} lg={4} className="text-right">
                    Name
                  </Form.Label>
                  <Col xs={7} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Contact Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={isError('name')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {get(errors, 'name')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} lg={4} className="text-right">
                    Country
                  </Form.Label>
                  <Col xs={7} lg={8}>
                    <Select
                      className={classNames('Select', {
                        'is-invalid': isError('contactInfo.country'),
                      })}
                      classNamePrefix="Select"
                      placeholder="Select country"
                      options={countryOptions}
                      value={countryOptions.find(c => c.value === values.contactInfo.country)}
                      onChange={({ value }) => setFieldValue('contactInfo.country', value)}
                      onBlur={() => setFieldTouched('contactInfo.country', true)}
                    />
                    {isError('contactInfo.country') && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {get(errors, 'contactInfo.country')}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} lg={4} className="text-right">
                    City
                  </Form.Label>
                  <Col xs={7} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="contactInfo.city"
                      value={values.contactInfo.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={isError('contactInfo.city')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {get(errors, 'contactInfo.city')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} lg={4} className="text-right">
                    Address
                  </Form.Label>
                  <Col xs={7} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="contactInfo.address"
                      value={values.contactInfo.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={isError('contactInfo.address')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {get(errors, 'contactInfo.address')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} lg={4} className="text-right">
                    Phone Number
                  </Form.Label>
                  <Col xs={7} lg={8}>
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name="contactInfo.phone"
                      value={values.contactInfo.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={isError('contactInfo.phone')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {get(errors, 'contactInfo.phone')}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} lg={4} className="text-right">
                    IM
                  </Form.Label>
                  <Col xs={7} lg={8}>
                    <SocialList socials={socials} onChange={onSocialsChange} />
                  </Col>
                </Form.Group>
              </div>
              {pubType === 'company' ? (
                <div>
                  <h5>Company Information:</h5>
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      Company
                    </Form.Label>
                    <Col xs={7} lg={8}>
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        name="company.name"
                        value={get(values, 'company.name', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={isError('company.name')}
                        minLength={6}
                        maxLength={200}
                      />
                      <Form.Control.Feedback type="invalid">
                        {get(errors, 'company.name')}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      Tax Code
                    </Form.Label>
                    <Col xs={7} lg={8}>
                      <Form.Control
                        type="text"
                        placeholder="Tax Code"
                        name="company.taxCode"
                        value={get(values, 'company.taxCode', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={isError('company.taxCode')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {get(errors, 'company.taxCode')}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
              ) : (
                <div>
                  <h5>ID/Passport Information:</h5>
                  <p>
                    This information will be used to entering the online contract. Please check your
                    information correctly before submit.
                  </p>
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      ID/Passport Number
                    </Form.Label>
                    <Col xs={7} lg={8}>
                      <Form.Control
                        type="text"
                        placeholder="Tax Card/Passport Number"
                        name="PIN.number"
                        value={get(values, 'PIN.number', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={isError('PIN.number')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {get(errors, 'PIN.number')}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      Issued Date
                    </Form.Label>
                    <Col xs={7} lg={8}>
                      <DatePicker
                        size="lg"
                        placeholder="Ex: 14/07/2005"
                        value={
                          get(values, 'PIN.issDate')
                            ? moment(values.PIN.issDate, 'DD/MM/YYYY')
                            : null
                        }
                        maxDate={moment()}
                        onChange={d => setFieldValue('PIN.issDate', d.format('DD/MM/YYYY'))}
                        onFocus={() => setFieldTouched('PIN.issDate')}
                      />
                      {isError('PIN.issDate') && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                          {get(errors, 'PIN.issDate')}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column xs={5} lg={4} className="text-right">
                      Issued Place
                    </Form.Label>
                    <Col xs={7} lg={8}>
                      <Form.Control
                        type="text"
                        placeholder="EX: HA NOI, VN"
                        name="PIN.issPlace"
                        value={get(values, 'PIN.issPlace', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={isError('PIN.issPlace')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {get(errors, 'PIN.issPlace')}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
              )}
              <Row>
                <Col />
                <Col xs={7} lg={8}>
                  {submitError && (
                    <Alert className="mt-3" dismissible variant="danger">
                      {submitError.message || submitError.error || 'Something went wrong.'}
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row>
                <Col />
                <Col xs={7} lg={8}>
                  <Button
                    key="cancel"
                    className="mr-3"
                    variant="outline-secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={submitting || !dirty}>
                    <Icon className="mr-2" name="save" />
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  },
);

export default AccountInfoForm;
