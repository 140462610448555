import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';

import Icon from 'components/Icon';

import styles from './AppDetail.module.scss';

const AssignUserDropdown = ({ users = [], selected = [], onChange }) => {
  const onClick = (userId, checked) => {
    if (checked) {
      onChange(selected.concat(userId));
    } else {
      onChange(selected.filter(s => s !== userId));
    }
  };

  return (
    <div className={styles.assignUserDropdown}>
      <Dropdown>
        <Dropdown.Toggle size="sm" variant="primary" id="dropdown-basic">
          <Icon name="user-plus" className="mr-1">Assign</Icon>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>Assign to users</Dropdown.Header>
          <Dropdown.Item>
            <a href="#all" onClick={e => e.stopPropagation()}>
              <Form.Check
                id="everyone"
                type="checkbox"
                checked={selected.length === users.length}
                onChange={e => onChange(e.target.checked ? ['*'] : [])}
                label="Everyone"
              >
              </Form.Check>
            </a>
          </Dropdown.Item>
          <Dropdown.Divider />
          {users.map(u => (
            <Dropdown.Item key={u.id}>
              <a href={`#${u.username}`} onClick={e => e.stopPropagation()}>
                <Form.Check
                  type="checkbox"
                  checked={selected.includes(u.id)}
                  onChange={e => onClick(u.id, e.target.checked)}
                  label={`${u.username} (${u.role})`}
                />
              </a>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AssignUserDropdown;
