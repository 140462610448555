import React from 'react';
import ReactGA from 'react-ga';
import Error from './pages/Error';

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    componentDidCatch(error, { componentStack }) {
      this.setState({ hasError: true });
      ReactGA.exception({
        error,
        componentStack,
      });
    }

    render() {
      if (this.state.hasError) {
        return <Error code={500} />;
      }
      return this.props.children;
    }
}

export default ErrorBoundary;
