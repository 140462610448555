import React, { useEffect, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import classNames from 'classnames';

import { useModel } from 'models';

const monitorSelector = ({ searchMonitors, monitors }) => ({
  monitors: (searchMonitors.ids || []).map(i => monitors[i]),
  total: searchMonitors.total,
  loading: searchMonitors.loading,
});

const MonitorInput = ({
  filter = {},
  onChange,
  value,
  emptyOption,
  error,
  className,
  ...rest
}) => {
  const [{ loading, monitors }, { searchMonitors }] = useModel('monitor', monitorSelector);

  useEffect(() => {
    searchMonitors({ ...filter, limit: 200 });
  }, []);
  return (
    <Fragment>
      <Select
        isClearable
        isLoading={loading}
        className={classNames('Select', { 'is-invalid': error }, className)}
        classNamePrefix="Select"
        getOptionValue={v => v.id}
        getOptionLabel={v => v.name}
        options={monitors}
        onChange={onChange}
        value={value ? monitors.find(m => m.id === value) : null}
        noOptionsMessage={() => 'No result'}
        // menuIsOpen={!!keyword && keyword !== value}
        {...rest}
      />
      {error && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {error}
        </Form.Control.Feedback>
      )}
    </Fragment>
  );
};

export default MonitorInput;
