import React from 'react';
import { findDOMNode } from 'react-dom';
import Notification from 'rc-notification';
import { Alert } from 'react-bootstrap';
import classNames from 'classnames';

import Icon from './Icon';
import styles from './Notification.module.scss';

const defaultDuration = 2;
const defaultMaxCount = 3;
let notiInstance = null;
let key = 1;

const notiTypes = {
  info: { icon: 'info', alert: 'info' },
  success: { icon: 'check', alert: 'success' },
  error: { icon: 'x-circle', alert: 'danger' },
  warning: { icon: 'alert-circle', alert: 'warning' },
  loading: { icon: 'loader', alert: 'primary' },
};

const getInstance = () => new Promise((resolve) => {
  if (notiInstance) {
    resolve(notiInstance);
    return;
  }
  Notification.newInstance(
    {
      prefixCls: styles.noti,
      style: {},
      maxCount: defaultMaxCount,
    },
    (instance) => {
      if (notiInstance) {
        resolve(notiInstance);
        return;
      }
      notiInstance = instance;
      resolve(instance);
    },
  );
});

async function notice({
  duration, type, content, onClose,
}) {
  if (typeof duration === 'function') {
    onClose = duration;
    duration = undefined;
  }
  const instance = await getInstance();
  let alert = null;
  const notiId = key++;
  instance.notice({
    key: notiId,
    duration: duration || defaultDuration,
    content: (
      <Alert
        ref={(e) => {
          alert = e;
        }}
        variant={notiTypes[type].alert}
        className={classNames(
          'mb-2 py-2 px-3 d-inline-block animated slideInDown faster',
          `noti-${type}`,
          styles.content,
        )}
      >
        <Icon className="mr-1 noti-icon" name={notiTypes[type].icon} />
        <span>{content}</span>
      </Alert>
    ),
    onClose: () => {
      if (alert) {
        // eslint-disable-next-line react/no-find-dom-node
        const node = findDOMNode(alert);
        if (node) {
          node.classList.remove('slideInDown');
          node.classList.add('fadeOutUp');
        }
      }
      if (onClose) onClose();
    },
  });
  return () => instance.removeNotice(notiId);
}

/**
 * @type {local.Notification}
 */
const Noti = {
  destroy() {
    if (notiInstance) {
      notiInstance.destroy();
      notiInstance = null;
    }
  },
};

Object.keys(notiTypes).forEach((type) => {
  Noti[type] = (content, duration, onClose) => notice({
    content,
    duration,
    type,
    onClose,
  });
});

export default Noti;
