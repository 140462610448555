import React, { useEffect, useContext } from 'react';
import {
  Modal, Button, Alert, ListGroup, Media,
} from 'react-bootstrap';
import { Dimmer } from 'tabler-react';
import Scrollbar from 'react-scrollbars-custom';

import { useModel } from 'models';
import useTempState from 'utils/useTempState';
import EmptyState from 'components/EmptyState';
import { AppContext } from 'App';
import useActionEffect from 'utils/useActionEffect';
import Icon from 'components/Icon';

import styles from './ImportPageModal.module.scss';
import defaultIcon from 'static/images/app-icon-default.png';

const ImportPageModal = ({ show, onHide }) => {
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);
  const [ids, setIds] = useTempState([], [show]);
  const [{ getListState, importState }, { getListImportPages, importPages }] = useModel(
    'page',
    state => ({
      getListState: { ...state.getListImportPages },
      importState: { ...state.importPages },
    }),
  );
  const { dialog } = useContext(AppContext);

  const {
    ownedPages, clientPages, loading, error,
  } = getListState;

  const options = (ownedPages || [])
    .concat(clientPages || [])
    .map(p => ({ value: p.id, label: p.name, icon: p.picture }));

  useEffect(() => {
    if (show) {
      getListImportPages();
    }
  }, [show]);

  useEffect(() => {
    setSubmitStatus({ loading: importState.loading, error: importState.error });
  }, [importState.loading, importState.error]);

  useActionEffect({ loading: importState.loading, error: importState.error, onSuccess: onHide });

  const onSelectAll = () => {
    setIds(options.map(o => o.value));
  };

  const onDeselectall = () => {
    setIds([]);
  };

  const submit = () => {
    dialog.warning({
      title: 'Import page',
      content: 'Are you sure want to import all selected pages?',
      onOK: (d) => {
        importPages({ pageIds: ids });
        d.dismiss();
      },
    });
  };

  const onClickPage = (pageId) => {
    if (!ids.includes(pageId)) {
      setIds(ids.concat(pageId));
    } else {
      setIds(ids.filter(id => id !== pageId));
    }
  };

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <Modal className={styles.importPageModal} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Import new page</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 pl-1">
        {error ? (
          <Alert variant="danger">{error.message || error.error || 'Something went wrong.'}</Alert>
        ) : (
          <Dimmer active={loading} loader>
            {options.length > 0 || loading ? (
              <Scrollbar
                noScrollX
                style={{ width: '100%' }}
              >
                <ListGroup variant="flush">
                  {options.map(o => (
                    <ListGroup.Item
                      key={o.value}
                      onClick={() => onClickPage(o.value)}
                      action
                      className="px-3 py-2"
                    >
                      <Media>
                        <img className="icon mr-3 rounded" src={o.icon || defaultIcon} alt="" />
                        <Media.Body className="d-flex justify-content-between">
                          <a className="text-decoration-none" href="#" title={o.label}>
                            {o.label}
                          </a>
                          {ids.includes(o.value) && <Icon name="check" size={22} color="#81BD46" />}
                        </Media.Body>
                      </Media>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Scrollbar>
            ) : (
              <EmptyState className="mt-3" desc="No page to import" />
            )}
          </Dimmer>
        )}
        {submitStatus.error && (
          <Alert variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div>
          {options.length > 0 && (
            <Button variant="link" onClick={onSelectAll}>
              Select all
            </Button>
          )}
          {ids.length > 0 && (
            <Button variant="link" className="deselect" onClick={onDeselectall}>
              Deselect all
            </Button>
          )}
        </div>
        <div>
          <Button
            disabled={ids.length === 0 || submitStatus.loading}
            variant="primary"
            onClick={submit}
          >
            Import
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportPageModal;
