import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Alert } from 'react-bootstrap';
import { Button, Media } from 'tabler-react';

import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import { useModel } from 'models';
import Icon from 'components/Icon';
import styles from './Settings.module.scss';

const ListBusinessAccount = ({ businesses = [], viewOnly }) => {
  const [{ loading, error }, { update }] = useModel('auth', state => state.update);
  const prevLoading = useRef(loading);
  const [showModal, setShowModal] = useState();
  useEffect(() => {
    if (prevLoading.current && !loading && !error) {
      setShowModal(false);
    }
    prevLoading.current = loading;
  }, [loading]);
  const columns = [
    {
      key: 'id',
      title: 'Business',
      render: (v, r) => ({
        children: (
          <Media>
            <Media.Object className="mr-3" size="lg" avatar objectURL={r.picture} />
            <Media.Body className="align-self-center">
              <Button className="title" link onClick={() => setShowModal(r.id)}>
                {r.name}
              </Button>
              <a
                className="ml-2"
                href={`https://business.facebook.com?business_id=${r.id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon name="external-link" />
              </a>
            </Media.Body>
          </Media>
        ),
      }),
    },
    {
      key: 'status',
      title: 'Status',
      render: v => (
        <span className={v === 'active' ? 'text-success' : 'text-danger'}>{v.toUpperCase()}</span>
      ),
    },
    {
      key: 'sysUserName',
      title: 'System User',
      render: (v, r) => (
        <>
          <div>{v}</div>
          <small className="text-muted">{`Role: ${r.sysUserRole}`}</small>
        </>
      ),
    },
    { key: 'disableAutoFetchApp', title: 'Fetch Apps', render: v => (v ? 'No' : 'Yes') },
  ];
  const onAddAccount = ({
    businessId: id, sysUserToken, disableAutoFetchApp, status,
  }) => update({
    sysSettings: {
      fanBusinesses: [
        {
          id,
          sysUserToken,
          disableAutoFetchApp,
          status,
        },
      ],
    },
  });
  return (
    <div className={styles.businessTable}>
      {!viewOnly && (
        <Button
          className="ml-auto d-block mb-3"
          color="primary"
          onClick={() => setShowModal('new')}
        >
          Add new
        </Button>
      )}
      <DataTable
        rowKey="id"
        columns={columns}
        rows={businesses}
        scroll={{ x: true }}
        emptyText={<EmptyState desc="No data" />}
      />
      {!viewOnly && (
        <AddModal
          centered
          loading={loading}
          error={error}
          show={!!showModal}
          onSubmit={onAddAccount}
          business={showModal ? businesses.find(b => b.id === showModal) || {} : {}}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ListBusinessAccount;

const AddModal = ({
  error, loading, onSubmit: submitCallback, business = {}, ...rest
}) => {
  const initState = {
    businessId: business.id || '',
    sysUserToken: '',
    disableAutoFetchApp: business.disableAutoFetchApp || false,
  };
  const [state, setState] = useState(initState);
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    setState(initState);
  }, [business.id]);
  const form = useRef(null);
  const onSubmit = () => {
    setValidated(true);
    if (form.current.checkValidity()) {
      submitCallback(state);
    }
  };
  const toggleStatus = status => submitCallback({ businessId: business.id, status });

  const onExited = () => {
    setState(initState);
    setValidated(false);
  };

  return (
    <Modal {...rest} onExited={onExited}>
      <Modal.Header closeButton>
        <Modal.Title>
          {business.id ? `Update business account: ${business.name}` : 'Add new business account'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={form} noValidate validated={validated}>
          <Form.Group>
            <Form.Label>
              Business ID
              <span className="form-required">*</span>
            </Form.Label>
            <Form.Control
              value={business.id || state.businessId}
              onChange={e => setState({ ...state, businessId: e.target.value })}
              readOnly={!!business.id}
              required
            />
            <Form.Control.Feedback type="invalid">Business ID is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              System User Token
              {!business.id && <span className="form-required">*</span>}
            </Form.Label>
            <Form.Control
              value={state.sysUserToken}
              onChange={e => setState({ ...state, sysUserToken: e.target.value })}
              required={!business.id}
            />
            <Form.Control.Feedback type="invalid">
              System User Token is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Check
              id="disable-fetchapp"
              onChange={e => setState({ ...state, disableAutoFetchApp: e.target.checked })}
              inline
              label="Do not fetch applications automatically."
              checked={state.disableAutoFetchApp}
            />
          </Form.Group>
        </Form>
        {error && (
          <Alert variant="danger">{error.message || error.error || 'Something went wrong'}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {business.id && (
          <Button
            className="mr-auto"
            loading={loading}
            onClick={() => toggleStatus(business.status === 'active' ? 'inactive' : 'active')}
            color={business.status === 'active' ? 'danger' : 'success'}
          >
            {business.status === 'active' ? 'Deactive' : 'Active'}
          </Button>
        )}
        <Button loading={loading} disabled={loading} onClick={onSubmit} color="primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
