import React from 'react';
import { Container, Header } from 'tabler-react';

const ErrorState = ({ history, title, subtitle, detail, children }) => (
  <Container>
    <Header.H1 className="display-1 text-muted mb-5">{title}</Header.H1>
    <Header.H2>{subtitle}</Header.H2>
    {detail && <Header.H4 className="text-muted font-weight-normal mb-7">{detail}</Header.H4>}
    {children}
  </Container>
);

export default ErrorState;
