import userService from 'services/user';
import User from 'entities/User';
import Account from 'entities/Account';

export default {
  name: 'publisher',
  state: {
    publishers: {},
    contracts: {},
    profiles: {},
    listPublishers: {
      total: 0,
      ids: [],
      error: null,
      loading: false,
    },
    getDetail: {}, // [id] => {loading, error}
    updatePublisher: {}, // [id] => {loading, error}
    updatePublishers: {
      errors: [],
      successIds: [],
    },
    getProfiles: {},
    getContracts: {}, // [publiserId] => {contractIds, loading, error}
    updateProfile: {},
    addProfile: {},
    getContract: {},
    generateContract: {},
    sendFeedback: {},
    getPayoneerLinks: {},
    generateApiKey: {
      loading: false,
      error: null,
    },
  },
  reducers: {
    getPublishers: (state, payload, effectStatus) => {
      switch (effectStatus) {
        case 'success': {
          const { total, accounts } = payload;
          return {
            publishers: (accounts || []).reduce(
              (a, v) => ({ ...a, [v.id]: new Account(v) }),
              state.publishers,
            ),
            listPublishers: {
              ...state.listPublishers,
              total,
              ids: (accounts || []).map((a) => a.id),
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            listPublishers: {
              ...state.listPublishers,
              loading: false,
              error: null,
            },
          };
        default:
          return {
            listPublishers: {
              ...state.listPublishers,
              loading: true,
              error: null,
            },
          };
      }
    },
    getDetail: (state, payload, status) => {
      const { id, error } = payload;
      switch (status) {
        case 'success':
          return {
            publishers: {
              ...state.publishers,
              [id]: new Account(payload),
            },
            getDetail: {
              ...state.getDetail,
              [id]: {
                loading: false,
                error: null,
              },
            },
          };
        case 'error':
          return {
            getDetail: {
              ...state.getDetail,
              [id]: {
                loading: false,
                error,
              },
            },
          };
        default:
          return {
            getDetail: {
              ...state.getDetail,
              [id]: {
                loading: true,
                error: null,
              },
            },
          };
      }
    },
    getContracts: (state, payload, status) => {
      switch (status) {
        case 'success': {
          const { contracts } = payload;
          return {
            contracts: (contracts || []).reduce((a, v) => ({ ...a, [v.id]: v }), state.contracts),
            getContracts: {
              ...state.getContracts,
              [payload.publisherId]: {
                loading: false,
                error: null,
                contractIds: payload.contracts.map((c) => c.id),
              },
            },
          };
        }
        case 'error':
          return {
            getContracts: {
              ...state.getContracts,
              [payload.publisherId]: {
                loading: false,
                error: payload.error,
              },
            },
          };
        default:
          return {
            getDetail: {
              ...state.getDetail,
              [payload.publisherId]: {
                loading: false,
                error: null,
              },
            },
          };
      }
    },
    updatePublisher: (state, payload, status) => {
      const { id, error } = payload;
      switch (status) {
        case 'success':
          return {
            publishers: {
              ...state.publishers,
              [id]: new Account({
                ...state.publishers[id],
                ...payload,
                AM: payload.AM,
                amInfo: payload.amInfo,
                locked: payload.locked,
                adbreakEnabled: payload.adbreakEnabled,
              }),
            },
            updatePublisher: {
              ...state.updatePublisher,
              [id]: {
                loading: false,
                error: null,
              },
            },
          };
        case 'error':
          return {
            updatePublisher: {
              ...state.updatePublisher,
              [id]: {
                loading: false,
                error,
              },
            },
          };
        default:
          return {
            updatePublisher: {
              ...state.updatePublisher,
              [id]: {
                loading: true,
                error: null,
              },
            },
          };
      }
    },
    getPayoneerLinks: (state, payload, status) => {
      switch (status) {
        case 'success': {
          return {
            getPayoneerLinks: {
              loading: false,
              error: null,
              loginLink: payload.loginLink,
              registrationLink: payload.registrationLink,
            },
          };
        }
        case 'error':
          return {
            getPayoneerLinks: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            getPayoneerLinks: {
              loading: false,
              error: null,
            },
          };
      }
    },
    updatePublishers: (state, payload, status) => {
      switch (status) {
        case 'success':
          const updatedPublishers = payload.filter((p) => !p.error).map((p) => p.publisher);
          const errors = payload.filter((p) => p.error).map((p) => p.error);
          return {
            publishers: (updatedPublishers || []).reduce(
              (v, a) => ({
                ...v,
                [a.id]: new Account({ ...state.publishers[a.id], ...a, AM: a.AM }),
              }),
              { ...state.publishers },
            ),
            updatePublishers: {
              loading: false,
              errors,
              successIds: updatedPublishers.map((p) => p.id),
            },
          };
        default:
          return {
            updatePublishers: {
              ...state.updatePublishers,
              loading: true,
            },
          };
      }
    },
    getProfiles: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            profiles: (payload.profiles || []).reduce(
              (a, v) => ({ ...a, [v.id]: v }),
              state.profiles,
            ),
            getProfiles: {
              ids: (payload.profiles || []).map((a) => a.id),
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            getProfiles: {
              ...state.getProfiles,
              loading: true,
              error: payload,
            },
          };
        default:
          return {
            getProfiles: {
              ...state.getProfiles,
              loading: true,
              error: null,
            },
          };
      }
    },
    addProfile: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            profiles: {
              ...state.profiles,
              [payload.id]: payload,
            },
            getProfiles: {
              ...state.getProfiles,
              ids: (state.getProfiles.ids || []).concat(payload.id),
            },
            addProfile: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            addProfile: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            addProfile: {
              loading: true,
              error: null,
            },
          };
      }
    },
    updateProfile: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            profiles: {
              ...state.profiles,
              [payload.id]: payload,
            },
            updateProfile: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            updateProfile: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            updateProfile: {
              loading: true,
              error: null,
            },
          };
      }
    },
    getContract: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            contracts: {
              ...state.contracts,
              [payload.id]: payload,
            },
            getContract: {
              id: payload.id,
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            getContract: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            getContract: {
              loading: true,
              error: null,
            },
          };
      }
    },
    generateContract: (state, payload, status) => {
      switch (status) {
        case 'success':
          const { accountId, contract } = payload;
          const getContracts = { ...state.getContracts };
          if (getContracts[accountId] && getContracts[accountId].contractIds) {
            getContracts[accountId] = {
              ...getContracts[accountId],
              contractIds: [contract.id].concat(
                (getContracts[accountId].contractIds || []).filter((id) => id !== contract.id),
              ),
            };
          }
          return {
            contracts: {
              ...state.contracts,
              [contract.id]: contract,
            },
            generateContract: {
              id: contract.id,
              loading: false,
              error: null,
            },
            getContracts,
          };
        case 'error':
          return {
            generateContract: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            generateContract: {
              loading: true,
              error: null,
            },
          };
      }
    },
    sendFeedback: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            sendFeedback: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            sendFeedback: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            sendFeedback: {
              loading: true,
              error: null,
            },
          };
      }
    },
    generateApiKey: (state, payload, status) => {
      switch (status) {
        case 'success':
          const { id, apiKey } = payload;
          return {
            ...state,
            publishers: {
              ...state.publishers,
              [id]: new Account({ ...state.publishers[id], apiKey }),
            },
            generateApiKey: { loading: false, error: null },
          };
        case 'error':
          return {
            ...state,
            generateApiKey: {
              loading: false,
              error: payload,
            },
          };

        default:
          return {
            ...state,
            generateApiKey: {
              loading: true,
              error: null,
            },
          };
      }
    },
  },
  effects: {
    async getPublishers(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.getAccounts(payload));
      } catch (e) {
        onError(e);
      }
    },
    async getDetail({ id }, onSuccess, onError) {
      try {
        onSuccess(await userService.getAccountDetail(id));
      } catch (error) {
        onError({ id, error });
      }
    },
    async getContracts({ id }, onSuccess, onError) {
      try {
        const response = await userService.getContracts({ accountId: id });
        onSuccess({ publisherId: id, ...response });
      } catch (error) {
        onError({ publisherId: id, error });
      }
    },
    async updatePublisher({ id, data }, onSuccess, onError) {
      try {
        onSuccess(await userService.updateAccount(id, data));
      } catch (error) {
        onError({ id, error });
      }
    },
    updatePublishers: async ({ ids, data }, onSuccess) => {
      const responses = [];
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        try {
          // eslint-disable-next-line no-await-in-loop
          const publisher = await userService.updateAccount(id, data);
          responses.push({ id, publisher });
        } catch (e) {
          responses.push({ id, error: e });
        }
      }
      onSuccess(responses);
    },
    async getProfiles(_, onSuccess, onError) {
      try {
        onSuccess(await userService.getProfiles());
      } catch (error) {
        onError(error);
      }
    },
    async addProfile(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.saveProfile(payload));
      } catch (error) {
        onError(error);
      }
    },
    async updateProfile(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.saveProfile(payload));
      } catch (error) {
        onError(error);
      }
    },
    async generateContract(payload, onSuccess, onError) {
      try {
        const res = await userService.generateContract(payload);
        onSuccess({ accountId: payload.accountId, contract: res });
      } catch (error) {
        onError(error);
      }
    },
    async getContract({ accountId, contractId }, onSuccess, onError) {
      try {
        onSuccess(await userService.getContract({ accountId, contractId }));
      } catch (error) {
        onError(error);
      }
    },
    async sendFeedback(payload, onSuccess, onError) {
      try {
        onSuccess(await userService.sendFeedback(payload));
      } catch (error) {
        onError(error);
      }
    },
    async getPayoneerLinks({ id, redirectUrl }, onSuccess, onError) {
      try {
        onSuccess(await userService.getPayoneerLinks(id, redirectUrl));
      } catch (error) {
        onError(error);
      }
    },
    async generateApiKey({ id }, onSuccess, onError) {
      try {
        const res = await userService.generateApiKey(id);
        onSuccess({ ...res, id });
      } catch (e) {
        onError(e);
      }
    },
  },
  actions: {
    getPublishers: (params) => ({ ...params, type: User.TYPE_PUBLISHER }),
    getDetail: (id) => ({ id }),
    getContracts: (accountId) => ({ id: accountId }),
    updatePublisher: (id, data) => ({ id, data }),
    generateApiKey: (id) => ({ id }),
    updatePublishers: (ids, data) => ({ ids, data }),
    getProfiles: (params) => params,
    updateProfile: (params) => params,
    addProfile: (params) => params,
    generateContract: (params) => params,
    getContract: ({ accountId, contractId }) => ({ accountId, contractId }),
    sendFeedback: (params) => params,
    getPayoneerLinks: (id, redirectUrl) => ({ id, redirectUrl }),
  },
};
