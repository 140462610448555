import React, { useContext } from 'react';

import { useModel } from 'models';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import InvoiceForm from 'components/InvoiceForm';
import { AppContext } from 'App';

import useActionEffect from 'utils/useActionEffect';

const InvoiceDetail = ({ history, match }) => {
  const { accountId } = match.params;
  const [addState, { addInvoice }] = useModel('invoice', state => ({ ...state.addInvoice }));
  const { dialog } = useContext(AppContext);

  useActionEffect({
    loading: addState.loading,
    error: addState.error,
    onSuccess: () => history.push(`/invoices/${addState.id}`),
  });

  const onAddInvoice = (values) => {
    dialog.warning({
      title: 'Add invoice',
      content: 'Are you sure want to add invoice?',
      onOK: (d) => {
        addInvoice({ accountId, ...values });
        d.dismiss();
      },
    });
  };

  return (
    <MainLayout>
      <PageHeader title={<strong className="mr-2">Create Invoice</strong>} />
      <InvoiceForm
        className="mt-3"
        accountId={accountId}
        onSubmit={onAddInvoice}
        submitting={addState.loading}
        submitError={addState.error}
      />
    </MainLayout>
  );
};

export default InvoiceDetail;
