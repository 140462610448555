import reportService from 'services/report';

export default {
  name: 'report',
  state: {
    getReports: {}, // id: {reports, loading, error}
    getPageReports: {}, // id: {reports, loading, error}
    getAdmobReports: {}, // id: {reprots, loading, error}
  },
  reducers: {
    getReports: (state, { id, reports, error }, status) => {
      switch (status) {
        case 'success':
          return {
            getReports: {
              ...state.getReports,
              [id]: { loading: false, error: null, reports },
            },
          };
        case 'error':
          return {
            getReports: {
              ...state.getReports,
              [id]: { ...state.getReports[id], loading: false, error },
            },
          };
        default:
          return {
            getReports: {
              ...state.getReports,
              [id]: { ...state.getReports[id], loading: true, error: null },
            },
          };
      }
    },
    getPageReports: (state, { id, reports, error }, status) => {
      switch (status) {
        case 'success':
          return {
            getPageReports: {
              ...state.getPageReports,
              [id]: { loading: false, error: null, reports },
            },
          };
        case 'error':
          return {
            getPageReports: {
              ...state.getPageReports,
              [id]: { ...state.getPageReports[id], loading: false, error },
            },
          };
        default:
          return {
            getPageReports: {
              ...state.getPageReports,
              [id]: { ...state.getPageReports[id], loading: true, error: null },
            },
          };
      }
    },
    getAdmobReports: (state, { id, reports, error }, status) => {
      switch (status) {
        case 'success':
          return {
            getAdmobReports: {
              ...state.getAdmobReports,
              [id]: { loading: false, error: null, reports },
            },
          };
        case 'error':
          return {
            getAdmobReports: {
              ...state.getAdmobReports,
              [id]: { ...state.getAdmobReports[id], loading: false, error },
            },
          };
        default:
          return {
            getAdmobReports: {
              ...state.getAdmobReports,
              [id]: { ...state.getAdmobReports[id], loading: true, error: null },
            },
          };
      }
    },
  },
  effects: {
    async getReports({ id, params, aggregate }, onSuccess, onError) {
      try {
        const reports = await new Promise(async resolve => {
          const r = await reportService.getReports(params);
          if (!aggregate) {
            resolve(r);
            return;
          }
          resolve(await aggregate(r));
        });
        onSuccess({ id, reports });
      } catch (error) {
        onError({ id, error });
      }
    },
    async getPageReports({ id, params, aggregate }, onSuccess, onError) {
      try {
        const reports = await new Promise(async resolve => {
          const r = await reportService.getPageReports(params);
          if (!aggregate) {
            resolve(r);
            return;
          }
          resolve(await aggregate(r));
        });
        onSuccess({ id, reports });
      } catch (error) {
        onError({ id, error });
      }
    },
    async getAdmobReports({ id, params, aggregate }, onSuccess, onError) {
      try {
        const reports = await new Promise(async resolve => {
          const r = await reportService.getAdmobReports(params);
          if (!aggregate) {
            resolve(r);
            return;
          }
          resolve(await aggregate(r));
        });
        onSuccess({ id, reports });
      } catch (error) {
        onError({ id, error });
      }
    },
  },
  actions: {
    getReports: (id, params, aggregate) => ({ id, params, aggregate }),
    getPageReports: (id, params, aggregate) => ({ id, params, aggregate }),
    getAdmobReports: (id, params, aggregate) => ({ id, params, aggregate }),
  },
};
