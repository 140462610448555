import React from 'react';
import camelCase from 'lodash.camelcase';

import * as Feather from 'react-icons/fi';
import {
  FaPaypal,
  FaRegCreditCard,
  FaTimes,
  FaFacebook,
  FaQq,
  FaWhatsapp,
  FaTelegram,
  FaTwitter,
  FaSkype,
  FaWeixin,
  FaFacebookMessenger,
  FaTachometerAlt,
  FaQuestion,
  FaCalendarCheck,
  FaExclamationTriangle,
  FaClock,
  FaCheckCircle,
  FaSortAmountUp,
  FaSortAmountDown,
  FaAngleDown,
  FaUser,
  FaBuilding,
  FaChartBar,
  FaUserTie,
  FaEllipsisV,
  FaTwitterSquare,
  FaCube,
  FaEnvelope,
  FaPhone,
  FaAndroid,
  FaApple,
  FaHtml5,
  FaChromecast,
  FaTv,
} from 'react-icons/fa';

const FA = {
  FaPaypal,
  FaCreditCard: FaRegCreditCard,
  FaTimes,
  FaFacebook,
  FaQq,
  FaWhatsapp,
  FaTelegram,
  FaTwitter,
  FaSkype,
  FaWeixin,
  FaFacebookMessenger,
  FaTachometerAlt,
  FaQuestion,
  FaCalendarCheck,
  FaExclamationTriangle,
  FaClock,
  FaCheckCircle,
  FaSortAmountUp,
  FaSortAmountDown,
  FaAngleDown,
  FaUser,
  FaBuilding,
  FaChartBar,
  FaUserTie,
  FaEllipsisV,
  FaTwitterSquare,
  FaCube,
  FaEnvelope,
  FaPhone,
  FaAndroid,
  FaApple,
  FaHtml5,
  FaChromecast,
  FaTv,
};

/**
 * @param {Object} props
 * @param {'fi'|'fa'|'io'|'md'} props.type
 */
const Icon = ({
  type = 'fi', name, children, ...rest
}) => {
  const providers = {
    fi: Feather,
    fa: FA,
  };
  name = type[0].toUpperCase() + camelCase(`${type.slice(1)} ${name}`);
  const I = providers[type][name];
  if (I) {
    return (
      <>
        <I {...rest} />
        {children}
      </>
    );
  }

  return null;
};

export default Icon;
