import React from 'react';
import {
  Alert, Modal, Button, Form,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';

const EditAppModal = ({
  show, app, onSuccess, onCancel,
}) => {
  const [{ loading, error }, { updateApp }] = useModel('app', state => ({
    ...(state.updateApp[app.id] || {}),
  }), app.id);

  useActionEffect({ loading, error, onSuccess });

  const schema = yup.object({
    storeAppId: yup.string(),
  });

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={values => updateApp(app.id, values)}
      initialValues={{
        getStoreInfo: true,
        storeAppId: (app.platform === 'ios' ? app.storeUrl : app.storeAppId) || '',
        installUrl: app.installUrl || '',
        desc: app.desc || '',
        icon: app.icon || '',
        name: app.name || '',
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
      }) => {
        const isError = name => errors[name] && touched[name];
        const getSubmitText = () => {
          if (!values.getStoreInfo) {
            return 'Update';
          }
          if (app.platform === 'ios') {
            return 'Get info from iTunes';
          }
          return 'Get info from GP';
        };

        return (
          <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Check
                className="mb-3"
                id="edit-manual"
                type="checkbox"
                checked={!values.getStoreInfo}
                onChange={e => setFieldValue('getStoreInfo', !e.target.checked)}
                label="Edit manually (App has not been published)"
              />
              {!values.getStoreInfo && (
                <>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="App name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Icon</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Icon URL"
                      name="icon"
                      value={values.icon}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group>
                <Form.Label>{app.platform === 'ios' ? 'iTunes URL' : 'GP Package Name'}</Form.Label>
                <Form.Control
                  type="text"
                  name="storeAppId"
                  value={values.storeAppId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={isError('storeAppId')}
                />
                <Form.Control.Feedback type="invalid">{errors.storeAppId}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Install URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="URL to get your app if it hasnot been published to store"
                  name="installUrl"
                  value={values.installUrl}
                  onChange={handleChange}
                />
              </Form.Group>
              {!values.getStoreInfo && (
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Description"
                    name="desc"
                    value={values.desc}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error.message || error.error || 'Something went wrong.'}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onCancel}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={loading}
                onClick={handleSubmit}
              >
                {getSubmitText()}
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditAppModal;
