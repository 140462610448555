import invoiceService from 'services/invoice';
import Invoice from 'entities/Invoice';

export default {
  name: 'invoice',
  state: {
    getInvoices: {},
    getInvoiceDetail: {},
    invoices: {},
    addInvoice: {},
    updateInvoice: {},
    sendInvoice: {},
    confirmInvoice: {},
    payInvoice: {},
    deleteInvoice: {},
    getPaymentInfo: {},
  },
  reducers: {
    getInvoices: (state, {
      total, totalAmount, invoices, error,
    }, status) => {
      switch (status) {
        case 'success':
          return {
            invoices: (invoices || []).reduce(
              (v, i) => ({ ...v, [i.id]: new Invoice({ ...v[i.id], ...i }) }),
              {
                ...state.invoices,
              },
            ),
            getInvoices: {
              total,
              totalAmount,
              ids: (invoices || []).map(p => p.id),
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            getInvoices: {
              ...state.getInvoices,
              loading: false,
              error,
            },
          };
        default:
          return {
            getInvoices: {
              ...state.getInvoices,
              loading: true,
              error: null,
            },
          };
      }
    },
    getInvoiceDetail: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            invoices: {
              ...state.invoices,
              [payload.id]: new Invoice(payload),
            },
            getInvoiceDetail: {
              ...state.getInvoiceDetail,
              [payload.id]: {
                loading: false,
                error: null,
              },
            },
          };
        case 'error':
          return {
            getInvoiceDetail: {
              ...state.getInvoiceDetail,
              [payload.id]: {
                loading: false,
                error: payload.error,
              },
            },
          };
        default:
          return {
            getInvoiceDetail: {
              ...state.getInvoiceDetail,
              [payload.id]: {
                loading: true,
                error: null,
              },
            },
          };
      }
    },
    updateInvoice: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            invoices: {
              ...state.invoices,
              [payload.id]: new Invoice(payload),
            },
            updateInvoice: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            updateInvoice: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            updateInvoice: {
              loading: true,
              error: null,
            },
          };
      }
    },
    addInvoice: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            addInvoice: {
              id: payload.id,
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            addInvoice: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            addInvoice: {
              loading: true,
              error: null,
            },
          };
      }
    },
    sendInvoice: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            invoices: {
              ...state.invoices,
              [payload.id]: new Invoice({ ...state.invoices[payload.id], status: Invoice.STATUS_SENT }),
            },
            sendInvoice: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            sendInvoice: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            sendInvoice: {
              loading: true,
              error: null,
            },
          };
      }
    },
    confirmInvoice: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            invoices: {
              ...state.invoices,
              [payload.id]: new Invoice({ ...state.invoices[payload.id], status: payload.status }),
            },
            confirmInvoice: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            confirmInvoice: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            confirmInvoice: {
              loading: true,
              error: null,
            },
          };
      }
    },
    payInvoice: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            invoices: {
              ...state.invoices,
              [payload.id]: new Invoice({ ...state.invoices[payload.id], ...payload }),
            },
            payInvoice: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            payInvoice: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            payInvoice: {
              loading: true,
              error: null,
              id: payload,
            },
          };
      }
    },
    deleteInvoice: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            getInvoices: {
              ...state.getInvoices,
              ids: (state.getInvoices.ids || []).filter(id => id !== payload.id),
              total: state.getInvoices.total > 0 ? state.getInvoices.total - 1 : 0,
            },
            deleteInvoice: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            deleteInvoice: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            deleteInvoice: {
              loading: true,
              error: null,
            },
          };
      }
    },
    getPaymentInfo: (state, payload, status) => {
      switch (status) {
        case 'success':
          const { invoices, error } = payload;
          return {
            getPaymentInfo: {
              invoice: invoices.length > 0 && new Invoice(invoices[0]),
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            getPaymentInfo: {
              ...state.getPaymentInfo,
              loading: false,
              error,
            },
          };
        default:
          return {
            getPaymentInfo: {
              ...state.getPaymentInfo,
              loading: true,
              error: null,
            },
          };
      }
    },
  },
  effects: {
    async getInvoices(payload, onSuccess, onError) {
      try {
        onSuccess(await invoiceService.getInvoices(payload));
      } catch (e) {
        onError(e);
      }
    },
    async getInvoiceDetail(id, onSuccess, onError) {
      try {
        onSuccess(await invoiceService.getInvoice(id));
      } catch (e) {
        onError({ id, error: e });
      }
    },
    addInvoice: async (payload, success, error) => {
      try {
        success(await invoiceService.addInvoice(payload));
      } catch (e) {
        error(e);
      }
    },
    updateInvoice: async (payload, success, error) => {
      try {
        success(await invoiceService.updateInvoice(payload));
      } catch (e) {
        error(e);
      }
    },
    sendInvoice: async ({ id, ...opts }, success, error) => {
      try {
        await invoiceService.sendInvoice(id, opts);
        success({ id });
      } catch (e) {
        error(e);
      }
    },
    confirmInvoice: async (payload, success, error) => {
      try {
        await invoiceService.confirmInvoice(payload);
        success({
          id: payload.id,
          status: payload.accept ? Invoice.STATUS_ACCEPTED : Invoice.STATUS_REJECTED,
        });
      } catch (e) {
        error(e);
      }
    },
    payInvoice: async (id, success, error) => {
      try {
        const response = await invoiceService.payInvoice(id);
        success({ id, ...response });
      } catch (e) {
        error(e);
      }
    },
    deleteInvoice: async (id, success, error) => {
      try {
        await invoiceService.deleteInvoice(id);
        success({ id });
      } catch (e) {
        error(e);
      }
    },
    async getPaymentInfo(payload, onSuccess, onError) {
      try {
        onSuccess(await invoiceService.getInvoices({ ...payload, limit: 1 }));
      } catch (e) {
        onError(e);
      }
    },
  },
  actions: {
    getInvoices: params => params,
    getInvoiceDetail: id => id,
    addInvoice: params => params,
    updateInvoice: params => params,
    sendInvoice: (id, opts = {}) => ({ ...opts, id }),
    confirmInvoice: params => params,
    payInvoice: id => id,
    deleteInvoice: id => id,
    getPaymentInfo: params => params,
  },
};
