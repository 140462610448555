import React, { forwardRef } from 'react';
import {
  Col, Form, Row, InputGroup, OverlayTrigger, Button, Popover,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';
import update from 'immutability-helper';
import pickBy from 'lodash.pickby';
import get from 'lodash.get';

import Icon from 'components/Icon';
import PublisherInput from 'components/PublisherInput';
import AppInput from 'components/AppInput';
import TagInput from 'components/TagInput';
import AlertTypeSelect from 'components/AlertTypeSelect';
import MonitorTriggerList from 'components/MonitorTriggerList';

import styles from './MonitorForm.module.scss';

const exprRegex = /^(-?({(REQUESTS|MATCHED_REQUESTS|IMPRESSIONS|CLICKS|EARNINGS|CPC|CTR|eCPM)(_PREV)?}|\d+(\.\d+)?)|\(-?({(REQUESTS|MATCHED_REQUESTS|IMPRESSIONS|CLICKS|EARNINGS|CPC|CTR|eCPM)(_PREV)?}|\d+(\.\d+)?)(\s*(\+|-|\*|\/)\s*-?({(REQUESTS|MATCHED_REQUESTS|IMPRESSIONS|CLICKS|EARNINGS|CPC|CTR|eCPM)(_PREV)?}|\d+(\.\d+)?))*\))(\s*(\+|-|\*|\/)\s*(-?({(REQUESTS|MATCHED_REQUESTS|IMPRESSIONS|CLICKS|EARNINGS|CPC|CTR|eCPM)(_PREV)?}|\d+(\.\d+)?)|\(-?({(REQUESTS|MATCHED_REQUESTS|IMPRESSIONS|CLICKS|EARNINGS|CPC|CTR|eCPM)(_PREV)?}|\d+(\.\d+)?)(\s*(\+|-|\*|\/)\s*-?({(REQUESTS|MATCHED_REQUESTS|IMPRESSIONS|CLICKS|EARNINGS|CPC|CTR|eCPM)(_PREV)?}|\d+(\.\d+)?))*\)))*$/;

const schema = yup.object({
  name: yup.string().required('This field is required'),
  pubId: yup.string().required('No target selected'),
  alert: yup.string().required('This field is required'),
  triggers: yup.array().of(
    yup.object({
      expr: yup.string().test('expr', 'Invalid expression', value => exprRegex.test(value)),
    }),
  ),
});

const MonitorForm = forwardRef(({ className, initialValues, onSubmit }, ref) => {
  const alertMessagePopover = (
    <Popover className={styles.alertMessageHelp}>
      You can use variables
      <code>{'{EXP1_RESULT}'}</code>
      <span> , </span>
      <code>{'{EXP2_RESULT}'}</code>
      <span> , </span>
      <code>{'{EXP3_RESULT}'}</code>
      ,... to show result of corresponsive trigger&lsquo;s expression
    </Popover>
  );

  const triggerHelpPopover = (
    <Popover className={styles.triggerHelp} title="Expression">
      <div>
        Available operators:
        <code>+</code>
        <span> , </span>
        <code>-</code>
        <span> , </span>
        <code>*</code>
        <span> , </span>
        <code>/</code>
      </div>
      <div>
        Available variables:
        <ul>
          <li>
            <code>{'{REQUESTS}'}</code>
            <span>: Number of ad requests</span>
          </li>
          <li>
            <code>{'{MATCHED_REQUESTS}'}</code>
            <span>: Number of matched ad requests</span>
          </li>
          <li>
            <code>{'{IMPRESSIONS}'}</code>
            <span>: Number of impressions</span>
          </li>
          <li>
            <code>{'{CLICKS}'}</code>
            <span>: Number of clicks</span>
          </li>
          <li>
            <code>{'{EARNINGS}'}</code>
            <span>: Estimated revenue</span>
          </li>
          <li>
            <code>{'{CTR}'}</code>
            <span>: CTR</span>
          </li>
          <li>
            <code>{'{CPC}'}</code>
            <span>: CPC</span>
          </li>
          <li>
            <code>{'{eCPM}'}</code>
            <span>: eCPM</span>
          </li>
          <li>
            <code>{'{REQUESTS_PREV}'}</code>
            <span>: Number of ad requests of prev period</span>
          </li>
          <li>
            <code>{'{MATCHED_REQUESTS_PREV}'}</code>
            <span>: Number of matched ad requests of prev period</span>
          </li>
          <li>
            <code>{'{IMPRESSIONS_PREV}'}</code>
            <span>: Number of impressions of prev period</span>
          </li>
          <li>
            <code>{'{CLICKS_PREV}'}</code>
            <span>: Number of clicks of prev period</span>
          </li>
          <li>
            <code>{'{EARNINGS_PREV}'}</code>
            <span>: Estimated revenue of prev period</span>
          </li>
          <li>
            <code>{'{CTR_PREV}'}</code>
            <span> CTR of prev period</span>
          </li>
          <li>
            <code>{'{CPC_PREV}'}</code>
            <span>: CPC of prev period</span>
          </li>
          <li>
            <code>{'{eCPM_PREV}'}</code>
            <span>: eCPM of prev period</span>
          </li>
        </ul>
      </div>
    </Popover>
  );

  return (
    <Formik
      ref={ref}
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          name: '',
          pubId: '',
          appId: '',
          tagId: '',
          period: 1,
          oteval: false,
          alertType: 1,
          alert: '',
          triggers: [{ expr: '', op: '>=', threshold: 0 }],
        }
      }
    >
      {(formProps) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleBlur,
        } = formProps;
        const isError = name => errors[name] && touched[name];
        const onAddCondition = () => {
          setFieldValue(
            'triggers',
            update(values.triggers, { $push: [{ op: '>=', threshold: 0 }] }),
          );
        };

        return (
          <Form className={classNames(styles.monitorForm, className)} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={isError('name')}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Target</Form.Label>
              <Row>
                <Col sm={4} className="mb-3">
                  <PublisherInput
                    isDisabled={!!initialValues}
                    placeholder="Publisher"
                    error={isError('pubId') && errors.pubId}
                    fixedOptions={[{ id: '*', name: 'Every publishers' }]}
                    value={values.pubId}
                    onChange={(p) => {
                      setFieldValue('pubId', p ? p.id : '');
                      setFieldValue('appId', '');
                      setFieldValue('tagId', '');
                    }}
                    onBlur={() => setFieldTouched('pubId', true)}
                  />
                </Col>
                {values.pubId && (
                  <Col key={values.pubId} sm={4} className="mb-3">
                    <AppInput
                      isDisabled={!!initialValues}
                      placeholder="Application"
                      filter={pickBy({ aid: values.pubId })}
                      fixedOptions={[{ id: '*', name: 'Every applications' }]}
                      value={values.appId}
                      onChange={(a) => {
                        setFieldValue('appId', a ? a.id : '');
                        setFieldValue('tagId', '');
                      }}
                      onBlur={() => setFieldTouched('appId', true)}
                    />
                  </Col>
                )}
                {values.appId && (
                  <Col sm={4} className="mb-3">
                    <TagInput
                      isDisabled={!!initialValues}
                      placeholder="Tag"
                      appId={values.appId}
                      fixedOptions={[{ id: '*', name: 'Every tags' }]}
                      value={values.tagId}
                      onChange={t => setFieldValue('tagId', t ? t.id : '')}
                      onBlur={() => setFieldTouched('tagId', true)}
                    />
                  </Col>
                )}
              </Row>
            </Form.Group>

            <Form.Group>
              <Form.Label>Time Period</Form.Label>
              <Row>
                <Col sm={4} className="mb-3">
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      name="period"
                      value={values.period}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>days</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col sm={8} className="pt-2 mb-3">
                  <Form.Check
                    inline
                    id="oteval"
                    type="checkbox"
                    label="Check monitor at the end of the day only"
                    checked={values.oteval || false}
                    onChange={e => setFieldValue('oteval', e.target.checked)}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Form.Label>Alert Type</Form.Label>
              <Row>
                <Col sm={4} className="mb-3">
                  <AlertTypeSelect
                    isClearable={false}
                    value={values.alertType}
                    onChange={type => setFieldValue('alertType', type)}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <span className="mr-1">Alert Message</span>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="right"
                  overlay={alertMessagePopover}
                >
                  <Button size="sm" className="buttonIcon">
                    <Icon name="question" type="fa" size={8} />
                  </Button>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="alert"
                value={values.alert}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={isError('alert')}
              />
              <Form.Control.Feedback type="invalid">{errors.alert}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="m-0">
              <Form.Label>
                <span className="mr-1">Trigger</span>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="right"
                  overlay={triggerHelpPopover}
                >
                  <Button size="sm" className="buttonIcon">
                    <Icon name="question" type="fa" size={8} />
                  </Button>
                </OverlayTrigger>
              </Form.Label>
              <MonitorTriggerList
                errors={(errors.triggers || []).map(
                  (e, index) => get(touched, ['triggers', index]) && e,
                )}
                value={values.triggers}
                onChange={triggers => setFieldValue('triggers', triggers)}
              />
              <div className="text-center mt-1">
                <Button variant="secondary" className="addCondition" onClick={onAddCondition}>
                  <Icon name="plus" className="mr-1" />
                  Add condition
                </Button>
              </div>
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
});

export default MonitorForm;
