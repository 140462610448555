import React, { useEffect } from 'react';
import {
  Modal, Button, Alert, Form,
} from 'react-bootstrap';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import useTempState from 'utils/useTempState';

const AddRoleModal = ({ show, onSuccess, onHide }) => {
  const [formState, setFormState] = useTempState({ name: '', perms: [] }, [show]);
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);
  const [{ loading, error }, { addRole }] = useModel('auth', state => ({
    ...state.addRole,
  }));

  useEffect(
    () => {
      setSubmitStatus({ loading, error });
    },
    [loading, error],
  );

  useActionEffect({ loading, error, onSuccess: () => onSuccess(formState) });

  const onSubmit = () => {
    addRole({ roles: [formState] });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create new role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control
            placeholder="Role name"
            value={formState.name}
            onChange={e => setFormState({ ...formState, name: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            inline
            type="checkbox"
            id="perm-pub-all"
            label="Manage all publishers"
            checked={formState.perms.length === 1}
            onChange={e => setFormState({ ...formState, perms: e.target.checked ? ['pub.all'] : [] })}
          />
        </Form.Group>
        {submitStatus.error && (
          <Alert variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button disabled={loading || !formState.name} variant="primary" onClick={onSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddRoleModal;
