import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import get from 'lodash.get';

import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import InvoiceForm from 'components/InvoiceForm';
import InvoiceSheet from 'components/InvoiceSheet';
import RejectInvoiceModal from 'components/RejectInvoiceModal';
import Icon from 'components/Icon';
import User from 'entities/User';
import { AppContext } from 'App';

import { useModel } from 'models';
import usePrevState from 'utils/usePrevState';

const selector = (invoiceId) => (state) => ({
  updateState: state.updateInvoice,
  sendState: state.sendInvoice,
  confirmState: state.confirmInvoice,
  detailState: {
    invoice: state.invoices[invoiceId],
    ...(state.getInvoiceDetail[invoiceId] || {}),
  },
});

const InvoiceDetail = ({ currentUser, match }) => {
  const { invoiceId } = match.params;
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const { dialog } = useContext(AppContext);
  const [{ account }] = useModel('auth');
  const [state, actions] = useModel('invoice', selector(invoiceId));
  const alertDialog = useRef();
  const {
    detailState, updateState, sendState, confirmState,
  } = state;

  const {
    getInvoiceDetail, updateInvoice, sendInvoice, confirmInvoice,
  } = actions;

  const prevSendState = usePrevState(sendState) || {};
  const prevConfirmState = usePrevState(confirmState) || {};
  const { invoice } = detailState;

  useEffect(() => {
    if (!invoice) {
      getInvoiceDetail(invoiceId);
    }
  }, [invoiceId]);

  const onPrint = () => {
    const { title } = document;
    document.title = `Invoice${invoice.id}_${get(invoice, 'pubInfo.name', 'Unknown')}`;
    window.print();
    document.title = title;
  };

  const WarningContent = ({ ignoreError = false }) => (
    <>
      <div>Are you sure want to send this invoice?</div>
      <Form.Check
        inline
        className="mt-3 small"
        id="igoreErrorCheckbox"
        type="checkbox"
        label="Ignore error while sending email"
        checked={ignoreError}
        onChange={(e) => {
          if (alertDialog.current) {
            alertDialog.current.setState({ ignoreError: e.target.checked });
            alertDialog.current.update({
              content: <WarningContent ignoreError={e.target.checked} />,
            });
          }
        }}
      />
    </>
  );

  const onSend = () => {
    alertDialog.current = dialog.warning({
      title: 'Send invoice',
      content: <WarningContent />,
      onOK: (d) => {
        const { ignoreError = false } = d.state;
        sendInvoice(invoiceId, { ignoreSendMailError: ignoreError });
        d.dismiss();
      },
    });
  };

  const onReject = (reason) => {
    confirmInvoice({ id: invoiceId, reject: true, reason });
  };

  const onAccept = () => {
    dialog.warning({
      title: 'Accept invoice',
      content: 'Are you sure want to accept this invoice?',
      onOK: (d) => {
        confirmInvoice({ id: invoiceId, accept: true });
        d.dismiss();
      },
    });
  };

  const pageHeaderTitle = (
    <span className="d-flex align-items-center">
      <strong className="mr-2">{`Invoice: ${invoiceId}`}</strong>
      {invoice && invoice.getStatusBadge()}
    </span>
  );

  const pageHeaderActions = [
    {
      visible: account.isAdmin() && invoice && invoice.isDraft(),
      render: () => (
        <Button key="send" disabled={sendState.loading} className="ml-2" variant="success" onClick={onSend}>
          <Icon name={sendState.loading ? 'loader' : 'send'} className="mr-1" />
          {sendState.loading ? 'Sending...' : 'Sent' }
        </Button>
      ),
    },
    {
      visible: account.isPublisher() && invoice && invoice.isSent(),
      render: () => (
        <Button
          key="Reject"
          className="ml-2"
          variant="danger"
          onClick={() => setRejectModalVisible(true)}
        >
          <Icon name="x" className="mr-1" />
          Reject
        </Button>
      ),
    },
    {
      visible: account.isPublisher() && invoice && (invoice.isSent() || invoice.isRejected()),
      render: () => (
        <Button key="Accept" className="ml-2" variant="success" onClick={onAccept}>
          <Icon name="check" className="mr-1" />
          Accept
        </Button>
      ),
    },
    {
      visible: true,
      render: () => (
        <Button key="print" className="ml-2" onClick={onPrint}>
          <Icon name="printer" className="mr-1" />
          Print
        </Button>
      ),
    },
  ];

  const renderAlerts = () => {
    if (detailState.error) {
      return (
        <Alert dismissible variant="danger" className="mt-3">
          {detailState.error.message || detailState.error.error}
        </Alert>
      );
    }
    if (prevSendState.loading && sendState.error) {
      return (
        <Alert className="mt-3" dismissible variant="danger">
          {sendState.error.message || sendState.error.error || 'Something went wrong.'}
        </Alert>
      );
    }
    if (prevConfirmState.loading && confirmState.error) {
      return (
        <Alert className="mt-3" dismissible variant="danger">
          {confirmState.error.message || confirmState.error.error || 'Something went wrong.'}
        </Alert>
      );
    }
    if (prevSendState.loading && !sendState.loading && !sendState.error) {
      return (
        <Alert className="mt-3" dismissible variant="success">
          Invoice has been sent.
        </Alert>
      );
    }
    if (
      prevConfirmState.loading
      && !confirmState.loading
      && !confirmState.error
      && invoice.isAccepted()
    ) {
      return (
        <Alert className="mt-3" dismissible variant="success">
           Accept invoice sucessfully.
        </Alert>
      );
    }
  };

  return (
    <>
      <MainLayout className="d-print-none">
        <PageHeader
          title={pageHeaderTitle}
          extraContent={pageHeaderActions.map((a) => a.visible && a.render())}
          breadcrumb={[{ title: 'List Invoices', href: '/invoices' }, { title: invoiceId }]}
        />
        {renderAlerts()}
        {currentUser.hasPermission(User.PermPubInvoiceEdit)
          && invoice
          && (invoice.isDraft() || invoice.isRejected()) && (
            <InvoiceForm
              className="mt-3"
              accountId={get(invoice, 'accountId')}
              initialValues={invoice.toFormData()}
              onSubmit={(values) => updateInvoice({ id: invoiceId, ...values })}
              submitting={updateState.loading}
              submitError={updateState.error}
            />
        )}
        <InvoiceSheet className="mt-3 mb-3" invoice={invoice} />
      </MainLayout>
      <InvoiceSheet className="d-none d-print-block" invoice={invoice} />
      {invoice && (
        <RejectInvoiceModal
          invoiceId={invoice.id}
          show={rejectModalVisible}
          onSubmit={(reason) => {
            onReject(reason);
            setRejectModalVisible(false);
          }}
          onCancel={() => setRejectModalVisible(false)}
        />
      )}
    </>
  );
};
export default InvoiceDetail;
