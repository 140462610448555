import referralService from 'services/referrals';

export default {
  name: 'referral',
  state: {
    getReferrals: {},
    getReferralReport: {},
  },
  reducers: {
    getReferrals: (state, { accountId, referrals, error }, status) => {
      switch (status) {
        case 'success':
          return {
            getReferrals: {
              ...state.getReferrals,
              [accountId]: {
                referrals,
                loading: false,
                error: null,
              },
            },
          };
        case 'error':
          return {
            getReferrals: {
              ...state.getReferrals,
              [accountId]: {
                loading: false,
                error,
              },
            },
          };
        default:
          return {
            getReferrals: {
              ...state.getReferrals,
              [accountId]: {
                loading: true,
                error: null,
              },
            },
          };
      }
    },
    getReferralReport: (state, payload, status) => {
      switch (status) {
        case 'success':
          const { headers, rows, totalPaid } = payload;
          return {
            getReferralReport: {
              headers,
              rows,
              totalPaid,
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            getReferralReport: {
              ...state.getReferralReport,
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            getReferralReport: {
              ...state.getReferralReport,
              loading: true,
              error: null,
            },
          };
      }
    },
  },
  effects: {
    async getReferrals({ accountId, data }, onSuccess, onError) {
      try {
        const response = await referralService.getReferrals(accountId, data);
        onSuccess({ accountId, ...response });
      } catch (e) {
        onError({ accountId, error: e });
      }
    },
    async getReferralReport(payload, onSuccess, onError) {
      try {
        onSuccess(await referralService.getRefferalReport(payload));
      } catch (e) {
        onError(e);
      }
    },
  },
  actions: {
    getReferrals: (accountId, data) => ({ accountId, data }),
    getReferralReport: params => params,
  },
};
