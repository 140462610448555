import React, { useState, useEffect, useRef } from 'react';
import { Button, Loader } from 'tabler-react';
import {
  Alert, Card, Form, InputGroup, DropdownButton, Dropdown,
} from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Steps from 'rc-steps';
import Select from 'react-select';
import { useCookies } from 'react-cookie';
import { parse } from 'query-string';

import { useModel } from 'models';
import configs from 'configs/configs';
import Centered from 'layouts/Centered';
import logoFAN from 'static/images/adblue2-white.png';
import logoADX from 'static/images/appotax.png';
import Icon from 'components/Icon';
import countries from 'utils/countries';
import { sessionStorage } from 'utils/store';
import ErrorCode from 'entities/ErrorCode';

import styles from './Login.module.scss';

const steps = [
  { key: 'account', title: 'Account Info', icon: 'user' },
  { key: 'contact', title: 'Contact Info', icon: 'mail' },
  { key: 'traffic', title: 'Traffic Info', icon: 'zap' },
];

const IMs = [
  { key: 'skype', icon: 'skype', name: 'Skype' },
  { key: 'facebook', icon: 'facebook-messenger', name: 'Messenger' },
  { key: 'twitter', icon: 'twitter', name: 'Twitter' },
  { key: 'telegram', icon: 'telegram', name: 'Telegram' },
  { key: 'whatsapp', icon: 'whatsapp', name: 'WhatsApp' },
  { key: 'wechat', icon: 'weixin', name: 'WeChat' },
  { key: 'qq', icon: 'qq', name: 'QQ' },
];

/**
 * @param {import('react-router-dom').RouteComponentProps} props
 */
const Register = ({ location }) => {
  let initState = {
    email: '',
    name: '',
    username: '',
    password: '',
    type: 'personal',
    company: '',
    country: null,
    city: '',
    address: '',
    phone: '',
    mau: '',
    appUrls: '',
    pageUrls: '',
    im: { key: IMs[0].key, value: '' },
    website: '',
  };
  const cached = sessionStorage.get('register_data');
  try {
    const data = JSON.parse(cached);
    initState = { ...initState, ...data };
  } catch (e) {
    // do nothing
  }
  const [input, setInputState] = useState(initState);
  const setInput = v => {
    sessionStorage.set('register_data', JSON.stringify(v));
    setInputState(v);
  };
  const [
    {
      user,
      account,
      register: { inprogress, loading, error },
    },
    { register },
  ] = useModel('auth');
  const prevLoading = useRef();
  const [validity, setValidity] = useState({});
  const [step, setStep] = useState(0);
  const [captchaReady, setCaptchaReady] = useState(false);
  useEffect(() => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => setCaptchaReady(true));
    }
    if (inprogress === undefined) {
      register({ resume: true, checkAppotaId: true });
    }
  }, []);
  const [cookies, setCookie, removeCookie] = useCookies(['ref']);

  useEffect(() => {
    const query = parse(location.search);
    let refsrc = '';
    const a = document.createElement('a');
    if (document.referrer) {
      refsrc = document.referrer;
      a.href = document.referrer;
    }
    if (query.ref) {
      const ref = decodeURIComponent(query.ref.replace(/\+/g, ' '));
      const t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      if (refsrc && a.hostname !== window.location.hostname) {
        setCookie('refsrc', refsrc, { expires: t });
      } else {
        removeCookie('refsrc');
      }
      setCookie('refts', new Date().getTime(), { expires: t });
      setCookie('ref', ref, { expires: t });
    }
  }, []);

  useEffect(() => {
    if (!loading && prevLoading.current) {
      if (user && steps[step].key === 'account') {
        // check appotaid success
        nextStep();
      }

      if (
        step !== 0
        && error
        && (error.code === ErrorCode.ERR_REGISTER_EMAIL_EXIST
          || error.code === ErrorCode.ERR_REGISTER_USERNAME_EXIST)
      ) {
        setStep(0);
      }
    }

    prevLoading.current = loading;
  }, [loading]);

  const selectedIM = IMs.find(i => i.key === input.im.key);
  const setCountry = c => {
    setInput({ ...input, country: c });
    setValidity({ ...validity, country: '' });
  };
  const setAccountType = e => e.target.checked && setInput({ ...input, type: e.target.value });

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const setPhone = e => {
    let v = e.target.value;
    v = (v[0] === '+' ? '+' : '') + v.replace(/[^\d]/g, '');
    setInput({ ...input, phone: v });
  };

  const submit = e => {
    e.preventDefault();
    const errors = {};
    switch (steps[step].key) {
      case 'contact':
        if (!input.country) {
          errors.country = 'Country is required';
        }

        if (!/^\+?\d{8,15}$/.test(input.phone)) {
          errors.phone = 'Invalid phone number';
        }

        if (!input.city.trim()) {
          errors.city = 'Invalid city';
        }

        if (!input.address.trim()) {
          errors.address = 'Invalid city';
        }

        if (!input.im.value.trim()) {
          errors.im = 'Invalid IM';
        }
        break;
      case 'traffic':
        if ((!input.mau && configs.platform === 'ADX') || parseInt(input.mau, 10) < 0) {
          errors.mau = 'Invalid MAU';
        }

        input.appUrls
          .trim()
          .replace(/\n+/g, '\n')
          .split('\n')
          .filter(v => v || configs.platform === 'ADX') // appUrls is required for ADX
          .forEach(u => {
            if (!/https?:\/\/.+/i.test(u)) {
              errors.appUrls = 'Invalid app URL';
            }
          });
        if (configs.platform === 'FAN') {
          input.pageUrls
            .trim()
            .replace(/\n+/g, '\n')
            .split('\n')
            .filter(v => v)
            .forEach(u => {
              if (!/https?:\/\/.+/i.test(u)) {
                errors.pageUrls = 'Invalid page URL';
              }
            });
        }
        break;
      case 'account':
        if (input.type === 'company') {
          if (!input.company.trim()) {
            errors.company = 'Invalid company';
          }
        }

        if (!input.name.trim()) {
          errors.name = 'Invalid name';
        }
        if (!inprogress && input.username.length < 6) {
          errors.username = 'Invalid username';
        }
        break;
      default:
    }
    setValidity(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (!inprogress && steps[step].key === 'account') {
      // check appota id
      register({
        checkAppotaId: true,
        username: input.username,
        password: input.password,
        email: input.email,
      });
      return;
    }

    if (step < steps.length - 1) {
      nextStep();
      return;
    }

    const params = {
      contactInfo: {
        country: input.country.code,
        phone: input.phone,
        city: input.city.trim(),
        address: input.address.trim(),
        [input.im.key]: input.im.value.trim(),
      },
      email: input.email,
      mau: parseInt(input.mau, 10),
      appUrls: input.appUrls
        .trim()
        .replace(/\n+/g, '\n')
        .split('\n')
        .filter(v => v),
      pageUrls: input.pageUrls
        .trim()
        .replace(/\n+/g, '\n')
        .split('\n')
        .filter(v => v),
      type: input.type,
      company: input.company.trim(),
      name: input.name.trim(),
      platform: configs.platform,
    };
    const { ref, refts, refsrc } = cookies;
    let ts = refts;
    if (ref && ts) {
      ts = parseInt(ts, 10);
      const t = new Date().getTime();
      params.refts = parseInt((t - ts) / 1000, 10);
      params.ref = ref;
      params.refsrc = refsrc;
    }
    if (!user) {
      params.username = input.username.trim();
      params.password = input.password;
    }

    register(params);
  };

  if (inprogress === undefined) {
    // checking progress
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  if (account && account.id) {
    // register successfully
    return (
      <Centered>
        <div className={styles.wrapper}>
          <img
            className={classNames(styles.logo, 'mb-3 d-block mx-auto')}
            src={configs.platform === 'ADX' ? logoADX : logoFAN}
            alt=""
          />
          <h2 className="text-center">Register new account</h2>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">
                <Icon className="text-success mr-2" name="check-circle" size={28} />
                <span>Success!</span>
              </Card.Title>
              {!account.appotaIDActivated && (
                <Alert variant="info">Please check your inbox to activate your Appota ID.</Alert>
              )}
              <p>
                Your initial information has been saved. We will check and send you feedback via
                email shortly within 24 hours.
                <br />
                <br />
                Thank you for your patience!
              </p>

              <Button link block href="/" RootComponent="a">
                <Icon name="arrow-left"> Back to homepage</Icon>
              </Button>
            </Card.Body>
          </Card>
        </div>
      </Centered>
    );
  }

  return (
    <Centered>
      <div className={styles.wrapper}>
        <img
          className={classNames(styles.logo, 'mb-3 d-block mx-auto')}
          src={configs.platform === 'ADX' ? logoADX : logoFAN}
          alt=""
        />
        <h2 className="text-center">Register new account</h2>
        <Steps current={step} className="my-5">
          {steps.map((s, i) => (
            <Steps.Step key={i} title={s.title} icon={<Icon size={24} name={s.icon} />} />
          ))}
        </Steps>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>{steps[step].title}</Card.Title>
            <Form onSubmit={submit} noValidate>
              {steps[step].key === 'account' && (
                <>
                  <Form.Group>
                    <Form.Label>
                      {'Account Type'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Form.Check
                      id="type-personal"
                      type="radio"
                      value="personal"
                      label="Personal"
                      name="type"
                      inline
                      onChange={setAccountType}
                      required
                      checked={input.type === 'personal'}
                    />
                    <Form.Check
                      id="type-company"
                      inline
                      className="ml-5"
                      type="radio"
                      value="company"
                      label="Company"
                      name="type"
                      onChange={setAccountType}
                      required
                      checked={input.type === 'company'}
                    />
                  </Form.Group>
                  {input.type === 'company' && (
                    <Form.Group>
                      <Form.Label>
                        {'Company'}
                        <span className="form-required">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={input.company}
                        onChange={e => setInput({ ...input, company: e.target.value })}
                        required
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label>
                      {'Your Name'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={input.name}
                      onChange={e => setInput({ ...input, name: e.target.value })}
                      required
                      minLength={3}
                      maxLength={100}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {'Email'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={user ? user.email : input.email}
                      onChange={e => setInput({ ...input, email: e.target.value })}
                      required
                      disabled={user && user.email}
                      isValid={user && user.email}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {'Username'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={user ? user.username : input.username}
                      onChange={e => setInput({ ...input, username: e.target.value.replace(/[^a-z0-9_]/gi, '') })}
                      required
                      minLength={6}
                      maxLength={30}
                      disabled={inprogress}
                      isValid={inprogress}
                    />
                  </Form.Group>
                  {!inprogress && (
                    <Form.Group>
                      <Form.Label>
                        {'Password'}
                        <span className="form-required">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        value={input.password}
                        onChange={e => setInputState({ ...input, password: e.target.value })}
                        required
                        minLength={6}
                      />
                    </Form.Group>
                  )}
                </>
              )}
              {steps[step].key === 'contact' && (
                <>
                  <Form.Group>
                    <Form.Label>
                      {'Country'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Select
                      className={classNames('Select', { ' is-invalid': validity.country })}
                      classNamePrefix="Select"
                      options={countries}
                      getOptionValue={v => v.code}
                      getOptionLabel={v => v.name}
                      onChange={setCountry}
                      value={input.country}
                      onBlur={() => !input.country
                        && setValidity({ ...validity, country: 'Country is required' })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {'City'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={input.city}
                      onChange={e => setInput({ ...input, city: e.target.value })}
                      required
                      isInvalid={!!validity.city}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {'Address'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={input.address}
                      onChange={e => setInput({ ...input, address: e.target.value })}
                      required
                      isInvalid={!!validity.address}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {'Phone Number'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={input.phone}
                      onChange={setPhone}
                      placeholder="+84987654321"
                      required
                      isInvalid={!!validity.phone}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {'IM'}
                      <span className="form-required">*</span>
                    </Form.Label>
                    <InputGroup>
                      <DropdownButton
                        variant="outline-primary"
                        className="im-dropdown"
                        as={InputGroup.Prepend}
                        title={(
                          <Icon
                            className={selectedIM.icon}
                            size={20}
                            type="fa"
                            name={selectedIM.icon}
                          />
)}
                      >
                        {IMs.map(i => (
                          <Dropdown.Item
                            key={i.key}
                            active={i.key === selectedIM.key}
                            onSelect={() => setInput({ ...input, im: { ...input.im, key: i.key } })}
                          >
                            <Icon
                              type="fa"
                              size={20}
                              className={classNames('mr-2', i.icon)}
                              name={i.icon}
                            >
                              {i.name}
                            </Icon>
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <Form.Control
                        type="text"
                        value={input.im.value}
                        onChange={e => setInput({
                          ...input,
                          im: { ...input.im, value: e.target.value },
                        })}
                        required
                        isInvalid={!!validity.im}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="url"
                      value={input.website}
                      onChange={e => setInput({ ...input, website: e.target.value })}
                      placeholder="http://your-company.com"
                    />
                  </Form.Group>
                </>
              )}
              {steps[step].key === 'traffic' && (
                <>
                  <Form.Group>
                    <Form.Label>
                      {'Monthly Active Users'}
                      {configs.platform === 'ADX' && <span className="form-required">*</span>}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      step={100}
                      value={input.mau}
                      onChange={e => setInput({ ...input, mau: e.target.value })}
                      isInvalid={!!validity.mau}
                      placeholder="1000"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {'Your most popular apps (iTunes, GP)'}
                      {configs.platform === 'ADX' && <span className="form-required">*</span>}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={input.appUrls}
                      onChange={e => setInput({ ...input, appUrls: e.target.value })}
                      placeholder="https://play.google.com/store/apps/details?id=myapp https://apps.apple.com/app/myapp/id12345678"
                      isInvalid={!!validity.appUrls}
                    />
                  </Form.Group>
                  {configs.platform === 'FAN' && (
                    <Form.Group>
                      <Form.Label>
                        {'Your facebook fanpages (for Ad Break)'}
                        {/* <span className="form-required">*</span> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={input.pageUrls}
                        onChange={e => setInput({ ...input, pageUrls: e.target.value })}
                        placeholder="https://facebook.com/your-page"
                        isInvalid={!!validity.pageUrls}
                      />
                    </Form.Group>
                  )}
                </>
              )}
              {error && <Alert variant="danger">{error.message || error.error || error}</Alert>}
              {step > 0 && (
                <Button loading={loading} link onClick={prevStep} type="button">
                  <Icon className="mr-1" name="arrow-left">
                    Back
                  </Icon>
                </Button>
              )}
              <Button
                disabled={!captchaReady}
                loading={loading}
                color="primary"
                className="float-right"
                type="submit"
              >
                {step === steps.length - 1 ? (
                  'Submit'
                ) : (
                  <>
                    <span>Next</span>
                    <Icon className="ml-1" name="arrow-right" />
                  </>
                )}
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="text-center">
          <span>Have an account? </span>
          <Link to="/login">Login</Link>
        </div>
      </div>
    </Centered>
  );
};

export default Register;
