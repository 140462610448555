import React, { useState, useEffect, useRef } from 'react';
import Clipboard from 'clipboard';
import { Overlay, Tooltip } from 'react-bootstrap';
import debounce from 'lodash.debounce';

const CopyableText = ({
  text, tooltipPlacement = 'top', copiedText = 'Copied', children,
}) => {
  const wrapperRef = useRef();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let hideTooltip;
    const clipboard = new Clipboard(wrapperRef.current, {
      text: () => text || children,
    });
    clipboard.on('error', e => console.log('copy failed', e));
    clipboard.on('success', () => {
      setSuccess(true);
      if (hideTooltip) {
        hideTooltip.cancel();
      }
      hideTooltip = debounce(() => setSuccess(false), 1000);
      hideTooltip();
    });
    return () => {
      clipboard.destroy();
      if (hideTooltip) {
        hideTooltip.cancel();
      }
    };
  }, [children, text]);
  return (
    <>
      <span className="copyable" title="Tap to copy" ref={wrapperRef}>
        {children || text}
      </span>
      <Overlay target={wrapperRef.current} show={success} placement={tooltipPlacement}>
        {({ show, ...props }) => <Tooltip {...props}>{copiedText}</Tooltip>}
      </Overlay>
    </>
  );
};

export default CopyableText;
