import appService from 'services/app';
import update from 'immutability-helper';
import get from 'lodash.get';
import App from 'entities/App';

export default {
  name: 'app',
  state: {
    apps: {},
    listApps: {
      total: 0,
      ids: [],
      error: null,
      loading: false,
    },
    searchApps: {
      ids: [],
    },
    warningApps: {
      ids: [],
    },
    addApp: {},
    updateApp: {},
    getAppDetail: {},
    addTag: {},
    updateTag: {},
    deleteTag: {},
    updateMulti: {},
  },
  reducers: {
    getListApps: (state, payload, effectStatus) => {
      switch (effectStatus) {
        case 'success': {
          const { skip, total, apps } = payload;
          return {
            apps: apps.reduce(
              (a, v) => ({ ...a, [v.id]: new App({ ...a[v.id], ...v }) }),
              state.apps,
            ),
            listApps: {
              ...state.listApps,
              total,
              ids: (skip === 0 ? [] : state.listApps.ids).concat(apps.map(a => a.id)),
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            listApps: {
              ...state.listApps,
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            listApps: {
              ...state.listApps,
              loading: true,
              error: null,
            },
          };
      }
    },
    searchApps: (state, payload, effectStatus) => {
      switch (effectStatus) {
        case 'success': {
          const { total, apps } = payload;
          return {
            apps: apps.reduce(
              (a, v) => ({ ...a, [v.id]: new App({ ...a[v.id], ...v }) }),
              state.apps,
            ),
            searchApps: {
              ...state.searchApps,
              total,
              ids: apps.map(a => a.id),
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            searchApps: {
              ...state.searchApps,
              ids: [],
              loading: false,
              error: null,
            },
          };
        default:
          return {
            searchApps: {
              ...state.searchApps,
              ids: [],
              loading: true,
              error: null,
            },
          };
      }
    },
    getWarningApps: (state, payload, effectStatus) => {
      switch (effectStatus) {
        case 'success': {
          const { total, apps } = payload;
          return {
            apps: apps.reduce(
              (a, v) => ({ ...a, [v.id]: new App({ ...a[v.id], ...v }) }),
              state.apps,
            ),
            warningApps: {
              ...state.warningApps,
              total,
              ids: apps.map(a => a.id),
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            warningApps: {
              ...state.warningApps,
              ids: [],
              loading: false,
              error: null,
            },
          };
        default:
          return {
            warningApps: {
              ...state.warningApps,
              ids: [],
              loading: true,
              error: null,
            },
          };
      }
    },
    addApp: (state, payload, status) => {
      switch (status) {
        case 'success': {
          return {
            apps: {
              ...state.apps,
              [payload.id]: new App(payload),
            },
            listApps: {
              ...state.listApps,
              ids: state.listApps.ids.find(i => i === payload.id)
                ? [...state.listApps.ids]
                : [payload.id, ...state.listApps.ids],
            },
            addApp: {
              id: payload.id,
              loading: false,
              error: null,
            },
          };
        }
        case 'error': {
          return {
            addApp: {
              loading: false,
              error: payload,
            },
          };
        }
        default:
          return {
            addApp: {
              loading: true,
              error: false,
            },
          };
      }
    },
    updateApp: (state, payload, status) => {
      switch (status) {
        case 'success': {
          const app = payload;
          return {
            apps: { ...state.apps, [app.id]: new App({ ...state.apps[app.id], ...app }) },
            updateApp: {
              ...state.updateApp,
              [app.id]: { loading: false, error: null },
            },
          };
        }
        case 'error': {
          const { id, error } = payload;
          return {
            updateApp: {
              ...state.updateApp,
              [id]: { loading: false, error },
            },
          };
        }
        default:
          return {
            updateApp: {
              ...state.updateApp,
              [payload.id]: { loading: true, error: null },
            },
          };
      }
    },
    getAppDetail: (state, payload, status) => {
      const { id, error } = payload;
      switch (status) {
        case 'success':
          return {
            apps: {
              ...state.apps,
              [id]: new App(payload),
            },
            getAppDetail: {
              ...state.getAppDetail,
              [id]: { loading: false, error: null },
            },
          };
        case 'error':
          return {
            getAppDetail: {
              ...state.getAppDetail,
              [id]: { loading: false, error },
            },
          };
        default:
          return {
            getAppDetail: {
              ...state.getAppDetail,
              [id]: { loading: true, error: null },
            },
          };
      }
    },
    addTag: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            apps: update(state.apps, {
              [payload.appId]: { tags: { $apply: t => [payload.tag].concat(t || []) } },
            }),
            addTag: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            addTag: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            addTag: {
              loading: true,
              error: null,
            },
          };
      }
    },
    updateTag: (state, payload, status) => {
      switch (status) {
        case 'success':
          const tagIndex = get(state, ['apps', payload.appId, 'tags'], []).findIndex(
            t => t.id === payload.tag.id,
          );
          return {
            apps: update(state.apps, {
              [payload.appId]: { tags: { [tagIndex]: { $set: payload.tag } } },
            }),
            updateTag: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            updateTag: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            updateTag: {
              loading: true,
              error: null,
            },
          };
      }
    },
    deleteTag: (state, payload, status) => {
      switch (status) {
        case 'success':
          return {
            apps: update(state.apps, {
              [payload.appId]: {
                tags: { $apply: t => (t || []).filter(t => t.id !== payload.tagId) },
              },
            }),
            deleteTag: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            deleteTag: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            deleteTag: {
              loading: true,
              error: null,
            },
          };
      }
    },
    updateMulti: (state, payload, effectStatus) => {
      switch (effectStatus) {
        case 'success':
          const { appIds, status } = payload;
          return {
            apps: appIds.reduce(
              (newApps, id) => ({ ...newApps, [id]: new App({ ...newApps[id], status }) }),
              { ...state.apps },
            ),
            updateMulti: {
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            updateMulti: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            updateMulti: {
              loading: true,
              error: null,
            },
          };
      }
    },
  },
  effects: {
    async getListApps(payload, onSuccess, onError) {
      try {
        const result = await appService.getListApps(payload);
        onSuccess({ ...result, skip: payload.skip });
      } catch (e) {
        onError(e);
      }
    },
    async updateApp({ id, data }, onSuccess, onError) {
      try {
        onSuccess(await appService.updateApp(id, data));
      } catch (e) {
        onError({ id, error: e });
      }
    },
    async getAppDetail({ id, params }, onSuccess, onError) {
      try {
        onSuccess(await appService.getAppDetail(id, params));
      } catch (e) {
        onError({ id, error: e });
      }
    },
    async addApp(payload, onSuccess, onError) {
      try {
        onSuccess(await appService.addApp(payload));
      } catch (e) {
        onError(e);
      }
    },
    async addTag({ appId, data }, onSuccess, onError) {
      try {
        const tag = await appService.addTag(appId, data);
        onSuccess({ appId, tag });
      } catch (e) {
        onError(e);
      }
    },
    async updateTag({ appId, tagId, data }, onSuccess, onError) {
      try {
        const tag = await appService.updateTag(appId, tagId, data);
        onSuccess({ appId, tag });
      } catch (e) {
        onError(e);
      }
    },
    async deleteTag({ appId, tagId }, onSuccess, onError) {
      try {
        await appService.deleteTag(appId, tagId);
        onSuccess({ appId, tagId });
      } catch (e) {
        onError(e);
      }
    },
    async searchApps(payload, onSuccess, onError) {
      try {
        onSuccess(await appService.getListApps(payload));
      } catch (e) {
        onError(e);
      }
    },
    async getWarningApps(payload, onSuccess, onError) {
      try {
        onSuccess(await appService.getListApps({ updateWarning: 1 }));
      } catch (e) {
        onError(e);
      }
    },
    async updateMulti({ appIds, status }, onSuccess, onError) {
      try {
        await appService.updateMulti({ appIds, status });
        onSuccess({ appIds, status });
      } catch (e) {
        onError(e);
      }
    },
  },
  actions: {
    getListApps: params => params,
    updateApp: (id, data) => ({ data, id }),
    getAppDetail: (id, params) => ({ id, params }),
    addApp: params => params,
    addTag: (appId, data) => ({ appId, data }),
    updateTag: (appId, tagId, data) => ({ appId, tagId, data }),
    deleteTag: (appId, tagId) => ({ appId, tagId }),
    searchApps: params => params,
    getWarningApps: () => {},
    updateMulti: params => params,
  },
};
