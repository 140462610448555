import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';

import { AppContext } from 'App';
import Icon from 'components/Icon';
import defaultIcon from 'static/images/app-icon-default.png';

import styles from './SupportInfoModal.module.scss';

const SupportInfoModal = ({
  show, onHide,
}) => {
  const { currentAccount } = useContext(AppContext);
  const { amInfo } = currentAccount;
  return (
    <Modal className={styles.supportInfoModal} show={show} onHide={onHide}>
      <Modal.Body className="text-center">
        <img className="avatar mb-3" src={amInfo.avatar || defaultIcon} alt="" />
        <h4 className="text-center">{amInfo.name}</h4>
        <div className="text-left">
          <div className="mt-3">
            <Icon name="mail" color="#C2513F" size={22} className="mr-2" />
            <a href={`mailto:${amInfo.email || 'N/A'}`}>{amInfo.email || 'N/A'}</a>
          </div>
          <div className="mt-3">
            <Icon name="phone" color="#EE9838" size={22} className="mr-2" />
            {amInfo.phone || 'N/A'}
          </div>
          <div className="mt-3">
            <Icon name="skype" color="#4CADEA" type="fa" size={22} className="mr-2" />
            {amInfo.skype || 'N/A'}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SupportInfoModal;
