import React from 'react';
import Select from 'react-select';

const InvoiceStatusInput = ({ onChange, value, ...rest }) => {
  const statusOptions = [
    { label: 'DRAFT', value: '0' },
    { label: 'SENT', value: '1' },
    { label: 'ACCEPTED', value: '2' },
    { label: 'REJECTED', value: '3' },
    { label: 'PAID', value: '4' },
  ];

  return (
    <Select
      isClearable
      className="Select"
      classNamePrefix="Select"
      options={statusOptions}
      onChange={o => onChange(o && o.value)}
      value={statusOptions.find(s => s.value === value)}
      noOptionsMessage={() => 'No result'}
      {...rest}
    />
  );
};

export default InvoiceStatusInput;
