import React, { useEffect } from 'react';
import { Modal, Alert, Form } from 'react-bootstrap';
import { Button } from 'tabler-react';
import Select from 'react-select';
import get from 'lodash.get';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import useTempState from 'utils/useTempState';

/**
 * @param {object} props
 * @param {import('entities/User').default} props.account
 */
const GenerateContractModal = ({
  show, account, onHide, templates, contract,
}) => {
  const [formState, setFormState] = useTempState(
    {
      desc: contract ? contract.desc : '',
      paymentMethodId: get(account, 'payment.defaultMethod') || '',
    },
    [show],
  );
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);

  const [{ genState }, { generateContract }] = useModel('publisher', state => ({
    genState: state.generateContract,
  }));

  useEffect(() => {
    setSubmitStatus({
      loading: genState.loading,
      error: genState.error,
    });
  }, [genState.loading, genState.error]);

  useActionEffect({
    loading: genState.loading,
    error: genState.error,
    onSuccess: onHide,
  });

  const onSubmit = () => {
    if (!formState.templateId) {
      setSubmitStatus({
        error: { message: 'Contract template is required' },
      });
      return;
    }
    const req = { accountId: account.id, ...formState };
    if (contract) {
      req.id = contract.id;
    }
    generateContract(req);
  };

  const paymentMethods = (account.payment.bankTransfers || []).map(p => ({
    id: p.id,
    name: `Bank: ${p.currency} - ${p.accName} - ${p.accNumber}`,
  }));

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{contract ? 'Re-generate Contract' : 'Generate Contract'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>
              Template
              <span className="form-required">*</span>
            </Form.Label>
            <Select
              className="Select"
              classNamePrefix="Select"
              placeholder="Select Template"
              getOptionValue={v => v.id}
              getOptionLabel={v => v.name}
              options={templates}
              value={templates.find(p => p.id === formState.templateId)}
              onChange={v => setFormState({ ...formState, templateId: v.id })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Payment method
              <span className="form-required">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              value={formState.paymentMethodId}
              onChange={e => setFormState({ ...formState, paymentMethodId: e.target.value })}
            >
              <option value="" disabled>
                Select payment method
              </option>
              {paymentMethods.map(m => (
                <option key={m.id} value={m.id}>
                  {m.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Desc</Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              placeholder="Describe why generate new contract"
              value={formState.desc}
              onChange={e => setFormState({ ...formState, desc: e.target.value })}
            />
          </Form.Group>
          {submitStatus.error && (
            <Alert className="mt-3" dismissible variant="danger">
              {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
            </Alert>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" loading={submitStatus.loading} onClick={onSubmit}>
          {contract ? 'Re-generate' : 'Generate'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateContractModal;
