import React, { useState, useRef, useEffect } from 'react';
import Chart from 'react-google-charts';
import elementResizeEvent from 'element-resize-event';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { Dropdown, DropdownButton } from 'react-bootstrap';

// eslint-disable-next-line no-unused-vars
import { FANMetric, formatMetric, getMetricName } from 'entities/Report';
import EmptyState from 'components/EmptyState';
import Icon from 'components/Icon';
import styles from './MetricChart.module.scss';

let count = 0;

/**
 * @param {Object} props
 * @param {{current: FANMetric, prev: FANMetric}} props.total
 * @param {[string, FANMetric]} props.data [date, metric]
 * @param {[string|{key:string, title:string}]} props.metrics
 */
const MetricChart = ({
  total = {},
  className,
  data = [],
  metrics,
  metric: defaultMetric,
  onChangeMetric,
  showHeader,
  showPrev,
  fixedMetric,
}) => {
  const [metric, setMetric] = useState(defaultMetric);
  const chart = useRef(null);
  const container = useRef(null);
  /** @type {{current: import("lodash").Cancelable}} */
  const resizeDebounce = useRef(null);
  useEffect(() => {
    elementResizeEvent(container.current, () => {
      if (resizeDebounce.current) {
        resizeDebounce.current.cancel();
      }
      resizeDebounce.current = debounce(() => chart.current && chart.current.draw(), 200);
      resizeDebounce.current();
    });

    return () => {
      elementResizeEvent.unbind(container.current);
      if (resizeDebounce.current) {
        resizeDebounce.current.cancel();
      }
      if (chart.current) {
        chart.current.clear();
      }
    };
  }, []);
  useEffect(() => {
    setMetric(defaultMetric);
  }, [defaultMetric]);
  const chartEvents = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper }) => {
        // TODO: ensure component hasnot been unmounted
        chart.current = chartWrapper;
      },
    },
  ];

  let format = 'decimal';
  if (metric.match(/REVENUE|ECPM|RPM|EARNINGS/)) {
    format = 'currency';
  }
  const fontName = 'Google Sans';
  const opts = {
    theme: 'material',
    chart: {
      // title: metricToTitle[metric],
    },
    chartArea: {
      left: 50,
      width: '90%',
      top: 10,
      height: 240,
    },
    colors: ['#467fcf', '#e74c3c'],
    focusTarget: 'category',
    legend: { position: 'none' },
    // axes: {
    //   y: [{ format: { pattern: format } }],
    // },
    pointsVisible: false,
    pointSize: 4,
    vAxis: {
      textStyle: { fontName },
      format,
      minorGridlines: { count: 0 },
    },
    hAxis: { textStyle: { fontName } },
    tooltip: {
      textStyle: { fontName },
      isHtml: true,
    },
  };
  // if (!total.current) {
  //   total.current = new FANMetric();
  // }
  // if (!total.prev) {
  //   total.prev = new FANMetric();
  // }
  const changes = total.current.getChange(total.prev);
  const metricNames = metrics.reduce((c, v) => ({
    ...c,
    ...(typeof v === 'object' ? { [v.key]: v.title } : { [v]: getMetricName(v) }),
  }), {});

  const renderTooltip = ([x, d]) => `
    <div class="google-visualization-tooltip-item font-weight-bold">${metricNames[metric]}</div>
    <div class="google-visualization-tooltip-item">${d.DATE || d.MONTH}: <span class="font-weight-bold" style="color:${opts.colors[0]}">${d.current.format(metric)}</span></div>
    <div class="google-visualization-tooltip-item">${d.PREV_DATE || d.PREV_MONTH}: <span class="font-weight-bold" style="color:${opts.colors[1]}">${d.prev.format(metric)}<span></div>
  `;

  const chartData = [['Date', metricNames[metric]]];
  if (showPrev) {
    chartData[0].splice(1, 0, { type: 'string', role: 'tooltip', p: { html: true } });
    chartData[0].push(`Prev ${metricNames[metric]}`);
  }

  data.forEach(d => {
    const row = [d[0], d[1].current[metric]];
    if (showPrev) {
      row.push(d[1].prev[metric]);
      row.splice(1, 0, renderTooltip(d));
    }
    chartData.push(row);
  });
  return (
    <div
      className={classNames(className, styles.wrapper)}
      ref={container}
      style={{ minHeight: 300 }}
    >
      <div className="mb-3 d-flex align-items-center">
        {showHeader && (
          <div className="total">
            <h2 className="m-0">{total.current.format(metric)}</h2>
            <h4
              className={classNames('my-1', {
                'text-success': changes[metric] > 0,
                'text-danger': changes[metric] < 0,
              })}
            >
              <Icon name={`arrow-${changes[metric] > 0 ? 'up' : 'down'}`}>
                {formatMetric('FAN_CTR', Math.abs(changes[metric]), { round: true })}
              </Icon>
            </h4>
          </div>
        )}
        {!fixedMetric && (
        <DropdownButton
          className="ml-auto"
          variant="link"
          id={`MetricChart-dropdown-${++count}`}
          title={metricNames[metric]}
          alignRight
          onSelect={k => (onChangeMetric || setMetric)(k)}
        >
          {metrics.map(m => (typeof m === 'object' ? m.key : m)).map(m => (
            <Dropdown.Item key={m} active={m === metric} eventKey={m}>
              {metricNames[m]}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        )}
      </div>
      {data.length > 0 && (
      <Chart
        className="metric-chart"
        height="300px"
        width="100%"
        chartType="LineChart"
        chartEvents={chartEvents}
        loader={<div>Loading...</div>}
        data={chartData}
        options={opts}
      />
      )}
      {!data.length && <EmptyState className="mt-3" icon="trending-up" desc="No data" />}
    </div>
  );
};

export default MetricChart;
