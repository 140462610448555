import React, { useState, useContext, useEffect } from 'react';
import {
 Badge, Dropdown, OverlayTrigger, Popover 
} from 'react-bootstrap';
import { Card, Loader } from 'tabler-react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import Account from 'entities/Account';
import SharingConfigs from 'components/Settings/SharingConfigs';
import ListContract from 'components/Settings/ListContract';
import AccountInfo from 'components/AccountInfo';
import TeamMember from 'components/Settings/TeamMember';
import PaymentSetting from 'components/PaymentSetting';
import PublisherReferrals from 'components/PublisherReferrals';
import PublisherInvoices from 'components/PublisherInvoices';
import PublisherApps from 'components/PublisherApps';
import PublisherPages from 'components/PublisherPages';
import User from 'entities/User';
import { AppContext } from 'App';
import AssignPublisherModal from 'components/AssignPublisherModal';
import { useModel } from 'models';
import Icon from 'components/Icon';
import configs from 'configs/configs';
import AdmobSettings from 'components/AdmobSettings';
import NavCard from '../NavCard';
import styles from './AccountDetailCard.module.scss';
import CopyableText from '../CopyableText';
import APIKey from './APIKey';

const AccountDetailCard = ({
  className,
  account: acc,
  activeTab,
  onChangeTab,
  accountId,
  history,
}) => {
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const account = new Account(acc);
  const [{ updateState, generateApiKeyState }, { updatePublisher, generateApiKey }] = useModel(
    'publisher',
    state => ({
      updateState: { ...state.updatePublisher[account.id] },
      generateApiKeyState: { ...state.generateApiKey },
    }),
    account.id,
  );
  const prevLoading = usePrevious(updateState.loading);
  useEffect(() => {
    if (prevLoading && !updateState.loading) {
      setAssignModalVisible(false);
    }
  }, [updateState.loading]);

  const { currentAccount, currentUser, dialog } = useContext(AppContext);

  const onUnAssign = () => {
    dialog.warning({
      title: 'Unassign managers',
      content: 'Are you sure want to unassign all managers of this publisher?',
      onOK: d => {
        updatePublisher(account.id, { AM: [] });
        d.dismiss();
      },
    });
  };

  const onGenerateApiKey = () => {
    dialog.warning({
      title: 'Regenerate API Key',
      content: 'Are you sure want to regenerate new API key, current key will be invalid?',
      onOK: d => {
        generateApiKey(account.id);
        d.dismiss();
      },
    });
  };

  const header = (
    <Card.Header>
      <Card.Title>
        <span>{account.name}</span>
        <StatusBadge
          status={account.status}
          deactivatedReason={account.deactivatedReason}
          className="ml-1"
        />
        <small className="m-0 text-muted">
          {'ID: '}
          <CopyableText>{account.id}</CopyableText>
        </small>
      </Card.Title>
      <Card.Options>
        <Dropdown alignRight>
          <Dropdown.Toggle className="p-0" variant="link">
            <Icon name="ellipsis-v" type="fa" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {currentUser.hasPermission(User.PermPubInvoiceEdit) && (
              <Dropdown.Item onClick={() => history.push(`/publishers/${account.id}/invoices`)}>
                Create invoice
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => history.push(`/app-reports?breakdown=publisher&pid=${account.id}`)}
            >
              View report
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setAssignModalVisible(true)}>
              Assign managers
            </Dropdown.Item>
            {account.AM && account.AM.length > 0 && (
              <Dropdown.Item onClick={onUnAssign}>Unassign managers</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Card.Options>
    </Card.Header>
  );

  const nav = [
    { key: 'info', title: 'Account Info', content: <AccountInfo account={account} /> },
    { key: 'payment', title: 'Payment', content: <PaymentSetting account={account} /> },
    {
      key: 'member',
      title: 'Members',
      show: !account.isPersonal() || (account.team && account.team.length > 1),
      content: <TeamMember account={account} members={account.team} viewOnly />,
    },
    {
      key: 'settings',
      title: 'Settings',
      content: (
        <SharingConfigs
          accountId={accountId}
          adbreakEnabled={acc.adbreakEnabled}
          configs={acc.sharingConfig || []}
          adBreakConfigs={acc.adbreakSharingConfigs || []}
        />
      ),
    },
    {
      key: 'contracts',
      show: account.isPersonal(),
      title: 'Contracts',
      content: !!account.id && <ListContract account={account} />,
    },
    {
      key: 'referrals',
      show: configs.platform === 'ADX',
      title: 'Referrals',
      content: !!account.id && <PublisherReferrals account={account} />,
    },
    {
      key: 'invoices',
      show: currentAccount.isAdmin(),
      title: 'Invoices',
      content: !!account.id && <PublisherInvoices account={account} />,
    },
    {
      key: 'apps',
      show: currentAccount.isAdmin(),
      title: 'Apps',
      content: !!account.id && <PublisherApps account={account} />,
    },
    {
      key: 'pages',
      show: configs.platform === 'FAN' && currentAccount.isAdmin() && !!account.adbreakEnabled,
      title: 'Pages',
      content: !!account.id && <PublisherPages account={account} />,
    },
    {
      key: 'apiKey',
      show: configs.platform === 'ADX',
      title: 'API Key',
      content: !!account.id && (
        <APIKey
          generateState={generateApiKeyState}
          apiKey={account.apiKey || {}}
          onGenerate={account.isActivated() && currentUser.isAdmin() ? onGenerateApiKey : null}
        />
      ),
    },
    {
      key: 'admob',
      show: configs.platform === 'ADX',
      title: 'Admob',
      content: <AdmobSettings accountId={account.id} config={account.admobConfig || {}} />,
    },
  ];

  const showNav = nav.filter(n => n.show === undefined || n.show);
  let activeNav = showNav.find(n => n.key === activeTab);
  if (!activeNav) {
    [activeNav] = showNav;
  }

  return (
    <NavCard
      className={classNames(className, styles.card)}
      header={header}
      nav={showNav.map(({ content, ...rest }) => rest)}
      activeKey={activeNav.key}
      vertical
      onNavigate={onChangeTab}
    >
      {activeNav.content}
      {!account.id && <Loader className="card-loading" />}
      <AssignPublisherModal
        show={assignModalVisible}
        publisherAM={account.AM}
        loading={updateState.loading}
        error={updateState.error}
        onSubmit={AM => updatePublisher(account.id, { AM })}
        onHide={() => setAssignModalVisible(false)}
      />
    </NavCard>
  );
};

export default withRouter(AccountDetailCard);

export const StatusBadge = ({ status, deactivatedReason, ...rest }) => {
  const statuses = ['', 'Pending', 'Activated', 'Deactivated'];
  const variants = ['', 'secondary', 'primary', 'danger'];

  const pop = (
    <Popover id="reason-pop">
      <Popover.Title>Reason</Popover.Title>
      <Popover.Content>{deactivatedReason}</Popover.Content>
    </Popover>
  );

  return (
    <>
      <Badge pill variant={variants[status]} {...rest}>
        {statuses[status]}
      </Badge>
      {status === 3 && deactivatedReason && (
        <OverlayTrigger placement="auto-start" rootClose trigger="click" overlay={pop}>
          <Icon name="info" className="ml-1 text-info" />
        </OverlayTrigger>
      )}
    </>
  );
};
