import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import {
  Alert, Modal, Button, Form,
} from 'react-bootstrap';
import Select from 'react-select';
import { Formik } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import { useModel } from 'models';
import { AppContext } from 'App';
import useActionEffect from 'utils/useActionEffect';
import PublisherInput from 'components/PublisherInput';
import configs from 'configs/configs';

const AddAppModal = ({ show, onSuccess, onCancel }) => {
  const [published, setPublished] = useState(true);
  const form = useRef();
  const [{ id, loading, error }, { addApp }] = useModel('app', state => ({
    ...state.addApp,
  }));
  const prevLoading = usePrevious(loading);

  const { currentAccount } = useContext(AppContext);

  useActionEffect({ loading, error, onSuccess: () => onSuccess(id) });

  useEffect(() => {
    form.current.resetForm();
  }, [show]);

  const onSubmit = configs.platform === 'ADX'
    ? ({
      accountId, platform, storeAppId, name,
    }) => addApp({
      accountId,
      platform,
      name,
      storeAppId: published ? storeAppId : null,
    })
    : ({ businessId, appId, accountId }) => addApp({ businessId, appId, accountId });

  const schema = configs.platform === 'ADX'
    ? yup.object({
      platform: yup.string().required('This field is required'),
      ...(published
        ? {
          storeAppId: yup.string().required('This field is required'),
        }
        : {
          name: yup.string().required('This field is required'),
        }),
      accountId: currentAccount.isAdmin() && yup.string().required('This field is requried'),
    })
    : yup.object({
      businessId: yup.string().required('Business is required'),
      accountId: yup.string().required('Publisher is required'),
      appId: yup.string().required('App ID is required'),
    });

  return (
    <Formik
      ref={form}
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        accountId: '',
        platform: '',
        storeAppId: '',
        name: '',
        appId: '',
        businessId: '',
      }}
    >
      {({
        values,
        dirty,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        handleBlur,
      }) => {
        const isError = name => errors[name] && touched[name];
        const platformOptions = [
          { value: 'ios', label: 'iOS' },
          { value: 'android', label: 'Android' },
        ];

        const getStorePlaceholder = () => {
          if (!values.platform) {
            return 'Please select platform first';
          }
          if (values.platform === 'ios') {
            return 'https://apps.apple.com/app/myapp/id123456789';
          }
          return 'com.example';
        };

        const businesses = currentAccount.isAdmin() ? currentAccount
          .getSystemSettings()
          .fanBusinesses.filter(b => b.status === 'active') : [];

        return (
          <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {configs.platform === 'FAN' && (
                <Form.Group>
                  <Select
                    classNamePrefix="Select"
                    className={classNames('Select', { 'is-invalid': isError('businessId') })}
                    options={businesses}
                    getOptionValue={b => b.id}
                    getOptionLabel={b => b.name}
                    placeholder="Business Account"
                    value={
                      values.businessId ? businesses.find(b => b.id === values.businessId) : null
                    }
                    onChange={b => setFieldValue('businessId', b ? b.id : '')}
                    onBlur={() => setFieldTouched('businessId', true)}
                    noOptionsMessage={() => 'No business account'}
                  />
                  {isError('businessId') && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.businessId}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              {currentAccount.isAdmin() && (
                <Form.Group>
                  <PublisherInput
                    placeholder="Publisher"
                    value={values.accountId}
                    onBlur={() => setFieldTouched('accountId', true)}
                    onChange={p => setFieldValue('accountId', p ? p.id : '')}
                    error={isError('accountId') && errors.accountId}
                  />
                </Form.Group>
              )}
              {configs.platform === 'FAN' && (
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="appId"
                    placeholder="App ID"
                    value={values.appId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={isError('appId')}
                  />
                  {isError('appId') && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.appId}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              {configs.platform === 'ADX' && (
                <Form.Group>
                  <Select
                    className="Select"
                    classNamePrefix="Select"
                    placeholder="Select platform"
                    options={platformOptions}
                    value={platformOptions.find(p => p.value === values.platform)}
                    onChange={({ value }) => setFieldValue('platform', value)}
                    onBlur={() => setFieldTouched('platform', true)}
                    isInvalid={isError('platform')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.platform}</Form.Control.Feedback>
                </Form.Group>
              )}
              {configs.platform === 'ADX' && currentAccount.isAdmin() && (
                <Form.Check
                  className="my-3"
                  id="no-store"
                  type="checkbox"
                  checked={!published}
                  onChange={e => setPublished(!e.target.checked)}
                  label="This app has not published to store yet"
                />
              )}
              {configs.platform === 'ADX' && published && (
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder={getStorePlaceholder()}
                    name="storeAppId"
                    value={values.storeAppId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={isError('storeAppId')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.storeAppId}</Form.Control.Feedback>
                </Form.Group>
              )}
              {configs.platform === 'ADX' && !published && (
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="App Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={isError('name')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
              )}
              {error && !loading && prevLoading && (
                <Alert className="mt-3" variant="danger">
                  {error.message || error.error || 'Something went wrong.'}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onCancel}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={!dirty || loading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddAppModal;
