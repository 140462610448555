import configs from 'configs/configs';
import api from './api';

export default {
  getListApps(params) {
    let path = '/apps';
    if (configs.platform === 'FAN') {
      path = '/fan/apps';
    }
    return api.GET(path, params);
  },
  addApp(params) {
    let path = '/apps';
    if (configs.platform === 'FAN') {
      path = '/fan/apps';
    }
    return api.POST(path, params);
  },
  updateApp(id, data) {
    let path = `/apps/${id}`;
    if (configs.platform === 'FAN') {
      path = `/fan/apps/${id}`;
    }
    return api.POST(path, data);
  },
  getAppDetail(id, data) {
    let path = `/apps/${id}`;
    if (configs.platform === 'FAN') {
      path = `/fan/apps/${id}`;
    }
    return api.GET(path, data);
  },
  addTag(appId, data) {
    let path = `/apps/${appId}/tags`;
    if (configs.platform === 'FAN') {
      path = `/fan/apps/${appId}/tags`;
    }
    return api.POST(path, data);
  },
  updateTag(appId, tagId, data) {
    let path = `/apps/${appId}/tags/${tagId}`;
    if (configs.platform === 'FAN') {
      path = `/fan/apps/${appId}/tags${tagId}`;
    }
    return api.POST(path, data);
  },
  deleteTag(appId, tagId) {
    let path = `/apps/${appId}/tags/${tagId}`;
    if (configs.platform === 'FAN') {
      path = `/fan/apps/${appId}/tags${tagId}`;
    }
    return api.DELETE(path);
  },
  updateMulti(params) {
    return api.POST('/multi_apps', params);
  },
};
