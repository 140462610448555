import React, { useState } from 'react';
import classNames from 'classnames';

import MonthPicker from '../MonthPicker/MonthPicker';

import styles from './MonthRangePicker.module.scss';

const MonthRangePicker = ({
  className, onChange, pastScrollRange, startMonth, endMonth,
}) => {
  const [focused, setFocused] = useState('');
  const [value, setValue] = useState({ startMonth, endMonth });

  const onMonthChange = (v) => {
    let start = v.startMonth;
    let end = v.endMonth;
    if (v.endMonth.isBefore(v.startMonth, 'month')) {
      if (v.startMonth.isSame(value.startMonth, 'month')) {
        start = end.clone();
      } else {
        end = start.clone();
      }
    }
    setValue({ startMonth: start, endMonth: end });
    onChange(start, end);
  };

  return (
    <div className={classNames(className, styles.monthRangePicker)}>
      <MonthPicker
        menuIsOpen={focused === 'start'}
        placeholder="Start month"
        pastScrollRange={pastScrollRange}
        value={startMonth}
        onChange={v => onMonthChange({ startMonth: v, endMonth: value.endMonth })}
        onMenuOpen={() => setFocused('start')}
        onMenuClose={() => setFocused('')}
      />
      <span>-</span>
      <MonthPicker
        menuIsOpen={focused === 'end'}
        placeholder="End month"
        pastScrollRange={pastScrollRange}
        value={endMonth}
        onChange={v => onMonthChange({ startMonth: value.startMonth, endMonth: v })}
        onMenuOpen={() => setFocused('end')}
        onMenuClose={() => setFocused('')}
      />
    </div>
  );
};

export default MonthRangePicker;
