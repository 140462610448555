import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { usePrevious } from 'react-use';

import { AppContext } from 'App';
import Icon from 'components/Icon';
import Notification from 'components/Notification';
import AddNetworkConfigModal from 'components/AddNetworkConfigModal';
import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import User from 'entities/User';
import Account from 'entities/Account';
import configs from 'configs/configs';
import styles from './AccountInfo.module.scss';
import UpdateAccountModal from './UpdateAccountModal';
import AccountInfoForm from './AccountInfoForm';

const colProps = {
  xs: 5,
  sm: 4,
  md: 3,
};

const actionMessages = {
  lock: 'This account has been locked',
  unlock: 'This account has been unlocked',
  reactivate: 'This account has been activated',
  updateAdxConfigs: "Publisher's ADX configs has been updated",
};

/**
 * @param {Object} props
 * @param {import('entities/Account').default} props.account
 */
const AccountInfo = ({ account }) => {
  const [displayMode, setDisplayMode] = useState('view');
  const [action, setAction] = useState('');

  const [{ updateState }, { updatePublisher }] = useModel(
    'publisher',
    state => ({
      updateState: state.updatePublisher[account.id] || {},
    }),
    account.id,
  );

  /** @type {{currentUser:import('entities/User').default, currentAccount: import('entities/Account').default}} */
  const { currentUser, currentAccount, dialog } = useContext(AppContext);

  useActionEffect({
    loading: updateState.loading,
    error: updateState.error,
    onError: () => {
      if (!action) {
        Notification.error(
          updateState.error.message || updateState.error.error || 'Something went wrong.',
        );
      }
    },
    onSuccess: () => {
      if (displayMode === 'edit') {
        setDisplayMode('view');
      }
      // eslint-disable-next-line no-unused-expressions
      actionMessages[action] && Notification.success(actionMessages[action]);
      setAction('');
    },
  });

  const onActivateAccount = () => {
    if ((account.adxId || configs.platform === 'FAN') && account.sharingConfig) {
      dialog.warning({
        title: 'Activate account',
        content: 'Are you sure want to activate this account?',
        onOK: (d) => {
          setAction('reactivate');
          updatePublisher(account.id, { status: Account.STATUS_ACTIVATED });
          d.dismiss();
        },
      });
    } else {
      setAction('activate');
    }
  };

  const onUpdateAdxConfig = (adxConfigs) => {
    const params = {
      adxConfigs,
      ...(adxConfigs[Account.ADX_NETWORK_2] || {}), // backword compatible
    };
    setAction('updateAdxConfigs');
    updatePublisher(account.id, params);
  };

  const onLockAccount = () => {
    dialog.warning({
      title: 'Lock account',
      content:
        'Lock account cause publishers cannot update their information. Are you sure to lock this account?',
      onOK: (d) => {
        setAction('lock');
        updatePublisher(account.id, { locked: true });
        d.dismiss();
      },
    });
  };

  const onUnlock = () => {
    setAction('unlock');
    updatePublisher(account.id, { locked: false });
  };

  if (!account.id) {
    return '';
  }

  const canEdit = currentUser.hasPermission(User.PermPubInfoEdit);

  const {
    company = {}, contactInfo = {}, PIN = {}, amInfo = {}, trafficInfo = {}, team,
  } = account;

  if (displayMode === 'edit') {
    return (
      <AccountInfoForm
        initialValues={account.getFormData()}
        submitting={updateState.loading}
        submitError={updateState.error}
        onSubmit={values => updatePublisher(account.id, values)}
        onCancel={() => setDisplayMode('view')}
        currentAccount={currentAccount}
      />
    );
  }

  return (
    <Row className={styles.main}>
      <Col {...colProps}>
        <strong>Account Type:</strong>
      </Col>
      <Col>{account.isPersonal() ? 'Personal' : 'Company'}</Col>
      <div className="w-100" />
      {!account.isPersonal() && (
        <>
          <Col {...colProps}>
            <strong>Company:</strong>
          </Col>
          <Col>{company.name}</Col>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Tax Code:</strong>
          </Col>
          <Col>{company.taxCode}</Col>
        </>
      )}
      {account.isPersonal() && (
        <>
          <Col {...colProps}>
            <strong>Username:</strong>
          </Col>
          <Col>{team[0].username}</Col>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>ID/Passport:</strong>
          </Col>
          <Col>{PIN.number}</Col>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Issue Date:</strong>
          </Col>
          <Col>{PIN.issDate}</Col>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Issue Place:</strong>
          </Col>
          <Col>{PIN.issPlace}</Col>
        </>
      )}
      {account.isPublisher() && currentAccount.isAdmin() && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>AM:</strong>
          </Col>
          <Col>{amInfo.id ? `${amInfo.name} (${amInfo.email})` : 'N/A'}</Col>
          {account.inviteUrl && configs.platform === 'ADX' && (
            <>
              <div className="w-100" />
              <Col {...colProps}>
                <strong>Invite URL:</strong>
              </Col>
              <Col style={{ wordBreak: 'break-all' }}>{account.inviteUrl}</Col>
            </>
          )}
        </>
      )}
      {configs.platform === 'FAN' && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Ad break:</strong>
          </Col>
          <Col>{account.adbreakEnabled ? 'Enabled' : 'Disabled'}</Col>
        </>
      )}
      <div className="w-100" />
      <Col {...colProps}>
        <strong>Registered Date:</strong>
      </Col>
      <Col>{moment(account.ts).format('lll')}</Col>
      {configs.platform === 'ADX' && currentAccount.isAdmin() && (
        <ADXConfig
          adxConfigs={account.adxConfigs}
          canEdit={canEdit}
          onUpdate={onUpdateAdxConfig}
          updateState={action === 'updateAdxConfigs' ? updateState : {}}
          accountIsActivated={account.isActivated()}
        />
      )}
      <h5 className="col-12 py-3">Contact Info</h5>
      <Col {...colProps}>
        <strong>Email:</strong>
      </Col>
      <Col>{contactInfo.email}</Col>
      <div className="w-100" />
      <Col {...colProps}>
        <strong>Phone:</strong>
      </Col>
      <Col>{contactInfo.phone}</Col>
      <div className="w-100" />
      <Col {...colProps}>
        <strong>Country:</strong>
      </Col>
      <Col>{contactInfo.country}</Col>
      <div className="w-100" />
      <Col {...colProps}>
        <strong>City:</strong>
      </Col>
      <Col>{contactInfo.city}</Col>
      <div className="w-100" />
      <Col {...colProps}>
        <strong>Address:</strong>
      </Col>
      <Col>{contactInfo.address}</Col>
      {contactInfo.facebook && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Facebook:</strong>
          </Col>
          <Col>{contactInfo.facebook}</Col>
        </>
      )}
      {contactInfo.skype && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Skype:</strong>
          </Col>
          <Col>{contactInfo.skype}</Col>
        </>
      )}
      {contactInfo.twitter && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Twitter:</strong>
          </Col>
          <Col>{contactInfo.twitter}</Col>
        </>
      )}
      {contactInfo.telegram && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Telegram:</strong>
          </Col>
          <Col>{contactInfo.telegram}</Col>
        </>
      )}
      {contactInfo.whatsapp && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>WhatsApp:</strong>
          </Col>
          <Col>{contactInfo.whatsapp}</Col>
        </>
      )}
      {contactInfo.wechat && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>Wechat:</strong>
          </Col>
          <Col>{contactInfo.wechat}</Col>
        </>
      )}
      {contactInfo.qq && (
        <>
          <div className="w-100" />
          <Col {...colProps}>
            <strong>QQ:</strong>
          </Col>
          <Col>{contactInfo.qq}</Col>
        </>
      )}
      <div className="w-100" />
      <Col {...colProps}>
        <strong>Website:</strong>
      </Col>
      <Col>
        <a href={contactInfo.website} target="_blank" rel="noopener noreferrer">
          {contactInfo.website || 'N/A'}
        </a>
      </Col>
      <h5 className="col-12 py-3">Traffic Info</h5>
      <Col {...colProps}>
        <strong>MAU:</strong>
      </Col>
      <Col>{trafficInfo.mau}</Col>
      <div className="w-100" />
      <Col {...colProps}>
        <strong>Apps:</strong>
      </Col>
      <Col>
        {(trafficInfo.appUrls || []).map((u, i) => (
          <div key={i}>
            <a
              href={u}
              style={{ wordBreak: 'break-all' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {u}
            </a>
          </div>
        ))}
      </Col>
      <div className="w-100" />
      <Col {...colProps}>
        <strong>Pages:</strong>
      </Col>
      <Col>
        {(trafficInfo.pageUrls || []).map((u, i) => (
          <div key={i}>
            <a
              href={u}
              style={{ wordBreak: 'break-all' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {u}
            </a>
          </div>
        ))}
        {!trafficInfo.pageUrls && 'N/A'}
      </Col>
      {canEdit && (
        <>
          <div className="w-100" />
          <div className="text-center w-100 border-top pt-3 mt-5">
            <Button key="edit" className="mr-2" onClick={() => setDisplayMode('edit')}>
              <Icon name="edit" className="mr-2" />
              Edit
            </Button>
            {currentAccount.isAdmin() && (
              <>
                {!account.adxId && !account.inviteUrl && configs.platform === 'ADX' && (
                  <Button className="mr-2" variant="info" onClick={() => setAction('invite')}>
                    Send invite
                  </Button>
                )}
                {!account.isDeactivated() && (
                  <Button className="mr-2" variant="danger" onClick={() => setAction('deactivate')}>
                    Deactivate
                  </Button>
                )}
                {!account.isActivated() && (
                  <Button className="mr-2" variant="success" onClick={onActivateAccount}>
                    Activate
                  </Button>
                )}
                {account.isActivated() && !account.locked && configs.platform === 'ADX' && (
                  <Button key="lock" variant="warning" onClick={onLockAccount}>
                    <Icon name="lock" />
                  </Button>
                )}
                {account.isActivated() && account.locked && configs.platform === 'ADX' && (
                  <Button key="unlock" variant="success" onClick={onUnlock}>
                    <Icon name="unlock" />
                  </Button>
                )}
                <UpdateAccountModal
                  show={['activate', 'deactivate', 'invite'].some(v => v === action)}
                  action={action}
                  account={account}
                  loading={updateState.loading}
                  error={updateState.error}
                  submitError={updateState.error}
                  onSubmit={data => updatePublisher(account.id, data)}
                  onCancel={() => setAction('')}
                />
              </>
            )}
          </div>
        </>
      )}
    </Row>
  );
};

const networkNames = {
  [Account.ADX_NETWORK_1]: 'Appota Corporation',
  [Account.ADX_NETWORK_2]: 'APPOTA INC PTE. LTD',
};

const ADXConfig = ({
  adxConfigs = {}, canEdit, onUpdate, updateState = {}, accountIsActivated,
}) => {
  const [action, setAction] = useState();
  const canAddMore = canEdit && (!adxConfigs[Account.ADX_NETWORK_1] || !adxConfigs[Account.ADX_NETWORK_2]);
  const prevLoading = usePrevious(updateState.loading);
  useEffect(() => {
    if (prevLoading && !updateState.loading) {
      setAction('');
    }
  }, [updateState.loading]);

  const onSubmit = ({ networkId, adxId, dfpAdUnitID }) => onUpdate({
    ...adxConfigs,
    [networkId]: { adxId, dfpAdUnitID },
  });

  let networkId = action && action !== 'add' ? action : '';
  if (action === 'add') {
    if (adxConfigs[Account.ADX_NETWORK_1]) {
      networkId = Account.ADX_NETWORK_2;
    } else if (adxConfigs[Account.ADX_NETWORK_2]) {
      networkId = Account.ADX_NETWORK_1;
    }
  }

  const editing = adxConfigs[action] || {};

  return (
    <>
      <h5 className="col-12 py-3">
        ADX Configs
        {canAddMore && (
          <Button className="ml-2" size="sm" onClick={() => setAction('add')}>
            Add new
          </Button>
        )}
      </h5>
      {Object.keys(adxConfigs).length === 0 && (
        <Col xs={12} className="text-muted">
          No config
        </Col>
      )}
      {Object.keys(adxConfigs).map(n => (
        <React.Fragment key={n}>
          <Col xs={12} className="mb-2">
            <strong>{`${networkNames[n]} (${n})`}</strong>
          </Col>
          <div className="w-100" />
          <Col {...colProps} className="ml-4">
            <strong>ADX ID:</strong>
          </Col>
          <Col>{adxConfigs[n].adxId || 'N/A'}</Col>
          <div className="w-100" />
          <Col {...colProps} className="ml-4">
            <strong>Adunit ID:</strong>
          </Col>
          <Col>
            {adxConfigs[n].dfpAdUnitID || 'N/A'}
            {canEdit && !adxConfigs[n].dfpAdUnitID && (
              <Button className="p-0 mb-2" variant="link" onClick={() => setAction(n)}>
                <Icon size={14} name="edit" />
              </Button>
            )}
          </Col>
        </React.Fragment>
      ))}
      <AddNetworkConfigModal
        show={!!action}
        onSubmit={onSubmit}
        loading={false}
        error={null}
        onCancel={() => setAction('')}
        networkId={networkId}
        config={editing}
        canAutoGenerateAdunitID={accountIsActivated}
      />
    </>
  );
};

export default AccountInfo;
