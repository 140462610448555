import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  InputGroup, Button, Media, Row, Col, Container, Alert,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import defaultIcon from 'static/images/app-icon-default.png';
import PublisherInput from 'components/PublisherInput';
import Notification from 'components/Notification';
import CopyableText from 'components/CopyableText';
import { AppContext } from 'App';
import { useModel } from 'models';
import Page from 'entities/Page';

import EmptyState from '../EmptyState';
import Icon from '../Icon';
import styles from './PageDetail.module.scss';

const PageDetail = ({ pageId }) => {
  const [update, setUpdate] = useState({});
  const [{ page, loading, error }, { getPageDetail, updatePage }] = useModel('page', state => ({
    page: state.pages[pageId] || {},
    ...(state.updatePage[pageId] || {}),
  }), pageId);

  const prevLoading = useRef(loading);
  useEffect(() => {
    if (prevLoading.current && !loading) {
      // update finish
      if (error) {
        Notification.error(error.message || error.error || 'Something went wrong', 1);
      } else {
        Notification.success(`Page ${page.name} has been updated`);
        setUpdate({});
      }
    }
    prevLoading.current = loading;
  }, [loading]);

  useEffect(() => {
    if (!pageId) {
      return;
    }
    getPageDetail(pageId);
  }, [pageId]);

  /** @type {{currentUser:import('entities/User').default, currentAccount: import('entities/Account').default}} */
  const { currentAccount, dialog } = useContext(AppContext);

  if (!pageId) {
    return <EmptyState className="my-5" icon="box" desc="Select a page to view detail" />;
  }
  if (loading !== undefined && !page.id) {
    return (
      <Alert
        className="my-5 mx-3"
        variant="danger"
      >
        {`Page with ID '${pageId}' not exists`}
      </Alert>
    );
  }
  const assignPublisher = () => {
    dialog.warning({
      title: 'Are you sure?',
      content: 'You cannot re-assign publisher after submit.',
      onOK: (d) => {
        if (!update.accountId) {
          return;
        }
        updatePage(page.id, { accountId: update.accountId });
        d.dismiss();
      },
    });
  };

  const onDeactivate = () => {
    dialog.warning({
      title: 'Deactivate page',
      content: 'Are you sure want to deactivate this page?',
      onOK: (d) => {
        updatePage(page.id, { status: Page.STATUS_INACTIVE });
        d.dismiss();
      },
    });
  };

  const onActivatePage = () => {
    dialog.warning({
      title: 'Activate page',
      content: 'Are you sure want to activate this page?',
      onOK: (d) => {
        updatePage(page.id, { status: Page.STATUS_ACTIVE });
        d.dismiss();
      },
    });
  };

  const renderToggleStatusButton = () => {
    if (page.status === Page.STATUS_ACTIVE) {
      return (
        <Button size="sm" variant="danger" className="d-flex align-items-center ml-2" onClick={onDeactivate}>
          Deactivate
        </Button>
      );
    }
    return (
      <Button size="sm" variant="success" className="d-flex align-items-center ml-2" onClick={onActivatePage}>
        Activate
      </Button>
    );
  };


  const colProps = {
    xs: 12,
    sm: 4,
    md: 3,
    lg: 2,
  };

  if (!page.id) {
    return null;
  }

  return (
    <div className={styles.pageDetail}>
      <Media className="p-2 align-items-center">
        <img className="page-icon mr-2 rounded" src={page.picture || defaultIcon} alt="" />
        <Media.Body>
          <h5 className="m-0 page-name">
            {page.name}
            {page.link && (
              <a
                className="ml-2"
                href={page.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon className="align-top" name="external-link" />
              </a>
            )}
          </h5>
          <small className="text-muted">
            ID:
            {' '}
            <CopyableText>{page.id}</CopyableText>
          </small>
        </Media.Body>
        <Button
          size="sm"
          variant="secondary"
          className="mr-1"
          as={Link}
          to={{ pathname: '/page-reports', search: qs.stringify({ pgid: page.id }) }}
        >
          View reports
        </Button>
      </Media>
      <Container fluid className="main-info py-3">
        <Row className="mb-2">
          <Col {...colProps}>
            <strong>Status:</strong>
          </Col>
          <Col className="d-flex page-status">
            <span>{page.statusString()}</span>
            {currentAccount.isAdmin() && renderToggleStatusButton()}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col {...colProps}>
            <strong>Username:</strong>
          </Col>
          <Col>{page.username}</Col>
        </Row>
        {currentAccount.isAdmin() && (
          <Row className="mb-2">
            <Col {...colProps}>
              <strong>Publisher:</strong>
            </Col>
            <Col>
              {page.publisher && (
                <Link to={`/publishers/${page.publisher.id}`}>{page.publisher.name}</Link>
              )}
              {!page.accountId && (
                <InputGroup className="assign-pub-input" size="sm">
                  <PublisherInput
                    placeholder="Search publisher"
                    onChange={p => setUpdate({ ...update, accountId: p ? p.id : undefined })}
                    value={update.accountId}
                  />
                  <InputGroup.Append>
                    <Button size="sm" onClick={assignPublisher}>
                      Assign
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              )}
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col {...colProps}>
            <strong>About:</strong>
          </Col>
          <Col>{page.about}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageDetail;
