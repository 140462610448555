import React from 'react';
import Select from 'react-select';
import {
  Row, Col, Button, Form, InputGroup,
} from 'react-bootstrap';
import update from 'immutability-helper';
import classNames from 'classnames';

import Icon from 'components/Icon';

import styles from './MonitorTriggerList.module.scss';

const MonitorTriggerList = ({ value: triggers = [], errors = [], onChange }) => {
  const exprOptions = [
    { value: 'advanced_expression', label: 'ADVANCED EXPRESSION' },
    { value: '{REQUESTS}', label: 'REQUESTS' },
    { value: '{MATCHED_REQUESTS}', label: 'MATCHED_REQUESTS' },
    { value: '{IMPRESSIONS}', label: 'IMPRESSIONS' },
    { value: '{CLICKS}', label: 'CLICKS' },
    { value: '{EARNINGS}', label: 'EARNINGS' },
  ];

  const onRemove = (index) => {
    onChange(update(triggers, { $splice: [[index, 1]] }));
  };

  const onSelectExpr = (index, expr) => {
    onChange(
      update(triggers, {
        [index]: {
          $apply: t => ({
            ...t,
            ...(expr === 'advanced_expression' ? { advance: true } : { expr, advance: false }),
          }),
        },
      }),
    );
  };

  const onChangeExpr = (index, expr) => {
    onChange(
      update(triggers, {
        [index]: {
          $apply: t => ({ ...t, expr }),
        },
      }),
    );
  };

  const onChangeOp = (index, op) => {
    onChange(
      update(triggers, {
        [index]: {
          $apply: t => ({
            ...t,
            op,
          }),
        },
      }),
    );
  };

  const onChangeThreshold = (index, threshold) => {
    onChange(
      update(triggers, {
        [index]: {
          $apply: t => ({
            ...t,
            threshold,
          }),
        },
      }),
    );
  };

  return (
    <div className={styles.monitorTriggerList}>
      {triggers.map((t, index) => (
        <Row key={index}>
          <Col sm={6} className="mb-3">
            <Select
              className={classNames('Select', { 'is-invalid': errors[index] })}
              classNamePrefix="Select"
              placeholder="Expression"
              options={exprOptions}
              value={t.advance ? exprOptions[0] : exprOptions.find(expr => expr.value === t.expr)}
              onChange={expr => onSelectExpr(index, expr.value)}
            />
            {t.advance && (
              <Form.Control
                as="textarea"
                row="2"
                placeholder="Expression"
                value={t.expr}
                onChange={e => onChangeExpr(index, e.target.value)}
                isInvalid={errors[index]}
              />
            )}
            {errors[index] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors[index].expr}
              </Form.Control.Feedback>
            )}
          </Col>
          <Col sm={5} className="rightCol d-flex mb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <Button onClick={() => onChangeOp(index, t.op === '>=' ? '<=' : '>=')}>
                  {t.op}
                </Button>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                min={0}
                placeholder="Threshold"
                value={t.threshold}
                onChange={e => onChangeThreshold(index, e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col sm={1} className="mb-3">
            <Button
              block
              variant="danger"
              disabled={triggers.length <= 1}
              onClick={() => onRemove(index)}
            >
              <Icon name="x" />
            </Button>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default MonitorTriggerList;
