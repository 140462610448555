/* eslint-disable import/prefer-default-export */

import { stringify } from 'query-string';

const cache = {};

/**
 * @param {number} num
 * @param {Intl.NumberFormatOptions} [opts]
 * @param {string|string[]} [locale]
 */
export const formatNumber = (num, opts = {}, locale) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(num)) return '-';
  const ckey = stringify({ ...opts, locale });
  let nf = cache[ckey];
  if (!nf) {
    nf = new Intl.NumberFormat(locale, opts);
    cache[ckey] = nf;
  }
  return nf.format(num);
};
