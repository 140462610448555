import React from 'react';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import get from 'lodash.get';

import SearchInput from 'components/SearchInput';
import styles from './QuickSearchInput.module.scss';

const QuickSearchInput = ({
  className, options, value, onSearch, ...params
}) => {
  const selected = options.find(o => o.keyword === value);
  return (
    <Dropdown className={classNames(styles.dropdown, className)}>
      <Dropdown.Toggle
        as={SearchInput}
        id="togger"
        placeholder="Search"
        value={selected ? selected.label : value}
        onSearch={keyword => keyword !== get(selected, 'label') && onSearch(keyword)}
        autoComplete="off"
        onClear={() => onSearch('')}
        {...params}
      />
      {options && options.length > 0 && (
        <Dropdown.Menu>
          <Dropdown.Header>Quick Search</Dropdown.Header>
          {options.map(o => (
            <Dropdown.Item key={o.keyword} onClick={() => onSearch(o.keyword, true)}>
              {o.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default QuickSearchInput;
