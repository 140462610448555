import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import pickBy from 'lodash.pickby';
import { stringify, parse } from 'query-string';

import { useModel } from 'models';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import ImportPageModal from 'components/ImportPageModal';
import Icon from 'components/Icon';
import FilterPageModal from 'components/FilterPageModal';
import FlexLayoutCard from 'components/FlexLayoutCard';
import EmptyState from 'components/EmptyState';
import PageDetail from 'components/PageDetail';
import SearchInput from 'components/SearchInput';

import styles from './ListPage.module.scss';

const pageSelector = ({ listPages, pages }) => ({
  pages: listPages.ids.map(i => pages[i]),
  total: listPages.total,
  loading: listPages.loading,
});

const ListPages = ({
  currentAccount, history, location, match,
}) => {
  const { pageId } = match.params;
  const query = parse(location.search);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [{ total, pages, loading }, { getListPages }] = useModel('page', pageSelector);

  const filter = { ...query };
  delete filter.keyword;
  const numFilter = Object.keys(filter).length;

  useEffect(() => {
    getListPages({ ...query, limit: 30, skip: 0 });
  }, [query.keyword, query.status, query.accountId]);

  const onQueryParamsChange = q => history.push({
    ...location,
    search: stringify(pickBy({ ...query, ...q })),
  });

  const listPage = (
    <>
      {pages.map(p => (
        <FlexLayoutCard.RowItem
          key={p.id}
          active={pageId === p.id}
          onClick={() => history.push({ ...location, pathname: `/pages/${p.id}` })}
          name={p.name}
          icon={p.picture}
        />
      ))}
      {pages.length === 0 && (
        <EmptyState className="mt-5" icon="search" title="No data" desc="No page to show" />
      )}
    </>
  );

  const extraContent = (
    <InputGroup className={styles.filter} size="sm">
      <SearchInput
        searchOnEnter
        className="search p-0"
        placeholder="Search pages"
        value={query.keyword || ''}
        onSearch={keyword => onQueryParamsChange({ keyword })}
        onClear={() => onQueryParamsChange({ keyword: '' })}
      />
      <InputGroup.Append>
        <Button variant={numFilter > 0 ? 'primary' : 'outline-primary'} onClick={() => setShowFilterModal(true)}>
          <Icon name="filter" />
          {numFilter > 0 && `${numFilter} applied`}
        </Button>
        <FilterPageModal
          filter={filter}
          onApply={(f) => {
            onQueryParamsChange(f);
            setShowFilterModal(false);
          }}
          show={showFilterModal}
          onHide={() => setShowFilterModal(false)}
        />
      </InputGroup.Append>
    </InputGroup>
  );


  return (
    <MainLayout>
      <PageHeader
        title="List Pages"
        subTitle="Pages management"
        extraContent={
          currentAccount.isAdmin() && (
            <Button className="mt-3" onClick={() => setImportModalVisible(true)}>Import new page</Button>
          )
        }
      />
      <FlexLayoutCard
        className="mt-3 flex-grow-1"
        total={total}
        showDetail={pageId}
        extraContent={extraContent}
        onBack={() => history.push('/pages')}
        loading={loading}
        data={pages}
        sidebar={listPage}
        main={<PageDetail pageId={pageId} />}
        onFetchMore={() => getListPages({ ...query, limit: 30, skip: pages.length })}
      />
      {currentAccount.isAdmin() && (
        <ImportPageModal show={importModalVisible} onHide={() => setImportModalVisible(false)} />
      )}
    </MainLayout>
  );
};

export default ListPages;
