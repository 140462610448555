import React, { useEffect, useContext } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { Dimmer } from 'tabler-react';

import { useModel } from 'models';
import { AppContext } from 'App';

import payonnerIcon from 'static/images/payoneer.png';
import styles from './PaymentSetting.module.scss';

const PayoneerAuthModal = ({ publisherId, show, onCancel }) => {
  const [{
    loading, error, loginLink, registrationLink,
  }, { getPayoneerLinks }] = useModel(
    'publisher',
    state => ({
      ...state.getPayoneerLinks,
    }),
  );

  const { currentAccount } = useContext(AppContext);

  useEffect(() => {
    const hash = new Date().getTime();
    localStorage.setItem('payoneer_hash', hash);
    getPayoneerLinks(publisherId, !currentAccount.isAdmin() ? `${window.location.origin}/settings#${hash}` : '');
  }, []);

  return (
    <Modal dialogClassName={styles.payoneerAuthModal} show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <img
            src={payonnerIcon}
            alt="payoneer"
            style={{
              height: 24,
              width: 30,
              marginRight: 12,
              borderRadius: 3,
            }}
          />
          Payoneer Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dimmer active={loading} loader>
          {error ? (
            <Alert variant="danger" className="my-3">
              {error.message || error.error || 'Something went wrong.'}
            </Alert>
          ) : (
            <>
              <a
                style={{ backgroundColor: '#EC5728' }}
                variant="danger"
                block
                href={loginLink}
                className="btn btn-danger btn-block"
                target="_blank"
                rel="noopener noreferrer"
              >
                Login
              </a>
              <div className="mt-4 text-center">
                <span>If you don&lsquo;t have a Payoneer Account please </span>
                <a href={registrationLink} target="_blank" rel="noopener noreferrer">
                  Register
                </a>
              </div>
            </>
          )}
        </Dimmer>
      </Modal.Body>
    </Modal>
  );
};

export default PayoneerAuthModal;
