import React from 'react';
import { Badge } from 'react-bootstrap';
import { formatNumber } from 'utils/intl';
import moment from 'moment';
import get from 'lodash.get';

export default class Invoice {
    static STATUS_DRAFT = 0
    static STATUS_SENT = 1
    static STATUS_ACCEPTED = 2
    static STATUS_REJECTED = 3
    static STATUS_PAID = 4

  static PAYMENT_METHOD_BANK = 'bank'
  static PAYMENT_METHOD_PAYPAL = 'paypal'
  static PAYMENT_METHOD_PAYONEER = 'payoneer'

  constructor(invoice = {}) {
    Object.assign(this, invoice);
  }

  getStatusBadge() {
    let style = '';
    let badge = '';
    switch (this.status) {
      case 1:
        style = 'info';
        badge = 'SENT';
        break;
      case 2:
        style = 'primary';
        badge = 'ACCEPTED';
        break;
      case 3:
        style = 'warning';
        badge = 'REJECTED';
        break;
      case 4:
        style = 'success';
        badge = 'PAID';
        break;
      default:
        style = 'default';
        badge = 'DRAFT';
    }

    /* eslint react/react-in-jsx-scope: "off" */
    /* eslint react/jsx-filename-extension: "off" */
    return (
      <>
        <Badge pill variant={style}>
          {badge}
        </Badge>
        {this.isPayoneerPending() && (
          <Badge pill variant="primary" className="ml-2">
            PAYONEER PENDING
          </Badge>
        )}
      </>
    );
  }

  get formattedAmount() {
    return formatNumber(this.amount, { style: 'currency', currency: this.currency });
  }

  get paymentMethod() {
    try {
      if (this.payment.paypal) {
        return Invoice.PAYMENT_METHOD_PAYPAL;
      }
      if (this.payment.payoneerId) {
        return Invoice.PAYMENT_METHOD_PAYONEER;
      }
      return Invoice.PAYMENT_METHOD_BANK;
    } catch (e) {
      return null;
    }
  }

  isDraft() {
    return this.status === Invoice.STATUS_DRAFT;
  }

  isSent() {
    return this.status === Invoice.STATUS_SENT;
  }

  isAccepted() {
    return this.status === Invoice.STATUS_ACCEPTED;
  }

  isPayoneerPending() {
    return (this.isAccepted() || this.isPaid()) && get(this, 'payoneerPayout.id');
  }

  isRejected() {
    return this.status === Invoice.STATUS_REJECTED;
  }

  isPaid() {
    return this.status === Invoice.STATUS_PAID;
  }

  toFormData() {
    return {
      regenerate: false,
      usdToVnd: this.usdToVnd,
      paymentMethod: this.payment.methodId,
      contractId: this.pubInfo.contractNo,
      dueDate: moment.utc(this.dueDate).format('YYYY-MM-DD'),
      confirmDueDate: moment.utc(this.confirmDueDate).format('YYYY-MM-DD'),
      startDate: moment(this.startDate, 'YYYYMMDD').format('YYYY-MM-DD'),
      endDate: moment(this.endDate, 'YYYYMMDD').format('YYYY-MM-DD'),
      note: this.note,
      content: this.content,
      modifiers: this.modifiers,
    };
  }
}
