import React, { useEffect } from 'react';
import {
  Row, Col, Form, Button, InputGroup, Alert,
} from 'react-bootstrap';
import { Card } from 'tabler-react';
import Select from 'react-select';
import moment from 'moment';
import { Formik } from 'formik';
import * as yup from 'yup';
import get from 'lodash.get';
import merge from 'lodash.merge';
import classNames from 'classnames';

import Icon from 'components/Icon';
import DatePicker from 'components/DatePicker';
import { useModel } from 'models';
import AdjustmentList from '../AdjustmentList';
import styles from './InvoiceForm.module.scss';

const schema = yup.object({
  contractId: yup.string().required('This field is required'),
  paymentMethod: yup.string().required('This field is required'),
});

const InvoiceForm = ({
  className,
  accountId,
  initialValues,
  onSubmit,
  submitting,
  submitError,
}) => {
  const [{ accountState, contractState }, { getDetail, getContracts }] = useModel(
    'publisher',
    state => ({
      accountState: {
        ...state.getDetail[accountId],
        account: state.publishers[accountId],
      },
      contractState: {
        ...state.getContracts[accountId],
        contracts: get(state, ['getContracts', accountId, 'contractIds'], []).map(
          ci => state.contracts[ci],
        ),
      },
    }),
    [accountId],
  );

  const { account } = accountState;
  const { contracts } = contractState;

  useEffect(() => {
    if (accountId && !account && !accountState.loading && !accountState.error) {
      getDetail(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (account && account.isPersonal() && !contractState.loading && !contractState.error) {
      getContracts(accountId);
    }
  }, [account]);

  const payoneer = get(account, 'payment.payoneer');
  const paymentOptions = get(account, 'payment.bankTransfers', [])
    .map(bt => ({
      value: bt.id,
      label: `Bank: ${bt.accName} - ${bt.currency}`,
    }))
    .concat(
      get(account, 'payment.paypals', []).map(pp => ({
        value: pp.id,
        label: `Paypal: ${pp.account} - ${pp.currency}`,
      })),
    )
    .concat(
      payoneer
        ? {
          value: payoneer.id,
          label: 'Payoneer',
        }
        : [],
    );

  const contractOptions = (contracts || [])
    .filter(c => !c.isDraft)
    .map(c => ({ value: c.id, label: c.id }));

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={merge(
        {
          regenerate: false,
          usdToVnd: 0,
          ignoreThreshold: false,
          paymentMethod: get(account, 'payment.defaultMethod', ''),
          contractId: '',
        },
        initialValues,
      )}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        handleBlur,
      }) => {
        const isError = name => errors[name] && touched[name];
        const isEdit = !!initialValues;
        return (
          <Form className={`${styles.invoiceForm} ${className || ''}`} onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    {!isEdit && (
                      <Form.Group as={Row}>
                        <Form.Label column xs={4} md={3}>
                          Ignore Threshold
                        </Form.Label>
                        <Col xs={8} md={9}>
                          <Form.Check
                            id="ignore-threshold"
                            type="checkbox"
                            name="ignoreThreshold"
                            checked={values.ignoreThreshold}
                            onChange={handleChange}
                            label="Create invoice even the revenue does not reach the threshold?"
                          />
                        </Col>
                      </Form.Group>
                    )}
                    {isEdit && (
                      <Form.Group as={Row}>
                        <Form.Label column xs={4} md={3}>
                          Re-generate
                        </Form.Label>
                        <Col xs={8} md={9}>
                          <Form.Check
                            id="regenerate"
                            type="checkbox"
                            name="regenerate"
                            checked={values.regenerate}
                            onChange={handleChange}
                            label="Re-generate invoice"
                          />
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Rate USD-VND
                      </Form.Label>
                      <Col xs={8} md={9}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>1$ =</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="number"
                            name="usdToVnd"
                            value={values.usdToVnd}
                            onChange={handleChange}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>VND</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Due Date
                      </Form.Label>
                      <Col xs={8} md={9}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <Icon name="calendar" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <DatePicker
                            size="lg"
                            placeholder="Select date"
                            value={values.dueDate}
                            onDateChange={date => date && setFieldValue('dueDate', date.format('YYYY-MM-DD'))}
                            minDate={
                              values.confirmDueDate
                                ? moment(values.confirmDueDate, 'YYYY-MM-DD').add(1, 'days')
                                : moment()
                            }
                          />
                        </InputGroup>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Confirm Due Date
                      </Form.Label>
                      <Col xs={8} md={9}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <Icon name="calendar" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <DatePicker
                            size="lg"
                            placeholder="Select date"
                            value={values.confirmDueDate}
                            onDateChange={date => date && setFieldValue('confirmDueDate', date.format('YYYY-MM-DD'))}
                            minDate={moment()}
                          />
                        </InputGroup>
                      </Col>
                    </Form.Group>
                    {(!isEdit || values.regenerate) && (
                      <Form.Group as={Row}>
                        <Form.Label column xs={4} md={3}>
                          Start Date
                        </Form.Label>
                        <Col xs={8} md={9}>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <Icon name="calendar" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <DatePicker
                              size="lg"
                              placeholder="Select date"
                              initialVisibleMonth={() => moment().subtract(1, 'months')}
                              value={
                                values.startDate ? moment(values.startDate, 'YYYY-MM-DD') : null
                              }
                              onDateChange={date => date && setFieldValue('startDate', date.format('YYYY-MM-DD'))}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    )}
                    {(!isEdit || values.regenerate) && (
                      <Form.Group as={Row}>
                        <Form.Label column xs={4} md={3}>
                          End Date
                        </Form.Label>
                        <Col xs={8} md={9}>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <Icon name="calendar" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <DatePicker
                              size="lg"
                              placeholder="Select date"
                              initialVisibleMonth={() => moment().subtract(1, 'months')}
                              value={values.endDate ? moment(values.endDate, 'YYYY-MM-DD') : null}
                              onDateChange={date => date && setFieldValue('endDate', date.format('YYYY-MM-DD'))}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Adjustments
                      </Form.Label>
                      <Col md={9}>
                        <Form.Control
                          as={AdjustmentList}
                          adjustments={values.modifiers}
                          onChange={m => setFieldValue('modifiers', m)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Payment Method (*)
                      </Form.Label>
                      <Col xs={8} md={9}>
                        <Select
                          className={classNames('Select', {
                            'is-invalid': isError('paymentMethod'),
                          })}
                          classNamePrefix="Select"
                          options={paymentOptions}
                          isInvalid={isError('paymentMethod')}
                          value={paymentOptions.find(o => o.value === values.paymentMethod)}
                          onChange={({ value }) => setFieldValue('paymentMethod', value)}
                          onBlur={() => setFieldTouched('paymentMethod', true)}
                        />
                        {isError('paymentMethod') && (
                          <Form.Control.Feedback type="invalid" className="d-block">
                            {errors.paymentMethod}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Contract Number (*)
                      </Form.Label>
                      <Col xs={8} md={9}>
                        {account && account.isPersonal() ? (
                          <Select
                            className={classNames('Select', {
                              'is-invalid': isError('contractId'),
                            })}
                            classNamePrefix="Select"
                            options={contractOptions}
                            value={contractOptions.find(c => c.value === values.contractId)}
                            onChange={({ value }) => setFieldValue('contractId', value)}
                            onBlur={() => setFieldTouched('contractId', true)}
                          />
                        ) : (
                          <Form.Control
                            type="input"
                            isInvalid={isError('contractId')}
                            name="contractId"
                            value={values.contractId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        )}
                        {isError('contractId') && (
                          <Form.Control.Feedback type="invalid" className="d-block">
                            {errors.contractId}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Message
                      </Form.Label>
                      <Col xs={8} md={9}>
                        <Form.Control
                          as="textarea"
                          rows="2"
                          name="content"
                          value={values.content}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column xs={4} md={3}>
                        Note
                      </Form.Label>
                      <Col xs={8} md={9}>
                        <Form.Control
                          as="textarea"
                          rows="2"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {submitError && (
                  <Alert variant="danger">
                    {submitError.message || submitError.error || 'Something went wrong'}
                  </Alert>
                )}
              </Card.Body>

              <Card.Footer>
                <Button disabled={!dirty || submitting} type="submit">
                  Submit
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InvoiceForm;
