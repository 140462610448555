import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import get from 'lodash.get';

import Notification from 'components/Notification';
import NetworkSelect from 'components/NetworkSelect';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import configs from 'configs/configs';

import styles from './Settings.module.scss';

const getFormState = (account) => {
  if (configs.platform === 'ADX') {
    const {
      autoCreateAdunitForPub,
      autoCreateAdunitForTag,
      autoGenerateInvoiceDay,
      defaultAdxNetwork,
      lockInventory,
      enablePayoneer,
    } = account.sysSettings || {};
    return {
      autoCreateAdunitForPub,
      autoCreateAdunitForTag,
      defaultAdxNetwork,
      lockInventory,
      enablePayoneer,
      autoGenerateInvoiceDay: autoGenerateInvoiceDay || 5,
    };
  }

  return {
    enablePayoneer: account.sysSettings.enablePayoneer,
  };
};

const SystemSetting = ({ account }) => {
  const [selectEnabled, setSelectEnabled] = useState(!!get(account, 'sysSettings.autoGenerateInvoiceDay'));
  const [formState, setFormState] = useState(getFormState(account));

  const [{ loading, error }, { updatePublisher: updateSystemSetting }] = useModel(
    'publisher',
    state => ({
      ...(state.updatePublisher.admin || {}),
    }),
  );

  useActionEffect({
    loading,
    error,
    onError: () => Notification.error(error.message || error.error || 'Something went wrong.'),
    onSuccess: () => Notification.success('Save settings successfully'),
  });

  const onSubmit = () => {
    updateSystemSetting(account.id, {
      sysSettings: {
        ...formState,
        autoGenerateInvoiceDay: selectEnabled ? formState.autoGenerateInvoiceDay : 0,
      },
    });
  };

  const renderDayOptions = () => {
    const options = [];
    for (let i = 5; i <= 25; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return options;
  };

  return (
    <div className={styles.systemSetting}>
      {configs.platform === 'ADX' && (
        <>
          <h4>Inventories</h4>
          <div>
            <Form.Check
              id="DFP-publisher"
              inline
              type="checkbox"
              label="Auto create DFP AdUnit for publisher"
              checked={!!formState.autoCreateAdunitForPub}
              onChange={e => setFormState({ ...formState, autoCreateAdunitForPub: e.target.checked })}
            />
          </div>
          <div>
            <Form.Check
              id="DFP-tag"
              inline
              type="checkbox"
              label="Auto create DFP AdUnit for tag"
              checked={!!formState.autoCreateAdunitForTag}
              onChange={e => setFormState({ ...formState, autoCreateAdunitForTag: e.target.checked })}
            />
          </div>
          <div>
            <Form.Check
              id="unlock-inventory"
              inline
              type="checkbox"
              label="Allow publisher to create apps, tags"
              checked={!formState.lockInventory}
              onChange={e => setFormState({ ...formState, lockInventory: !e.target.checked })}
            />
          </div>
          <h4 className="mt-5">Invoices</h4>
          <div>
            <Form.Check
              id="gen-invoice"
              inline
              type="checkbox"
              label="Auto generate invoices at this day of each month"
              checked={selectEnabled}
              onChange={e => setSelectEnabled(e.target.checked)}
            />
            <Form.Control
              disabled={!selectEnabled}
              as="select"
              className="w-auto mt-2"
              value={formState.autoGenerateInvoiceDay}
              onChange={e => setFormState({ ...formState, autoGenerateInvoiceDay: parseInt(e.target.value, 10) })
          }
            >
              {renderDayOptions()}
            </Form.Control>
          </div>
          <h4 className="mt-5">Default DFP Account </h4>
          <NetworkSelect
            className="network-select"
            placeholder="DFP Account"
            value={formState.defaultAdxNetwork}
            onChange={nwid => setFormState({ ...formState, defaultAdxNetwork: nwid })}
          />
        </>
      )}
      <h4 className="mt-5">Payment Methods</h4>
      <Form.Check
        id="enable-payoneer"
        inline
        type="checkbox"
        label="Accept Payoneer"
        checked={formState.enablePayoneer}
        onChange={e => setFormState({ ...formState, enablePayoneer: e.target.checked })}
      />
      <Form.Row className="mt-6">
        <Col sm={4} />
        <Col sm={8}>
          <Button disabled={loading} onClick={onSubmit}>
            Save
          </Button>
        </Col>
      </Form.Row>
    </div>
  );
};

export default SystemSetting;
