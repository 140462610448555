import React, { useRef, useEffect } from 'react';
import {
  Alert, Modal, Button, Form, Row, Col,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import get from 'lodash.get';

import NetworkSelect from 'components/NetworkSelect';

const schema = yup.object({
  networkId: yup.string().required('This field is required'),
  adxId: yup.string().required('This field is required'),
  dfpAdUnitID: yup.string().required('This field is required'),
});

const AddNetworkConfigModal = ({
  show, onSubmit, loading, error, onCancel, networkId, config, canAutoGenerateAdunitID,
}) => {
  const form = useRef();

  useEffect(() => {
    form.current.resetForm();
  }, [show]);

  return (
    <Formik
      ref={form}
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        networkId: networkId || '',
        adxId: '',
        dfpAdUnitID: canAutoGenerateAdunitID ? 'auto' : '',
        ...config,
      }}
    >
      {({
        values,
        dirty,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
      }) => {
        const isError = name => errors[name] && touched[name];

        return (
          <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
              <Modal.Title>{config.adxId ? `Update ADX Config: ${networkId}` : 'Add ADX Config'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column xs={5} lg={4} className="text-right">
                  Network
                </Form.Label>
                <Col xs={7} lg={8}>
                  <NetworkSelect
                    isDisabled={!!networkId}
                    onChange={v => setFieldValue('networkId', v)}
                    value={values.networkId}
                    onBlur={handleBlur}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column xs={5} lg={4} className="text-right">
                  ADX ID
                </Form.Label>
                <Col xs={7} lg={8}>
                  <Form.Control
                    type="text"
                    placeholder="Enter ADX ID"
                    name="adxId"
                    value={values.adxId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={isError('adxId')}
                    disabled={config.adxId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {get(errors, 'adxId')}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column xs={5} lg={4} className="text-right">
                  Adunit ID
                </Form.Label>
                <Col xs={7} lg={8}>
                  {!config.adxId && canAutoGenerateAdunitID && (
                    <Form.Check
                      id="auto-adunit"
                      className="my-2"
                      inline
                      type="checkbox"
                      label="Auto create DFP Adunit ID"
                      checked={values.dfpAdUnitID === 'auto'}
                      onChange={e => setFieldValue('dfpAdUnitID', e.target.checked ? 'auto' : '')}
                    />
                  )}
                  {values.dfpAdUnitID !== 'auto' && (
                    <Form.Control
                      type="text"
                      placeholder="Enter Adunit ID"
                      name="dfpAdUnitID"
                      value={values.dfpAdUnitID}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={isError('dfpAdUnitID')}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    {get(errors, 'dfpAdUnitID')}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error.message || error.error || 'Something went wrong.'}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onCancel}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={!dirty || loading || !values.networkId}
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddNetworkConfigModal;
