import React, { forwardRef, useContext, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Formik } from 'formik';
import * as yup from 'yup';

import { AppContext } from 'App';

import App from 'entities/App';

const TagForm = forwardRef(({ className, initialValues, onSubmit }, ref) => {
  const { currentAccount } = useContext(AppContext);
  const [codes, setCodes] = useState({});
  const sdkCodeSchema = yup.string().test('sdkCode', 'Invalid SDK code', value => !value || /^\/(194427432|21617015150)(\/[a-z0-9-_.]+)+$/i.test(value));
  const codeSchema = yup.string().test('sdkCode', 'Invalid DFP code', value => !value || /^\d+$/.test(value));
  const schema = yup.object({
    name: yup.string().required('This field is required'),
    type: yup.number().required('This field is required'),
    ...(currentAccount.isAdmin()
      ? {
        sdkCode: codes.code ? sdkCodeSchema.required('This field is required') : sdkCodeSchema,
        code: codes.sdkCode ? codeSchema.required('This field is required') : codeSchema,
      }
      : {}),
  });

  return (
    <Formik
      ref={ref}
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          name: '',
          type: '',
          sizes: [],
          sdkCode: '',
          code: '',
          isDFP: true,
        }
      }
    >
      {(formProps) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleBlur,
        } = formProps;
        const isError = name => errors[name] && touched[name];
        const tagOptions = App.getTagOptions();
        const tagSizesOptions = App.getTagSizesOptions(values.type);
        const tagIcon = App.getTypeIcon(values.type);

        return (
          <Form className={className} onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label column xs={2}>
                Name *
              </Form.Label>
              <Col xs={10}>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Tag Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={isError('name')}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            {currentAccount.isAdmin() && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    Code
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Control
                      type="text"
                      name="code"
                      placeholder="Ad unit ID, Ex: 123456789"
                      value={values.code}
                      onChange={(e) => {
                        setFieldValue('code', e.target.value);
                        setCodes({ ...codes, code: e.target.value });
                      }}
                      onBlur={handleBlur}
                      isInvalid={isError('code')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    SDK Code
                  </Form.Label>
                  <Col xs={10}>
                    <Form.Control
                      type="text"
                      name="sdkCode"
                      placeholder="Tag Code, Ex: /194427432/adunit_code"
                      value={values.sdkCode}
                      onChange={(e) => {
                        setFieldValue('sdkCode', e.target.value);
                        setCodes({ ...codes, sdkCode: e.target.value });
                      }}
                      onBlur={handleBlur}
                      isInvalid={isError('sdkCode')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.sdkCode}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </>
            )}
            <Form.Group as={Row}>
              <Form.Label column xs={2}>
                Type *
              </Form.Label>
              <Col xs={tagIcon ? 7 : 10}>
                <Select
                  className="Select"
                  classNamePrefix="Select"
                  placeholder="Select tag type"
                  options={tagOptions}
                  value={tagOptions.find(t => t.value === values.type)}
                  onChange={({ value }) => setFieldValue('type', value)}
                  onBlur={() => setFieldTouched('type', true)}
                  isInvalid={isError('type')}
                />
                <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                {!isError('type') && <div>{App.getTypeDesc(values.type)}</div>}
              </Col>
              <Col xs={3}>
                {tagIcon && (
                  <img
                    alt="tag-type-icon"
                    src={App.getTypeIcon(values.type)}
                    style={{ height: '100px', width: '100px', objectFit: 'cover' }}
                  />
                )}
              </Col>
            </Form.Group>
            {tagSizesOptions.length > 0 && (
              <Form.Group as={Row}>
                <Form.Label column xs={2}>
                  Tag Sizes
                </Form.Label>
                <Col xs={10}>
                  <Select
                    isMulti
                    className="Select"
                    classNamePrefix="Select"
                    placeholder="Select tag sizes"
                    options={tagSizesOptions}
                    value={tagSizesOptions.filter(t => (values.sizes || []).includes(t.value))}
                    onChange={vs => setFieldValue('sizes', vs.map(v => v.value))}
                    onBlur={() => setFieldTouched('size', true)}
                  />
                </Col>
              </Form.Group>
            )}
          </Form>
        );
      }}
    </Formik>
  );
});

export default TagForm;
