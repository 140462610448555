import React, { useState, useEffect } from 'react';
import { Loader } from 'tabler-react';

import styles from './Gist.module.scss';

let gistCallbackId = 0;
let addedStyle = false;

const Gist = ({ id, filename, onShow }) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  useEffect(() => {
    const callback = `gist_callback_${gistCallbackId++}`;
    let showTimeout = 0;
    window[callback] = ({ div, stylesheet }) => {
      setLoading(false);
      setContent(div);
      if (!addedStyle) {
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = stylesheet;

        document.head.appendChild(link);
        addedStyle = true;
      }
      if (onShow) {
        showTimeout = setTimeout(onShow, 2000);
      }
    };

    let url = `https://gist.github.com/${id}.json?callback=${callback}`;
    if (filename) {
      url += `&file=${filename}`;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.head.appendChild(script);

    return () => {
      delete window[callback];
      clearTimeout(showTimeout);
    };
  }, [id]);

  if (loading) {
    return <Loader className="my-5 mx-auto" />;
  }

  return <div className={styles.gist} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default Gist;
