import React, { useContext } from 'react';
import Select from 'react-select';

import { AppContext } from 'App';

const BMSelect = ({
  onChange, value, className, ...rest
}) => {
  const { currentAccount } = useContext(AppContext);

  const options = currentAccount.sysSettings.fanBusinesses.map(bm => ({
    value: bm.id,
    label: bm.name,
  }));

  return (
    <Select
      isClearable
      className={`${className || ''} Select`}
      classNamePrefix="Select"
      options={options}
      onChange={o => onChange(o && o.value)}
      value={value ? options.find(s => s.value === value) : null}

      {...rest}
    />
  );
};

export default BMSelect;
