import React, { useEffect, useRef } from 'react';
import { Nav } from 'react-bootstrap';
import { Card } from 'tabler-react';
import classNames from 'classnames';
import ScrollHint from 'scroll-hint';

import styles from './NavCard.module.scss';

const NavCard = ({
  className, nav = [], activeKey, onNavigate, header, footer, children, vertical,
}) => {
  const navNode = useRef();
  useEffect(() => {
    // eslint-disable-next-line no-new
    new ScrollHint('.card-nav', { suggestiveShadow: true });
  }, []);

  return (
    <Card className={classNames(className, styles.card)}>
      {header}
      <Card.Body className={classNames('p-0', { 'd-flex flex-column flex-md-row': vertical })}>
        {nav.length > 0 && (
          <div className="card-nav d-flex flex-shrink-0" data-slideout-ignore>
            <Nav
              ref={navNode}
              variant="tabs"
              activeKey={activeKey || nav[0].key}
              onSelect={k => onNavigate && onNavigate(k)}
              className={classNames('m-0 flex-grow-1', { 'vertical flex-row flex-md-column': vertical })}
            >
              {nav.map(n => (
                <Nav.Item key={n.key}>
                  <Nav.Link eventKey={n.key} disabled={n.disabled}>
                    {n.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
        )}
        <div className={classNames('p-5 main-body', { 'flex-grow-1': vertical })}>{children}</div>
      </Card.Body>
      {footer && (
        <Card.Footer>
          {footer}
        </Card.Footer>
      )}
    </Card>
  );
};

export default NavCard;
