import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import qs from 'query-string';
import pickBy from 'lodash.pickby';
import Select from 'react-select';

import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import ListMonitorCard from 'components/ListMonitorCard';
import AddMonitorModal from 'components/AddMonitorModal';
import AlertTypeSelect from 'components/AlertTypeSelect';
import SearchInput from 'components/SearchInput';
import Icon from 'components/Icon';
import User from 'entities/User';

import { useModel } from 'models';

const monitorSelector = ({ getMonitors, monitors }) => ({
  monitors: (getMonitors.ids || []).map(i => monitors[i]),
  total: getMonitors.total,
});

const ListMonitors = ({ history, location, currentUser }) => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const params = qs.parse(location.search);
  const page = parseInt(params.p, 10) || 1;
  const perpage = parseInt(params.pp, 10) || 30;
  const statusOptions = [
    { value: 'running', label: 'RUNNING' },
    { value: 'stopped', label: 'STOPPED' },
  ];

  const viewOnly = !currentUser.hasPermission(User.PermMonitorEdit);

  const [{ total, monitors }, { getMonitors, updateMonitor }] = useModel(
    'monitor',
    monitorSelector,
  );

  useEffect(() => {
    getMonitors({
      limit: perpage,
      skip: `${(page - 1) * perpage}`,
      kw: params.kw,
      alertType: params.alertType,
      status: params.status,
    });
  }, [page, perpage, params.kw, params.alertType, params.status]);

  const onPaginate = (e) => {
    if (e.page !== page || e.perpage !== perpage) {
      history.push({
        ...location,
        search: qs.stringify({
          p: e.page,
          pp: e.perpage,
        }),
      });
    }
  };

  const onQueryParamsChange = p => history.push({
    ...location,
    search: qs.stringify(pickBy({ ...params, ...p })),
  });

  const action = !viewOnly && (
    <Button onClick={() => setAddModalVisible(true)}>
      <Icon name="plus" className="mr-2" />
      Create New
    </Button>
  );

  return (
    <MainLayout>
      <PageHeader title="List Monitors" subTitle="Monitors management" extraContent={action} />
      <Form autoComplete="off" className="mt-3">
        <Form.Row>
          <Col sm={4} md={4} lg={3} className="mb-3">
            <SearchInput
              searchOnEnter
              value={params.kw || ''}
              placeholder="Search"
              onSearch={kw => onQueryParamsChange({ kw })}
              onClear={() => onQueryParamsChange({ kw: '' })}
            />
          </Col>
          <Col sm={4} md={4} lg={2} className="mb-3">
            <Select
              className="Select"
              classNamePrefix="Select"
              isClearable
              placeholder="Status"
              options={statusOptions}
              value={statusOptions.find(o => o.value === params.status)}
              onChange={o => onQueryParamsChange({ status: o ? o.value : null })}
            />
          </Col>
          <Col sm={4} md={4} lg={2} className="mb-3">
            <AlertTypeSelect
              placeholder="Alert Type"
              value={params.alertType}
              onChange={type => onQueryParamsChange({ alertType: type })}
            />
          </Col>
        </Form.Row>
      </Form>
      <ListMonitorCard
        history={history}
        total={total}
        monitors={monitors}
        viewOnly={viewOnly}
        onPlay={id => updateMonitor(id, { status: 'running' })}
        onPause={id => updateMonitor(id, { status: 'stopped' })}
        pagination={{ current: page, perpage, onChange: onPaginate }}
      />
      <AddMonitorModal
        show={addModalVisible}
        onSuccess={() => setAddModalVisible(false)}
        onHide={() => setAddModalVisible(false)}
      />
    </MainLayout>
  );
};

export default ListMonitors;
