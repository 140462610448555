import React from 'react';
import Select from 'react-select';
import countries from 'utils/countries';

const CountryInput = ({
  onChange, value, className, ...rest
}) => (
  <Select
    isClearable
    className={`${className || ''} Select`}
    classNamePrefix="Select"
    getOptionValue={v => v.code}
    getOptionLabel={v => v.name}
    options={countries}
    onChange={onChange}
    value={value ? countries.find(p => p.code === value) : null}
    noOptionsMessage={() => 'No result'}
    {...rest}
  />
);

export default CountryInput;
