import React, { useState, useEffect, forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import Icon from 'components/Icon';

import { useUpdateEffect } from 'react-use';
import styles from './SearchInput.module.scss';

/** @type {import("lodash").Cancelable} */
let searchDebounced = null;

const SearchInput = (
  {
    className, size, value: val = '', searchOnEnter, onClear, onSearch, ...props
  },
  ref,
) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(val);
  }, [val]);

  useUpdateEffect(() => {
    if (searchDebounced) {
      searchDebounced.cancel();
    }
    if (searchOnEnter || !value) {
      return;
    }

    searchDebounced = debounce(() => onSearch(value), 300);
    searchDebounced();
  }, [value]);

  const onChange = v => {
    setValue(v);
  };

  const onKeyPress = e => {
    if (e.key === 'Enter' && searchOnEnter) {
      onSearch(value);
    }
  };

  return (
    <div className={classNames(styles.searchInput, className)}>
      <Form.Control
        ref={ref}
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyPress={onKeyPress}
        {...props}
      />
      {value && (
        <div className="close-icon">
          <Icon size={22} type="fa" name="times" onClick={() => setValue('') || onClear()} />
        </div>
      )}
    </div>
  );
};

/** @type {SearchInput} */
const SI = forwardRef(SearchInput);

export default SI;
