import React, { useState, useEffect, useContext } from 'react';
import { Dimmer } from 'tabler-react';
import { Button, Badge, Alert } from 'react-bootstrap';
import get from 'lodash.get';
import moment from 'moment';

import User from 'entities/User';
import { AppContext } from 'App';
import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import GenerateContractModal from 'components/GenerateContractModal';
import Icon from 'components/Icon';
import { useModel } from 'models';

const ListContract = ({ account }) => {
  const [modalState, setModalState] = useState({ visible: false, contract: null });
  /** @type {{currentAccount: import('entities/Account').default}} */
  const { currentUser, currentAccount, dialog } = useContext(AppContext);
  const canGenerateContract = currentUser.hasPermission(User.PermPubContractEdit) && account.canGenerateContract();

  const [{ contracts, loading, error }, { getContracts, generateContract }] = useModel('publisher', state => ({
    ...state.getContracts[account.id],
    contracts: get(state, ['getContracts', account.id, 'contractIds'], []).map(
      ci => state.contracts[ci],
    ),
  }));

  useEffect(() => {
    getContracts(account.id);
  }, []);

  const getContractURL = c => (c.documentId
    ? `https://docs.google.com/document/d/${c.documentId}`
    : `/api/v1.1/accounts/${account.id}/contracts/${c.id}?format=html`);

  const publishContract = ({ id, documentId }) => {
    dialog.warning({
      title: 'Publish contract',
      content: 'Are you sure want to publish this contract',
      onOK: (d) => {
        generateContract({
          id,
          documentId,
          accountId: account.id,
          save: true,
        });
        d.dismiss();
      },
    });
  };

  const columns = [
    {
      key: 'id',
      title: 'ID',
      render: (v, r) => ({
        children: (
          <>
            <a href={getContractURL(r)} rel="noopener noreferrer" target="_blank">
              {r.id}
              <Icon className="ml-1" name="external-link" />
            </a>
            <Badge className="ml-3" variant="primary">
              {r.lang}
            </Badge>
            {r.isDraft && (
              <Badge variant="secondary" className="ml-1">
                draft
              </Badge>
            )}
          </>
        ),
      }),
    },
    { key: 'desc', title: 'Desc' },
    { key: 'ts', title: 'Create Date' },
    {
      key: 'action',
      title: 'Actions',
      render: (v, r) => ({
        children: r.isDraft && canGenerateContract ? (
          <>
            <Button className="p-0 mr-2" variant="link" onClick={() => setModalState({ visible: true, contract: r })}>Re-generate</Button>
            <Button className="p-0" onClick={() => publishContract(r)} variant="link">Publish</Button>
          </>
        ) : '',
      }),
    },
  ];

  const rows = contracts.map(c => ({
    ...c,
    ts: moment(c.ts).format('DD/MM/YYYY'),
  }));

  return (
    <div>
      {error ? (
        <Alert dismissible variant="danger">
          {error.message || error.error || 'Something went wrong.'}
        </Alert>
      ) : (
        <Dimmer active={loading} loader>
          {canGenerateContract && (
            <Button className="float-right" onClick={() => setModalState({ visible: true })}>
              Generate
            </Button>
          )}
          {!account.canGenerateContract() && (
            <Alert variant="warning">
              Publisher needs update settings first to generate contract.
            </Alert>
          )}
          <DataTable
            rowKey="id"
            columns={columns}
            rows={rows}
            emptyText={<EmptyState desc="No data" />}
          />
        </Dimmer>
      )}
      {account.canGenerateContract() && canGenerateContract && (
        <GenerateContractModal
          show={modalState.visible}
          account={account}
          contract={modalState.contract}
          templates={currentAccount.getSystemSettings().contractTpls || []}
          onHide={() => setModalState({ visible: false, contract: null })}
        />
      )}
    </div>
  );
};

export default ListContract;
