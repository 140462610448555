import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Chart } from 'react-google-charts';

import { getMetricName } from 'entities/Report';
import configs from 'configs/configs';
import { countryNameByCode } from 'utils/countries';

import styles from './CountryReportChart.module.scss';

const getMetricKey = m => (typeof m === 'object' ? m.key : m);

const CountryReportChart = ({ metrics, data }) => {
  const [metricType, setMetricType] = useState(getMetricKey(metrics[0]));

  const fontName = 'Google Sans';
  const chartOpts = {
    colors: ['#fff', '#467fcf'],
    tooltip: {
      textStyle: {
        fontName,
        color: '#495057',
      },
    },
    legend: {
      textStyle: {
        fontName,
        color: '#495057',
      },
    },
  };

  const selectedMetric = metrics.find(m => (getMetricKey(m) === metricType));

  return (
    <div className={styles.chart}>
      <Dropdown>
        <Dropdown.Toggle variant="link" id="metric-type-dropwdown">
          {typeof selectedMetric === 'object' ? selectedMetric.title : getMetricName(selectedMetric)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {metrics.map(m => (typeof m === 'object' ? m : ({ key: m, title: getMetricName(m) }))).map(m => (
            <Dropdown.Item key={m.key} onClick={() => setMetricType(m.key)}>
              {m.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {data.length > 0 && (
        <Chart
          width="100%"
          height={500}
          chartType="GeoChart"
          data={[
            ['Country', getMetricName(metricType)],
            ...data.map(d => [countryNameByCode[d.COUNTRY_CODE], d.current[metricType]]),
          ]}
          options={chartOpts}
          mapsApiKey={configs.gmapAPIKey}
        />
      )}
    </div>
  );
};

export default CountryReportChart;
