import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ClipBoard from 'clipboard';
import { Button, Overlay, Tooltip } from 'react-bootstrap';

/* eslint react/no-find-dom-node: "off" */
class ClipBoardButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false,
    };
  }

  componentDidMount() {
    const { value, onCopy } = this.props;
    const btnCopy = ReactDOM.findDOMNode(this);
    this.clipboard = new ClipBoard(btnCopy, {
      text: () => value,
      container: btnCopy,
    });
    this.clipboard.on('success', (e) => {
      this.setState({
        isCopied: true,
      });
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.setState({
          isCopied: false,
        });
      }, 1000);
      if (onCopy) {
        onCopy(e.text);
      }
    });
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { tooltip, children, ...rest } = this.props;
    const { isCopied } = this.state;
    return (
      <Button {...rest}>
        {tooltip && (
          <Overlay show={isCopied} container={this} placement={tooltip.placement || 'bottom'} target={this}>
            <Tooltip
              id={`${Math.random()
                .toString(36)
                .substr(2, 9)}`}
            >
              Copied
            </Tooltip>
          </Overlay>
        )}
        {children}
      </Button>
    );
  }
}

export default ClipBoardButton;
