import React, { useState, useMemo, useEffect } from 'react';
import {
  InputGroup,
  FormControl,
  Button,
  ButtonGroup,
  ToggleButton,
  DropdownButton,
  Dropdown,
  Form,
} from 'react-bootstrap';
import { Card } from 'tabler-react';
import { CSVLink } from 'react-csv';
import classNames from 'classnames';
import { useWindowSize, usePrevious, useUpdateEffect } from 'react-use';

import NavCard from 'components/NavCard';
import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import Icon from 'components/Icon';
import { formatMetric, getDimensionName, getMetricName } from 'entities/Report';
import CountryReportChart from 'components/CountryReportChart';
import useTempState from 'utils/useTempState';
import styles from './ReportTableCard.module.scss';

/**
 *
 * param {Object} props
 * param {[string|{key: String, title: String}]} props.metrics
 */
const ReportTableCard = ({
  activeKey,
  data = [],
  metrics,
  optionalMetrics,
  dimension: {
    key, name, valueKey, render,
  },
  loading,
  nav,
  sortDefault,
  onNavigate,
}) => {
  const { width } = useWindowSize();
  const [sorting, setSorting] = useState([sortDefault, -1]);
  const [keyword, setKeyword] = useState('');
  const [showingMetrics, setShowingMetrics] = useState(
    metrics.map(m => m.key || m),
  );
  const [showMetricsToggleDropdown, setShowMetricsToggleDropdown] = useState(false);
  const [page, setPage] = useState(1);
  const [display, setDisplay] = useTempState('table', [key]);
  const timeKey = ['DATE', 'MONTH'].find(k => k === key);
  const pagination = {
    perpage: 10,
    current: page,
    onChange: p => setPage(p),
  };
  useEffect(() => {
    setSorting([sortDefault, -1]);
  }, [usePrevious(sortDefault) === sortDefault]);
  useEffect(() => {
    if (page !== 1) {
      setPage(1); // reset current page
    }
  }, [data]);
  useUpdateEffect(() => {
    setShowingMetrics(metrics.map(m => m.key || m));
  }, [metrics.join()]);
  pagination.total = Math.ceil(data.length / pagination.perpage);
  const skip = (page - 1) * pagination.perpage;
  const makeMetricRender = metric => (v, r) => {
    const changes = r.prev ? r.current.getChange(r.prev) : null;
    return {
      children: (
        <>
          <div>{(r.current || r).format(metric)}</div>
          {changes && !isNaN(changes[metric]) && (
            <small
              className={classNames({
                'text-success': changes[metric] > 0,
                'text-danger': changes[metric] <= 0,
              })}
            >
              <Icon name={`arrow-${changes[metric] > 0 ? 'up' : 'down'}`}>
                {formatMetric('FAN_CTR', Math.abs(changes[metric]))}
              </Icon>
            </small>
          )}
        </>
      ),
    };
  };

  const csvData = useMemo(() => {
    if (!data || data.length === 0) {
      return null;
    }
    const dataRows = (data || []).map(d => ({
      [key]: d[key],
      [valueKey]: d[valueKey],
      ...metrics.reduce((prev, m) => ({ ...prev, [m.key || m]: d.current[m.key || m] }), {}),
    }));
    const headers = [
      getDimensionName(key),
      getDimensionName(valueKey),
      ...metrics.map(m => m.title || getMetricName(m)),
    ];
    if (key === valueKey) {
      headers.shift();
    }
    const rows = [headers].concat(dataRows.map(d => Object.values(d)));
    return rows;
  }, [data]);
  const columns = [
    {
      key,
      title: name,
      render,
      className: 'dimension-col',
      width: 200,
      fixed: width > 500 && 'left',
    },
    ...[...metrics, ...(optionalMetrics || [])].map(m => (typeof m === 'object' ? { ...m } : { key: m, title: getMetricName(m) })),
  ].filter((c, i) => i === 0 || showingMetrics.some(m => m === c.key));

  columns.slice(timeKey ? 0 : 1).forEach(c => {
    c.className = 'metric';
    c.render = c.key !== timeKey ? makeMetricRender(c.key) : null;
    c.title = (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className="sortable"
        onClick={() => setSorting([c.key, (c.key === sorting[0] ? -1 : 1) * sorting[1]])}
      >
        <span>{c.title}</span>
        {c.key === sorting[0] && (
          <Icon className="ml-1" name={`chevron-${sorting[1] === -1 ? 'down' : 'up'}`} />
        )}
      </div>
    );
  });

  const rows = useMemo(
    () => data
      .filter(
        v => !keyword
            || !valueKey
            || (v[valueKey] || v[key]).toLowerCase().indexOf(keyword.toLowerCase()) > -1,
      )
      .sort((i, j) => {
        if (timeKey && sorting[0] === timeKey) {
          return sorting[1] * (i[timeKey] > j[timeKey] ? 1 : -1);
        }
        const ii = (i.current || i)[sorting[0]];
        const jj = (j.current || j)[sorting[0]];
        // return sorting[1] * ((i.current || i)[sorting[0]] - (j.current || j)[sorting[0]]);
        const compare = (x, y) => {
          if (x === y) return 0;
          if (isNaN(x)) return -1; // eslint-disable-line no-restricted-globals
          if (isNaN(y)) return 1; // eslint-disable-line no-restricted-globals
          return x - y;
        };
        return sorting[1] * compare(ii, jj);
      }),
    [data, sorting, valueKey, keyword],
  );

  const MetricsColumnToggleItem = ({ metric, ...rest }) => {
    const k = typeof metric === 'object' ? metric.key : metric;
    const label = typeof metric === 'object' ? metric.title : getMetricName(metric);
    const onToggle = (m, e) => {
      e.preventDefault();
      e.stopPropagation();
      const sm = showingMetrics.filter(v => v !== m);
      if (sm.length === showingMetrics.length) {
        sm.push(m);
      }
      setShowingMetrics(sm);
    };
    const disabled = showingMetrics.length === 1 && showingMetrics[0] === k;
    return (
      <Dropdown.Item disabled={disabled} {...rest} onClick={e => onToggle(k, e)}>
        <Form.Check
          type="checkbox"
          disabled={disabled}
          id={k}
          inline
          checked={!!showingMetrics.find(m => k === m)}
          label={label}
          onChange={() => null}
        />
      </Dropdown.Item>
    );
  };

  const header = (
    <Card.Header className="flex-column flex-sm-row">
      <Card.Title className="mb-1 mb-sm-0">
        <span className="total text-nowrap">{`Total: ${rows.length}`}</span>
      </Card.Title>
      <Card.Options>
        <InputGroup size="sm">
          <FormControl
            placeholder="Search"
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
          />
          <InputGroup.Append>
            <Button variant="outline-primary">
              <Icon name="search" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {csvData && (
          <CSVLink
            className="ml-1 export-btn btn btn-info btn-sm"
            data={csvData}
            target="_blank"
            filename={`report_by_${name.toLowerCase()}.csv`}
          >
            Export
          </CSVLink>
        )}
        <DropdownButton
          id="metrics-toggle-dropdown"
          className="ml-1"
          size="sm"
          title="Columns"
          show={showMetricsToggleDropdown}
          onToggle={(isOpen, e, { source }) => source !== 'select' && setShowMetricsToggleDropdown(!showMetricsToggleDropdown)}
          alignRight
        >
          {[...metrics, ...(optionalMetrics || [])].map(m => (
            <MetricsColumnToggleItem
              className="metric-toggle-item"
              key={typeof m === 'object' ? m.key : m}
              metric={m}
            />
          ))}
        </DropdownButton>
      </Card.Options>
    </Card.Header>
  );

  return (
    <NavCard
      className={styles.card}
      activeKey={activeKey}
      header={header}
      nav={nav}
      onNavigate={onNavigate}
    >
      {key === 'COUNTRY_CODE' && (
        <ButtonGroup
          onChange={v => setDisplay(v.target.value)}
          className="mt-3 mb-2"
          size="sm"
          toggle
          value={display}
        >
          <ToggleButton
            variant={display === 'table' ? 'primary' : 'secondary'}
            type="radio"
            value="table"
          >
            Table
          </ToggleButton>
          <ToggleButton
            variant={display === 'chart' ? 'primary' : 'secondary'}
            type="radio"
            value="chart"
          >
            Chart
          </ToggleButton>
        </ButtonGroup>
      )}
      {display === 'table' && (
        <DataTable
          rowKey={key}
          scroll={{ x: true }}
          columns={columns}
          rows={rows.slice(skip, skip + pagination.perpage)}
          emptyText={<EmptyState desc="No data" />}
          pagination={pagination}
          loading={loading}
        />
      )}
      {display === 'chart' && (
        <CountryReportChart metrics={metrics} loading={loading} data={data} />
      )}
    </NavCard>
  );
};

export default ReportTableCard;
