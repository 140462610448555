import React, { useState, useEffect, useContext } from 'react';
import { ProgressBar } from 'react-bootstrap';
import get from 'lodash.get';
import moment from 'moment';

import { AppContext } from 'App';

import configs from 'configs/configs';
import useActionEffect from 'utils/useActionEffect';
import useTempState from 'utils/useTempState';
import { formatNumber } from 'utils/intl';

import { useModel } from 'models';
import UpdatePaymentConfirmModal from './UpdatePaymentConfirmModal';
import PaymentSettingInfo from './PaymentSettingInfo';
import PaymentSettingForm from './PaymentSettingForm';

import styles from './PaymentSetting.module.scss';

const adxConfig = {
  dim: 'ADX_PUBLISHER_ID',
  metrics: ['ADX_PUBLISHER_EARNINGS'],
};

const fanConfig = {
  dim: 'FAN_PUB_ID',
  metrics: ['FAN_PUB_REVENUE'],
};

const conf = configs.platform === 'ADX' ? adxConfig : fanConfig;

const PaymentSetting = ({ account }) => {
  const [editting, setEditting] = useState(false);
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [editting]);
  const [confirmState, setConfirmState] = useState({ visible: false, payment: null });
  const appReportId = `app_earnnings_${account.id}`;
  const pageReportId = `page_earnings_${account.id}`;

  // prettier-ignore
  const [{ loading, error }, { updatePublisher }] = useModel('publisher', state => ({
    ...state.updatePublisher[account.id],
  }), account.id);

  const [paymentInfo, { getPaymentInfo }] = useModel('invoice', state => ({
    ...state.getPaymentInfo,
  }));

  const [{ appReports, pageReports, error: pageError }, { getReports, getPageReports }] = useModel(
    'report',
    (state) => {
      const ar = state.getReports[appReportId] || {};
      const pr = state.getPageReports[pageReportId] || {};
      return {
        appReports: ar.reports,
        pageReports: pr.reports,
        // loading: ar.loading || pr.loading,
        error: ar.error || pr.error,
      };
    },
    [appReportId, pageReportId],
  );

  const earnings = get(appReports, ['rows', 0, 1], 0) + get(pageReports, ['rows', 0, 1], 0);

  const { dialog } = useContext(AppContext);

  useEffect(() => {
    if (account.paymentThreshold) {
      getPaymentInfo({ accountId: account.id });
    }
  }, [account.paymentThreshold]);

  useActionEffect({
    loading: paymentInfo.loading,
    error: paymentInfo.error,
    onSuccess: () => {
      let from = '2016-01-01';
      if (paymentInfo.invoice) {
        from = moment(paymentInfo.invoice.endDate, 'YYYYMMDD')
          .add(1, 'day')
          .format('YYYY-MM-DD');
      }
      getReports(appReportId, {
        from,
        to: moment().format('YYYY-MM-DD'),
        [`filter.${conf.dim}`]: account.id,
        dimensions: conf.dim,
        metrics: conf.metrics,
      });
      if (configs.platform === 'FAN' && account.adbreakEnabled) {
        getPageReports(pageReportId, {
          from,
          to: moment().format('YYYY-MM-DD'),
          [`filter.${conf.dim}`]: account.id,
          dimensions: conf.dim,
          metrics: conf.metrics,
        });
      }
    },
  });

  useEffect(() => {
    setSubmitStatus({ loading, error });
  }, [loading, error]);

  useActionEffect({
    loading,
    error,
    onError: () => {
      if (error.code === 7) {
        setConfirmState({ ...confirmState, visible: true });
      }
    },
    onSuccess: () => {
      if (account.isPublisher()) {
        setConfirmState({ visible: false, payment: null });
      }
      setEditting(false);
      dialog.success({
        title: 'Update Successfully',
        content: 'Payment settings has been updated',
      });
    },
  });

  const onSubmit = (values) => {
    if (account.isPublisher()) {
      setConfirmState({ visible: false, payment: values });
    }
    updatePublisher(account.id, { payment: values });
  };

  const renderPaymenSetting = () => {
    if (editting) {
      return (
        <>
          <PaymentSettingForm
            publisherId={account.id}
            initialValues={account.payment}
            publisherCountry={account.getCountry()}
            submitting={submitStatus.loading}
            submitError={!confirmState.visible && submitStatus.error}
            onSubmit={onSubmit}
            onCancel={() => setEditting(false)}
          />
          <UpdatePaymentConfirmModal
            show={confirmState.visible}
            email={account.getEmail()}
            submitting={submitStatus.loading}
            submitError={submitStatus.error && submitStatus.error.code !== 7 && submitStatus.error}
            onCancel={() => setConfirmState({ visible: false, payment: null })}
            onSubmit={code => updatePublisher(account.id, { payment: confirmState.payment, verifyCode: code })
            }
          />
        </>
      );
    }
    return (
      <PaymentSettingInfo
        account={account}
        onEdit={() => setEditting(true)}
        onSetDefault={methodId => updatePublisher(account.id, { payment: { ...account.payment, defaultMethod: methodId } })} // prettier-ignore
      />
    );
  };

  return (
    <div className={styles.paymentSetting}>
      {(account.paymentThreshold && !pageError) && (
        <div className="border-bottom mb-5 pb-3">
          <div className="d-flex justify-content-between">
            <h5>Current earnings</h5>
            <h4>{formatNumber(earnings, { style: 'currency', currency: 'USD' })}</h4>
          </div>
          {earnings <= account.paymentThreshold && (
            <ProgressBar now={earnings} max={account.paymentThreshold} />
          )}
          {earnings > account.paymentThreshold && (
            <ProgressBar>
              <ProgressBar variant="info" now={account.paymentThreshold} max={earnings} key={1} />
              <ProgressBar now={earnings - account.paymentThreshold} max={earnings} key={2} />
            </ProgressBar>
          )}
          <div className="d-flex justify-content-between mt-1">
            <div className="text-muted">
              {`You've reached ${formatNumber(earnings / account.paymentThreshold, {
                style: 'percent',
              })} of your payment threshold`}
            </div>
            <div className="text-muted">
              {`Payment threshold: ${formatNumber(account.paymentThreshold, {
                style: 'currency',
                currency: 'USD',
              })}`}
            </div>
          </div>
        </div>
      )}
      {renderPaymenSetting()}
    </div>
  );
};

export default PaymentSetting;
