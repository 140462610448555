import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dimmer } from 'tabler-react';
import { Alert } from 'react-bootstrap';
import moment from 'moment';

import { useModel } from 'models';
import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';

const ListReferrals = ({ account }) => {
  const [{ referrals, loading, error }, { getReferrals }] = useModel('referral', state => ({
    ...state.getReferrals[account.id],
    referrals: (state.getReferrals[account.id] || {}).referrals || [],
  }));

  useEffect(() => {
    getReferrals(account.id);
  }, [account.id]);

  const columns = [
    { key: 'name', title: 'Referee Account' },
    { key: 'src', title: 'Referer' },
    { key: 'ts', title: 'Timestamp' },
  ];

  const rows = referrals.map(r => ({
    key: r.id,
    name: <Link to={`/publishers/${r.id}`}>{r.name}</Link>,
    src: r.src || '-',
    ts: moment(r.ts).format('lll'),
  }));

  return (
    <div>
      {error && (
        <Alert variant="danger">{error.message || error.error || 'Something went wrong.'}</Alert>
      )}
      <Dimmer active={loading} loader>
        <DataTable
          columns={columns}
          rows={rows}
          scroll={{ x: true }}
          emptyText={<EmptyState desc="No data" />}
        />
      </Dimmer>
    </div>
  );
};

export default ListReferrals;
