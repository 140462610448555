import React, { useEffect, useRef } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import useTempState from 'utils/useTempState';
import TagForm from 'components/TagForm';

import styles from './AddTagModal.module.scss';

const AddTagModal = ({
  show, appId, onSuccess, onHide,
}) => {
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);
  const form = useRef(null);
  const [{ loading, error }, { addTag }] = useModel('app', state => ({
    ...state.addTag,
  }));

  useEffect(
    () => {
      setSubmitStatus({ loading, error });
    },
    [loading, error],
  );

  useActionEffect({ loading, error, onSuccess });

  return (
    <Modal dialogClassName={styles.modal} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create new in-app tag</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TagForm
          ref={form}
          onSubmit={values => addTag(appId, values)}
          submitError={submitStatus.error}
          submitting={submitStatus.loading}
        />
        {submitStatus.error && (
          <Alert variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => form.current.submitForm()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTagModal;
