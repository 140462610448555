import expire from 'store/plugins/expire';
import engine from 'store/src/store-engine';
import local from 'store/storages/localStorage';
import session from 'store/storages/sessionStorage';
import cookie from 'store/storages/cookieStorage';
import memory from 'store/storages/memoryStorage';

export const sessionStorage = engine.createStore([session, memory]);
export default engine.createStore([local, session, cookie, memory], [expire]);

export const KEY_REPORT_DATE_RANGE = 'report-date-range';
