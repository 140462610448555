import React, { useState, useContext } from 'react';
import { Card } from 'tabler-react';
import classNames from 'classnames';
import {
  DropdownButton, Dropdown, Badge, Form, SplitButton,
} from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import { AppContext } from 'App';
import DataTable from 'components/DataTable';
import AssignPublisherModal from 'components/AssignPublisherModal';
import Notification from 'components/Notification';
import User from 'entities/User';
import Icon from 'components/Icon';
import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import EmptyState from '../EmptyState';
import styles from './ListPublishersCard.module.scss';

/**
 * @param {import("entities/Account").default} acc
 */
function renderStatus(acc) {
  if (acc.isActivated()) {
    return (
      <Badge pill variant="success">
        Activated
      </Badge>
    );
  }
  if (acc.isDeactivated()) {
    return (
      <Badge pill variant="danger">
        Deactivated
      </Badge>
    );
  }
  if (acc.isPending()) {
    return (
      <Badge pill variant="secondary">
        Pending
      </Badge>
    );
  }
  return '';
}

/**
 * @param {Object} props
 * @param {Account[]} props.publishers
 */
const ListPublishersCard = ({
  history,
  className,
  total = 0,
  publishers = [],
  additionColumns = [],
  pagination = {
    current: 0,
    perpage: 30,
  },
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const totalPage = Math.ceil(total / pagination.perpage);
  const { currentUser, currentAccount, dialog } = useContext(AppContext);

  const [{
    updating, updateError, updatedIds, totalUpdateError,
  }, { updatePublishers }] = useModel(
    'publisher',
    state => ({
      updating: state.updatePublishers.loading,
      updateError: state.updatePublishers.errors[0],
      updatedIds: state.updatePublishers.successIds,
      totalUpdateError: state.updatePublishers.errors.length,
    }),
  );

  useActionEffect({
    loading: updating,
    error: updateError,
    onSuccess: () => {
      Notification.success(`${updatedIds.length} publishers has been updated`);
      setAssignModalVisible(false);
    },
    onError: () => {
      Notification.error(
        `Some publishers has not been updated (${totalUpdateError}/${selectedIds.length})`,
      );
      setAssignModalVisible(false);
    },
  });

  const onUnassign = () => {
    dialog.warning({
      title: 'Unassign all managers',
      content: `Are you sure want to unassign all managers of ${
        selectedIds.length > 1 ? 'these publishers' : 'this publisher'
      }?`,
      onOK: d => {
        updatePublishers(selectedIds, { AM: [] });
        d.dismiss();
      },
    });
  };

  let seletedId = typeof assignModalVisible === 'string' && assignModalVisible;
  if (!seletedId && selectedIds.length === 1) {
    [seletedId] = selectedIds;
  }
  const selectedOne = seletedId && publishers.find(p => p.id === seletedId);
  const onAssign = AM => {
    dialog.warning({
      title: 'Unassign all managers',
      content: `Are you sure want to assign manager${AM.length > 0 ? 's' : ''} for ${
        selectedIds.length > 1 ? 'these publishers' : 'this publisher'
      }?`,
      onOK: d => {
        updatePublishers(selectedOne ? [selectedOne.id] : selectedIds, { AM });
        d.dismiss();
      },
    });
  };

  const onToggleSelect = (pid, checked) => {
    setSelectedIds(checked ? selectedIds.concat(pid) : selectedIds.filter(id => id !== pid));
  };

  /** @type {[DataTableColumProps]} */
  const columns = [
    {
      key: 'id',
      title: '',
      width: 10,
      render: (v, r) => ((
        <Form.Check
          type="checkbox"
          checked={selectedIds.includes(r.id)}
          onChange={e => onToggleSelect(r.id, e.target.checked)}
        />
      )),
    },
    { key: 'name', title: 'Name', render: (v, r) => <Link to={`/publishers/${r.id}`}>{v}</Link> },
    {
      key: 'AM',
      title: 'AM',
      render: ids => ids && ids.map(id => currentAccount.team.find(m => m.id === id).name).join(', '),
    },
    { key: 'status', title: 'Status' },
    { key: 'registerDate', title: 'Register Date' },
    {
      key: 'actions',
      title: '',
      render: (_, r) => ((
        <Dropdown>
          <Dropdown.Toggle className="p-0" variant="link">
            Actions
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {currentUser.hasPermission(User.PermPubInfoEdit) && (
              <Dropdown.Item onClick={() => setAssignModalVisible(r.id)}>Assign AMs</Dropdown.Item>
            )}
            {currentUser.hasPermission(User.PermPubInvoiceEdit) && (
              <Dropdown.Item onClick={() => history.push(`/publishers/${r.id}/invoices`)}>
                Create invoice
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => history.push(`/app-reports?breakdown=publisher&pid=${r.id}`)}
            >
              View report
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )),
    },
  ];

  additionColumns.forEach(c => {
    switch (c) {
      case 'admobStatus':
        columns.splice(-1, 0, {
          key: c,
          title: 'Admob Status',
          render: (_, r) => (r.admobEnabled ? 'Enabled' : 'Disabled'),
        });
        break;
      default:
    }
  });

  const rows = publishers.map(p => ({
    id: p.id,
    name: (
      <span style={{ whiteSpace: 'nowrap' }}>
        <Icon
          name={p.isPersonal() ? 'user' : 'building'}
          type="fa"
          className="mr-1 mb-1"
          size={14}
        />
        {p.name}
      </span>
    ),
    AM: p.AM,
    status: renderStatus(p),
    registerDate: moment(p.ts).format('DD/MM/YYYY'),
    admobEnabled: (p.admobConfig || {}).enabled,
    actions: '',
  }));

  const onPageChange = p => pagination.onChange
    && pagination.onChange({
      page: p,
      perpage: pagination.perpage,
    });
  const onPerPageChange = p => pagination.onChange
    && pagination.onChange({
      page: pagination.current,
      perpage: p,
    });
  const perpages = [10, 30, 50, 100];
  const showPerpage = total > Math.min(pagination.perpage, perpages[0]);

  return (
    <Card className={classNames('mt-3', styles.card, className)}>
      <Card.Header>
        <Card.Title>{`Total: ${total}`}</Card.Title>
        <div className="ml-auto">
          <SplitButton
            className="mr-3"
            id="assign-dropdown"
            size="sm"
            disabled={selectedIds.length === 0}
            title={`${selectedIds.length} selected`}
            variant="secondary"
            onClick={() => setSelectedIds([])}
            alignRight
          >
            <Dropdown.Item onClick={() => setAssignModalVisible(true)}>
              <Icon name="user-plus" className="mr-2 mb-1" size={18} />
              Assign managers
            </Dropdown.Item>
            <Dropdown.Item className="text-danger" onClick={onUnassign}>
              <Icon name="user-x" className="mr-2 mb-1" size={18} />
              Unassign all managers
            </Dropdown.Item>
          </SplitButton>
          {showPerpage && (
            <DropdownButton
              className="perpage-btn"
              size="sm"
              title={pagination.perpage}
              alignRight
              variant="outline-primary"
              onSelect={key => onPerPageChange(parseInt(key, 10))}
            >
              {perpages.map(p => (
                <Dropdown.Item key={p} eventKey={p}>
                  {p}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          rowKey="id"
          columns={columns}
          rows={rows}
          scroll={{ x: true }}
          emptyText={<EmptyState desc="No data" />}
          pagination={{
            total: totalPage,
            current: Math.min(totalPage, pagination.current),
            onChange: onPageChange,
          }}
        />
      </Card.Body>
      <AssignPublisherModal
        show={!!assignModalVisible}
        loading={updating}
        onSubmit={onAssign}
        publisherAM={selectedOne && selectedOne.AM}
        onHide={() => setAssignModalVisible(false)}
      />
    </Card>
  );
};

export default withRouter(ListPublishersCard);
