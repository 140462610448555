import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import {
  InputGroup,
  Button,
  Media,
  Row,
  Col,
  Container,
  Badge,
  ButtonToolbar,
  Alert,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
  FormControl,
} from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { Card } from 'tabler-react';
import moment from 'moment';

import User from 'entities/User';
import defaultIcon from 'static/images/app-icon-default.png';
import PublisherInput from 'components/PublisherInput';
import Notification from 'components/Notification';
import DataTable from 'components/DataTable';
import App from 'entities/App';
import AddTagModal from 'components/AddTagModal';
import UpdateTagModal from 'components/UpdateTagModal';
import { useModel } from 'models';
import { AppContext } from 'App';
import CopyableText from 'components/CopyableText';
import EditAppModal from 'components/EditAppModal';
import capitalize from 'utils/capitalize';
import AssignUserTable from './AssignUserTable';
import AssignUserDropdown from './AssignUserDropdown';
import EmptyState from '../EmptyState';
import Icon from '../Icon';
import styles from './AppDetail.module.scss';

const AppDetail = ({ appId }) => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const { currentUser, currentAccount, dialog } = useContext(AppContext);
  const [update, setUpdate] = useState({});
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [{ app, loading, error }, { updateApp, getAppDetail }] = useModel(
    'app',
    (state) => ({
      ...(state.updateApp[appId] || {}),
      app: state.apps[appId] || {},
    }),
    appId,
  );

  const [{ publisher }, { getDetail }] = useModel(
    'publisher',
    (state) => ({ publisher: state.publishers[app.accountId] || {} }),
    app.accountId,
  );

  useEffect(() => {
    if (currentAccount.isAdmin() && app.accountId) {
      getDetail(app.accountId);
    }
  }, [app.accountId]);

  const prevLoading = useRef(loading);

  useEffect(() => {
    if (prevLoading.current && !loading) {
      // update finish
      if (error) {
        Notification.error(error.message || error.error || 'Something went wrong', 1);
      } else {
        Notification.success(`App ${app.name} has been updated`);
        if (showBlockModal) {
          setShowBlockModal(false);
        }
      }
    }
    prevLoading.current = loading;
  }, [loading]);

  useEffect(() => {
    if (!appId) {
      return;
    }
    getAppDetail(appId, { withViolation: 1 });
  }, [appId]);

  const users = ((currentAccount.isAdmin() ? publisher.team : currentAccount.team) || []).filter(
    (u) => u.role !== 'admin',
  );
  const assignedToEveryone = !app.userIds || app.userIds.length === users.length;

  const columns = [
    {
      key: 'tag',
      title: 'Tag',
      width: 200,
    },
    {
      key: 'type',
      title: 'Type',
    },
    { key: 'sdkCode', title: 'SDK Code' },
    { key: 'sizes', title: 'Sizes' },
  ];

  const rows = (app.tags || []).map((t) => ({
    key: t.id,
    tag:
      currentUser.hasPermission(User.PermAppTagEdit) && !t.sdkCode ? (
        <a href="#tag" onClick={() => setSelectedTag(t)}>
          {t.name}
        </a>
      ) : (
        t.name
      ),
    type: App.getTagTypeStr(t.type),
    sdkCode: t.sdkCode ? (
      <span>
        <CopyableText>{t.sdkCode}</CopyableText>
        {(app.status === App.STATUS_BLOCKED || app.status === App.STATUS_ARCHIVED) && (
          <span className="tag-disabled">(Google Disabled)</span>
        )}
      </span>
    ) : (
      <Badge pill variant="default">
        <Icon name="clock" className="mr-1" />
        PENDING
      </Badge>
    ),
    sizes: App.getSizesStr(t.sizes),
  }));

  if (!appId) {
    return (
      <EmptyState
        className={classNames('my-5', styles.appDetail)}
        icon="box"
        desc="Select an app to view detail"
      />
    );
  }

  if (!app.id) {
    return (
      <EmptyState
        className={classNames('my-5', styles.appDetail)}
        icon="box"
        desc="Appication not found"
      />
    );
  }

  const assignPublisher = () => {
    if (!update.accountId) {
      return;
    }
    updateApp(app.id, { accountId: update.accountId });
  };

  const onUpdateApp = (status) => {
    if (app.status === status) {
      return;
    }
    if (status === App.STATUS_BLOCKED) {
      setShowBlockModal(true);
      return;
    }
    const msgs = {
      [App.STATUS_BLOCKED]: 'Are you sure want to block this app?',
      [App.STATUS_APPROVED]: 'Are you sure want to approve this app?',
      [App.STATUS_ARCHIVED]: 'Are you sure want to archive this app?',
    };
    dialog.warning({
      title: 'Block application',
      content: msgs[status] || 'Are you sure?',
      onOK: (d) => {
        updateApp(app.id, { status });
        d.dismiss();
      },
    });
  };

  const colProps = {
    xs: 5,
    sm: 4,
    md: 3,
    lg: 2,
  };

  const storeURL = app.getStoreURL();

  return (
    <div className={classNames(styles.appDetail)}>
      <Media className="p-2 align-items-center">
        <img className="app-icon mr-2 mb-2 rounded" src={app.icon || defaultIcon} alt="" />
        <Media.Body>
          <h5 className="m-0 app-name">
            {app.name}
            {app.link && (
              <a className="ml-2" href={app.link} rel="noopener noreferrer" target="_blank">
                <Icon className="align-top" name="external-link" />
              </a>
            )}
            {currentUser.hasPermission(User.PermAppEdit) && (
              <Button
                size="sm"
                variant="link"
                className="px-0 py-0"
                onClick={() => setEditModalVisible(true)}
              >
                <Icon name="edit" size={15} />
              </Button>
            )}
          </h5>
          <small className="text-muted">
            ID:
            {' '}
            <CopyableText>{app.id}</CopyableText>
          </small>
        </Media.Body>
        <ButtonToolbar>
          <Button
            size="sm"
            variant="secondary"
            className="mr-1"
            as={Link}
            to={{ pathname: '/app-reports', search: qs.stringify({ aid: app.id }) }}
          >
            View reports
          </Button>
          {!currentAccount.lockInventory && app.status === App.STATUS_APPROVED && (
            <Button size="sm" variant="secondary" onClick={() => setAddModalVisible(true)}>
              New Mobile In-app Tag
            </Button>
          )}
        </ButtonToolbar>
      </Media>
      <Container fluid className="main-info pt-3 pb-5">
        {app.updateWarning && (
          <Alert className="mt-2 warning-alert" variant="warning">
            <Icon size={14} className="mr-1" name="alert-triangle" />
            This app need to be updated
          </Alert>
        )}
        <Row className="mb-1">
          <Col {...colProps}>
            <strong>Status:</strong>
          </Col>
          <Col>
            <span className={classNames({
              'text-danger': app.status === App.STATUS_BLOCKED,
              'text-success': app.status === App.STATUS_APPROVED,
              'text-warning': app.status === App.STATUS_ARCHIVED,
            })}
            >
              {capitalize(app.status)}

            </span>
            {currentAccount.isAdmin() && currentUser.hasPermission(User.PermAppEdit) && (
              <Dropdown>
                <Dropdown.Toggle
                  size="sm"
                  split
                  variant="link"
                  id="update-actions"
                  className="p-0 ml-2"
                >
                  Edit&nbsp;
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {App.getStatusOptions()
                    .filter((o) => o.value !== 'pending')
                    .map((o) => (
                      <Dropdown.Item
                        key={o.value}
                        active={o.value === app.status}
                        onClick={() => onUpdateApp(o.value)}
                      >
                        {o.label}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {app.status === App.STATUS_BLOCKED && app.blockReason && <Alert className="mb-1 p-3" variant="danger">{app.blockReason}</Alert>}
          </Col>
        </Row>
        <Row className="mb-1">
          <Col {...colProps}>
            <strong>Platform:</strong>
          </Col>
          <Col>
            <span>{app.platform.toUpperCase()}</span>
            {storeURL && (
              <a className="ml-2" href={storeURL} target="_blank" rel="noopener noreferrer">
                <span>View on store</span>
                <Icon className="ml-1 mb-1" name="external-link" />
              </a>
            )}
          </Col>
        </Row>
        <Row className="mb-1">
          <Col {...colProps}>
            <strong>Publisher:</strong>
          </Col>
          <Col>
            {app.publisher && (
              <Link to={`/publishers/${app.publisher.id}`}>{app.publisher.name}</Link>
            )}
            {!app.accountId && (
              <InputGroup className="assign-pub-input" size="sm">
                <PublisherInput
                  placeholder="Search publisher"
                  onChange={(p) => setUpdate({ ...update, accountId: p ? p.id : undefined })}
                />
                <InputGroup.Append>
                  <Button size="sm" onClick={assignPublisher}>
                    Assign
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            )}
          </Col>
        </Row>
        <Row className="mb-1">
          <Col {...colProps}><strong>Create Date:</strong></Col>
          <Col>{moment(app.ts).format('lll')}</Col>
        </Row>
        {app.violations && app.violations.length > 0 && (
          <>
            <h5 className="section-header col-12 py-3">Policy Violations</h5>
            {app.getViolationStatus() && (
              <div className="font-weight-bold mb-2 text-danger">{app.getViolationStatus()}</div>
            )}
            {app.violations.map((v) => (
              <ViolationCard key={`${v.issuedDate}-${v.lastEnforDate}`} violation={v} />
            ))}
          </>
        )}
        {currentUser.hasPermission(User.PermAppTagView) && (
          <>
            <h5 className="section-header col-12 py-3">Mobile In-app Tags</h5>
            <DataTable
              className="mt-3"
              scroll={{ x: true }}
              columns={columns}
              rows={rows}
              emptyText={<EmptyState desc="No data" />}
            />
          </>
        )}
        {users.length > 0 && (
          <>
            <h5 className="section-header col-12 py-3">Assigned Users</h5>
            {currentUser.hasPermission(User.PermAppEdit) && (
              <div className="d-flex align-items-end">
                <AssignUserDropdown
                  onChange={(userIds) => updateApp(app.id, { userIds })}
                  users={users}
                  selected={app.userIds || users.map((u) => u.id)}
                />
              </div>
            )}
            {app.userIds === [] && (
              <div className="mt-3" style={{ color: '#DD656E' }}>
                This application shows for admin only.
              </div>
            )}
            {assignedToEveryone && (
              <div className="mt-3" style={{ color: '#4EA732' }}>
                No specified users was assigned, application is public for everyone.
              </div>
            )}
            {!assignedToEveryone && app.userIds.length > 0 && (
              <AssignUserTable
                scroll={{ x: true }}
                users={users.filter((u) => app.userIds.includes(u.id))}
              />
            )}
          </>
        )}
      </Container>
      <AddTagModal
        appId={appId}
        show={addModalVisible}
        onSuccess={() => setAddModalVisible(false)}
        onHide={() => setAddModalVisible(false)}
      />
      <UpdateTagModal
        appId={appId}
        tag={selectedTag}
        show={!!selectedTag}
        onSuccess={() => setSelectedTag(null)}
        onHide={() => setSelectedTag(null)}
      />
      <EditAppModal
        show={editModalVisible}
        app={app}
        onCancel={() => setEditModalVisible(false)}
        onSuccess={() => setEditModalVisible(false)}
      />
      <BlockReasonModal
        show={showBlockModal}
        onSubmit={(r) => updateApp(app.id, { status: App.STATUS_BLOCKED, reason: r })}
        onHide={() => setShowBlockModal(false)}
      />
    </div>
  );
};

export default AppDetail;

const howToFixes = {
  fixInGooglePlay:
    'Fix this violation by visiting Google Play and following the instructions. You may have to wait up to 7 days after fixing the violation',
  requestAReview:
    'Read more about each violation, identify the cause, fix the cause and then re-upload your app to its respective app store. Then, request a review.',
};

const ViolationCard = ({
  violation: {
    reasons, howToFix, issuedDate, lastEnforDate, reviewStatus, key,
  },
}) => {
  const [showingReasonId, setShowingReasonId] = useState();
  const showingReason = (showingReasonId && reasons.find((r) => r.id === showingReasonId)) || {};

  return (
    <Card className={styles.violationCard}>
      <Card.Body>
        {/* <h5 className="title">
          <span className="align-bottom">{title}</span>
          <Button onClick={() => setShowDetail(true)} className="ml-1 p-0" variant="link"><Icon name="info" size={18} /></Button>
        </h5> */}
        <div className="info">
          <strong>Issued Date: </strong>
          <span>{moment(issuedDate, 'YYYYMMDD').format('ll')}</span>
        </div>
        {lastEnforDate && (
          <div className="info">
            <strong>Last Enforcement: </strong>
            <span>{moment(lastEnforDate, 'YYYYMMDD').format('ll')}</span>
          </div>
        )}
        <div className="info">
          <strong>Violations: </strong>
          {reasons.length === 1 && (
            <Button
              onClick={() => setShowingReasonId(reasons[0].id)}
              className="ml-1 p-0 font-weight-normal text-left text-decoration-none"
              variant="link"
            >
              {reasons[0].title}
            </Button>
          )}
          {reasons.length > 1 && (
            <ul className="mb-1">
              {reasons.map((r) => (
                <li key={r.id}>
                  <Button
                    onClick={() => setShowingReasonId(r.id)}
                    className="ml-1 p-0 font-weight-normal text-left text-decoration-none"
                    variant="link"
                  >
                    {r.title}
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="info">
          <strong>How to fix: </strong>
          <span>{howToFixes[howToFix]}</span>
        </div>
        {reviewStatus && (
          <div className="info">
            <strong>Appeal status: </strong>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id={key}>
                  {reviewStatus.submittedTs && (
                  <div>{`Submitted ${moment(reviewStatus.submittedTs).format('ll')}`}</div>
                  )}
                  {/* {reviewStatus.reviewedTs && (
                  <div>{`Reviewed ${moment(reviewStatus.reviewedTs).format('ll')}`}</div>
                  )} */}
                </Tooltip>
              )}
            >
              <abbr title={reviewStatus.status}>
                {reviewStatuses[reviewStatus.status] || reviewStatus.status || 'UNKNOWN'}
              </abbr>
            </OverlayTrigger>
          </div>
        )}
      </Card.Body>
      <Modal show={showingReason.id} size="lg" onHide={() => setShowingReasonId(null)}>
        <Modal.Header>
          <Modal.Title>{showingReason.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: showingReason.detail }} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowingReasonId(null)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

const reviewStatuses = {
  FAILED_TO_REVIEW: 'FAILED',
  REJECTED_NO_RECOURSE: 'REJECTED',
};

const BlockReasonModal = ({ onHide, onSubmit, ...rest }) => {
  const [reason, setReason] = useState('');

  return (
    <Modal size="sm" onHide={onHide} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl
          rows="5"
          as="textarea"
          placeholder="Why do you block this app?"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={onHide}>Cancel</Button>
        <Button size="sm" variant="danger" onClick={() => onSubmit(reason)}>Block</Button>
      </Modal.Footer>
    </Modal>
  );
};
