import React, { useState, useEffect } from 'react';
import { DropdownButton, Button, InputGroup } from 'react-bootstrap';
import Select from 'react-select';

import PublisherInput from 'components/PublisherInput';
import CountryInput from 'components/CountryInput';
import AccountTypeSelect from 'components/AccountTypeSelect';
import AccopuntManagerSelect from 'components/AccountManagerSelect';
import Icon from 'components/Icon';
import Account from 'entities/Account';

import styles from './PublisherSegment.module.scss';

const emptyFilters = {
  'pub.country': '',
  'pub.type': '',
  'pub.AM': '',
  'pub.status': '',
  pid: '',
};

const PublisherSegment = ({ value, onChange }) => {
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState(value);
  const filterCount = Object.keys(value).filter(key => !!value[key] && key !== 'pid').length;

  useEffect(() => {
    setFilters({ ...filters, ...value });
  }, [value.pid, value['pub.country'], value['pub.type'], value['pub.AM'], value['pub.status']]);

  const onReset = () => {
    setFilters(emptyFilters);
    onChange(emptyFilters);
    setShow(false);
  };

  const onApply = () => {
    const hasFilter = Object.keys(filters).filter(key => !!filters[key] && key !== 'pid').length > 0;
    let fs = filters;
    if (hasFilter) {
      fs = { ...filters, pid: '' };
      setFilters(fs);
    }
    onChange(fs);
    setShow(false);
  };

  const onSelectPublisher = (p) => {
    const fs = { ...emptyFilters, pid: p ? p.id : '' };
    setFilters(fs);
    onChange(fs);
  };

  return (
    <InputGroup className={styles.publisherSegment}>
      <PublisherInput
        onFocus={() => setShow(false)}
        placeholder={
          filterCount > 0 ? `${filterCount} filter${filterCount > 1 ? 's' : ''}` : 'All publishers'
        }
        onChange={onSelectPublisher}
        value={filters.pid}
      />
      <DropdownButton
        as={InputGroup.Append}
        show={show}
        onToggle={() => setShow(!show)}
        className="publisherFilter"
        title={<Icon name="filter" />}
        variant="light"
        alignRight
      >
        <div>
          <AccopuntManagerSelect
            className="mb-3"
            placeholder="Filter by manager"
            value={filters['pub.AM'] || ''}
            onChange={amId => setFilters({ ...filters, 'pub.AM': amId })}
          />
          <Select
            isClearable
            className="Select mb-3"
            classNamePrefix="Select"
            placeholder="Status"
            options={Account.getStatusOptions()}
            onChange={c => setFilters({ ...filters, 'pub.status': c ? c.value : '' })}
            value={Account.getStatusOptions().find(s => `${s.value}` === filters['pub.status'])}
            noOptionsMessage={() => 'No result'}
          />
          <AccountTypeSelect
            className="mb-3"
            placeholder="Account Type"
            value={filters['pub.type'] || ''}
            onChange={type => setFilters({ ...filters, 'pub.type': type })}
          />
          <CountryInput
            className="mb-3"
            value={filters['pub.country'] || ''}
            onChange={c => setFilters({ ...filters, 'pub.country': c ? c.code : '' })}
            placeholder="Filter by country"
          />
          <div className="d-flex mt-4">
            <Button className="w-100" variant="secondary" onClick={onReset}>
              Reset
            </Button>
            <div className="px-1" />
            <Button className="w-100" variant="primary" onClick={onApply}>
              Apply
            </Button>
          </div>
        </div>
      </DropdownButton>
    </InputGroup>
  );
};

export default PublisherSegment;
