import React from 'react';
import Select from 'react-select';

const NetworkSelect = ({
  onChange, value, className, ...rest
}) => {
  const options = [
    { value: '194427432', label: 'Appota Corporation (194427432)' },
    { value: '21617015150', label: 'APPOTA INC PTE. LTD (21617015150)' },
  ];

  return (
    <Select
      className={`${className || ''} Select`}
      classNamePrefix="Select"
      options={options}
      onChange={o => onChange(o && o.value)}
      value={value ? options.find(s => s.value === value) : null}
      {...rest}
    />
  );
};

export default NetworkSelect;
