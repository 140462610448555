import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, Form, Alert, Badge,
} from 'react-bootstrap';
import { Button } from 'tabler-react';

import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import { useModel } from 'models';
import Icon from 'components/Icon';
import styles from './Settings.module.scss';

const ListContractTemplates = ({ templates = [], viewOnly }) => {
  const [{ loading, error }, { update }] = useModel('auth', state => state.update);
  const prevLoading = useRef(loading);
  const [showModal, setShowModal] = useState();
  const [invalid, setInvalid] = useState();
  useEffect(() => {
    if (prevLoading.current && !loading && !error) {
      setShowModal('');
      setInvalid(false);
    }
    prevLoading.current = loading;
  }, [loading]);
  const columns = [
    {
      key: 'id',
      title: 'Name',
      render: (v, r) => ({
        children: (
          <div>
            <a
              href={`https://docs.google.com/document/d/${r.id}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {r.name}
              <Icon className="ml-1" name="external-link" />
            </a>
            <div>
              <Badge variant="primary">{r.platform}</Badge>
              <Badge className="ml-1" variant="primary">{r.lang}</Badge>
            </div>
          </div>
        ),
      }),
    },
    { key: 'desc', title: 'Desc' },
  ];
  const onAddTemplate = data => {
    const idx = templates.findIndex(c => c.id === data.id);
    if (idx > -1) {
      setInvalid('Template with same document URL was existed');
      return;
    }
    update({
      sysSettings: { contractTpls: [...templates, data] },
    });
  };
  return (
    <div className={styles.listTemplates}>
      {!viewOnly && (
        <Button
          className="ml-auto d-block mb-3"
          color="primary"
          onClick={() => setShowModal('add')}
        >
          Add new
        </Button>
      )}
      <DataTable
        rowKey="id"
        columns={columns}
        rows={templates}
        scroll={{ x: true }}
        emptyText={<EmptyState desc="No data" />}
      />
      <h5>Template Variables</h5>
      <p>
        Template supports follow variables. Variables will be replaced with real data when
        generating contract.
      </p>
      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Variable</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code>{'{{CONTRACT_ID}}'}</code>
            </td>
            <td>ID of contract</td>
          </tr>
          <tr>
            <td>
              <code>{'{{DAY}}'}</code>
              {', '}
              <code>{'{{MONTH}}'}</code>
              {', '}
              <code>{'{{YEAR}}'}</code>
            </td>
            <td>Contract's timestamp</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_NAME}}'}</code>
            </td>
            <td>Publisher's name</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_ADDRESS}}'}</code>
            </td>
            <td>Publisher's address</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_PHONE}}'}</code>
            </td>
            <td>Publisher's phone number</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_ID_NUMBER}}'}</code>
            </td>
            <td>Publisher's ID card number</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_ID_DATE}}'}</code>
            </td>
            <td>Publisher's ID card issued date</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_ID_PLACE}}'}</code>
            </td>
            <td>Publisher's ID card issused place</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_BANK_NAME}}'}</code>
            </td>
            <td>Publisher's payment method: bank name</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_BANK_ADDRESS}}'}</code>
            </td>
            <td>Publisher's payment method: bank address</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_BANK_SWIFTCODE}}'}</code>
            </td>
            <td>Publisher's payment method: bank swiftcode</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_BANK_CURRENCY}}'}</code>
            </td>
            <td>Payment currency</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_BANK_ACCOUNT_NAME}}'}</code>
            </td>
            <td>Payment method: account holder</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_BANK_ACCOUNT_NUMBER}}'}</code>
            </td>
            <td>Payment method: account number</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_PAYPAL_NAME}}'}</code>
            </td>
            <td>Publisher's paypal holder</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_PAYPAL_ACCOUNT}}'}</code>
            </td>
            <td>Publisher's paypal account</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_PERCENT_CURRENT}}'}</code>
            </td>
            <td>Percent of revenue shares to publisher</td>
          </tr>
          <tr>
            <td>
              <code>{'{{SHARED_PERCENT_CURRENT}}'}</code>
            </td>
            <td>Percent of revenue shares to Adsota</td>
          </tr>
          <tr>
            <td>
              <code>{'{{SHARE_DATE_CURRENT}}'}</code>
            </td>
            <td>The start date that current sharing percent was applied</td>
          </tr>
          <tr>
            <td>
              <code>{'{{ADBREAK_PUB_PERCENT_CURRENT}}'}</code>
            </td>
            <td>Percent of revenue shares to publisher</td>
          </tr>
          <tr>
            <td>
              <code>{'{{ADBREAK_SHARED_PERCENT_CURRENT}}'}</code>
            </td>
            <td>Percent of revenue shares to Adsota</td>
          </tr>
          <tr>
            <td>
              <code>{'{{ADBREAK_SHARE_DATE_CURRENT}}'}</code>
            </td>
            <td>The start date that current sharing percent was applied</td>
          </tr>
          <tr>
            <td>
              <code>{'{{PUB_PAYONEER_ID}}'}</code>
            </td>
            <td>Payoneer ID of publisher&apos;s payoneer payment method</td>
          </tr>
        </tbody>
      </table>

      {!viewOnly && (
        <AddModal
          loading={loading}
          error={error || invalid}
          show={showModal === 'add'}
          onSubmit={onAddTemplate}
          onHide={() => setShowModal('')}
        />
      )}
    </div>
  );
};

export default ListContractTemplates;

const AddModal = ({
  error, loading, onSubmit: submitCallback, ...rest
}) => {
  const initState = {
    name: '',
    lang: 'vi',
    desc: '',
    docUrl: '',
  };
  const [state, setState] = useState(initState);
  const [validity, setValidity] = useState({});
  const onSubmit = () => {
    const valid = {};
    const matches = state.docUrl.match(/^https:\/\/docs\.google\.com\/document\/d\/([a-zA-Z0-9-_]+).*/);
    if (!matches || matches.length < 2 || !matches[1]) {
      valid.docUrl = 'invalid document url';
    }
    setValidity(valid);
    if (Object.keys(valid).length > 0) {
      return;
    }

    const id = matches[1];
    submitCallback({
      id,
      name: state.name,
      lang: state.lang,
      desc: state.desc,
    });
  };

  const onExisted = () => {
    setState(initState);
  };

  return (
    <Modal {...rest} onExited={onExisted}>
      <Modal.Header closeButton>
        <Modal.Title>Add contract template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>
              Document URL
              <span className="form-required">*</span>
            </Form.Label>
            <Form.Control
              type="url"
              value={state.docUrl}
              onChange={e => setState({ ...state, docUrl: e.target.value })}
              placeholder="https://docs.google.com/document/d/documentId/"
              required
              isInvalid={!!validity.docUrl}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Language
              <span className="form-required">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              onChange={e => setState({ ...state, lang: e.target.value })}
              required
              isInvalid={!!validity.lang}
              value={state.lang}
            >
              {/* <option value="" disabled>Select Language</option> */}
              <option value="vi">Vietnamese</option>
              <option value="en">English</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Custom Name</Form.Label>
            <Form.Control
              value={state.name}
              onChange={e => setState({ ...state, name: e.target.value })}
              maxLength={255}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Desc</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={state.desc}
              onChange={e => setState({ ...state, desc: e.target.value })}
              maxLength={1024}
            />
          </Form.Group>
        </Form>
        {error && (
          <Alert variant="danger">{error.message || error.error || error || 'Something went wrong'}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button loading={loading} disabled={loading} onClick={onSubmit} color="primary">
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
