import React from 'react';
import classNames from 'classnames';

import Icon from './Icon';

const EmptyState = ({
  className, icon, title, desc,
}) => (
  <div className={classNames('text-center text-muted', className)}>
    {icon && <Icon name={icon} size={40} />}
    {title && <h5 className="my-1">{title}</h5>}
    {desc && <p>{desc}</p>}
  </div>
);

export default EmptyState;
