import React from 'react';
import { Card } from 'tabler-react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import get from 'lodash.get';

import { formatNumber } from 'utils/intl';

import adsotaLogo from 'static/images/adsota.png';
import appotaXLogo from 'static/images/logo1_dark.svg';
import logoFAN from 'static/images/adblue2-white.png';
import configs from 'configs/configs';
import styles from './InvoiceSheet.module.scss';

const InvoiceDocument = ({ className, invoice }) => {
  if (!invoice) {
    return null;
  }
  return (
    <Card className={`${styles.invoiceSheet} ${className || ''}`}>
      <Card.Body>
        <div data-slideout-ignore>
          <header className="invoice-header">
            <img src={adsotaLogo} alt="Adsota" />
            <img
              className="ml-4"
              src={configs.platform === 'ADX' ? appotaXLogo : logoFAN}
              alt="logo"
            />
          </header>
          <h2 className="text-center mb-5">RECONCILIATION REPORT &amp; FINALIZED REVENUE</h2>
          <Row>
            <Col>
              <h4>INVOICE TO:</h4>
              <h3>{get(invoice, 'pubInfo.name', 'Unknown').toUpperCase()}</h3>
              <div className="divider" />
            </Col>
            <Col className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <h4>INVOICE ID:</h4>
                <h4>{invoice.id}</h4>
              </div>
              <div className="d-flex justify-content-between">
                <h4>DUE DATE:</h4>
                <h4>{moment(invoice.dueDate).format('DD/MM/YYYY')}</h4>
              </div>
              <div className="divider" />
            </Col>
          </Row>

          <Row className="subHeader mt-3">
            <Col className="contractInfo">
              <div className="d-flex d-flex align-items-baseline">
                <h4 className="mr-2">Address:</h4>
                <p>{get(invoice, 'pubInfo.address')}</p>
              </div>
              <div className="d-flex align-items-baseline">
                <h4 className="mr-2">Email:</h4>
                <p>{get(invoice, 'pubInfo.email')}</p>
              </div>
              <div className="d-flex align-items-baseline">
                <h4 className="mr-2">Phone:</h4>
                <p>{get(invoice, 'pubInfo.phone')}</p>
              </div>
              <div className="d-flex align-items-baseline">
                <h4 className="mr-2">Contract Number:</h4>
                <p>{get(invoice, 'pubInfo.contractNo')}</p>
              </div>
            </Col>
            <Col className="total">
              <div className="d-flex justify-content-between">
                <h3>AMOUNT:</h3>
                <h3>
                  {formatNumber(invoice.amount, {
                    style: 'currency',
                    currency: invoice.currency,
                  })}
                </h3>
              </div>
            </Col>
          </Row>

          {invoice.revenues && !!invoice.revenues.length && (
            <div className="py-4">
              <h4>
                {`ADX Revenue (From ${moment(invoice.startDate, 'YYYYMMDD').format(
                  'DD/MM/YYYY',
                )} to ${moment(invoice.endDate, 'YYYYMMDD').format('DD/MM/YYYY')})`}
              </h4>
              {invoice.revenues.map(rev => (
                <div key={rev.id} className="detailItem d-flex justify-content-between">
                  <div>{rev.name}</div>
                  <div>{formatNumber(rev.revenue, { style: 'currency', currency: 'USD' })}</div>
                </div>
              ))}
            </div>
          )}

          {invoice.admobRevenues && !!invoice.admobRevenues.length && (
            <div className="py-4">
              <h4>
                {`Admob Revenue (From ${moment(invoice.startDate, 'YYYYMMDD').format(
                  'DD/MM/YYYY',
                )} to ${moment(invoice.endDate, 'YYYYMMDD').format('DD/MM/YYYY')})`}
              </h4>
              {invoice.admobRevenues.map(rev => (
                <div key={rev.id} className="detailItem d-flex justify-content-between">
                  <div>{rev.name}</div>
                  <div>{formatNumber(rev.revenue, { style: 'currency', currency: 'USD' })}</div>
                </div>
              ))}
            </div>
          )}

          {invoice.pageRevenues && !!invoice.pageRevenues.length && (
            <div className="py-4">
              <h4>
                {`Adbreak Revenue (From ${moment(invoice.startDate, 'YYYYMMDD').format(
                  'DD/MM/YYYY',
                )} to ${moment(invoice.endDate, 'YYYYMMDD').format('DD/MM/YYYY')})`}
              </h4>
              {invoice.pageRevenues.map(rev => (
                <div key={rev.id} className="detailItem d-flex justify-content-between">
                  <div>{rev.name}</div>
                  <div>{formatNumber(rev.revenue, { style: 'currency', currency: 'USD' })}</div>
                </div>
              ))}
            </div>
          )}

          {invoice.refRev && (
            <div className="py-4 d-flex justify-content-between">
              <h4>
                {`REFERRAL REVENUE (From ${moment(invoice.refRev.from, 'YYYYMM').format(
                  'MM/YYYY',
                )} to ${moment(invoice.refRev.to, 'YYYYMM').format('MM/YYYY')})`}
              </h4>
              <h4>
                {formatNumber(invoice.refRev.revenue, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </h4>
            </div>
          )}

          <Row className="finalizedRevenue py-3 my-3">
            <Col xs={8}>
              <h4>FINALIZED REVENUE (in USD)</h4>
            </Col>
            <Col xs={4}>
              <h4 className="text-right">
                {formatNumber(invoice.pubRev, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </h4>
            </Col>
          </Row>

          {invoice.modifiers && invoice.modifiers.length > 0 && (
            <div className="detail mt-5">
              <h4>ADJUSTMENTS</h4>
              {[{ name: 'TOTAL', value: invoice.pubRev, unit: 'USD' }]
                .concat(invoice.modifiers || [])
                .map((m, i) => (
                  <Row key={i} className="adjustmentItem">
                    <Col xs={7} className="text-right">
                      <h4>{`${m.name}:`}</h4>
                    </Col>
                    <Col xs={5} className="d-flex justify-content-between">
                      <h4>
                        {m.unit === '%'
                          ? formatNumber(m.value / 100, { style: 'percent' })
                          : formatNumber(m.value, { style: 'currency', currency: m.unit })}
                      </h4>
                      <h4>
                        {m.unit === '%'
                          ? formatNumber((invoice.pubRev * m.value) / 100, {
                            style: 'currency',
                            currency: 'USD',
                          })
                          : formatNumber(m.value, { style: 'currency', currency: m.unit })}
                      </h4>
                    </Col>
                  </Row>
                ))}
            </div>
          )}

          <Row className="finalAmount mb-3 py-3">
            <Col xs={8}>
              <h2>FINAL AMOUNT</h2>
              <h4 className="text-muted font-italic">(based on your currency of payment method)</h4>
            </Col>
            <Col xs={4}>
              <h2 className="text-right">
                {formatNumber(invoice.amount, {
                  style: 'currency',
                  currency: invoice.currency,
                })}
              </h2>
            </Col>
          </Row>

          <Row>
            <Col span={12} className="paymentDetail">
              <h3>PAYMENT DETAIL</h3>
              {get(invoice, 'payment.payoneerId') && (
                <div className="d-flex align-items-baseline">
                  <h4 className="mr-2">Payoneer Payment ID:</h4>
                  <p>{get(invoice, 'payment.payoneerId')}</p>
                </div>
              )}
              {get(invoice, 'payment.paypal') && (
                <div className="d-flex align-items-baseline">
                  <h4 className="mr-2">Paypal:</h4>
                  <p>{get(invoice, 'payment.paypal', 'Unknown')}</p>
                </div>
              )}
              {get(invoice, 'payment.accNumber') && (
                <>
                  <div className="d-flex align-items-baseline">
                    <h4 className="mr-2">Bank name:</h4>
                    <p>{get(invoice, 'payment.bank', 'Unknown')}</p>
                  </div>
                  <div className="d-flex align-items-baseline">
                    <h4 className="mr-2">Bank address:</h4>
                    <p>{get(invoice, 'payment.bankAddr')}</p>
                  </div>
                  <div className="d-flex align-items-baseline">
                    <h4 className="mr-2">Swift Code:</h4>
                    <p>{get(invoice, 'payment.swiftCode')}</p>
                  </div>
                  <div className="d-flex align-items-baseline">
                    <h4 className="mr-2">Account Number:</h4>
                    <p>{get(invoice, 'payment.accNumber')}</p>
                  </div>
                  <div className="d-flex align-items-baseline">
                    <h4 className="mr-2">Account Name:</h4>
                    <p>{get(invoice, 'payment.accName')}</p>
                  </div>
                </>
              )}
            </Col>
            <Col span={12} className="content">
              <p>{invoice.content}</p>
            </Col>
          </Row>

          {invoice.note && (
            <div className="d-flex align-items-baseline mt-5">
              <h5 className="text-muted font-italic mr-2">Note:</h5>
              <p className="text-muted font-italic">{invoice.note}</p>
            </div>
          )}

          <Row className="invoiceFooter">
            <Col span={12}>
              <h4>Buyer Represented By</h4>
            </Col>
            <Col span={12}>
              <h4>Seller Represented By</h4>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InvoiceDocument;
