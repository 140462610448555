import React from 'react';
import {
  InputGroup, Form, Button, Dropdown,
} from 'react-bootstrap';

import Icon from 'components/Icon';

const SocialList = ({ socials, onChange }) => {
  const icons = {
    skype: <Icon type="fa" name="skype" size={18} style={{ color: '#5CBAEC' }} />,
    facebook: <Icon type="fa" name="facebook-messenger" size={18} style={{ color: '#548BF7' }} />,
    telegram: <Icon type="fa" name="telegram" size={18} style={{ color: '#4E9ED4' }} />,
    twitter: <Icon type="fa" name="twitter" size={18} style={{ color: '#4AAAE7' }} />,
    wechat: <Icon type="fa" name="weixin" size={18} style={{ color: '#58BF44' }} />,
    whatsapp: <Icon type="fa" name="whatsapp" size={18} style={{ color: '#77BD59' }} />,
    qq: <Icon type="fa" name="qq" size={18} style={{ color: '#393B50' }} />,
  };

  const options = [
    { key: 'skype', label: 'Skype' },
    { key: 'facebook', label: 'Facebook' },
    { key: 'telegram', label: 'Telegram' },
    { key: 'twitter', label: 'Twitter' },
    { key: 'wechat', label: 'WeChat' },
    { key: 'whatsapp', label: 'Whatsapp' },
    { key: 'qq', label: 'QQ' },
  ].filter(o => typeof socials[o.key] !== 'string');

  return (
    <div>
      {(Object.keys(socials) || [])
        .filter(s => typeof socials[s] === 'string')
        .map(s => (
          <InputGroup key={s} className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{icons[s]}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              value={socials[s]}
              onChange={e => onChange({ ...socials, [s]: e.target.value })}
            />
            <InputGroup.Append>
              <Button variant="secondary" onClick={() => onChange({ ...socials, [s]: undefined })}>
                <Icon size={16} style={{ color: '#FF0000' }} name="x" />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        ))}
      {options.length > 0 && (
        <Dropdown>
          <Dropdown.Toggle size="sm" id="add-new-dropdown">
            Add new
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options.map(o => (
              <Dropdown.Item key={o.key} onClick={() => onChange({ ...socials, [o.key]: '' })}>
                {icons[o.key]}
                <span className="mx-1" />
                {o.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default SocialList;
