import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import get from 'lodash.get';

import { useModel } from 'models';


const TagInput = ({
  appId, onChange, fixedOptions = [], value, ...rest
}) => {
  const [keyword, setKeyword] = useState(value);
  const [{ app, loading }, { getAppDetail }] = useModel('app', state => ({
    app: state.apps[appId],
    loading: (state.getAppDetail[appId] || {}).loading,
  }), appId);

  useEffect(() => {
    if (appId !== '*') {
      getAppDetail(appId);
    }
  }, [appId]);

  const options = fixedOptions.concat(get(app, 'tags', []));

  return (
    <Select
      isClearable
      isLoading={loading}
      className="Select"
      classNamePrefix="Select"
      getOptionValue={v => v.id}
      getOptionLabel={v => v.name}
      options={options}
      onChange={onChange}
      value={value ? options.find(t => t.id === value) : null}
      onInputChange={kw => setKeyword(kw)}
      filterOption={({ value: val, label }) => val === '*' || val.includes(keyword) || label.includes(keyword)}
      noOptionsMessage={() => 'No result'}
      {...rest}
    />
  );
};

export default TagInput;
