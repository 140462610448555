import React, { useState, useEffect, useRef } from 'react';
import { Button, Loader } from 'tabler-react';
import { Alert, Card, Form } from 'react-bootstrap';
import queryString from 'query-string';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useModel } from 'models';
import configs from 'configs/configs';
import Centered from 'layouts/Centered';
import logoFAN from 'static/images/adblue2-white.png';
import logoADX from 'static/images/appotax.png';

import styles from './Login.module.scss';

/**
 * @param {import('react-router-dom').RouteComponentProps} props
 */
const Login = ({ history, location }) => {
  const [input, setInput] = useState({});
  const prevLoading = useRef();
  const {
    request_token: requestToken, state, code, email, ...rest
  } = queryString.parse(location.search);

  const [
    {
      authorized,
      account,
      login: { loading, error },
    },
    { login, getCurrentUser },
  ] = useModel('auth');

  useEffect(() => {
    if (!code) {
      getCurrentUser();
    }
  }, []);

  useEffect(() => {
    if (authorized) {
      const next = rest.next || '/';
      history.replace(next[0] === '/' ? next : `/${next}`);
    } else if (!loading && prevLoading.current) {
      if (account && account.isUnregistered()) {
        history.replace('/register');
      }
    }

    prevLoading.current = loading;
  }, [authorized, loading]);

  useEffect(() => {
    if (requestToken) {
      const params = { requestToken, platform: configs.platform };
      if (state) {
        try {
          const j = JSON.parse(atob(state));
          if (j && j.as) {
            params.as = j.as;
          }
        } catch (e) {
          // do nothing
        }
      }
      login(params);
    }
  }, []);

  if (requestToken && !error) {
    return (
      <Centered>
        <div className={styles.wrapper}>
          <img
            className={classNames(styles.logo, 'mb-5 d-block mx-auto')}
            src={configs.platform === 'ADX' ? logoADX : logoFAN}
            alt=""
          />
          <div className="text-center">
            <Loader className="mx-auto mb-2" />
            Logging...
          </div>
        </div>
      </Centered>
    );
  }

  const submit = (e) => {
    e.preventDefault();
    const params = {
      username: email || input.username,
      password: input.password,
      platform: configs.platform,
      inviteCode: code,
    };
    if (rest && rest.as) {
      params.as = rest.as;
    }
    login(params);
  };

  const callbackUri = configs.absURL('/login');
  let stateParams = '';
  if (Object.keys(rest).length > 0) {
    stateParams = btoa(JSON.stringify(rest));
  }
  const appotaLoginUrl = `https://id.appota.com/oauth/request_token?response_type=code&scope=user.info&client_id=${
    configs.APPOTA_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(callbackUri)}&state=${encodeURIComponent(stateParams)}`;

  return (
    <Centered>
      <div className={styles.wrapper}>
        <img
          className={classNames(styles.logo, 'mb-5 d-block mx-auto')}
          src={configs.platform === 'ADX' ? logoADX : logoFAN}
          alt=""
        />
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Login with your Appota ID</Card.Title>
            <Form onSubmit={submit}>
              <Form.Group>
                <Form.Label>
                  Username or Email
                  <span className="form-required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={!!email}
                  value={email || input.username || ''}
                  onChange={e => setInput({ ...input, username: e.target.value })}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Password
                  <span className="form-required">*</span>
                  <a
                    tabIndex={-1}
                    className="float-right small"
                    href="https://id.appota.com/forgot_password"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Forgot your password?
                  </a>
                </Form.Label>
                <Form.Control
                  type="password"
                  value={input.password || ''}
                  onChange={e => setInput({ ...input, password: e.target.value })}
                  required
                />
              </Form.Group>
              {error && <Alert variant="danger">{error.message || error.error || error}</Alert>}
              <Button block loading={loading} color="primary" type="submit">
                Login
              </Button>
              <div className="my-4 small divider text-center">
                <span className="px-2">Using Facebook, Google?</span>
              </div>
              <div className="text-center">
                <a href={appotaLoginUrl}>Login from Appota ID</a>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <div className="text-center">
          <span>{`New to ${configs.platform === 'ADX' ? 'AppotaX' : 'AdBlue'}? `}</span>
          <Link to="/register">Register new account</Link>
        </div>
      </div>
    </Centered>
  );
};

export default Login;
