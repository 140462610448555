import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'scroll-hint/css/scroll-hint.css';
import 'rc-steps/assets/index.css';
import 'rc-table/assets/index.css';

import './tabler/scss/bundle.scss';
import './index.scss';

import 'rc-collapse/assets/index.css';
import moment from 'moment';
import ReactGA from 'react-ga';
import * as yup from 'yup';

import { registerModels } from 'models';
import authModel from 'models/auth';
import appModel from 'models/app';
import pubModel from 'models/publisher';
import reportModel from 'models/report';
import invoiceModel from 'models/invoice';
import notificationModel from 'models/notification';
import alertModel from 'models/alert';
import monitorModel from 'models/monitor';
import referralModel from 'models/referral';
import pageModel from 'models/page';
import configs from './configs/configs';

import App from './App';
import ErrorBoundary from './ErrorBoudary';
import * as serviceWorker from './serviceWorker';

ReactGA.initialize(configs.GA);

moment.updateLocale('en', { week: { dow: 1 } });

registerModels(
  authModel,
  appModel,
  pubModel,
  reportModel,
  invoiceModel,
  notificationModel,
  alertModel,
  monitorModel,
  referralModel,
  pageModel,
);

ReactDOM.render(
  <BrowserRouter basename={configs.baseURL}>
    <>
      <Helmet>
        <title>{configs.platform === 'ADX' ? 'AppotaX Dashboard' : 'AdBlue Dashboard'}</title>
      </Helmet>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
      <div id="uGDgEhWIBl0N4Av2x" />
    </>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'dateRange', function (format, validRange, message) {
  const test = input => {
    const d = moment(input, format);
    if (!d.isValid) return false;
    if (!validRange.length) return true;
    if (validRange[0] && d.isBefore(validRange[0])) return false;
    if (validRange.length > 1 && validRange[1] && d.isAfter(validRange[1])) return false;
    return true;
  };
  return this.test({
    name: 'dateRange',
    test,
    message,
  });
});
