import React, { useEffect } from 'react';
import {
  Modal, Button, Form, Alert, InputGroup,
} from 'react-bootstrap';
import moment from 'moment';
import get from 'lodash.get';

import Account from 'entities/Account';
import useTempState from 'utils/useTempState';
import configs from 'configs/configs';

const UpdateAccountModal = ({
  show, account, action, loading, error, onSubmit, onCancel,
}) => {
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);
  const [formState, setFormState] = useTempState({}, [show]);
  const from = parseInt(moment(account.ts).format('YYYYMMDD'), 10);
  const onSharingConfigChange = (value) => {
    setFormState({ ...formState, sharingConfig: { from, percent: value } });
  };

  useEffect(() => {
    setSubmitStatus({ loading, error });
  }, [loading, error]);

  const submit = () => {
    const data = { ...formState };
    if (action === 'deactivate') {
      data.status = Account.STATUS_DEACTIVATED;
    }
    if (action === 'activate') {
      data.status = Account.STATUS_ACTIVATED;
      if (configs.platform === 'ADX' && !account.adxId && !formState.adxId) {
        setSubmitStatus({ error: { message: 'Adx Network ID is required' } });
        return;
      }
      if (!account.sharingConfig && !get(formState, 'sharingConfig.percent' || '')) {
        setSubmitStatus({ error: { message: 'Sharing config is required' } });
        return;
      }
    }
    if (action === 'invite' && !/https:\/\/www\.google\.com\/adx\/unauth\/npm\/participant-registration\?invitation=.+$/i.test(formState.inviteUrl)) {
      setSubmitStatus({ error: { message: 'Invalid invite URL' } });
      return;
    }
    onSubmit(data);
  };

  const renderContent = () => {
    if (action === 'deactivate') {
      return (
        <Form.Group>
          <Form.Label>Reject reason</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Enter reject reason"
            value={formState.reason || ''}
            onChange={e => setFormState({ reason: e.target.value })}
          />
        </Form.Group>
      );
    }
    if (action === 'invite') {
      return (
        <Form.Group>
          <Form.Label>Invite URL</Form.Label>
          <Form.Control
            type="text"
            value={formState.inviteUrl || ''}
            onChange={e => setFormState({ inviteUrl: e.target.value })}
          />
        </Form.Group>
      );
    }
    if (action === 'activate') {
      return (
        <>
          {configs.platform === 'ADX' && (
            <Form.Group>
              <Form.Label>Adx Network ID</Form.Label>
              <Form.Control
                type="text"
                value={formState.adxId || ''}
                onChange={e => setFormState({ ...formState, adxId: e.target.value })}
              />
            </Form.Group>
          )}
          {!account.sharingConfig && (
            <Form.Group>
              <Form.Label>Appota&apos;s Revenue Share</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={get(formState, 'sharingConfig.percent' || '')}
                  onChange={e => onSharingConfigChange(parseInt(e.target.value, 10))}
                />
                <InputGroup.Append>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          Edit account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderContent()}
        {submitStatus.error && (
          <Alert variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
        <Button disabled={submitStatus.loading} variant="primary" onClick={submit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateAccountModal;
