import React from 'react';
import { Badge } from 'react-bootstrap';

const statuses = ['', 'Active', 'Inactive'];

export default class Page {
  static STATUS_ACTIVE = 1;
  static STATUS_INACTIVE = 2;

  constructor(app = {}) {
    Object.assign(this, app);
  }

  statusString() {
    return statuses[this.status] || '';
  }

  static getStatusOptions() {
    return [
      { value: this.STATUS_ACTIVE, label: 'Active' },
      { value: this.STATUS_INACTIVE, label: 'Inactive' },
    ];
  }

  getStatusBadge() {
    let style = '';
    let badge = '';
    switch (this.status) {
      case Page.STATUS_ACTIVE:
        style = 'success';
        badge = 'Active';
        break;
      default:
        style = 'default';
        badge = 'Inactive';
    }

    /* eslint react/react-in-jsx-scope: "off" */
    /* eslint react/jsx-filename-extension: "off" */
    return (
      <Badge pill variant={style}>
        {badge}
      </Badge>
    );
  }
}
