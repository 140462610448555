import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import Icon from 'components/Icon';

import useTempState from 'utils/useTempState';

import styles from './RejectInvoiceModal.module.scss';

const RejectInvoiceModal = ({
  invoiceId, show, onSubmit, onCancel,
}) => {
  const [reason, setReason] = useTempState('', [show]);

  return (
    <Modal dialogClassName={styles.modal} show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Icon name="alert-triangle" color="#BD332A" />
          {`Reject invoice ${invoiceId}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Reason:</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="What's wrong with this invoice?"
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
        <Button variant="primary" onClick={() => onSubmit(reason)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectInvoiceModal;
