import React, { useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';
import { Loader } from 'tabler-react';
import Table from 'rc-table';
import classNames from 'classnames';
import ScrollHint from 'scroll-hint';

import styles from './DataTable.module.scss';

/**
 * @param {DataTableProps} props
 */
const DataTable = ({
  className, columns = [], rows = [], data, loading, pagination, ...rest
}) => {
  const cols = columns.map(c => ({ ...c, dataIndex: c.dataIndex || c.key }));
  useEffect(() => {
    // eslint-disable-next-line no-new
    new ScrollHint('.table .rc-table-body', { suggestiveShadow: true });
  }, []);

  const Footer = () => (
    <div className={styles.footer}>
      {loading && <Loader />}
      {pagination && pagination.total > 1 && (
        <Paging
          className="my-3"
          size="sm"
          totalPages={pagination.total}
          currentPage={pagination.current}
          onChange={pagination.onChange}
        />
      )}
    </div>
  );
  return (
    <>
      <Table
        className={classNames('table', styles.table, className, { loading })}
        columns={cols}
        data={rows}
        {...rest}
        data-slideout-ignore
      />
      <Footer />
    </>
  );
};

const Paging = createUltimatePagination({
  WrapperComponent: Pagination,
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: ({ value, isActive, onClick }) => (
      <Pagination.Item active={isActive} onClick={onClick}>
        {value}
      </Pagination.Item>
    ),
    [ITEM_TYPES.ELLIPSIS]: ({ isActive, onClick }) => (
      <Pagination.Ellipsis disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.FIRST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.First disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Prev disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.NEXT_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Next disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.LAST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Last disabled={isActive} onClick={onClick} />
    ),
  },
});

export default DataTable;
