import React, { useState, useContext } from 'react';
import { Button, Alert } from 'react-bootstrap';
import moment from 'moment';

import { useModel } from 'models';
import styles from './Settings.module.scss';
import SharingConfigInput from './SharingConfigInput';
import useActionEffect from 'utils/useActionEffect';
import { AppContext } from 'App';
import User from 'entities/User';

const SharingConfigs = ({
  accountId, adbreakEnabled, configs = [], adBreakConfigs,
}) => {
  const [{ loading, error }, { updatePublisher }] = useModel(
    'publisher',
    state => state.updatePublisher[accountId] || {},
  );
  const [editing, setEditing] = useState();
  const [editingAdBreak, setEditingAdBreak] = useState();
  const onSubmit = ({ date, percent }) => {
    updatePublisher(accountId, {
      sharingConfig: { from: parseInt(date.format('YYYYMMDD'), 10), percent },
    });
  };

  const onSubmitAdBreak = ({ date, percent }) => {
    updatePublisher(accountId, {
      adbreakSharingConfig: { from: parseInt(date.format('YYYYMMDD'), 10), percent },
    });
  };

  const { currentUser } = useContext(AppContext);

  useActionEffect({
    loading,
    error,
    onSuccess: () => {
      setEditing(false);
      setEditingAdBreak(false);
    },
  });

  return (
    <div className={styles.sharingConfigs}>
      <h5>
        Sharing Configs
        {currentUser.hasPermission(User.PermPubSharingEdit) && (
          <Button
            variant="link"
            className="mb-1"
            onClick={() => {
              setEditing(editing ? false : {});
              setEditingAdBreak(false);
            }}
          >
            {editing ? 'Cancel' : 'Add'}
          </Button>
        )}
      </h5>
      <ul>
        {configs.map(c => (
          <li className="mt-2" key={c.from}>{`From ${moment(c.from, 'YYYYMMDD').format('L')}: ${c.percent}%`}</li>
        ))}
        {editing && (
          <li className="mt-3">
            <SharingConfigInput
              minDate={configs.length > 0 ? moment().add(1, 'day') : null}
              onSubmit={onSubmit}
              dateFormat="YYYYMMDD"
              loading={loading}
            />
            {error && (
              <Alert className="mt-2 py-2" variant="danger">
                {error.message || error.error}
              </Alert>
            )}
          </li>
        )}
      </ul>
      {adbreakEnabled && (
        <>
          <h5>
            Ad Break Sharing Configs
            {currentUser.hasPermission(User.PermPubSharingEdit) && (
            <Button
              variant="link"
              className="mb-1"
              onClick={() => {
                setEditingAdBreak(editingAdBreak ? false : {});
                setEditing(false);
              }}
            >
              {editingAdBreak ? 'Cancel' : 'Add'}
            </Button>
            )}
          </h5>
          <ul>
            {adBreakConfigs.map(c => (
              <li className="mt-2" key={c.from}>{`From ${moment(c.from, 'YYYYMMDD').format('L')}: ${c.percent}%`}</li>
            ))}
            {editingAdBreak && (
            <li className="mt-3">
              <SharingConfigInput
                minDate={adBreakConfigs.length > 0 ? moment().add(1, 'day') : null}
                onSubmit={onSubmitAdBreak}
                dateFormat="YYYYMMDD"
                loading={loading}
              />
              {error && (
              <Alert className="mt-2 py-2" variant="danger">
                {error.message || error.error}
              </Alert>
              )}
            </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default SharingConfigs;
