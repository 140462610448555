import React, { useState, useEffect, useContext } from 'react';
import {
  Button, InputGroup, Alert, Dropdown, ButtonGroup,
} from 'react-bootstrap';
import { stringify, parse } from 'query-string';
import pickBy from 'lodash.pickby';

import { useModel } from 'models';
import App from 'entities/App';
import Icon from 'components/Icon';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import AddAppModal from 'components/AddAppModal';
import Notification from 'components/Notification';
import FlexLayoutCard from 'components/FlexLayoutCard';
import QuickSearchInput from 'components/QuickSearchInput';
import EmptyState from 'components/EmptyState';
import FilterAppModal from 'components/FilterAppModal';
import configs from 'configs/configs';
import User from 'entities/User';
import AppDetail from 'components/AppDetail';
import { AppContext } from 'App';
import useActionEffect from 'utils/useActionEffect';

import styles from './ListApp.module.scss';

const appsSelector = ({
  listApps, apps, warningApps, updateMulti,
}) => ({
  apps: listApps.ids.map(i => apps[i]),
  total: listApps.total,
  loading: listApps.loading,
  error: listApps.error,
  totalWarning: warningApps.total,
  updatingMulti: updateMulti.loading,
  updateMultiError: updateMulti.error,
});

const ListApps = ({
  history, match, location, currentUser, currentAccount,
}) => {
  const { appId } = match.params;
  const query = parse(location.search);
  const [selectedApps, setSelectedApp] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [
    {
      total, totalWarning, apps, loading, error, updatingMulti, updateMultiError,
    },
    { getListApps, getWarningApps, updateMulti },
  ] = useModel('app', appsSelector);
  const canAddApp = currentUser.hasPermission(User.PermAppEdit)
    && ((configs.platform === 'ADX' && !currentAccount.lockInventory)
      || (configs.platform === 'FAN' && currentAccount.isAdmin()));
  const canUpdateMultiApps = currentAccount.isAdmin() && configs.platform === 'ADX';

  const { dialog } = useContext(AppContext);

  const quickSearchOptions = configs.platform === 'ADX'
    ? [
      { keyword: 'tags=0', label: 'App has no tags', value: { tags: 0 } },
      { keyword: 'storeAppId=1', label: 'App has store ID', value: { storeAppId: 1 } },
      { keyword: 'storeAppId=-', label: 'App has no store ID', value: { storeAppId: '-' } },
      { keyword: 'updateWarning=1', label: 'Update warning', value: { updateWarning: 1 } },
      { keyword: 'violation=1', label: 'Apps violated policy', value: { violation: 1 } },
    ]
    : [];

  useEffect(() => {
    if (configs.platform === 'ADX') {
      getWarningApps();
    }
  }, []);

  const quickSearch = quickSearchOptions.find(o => o.keyword === query.kw);
  const doGetListApps = ({ skip }) => {
    const params = {
      ...query,
      limit: 30,
      skip,
      ...(quickSearch ? { ...quickSearch.value, kw: undefined } : { kw: query.kw }),
    };
    if (query.violation) {
      params.violation = query.violation;
    }
    getListApps(params);
  };

  useEffect(() => {
    doGetListApps({ skip: 0 });
  }, [location.search]);

  useActionEffect({
    loading: updatingMulti,
    error: updateMultiError,
    onSuccess: () => {
      Notification.success('Update applications successfully');
      setSelectedApp([]);
    },
    onError: () => Notification.error(
      updateMultiError.message || updateMultiError.error || 'Something went wrong.',
    ),
  });

  const filter = { ...query };
  delete filter.kw;
  const numFilter = Object.keys(filter).length;
  if (quickSearch && quickSearch.value.violation && !filter.violation) {
    filter.violation = quickSearch.value.violation;
  }

  const onQueryParamsChange = q => history.push({
    ...location,
    search: stringify(pickBy({ ...query, ...q })),
  });

  const onUpdateMultiApps = (status) => {
    if (selectedApps.length === 0) {
      return;
    }
    dialog.warning({
      title: 'Update app status',
      content: `Are you sure want to update status of all these apps to ${status}?`,
      onOK: (d) => {
        updateMulti({ appIds: selectedApps, status });
        d.dismiss();
      },
    });
  };

  const listApp = (
    <>
      {apps.map(a => (
        <FlexLayoutCard.RowItem
          key={a.id}
          selectable={canUpdateMultiApps}
          selected={selectedApps.includes(a.id)}
          onSelectedChange={checked => setSelectedApp(
            checked ? selectedApps.concat(a.id) : selectedApps.filter(id => id !== a.id),
          )
          }
          active={appId === a.id}
          onClick={() => history.push({ ...location, pathname: `/apps/${a.id}` })}
          icon={a.icon}
          name={(
            <>
              <div>{a.name}</div>
              <div className={styles.warningIcon}>
                {a.updateWarning && <Icon name="alert-triangle" color="#f6bf09" />}
              </div>
            </>
)}
        />
      ))}
      {apps.length === 0 && (
        <EmptyState className="mt-5" icon="search" title="No data" desc="No app to show" />
      )}
    </>
  );

  const extraContent = (
    <>
      <InputGroup className={styles.filter} size="sm">
        <QuickSearchInput
          options={quickSearchOptions}
          className="search p-0"
          placeholder="Search apps"
          value={query.kw || ''}
          onSearch={kw => onQueryParamsChange({ kw })}
        />
        <InputGroup.Append>
          <Button
            variant={numFilter > 0 ? 'primary' : 'outline-primary'}
            onClick={() => setShowFilterModal(true)}
          >
            <Icon name="filter" />
            {numFilter > 0 && `${numFilter} applied`}
          </Button>
          <FilterAppModal
            filter={filter}
            onApply={(f) => {
              onQueryParamsChange(f);
              setShowFilterModal(false);
            }}
            show={showFilterModal}
            onHide={() => setShowFilterModal(false)}
          />
        </InputGroup.Append>
      </InputGroup>
    </>
  );

  return (
    <MainLayout>
      <PageHeader
        title="List Apps"
        subTitle="Apps management"
        extraContent={
          canAddApp && (
            <Button
              className="align-self-end align-self-sm-center"
              onClick={() => setAddModalVisible(true)}
            >
              <Icon name="plus" size={16}>
                {' '}
                Add Application
              </Icon>
            </Button>
          )
        }
      />
      {!!totalWarning && !error && (
        <Alert className="mt-3 mb-0" variant="warning">
          <Icon size={24} className="mr-2 font-weight-bold" name="alert-triangle" providers="fi" />
          {`You have ${totalWarning} apps need to be updated. Please update your apps, otherwise your app will be blocked.`}
        </Alert>
      )}
      {error && <Alert className="mt-3 mb-0" variant="danger">{error.message || error.error || 'Something went wrong'}</Alert>}
      <FlexLayoutCard
        className="mt-3 flex-grow-1"
        total={total}
        headerExtra={(
          <span>
            {canUpdateMultiApps && (
            <Dropdown as={ButtonGroup} className="ml-3">
              <Button
                size="sm"
                variant={selectedApps.length > 0 ? 'secondary' : 'outline-secondary'}
                onClick={() => setSelectedApp(selectedApps.length > 0 ? [] : apps.map(a => a.id))}
              >
                {selectedApps.length > 0 ? `${selectedApps.length} selected` : 'Select all'}
              </Button>
              <Dropdown.Toggle size="sm" split variant="outline-secondary" id="update-actions" />
              <Dropdown.Menu>
                {App.getStatusOptions()
                  .filter(o => o.value !== 'pending')
                  .map(o => (
                    <Dropdown.Item key={o.value} onClick={() => onUpdateMultiApps(o.value)}>
                      {o.label}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            )}
          </span>
)}
        showDetail={appId}
        onBack={() => history.push('/apps')}
        loading={loading}
        data={apps}
        sidebar={listApp}
        main={<AppDetail appId={appId} />}
        extraContent={extraContent}
        onFetchMore={() => doGetListApps({ skip: apps.length })}
      />
      {canAddApp && (
        <AddAppModal
          show={addModalVisible}
          onCancel={() => setAddModalVisible(false)}
          onSuccess={(id) => {
            Notification.success('Add application successfully');
            setAddModalVisible(false);
            history.push(`/apps/${id}`);
          }}
        />
      )}
    </MainLayout>
  );
};
export default ListApps;
