import pageService from 'services/page';
import Page from 'entities/Page';

export default {
  name: 'page',
  state: {
    pages: {},
    listPages: {
      total: 0,
      ids: [],
      error: null,
      loading: false,
    },
    getListImportPages: {
      ownedPages: [],
      clientPages: [],
      error: null,
      loading: false,
    },
    getPageDetail: {},
    importPages: {},
    updatePage: {},
    searchPages: {
      ids: [],
    },
  },
  reducers: {
    getListPages: (state, payload, status) => {
      switch (status) {
        case 'success': {
          const { skip, total, pages } = payload;
          return {
            pages: (pages || []).reduce(
              (p, v) => ({ ...p, [v.id]: new Page({ ...p[v.id], ...v }) }),
              state.pages,
            ),
            listPages: {
              ...state.listPages,
              total,
              ids: (skip === 0 ? [] : state.listPages.ids).concat(pages.map(a => a.id)),
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            listPages: {
              ...state.listPages,
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            listPages: {
              ...state.listPages,
              loading: true,
              error: null,
            },
          };
      }
    },
    getPageDetail: (state, payload, status) => {
      const { id, error } = payload;
      switch (status) {
        case 'success':
          return {
            pages: {
              ...state.pages,
              [id]: new Page(payload),
            },
            getPageDetail: {
              ...state.getPageDetail,
              [id]: { loading: false, error: null },
            },
          };
        case 'error':
          return {
            getPageDetail: {
              ...state.getPageDetail,
              [id]: { loading: false, error },
            },
          };
        default:
          return {
            getPageDetail: {
              ...state.getPageDetail,
              [id]: { loading: true, error: null },
            },
          };
      }
    },
    getListImportPages: (state, payload, status) => {
      switch (status) {
        case 'success': {
          return {
            getListImportPages: {
              ownedPages: payload.ownedPages,
              clientPages: payload.clientPages,
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            getListImportPages: {
              ...state.getListImportPages,
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            getListImportPages: {
              ...state.getListImportPages,
              loading: true,
              error: null,
            },
          };
      }
    },
    importPages: (state, payload, status) => {
      switch (status) {
        case 'success': {
          const { pages } = payload;
          return {
            pages: (pages || []).reduce(
              (p, v) => ({ ...p, [v.id]: new Page(v) }),
              state.pages,
            ),
            listPages: {
              ...state.listPages,
              total: state.listPages.total + (pages || []).length,
              ids: (pages || []).map(p => p.id).concat(state.listPages.ids),
            },
            importPages: {
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            importPages: {
              loading: false,
              error: payload,
            },
          };
        default:
          return {
            importPages: {
              loading: true,
              error: null,
            },
          };
      }
    },
    updatePage: (state, payload, status) => {
      switch (status) {
        case 'success': {
          return {
            pages: { ...state.pages, [payload.id]: new Page({ ...state.pages[payload.id], ...payload }) },
            updatePage: {
              ...state.updatePage,
              [payload.id]: { loading: false, error: null },
            },
          };
        }
        case 'error': {
          const { id, error } = payload;
          return {
            updatePage: {
              ...state.updatePage,
              [id]: { loading: false, error },
            },
          };
        }
        default:
          return {
            updatePage: {
              ...state.updatePage,
              [payload.id]: { loading: true, error: null },
            },
          };
      }
    },
    searchPages: (state, payload, status) => {
      switch (status) {
        case 'success': {
          const { total, pages } = payload;
          return {
            pages: pages.reduce((p, v) => ({ ...p, [v.id]: new Page({ ...p[v.id], ...v }) }), state.pages),
            searchPages: {
              ...state.searchPages,
              total,
              ids: pages.map(p => p.id),
              loading: false,
              error: null,
            },
          };
        }
        case 'error':
          return {
            searchPages: {
              ...state.searchPages,
              ids: [],
              loading: false,
              error: null,
            },
          };
        default:
          return {
            searchPages: {
              ...state.searchPages,
              ids: [],
              loading: true,
              error: null,
            },
          };
      }
    },
  },
  effects: {
    async getListPages(payload, onSuccess, onError) {
      try {
        const result = await pageService.getListPages(payload);
        onSuccess({ ...result, skip: payload.skip });
      } catch (e) {
        onError(e);
      }
    },
    async getListImportPages(payload, onSuccess, onError) {
      try {
        onSuccess(await pageService.getListImportPages(payload));
      } catch (e) {
        onError(e);
      }
    },
    async getPageDetail({ id }, onSuccess, onError) {
      try {
        onSuccess(await pageService.getPageDetail(id));
      } catch (e) {
        onError({ id, error: e });
      }
    },
    async importPages(payload, onSuccess, onError) {
      try {
        onSuccess(await pageService.importPages(payload));
      } catch (e) {
        onError(e);
      }
    },
    async updatePage({ id, data }, onSuccess, onError) {
      try {
        onSuccess(await pageService.updatePage(id, data));
      } catch (e) {
        onError({ id, error: e });
      }
    },
    async searchPages(payload, onSuccess, onError) {
      try {
        onSuccess(await pageService.getListPages(payload));
      } catch (e) {
        onError(e);
      }
    },
  },
  actions: {
    getListPages: params => params,
    getPageDetail: id => ({ id }),
    getListImportPages: params => params,
    importPages: params => params,
    updatePage: (id, data) => ({ data, id }),
    searchPages: params => params,
  },
};
