import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';

import Gist from 'components/Gist';
import { AppContext } from 'App';
import configs from 'configs/configs';

const WhatsNewModal = ({ show, onHide, onRead }) => {
  const { currentAccount } = useContext(AppContext);
  const { gistId, file } = currentAccount.isAdmin() ? configs.whatsNew.admin : configs.whatsNew.pub;

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>What&apos;s New</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Gist id={gistId} onShow={onRead} filename={file} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WhatsNewModal;
