import api from './api';

export default {
  getNotifications(params) {
    return api.GET('/notifications', params);
  },
  readNotification(params) {
    return api.PATCH('/notifications', { ...params, read: true });
  },
  addSystemAlert(params) {
    return api.POST('/notifications', params);
  },
  deleteSystemAlert(id) {
    return api.DELETE(`/notifications/${id}`);
  },
};
