import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import qs from 'query-string';
import {
  Row, Col, Alert, Form, ButtonGroup, Button, FormControl,
} from 'react-bootstrap';
import { Card, Dimmer } from 'tabler-react';
import pickBy from 'lodash.pickby';
import { Link } from 'react-router-dom';
import get from 'lodash.get';

import { useModel } from 'models';
import usePrevState from 'utils/usePrevState';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import DateRangePicker from 'components/DateRangePicker';
import MetricChart from 'components/MetricChart';
import {
  asyncAggregateReports, FANMetric, ADXMetric, getMetricName,
} from 'entities/Report';
import ReportTableCard from 'components/ReportTableCard';
import AppInput from 'components/AppInput';
import CountryInput from 'components/CountryInput';
import PublisherSegment from 'components/PublisherSegment';
import configs from 'configs/configs';
import Icon from 'components/Icon';
import BMSelect from 'components/BMSelect';
import FilterGroup from 'components/FilterGroup';
import NetworkSelect from 'components/NetworkSelect';
import MonthRangePicker from 'components/MonthRangePicker';

function getConfig(asPublisher = false) {
  const adxConfig = {
    MetricClass: ADXMetric,
    breakdowns: {
      date: { dims: ['DATE'], title: 'Dates', colName: 'Date' },
      // week: { dims: ['WEEK'], title: 'Weeks', colName: 'Week' },
      month: { dims: ['MONTH'], title: 'Months', colName: 'Month' },
      ...(asPublisher
        ? {}
        : {
          publisher: {
            dims: ['ADX_PUBLISHER_ID', 'ADX_PUBLISHER_NAME'],
            title: 'Publishers',
            colName: 'Publisher',
          },
        }),
      app: { dims: ['ADX_APP_ID', 'ADX_APP_NAME'], title: 'Apps', colName: 'App' },
      tag: {
        dims: ['AD_TAG_CODE', 'ADX_AD_TAG_NAME', 'AD_TAG_NAME'],
        title: 'Tags',
        colName: 'Tag',
      },
      country: { dims: ['COUNTRY_CODE'], title: 'Countries', colName: 'Country' },
    },
    metrics: [
      'AD_REQUESTS',
      'MATCHED_AD_REQUESTS',
      'AD_IMPRESSIONS',
      'CLICKS',
      'EARNINGS',
      'ADX_PUBLISHER_EARNINGS',
      '-AD_IMPRESSIONS',
      '-CLICKS',
      '-EARNINGS',
    ],
    filter: {
      pid: 'ADX_PUBLISHER_ID',
      aid: 'ADX_APP_ID',
      tcode: 'AD_TAG_CODE',
    },
    chartMetrics: [
      'AD_REQUESTS',
      'MATCHED_AD_REQUESTS',
      'AD_IMPRESSIONS',
      'CLICKS',
      'ADX_ECPM',
      ...(asPublisher
        ? [{ key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('EARNINGS') }]
        : ['EARNINGS', 'ADX_PUBLISHER_EARNINGS']),
    ],
    chartDefaultMetrics: [asPublisher ? 'ADX_PUBLISHER_EARNINGS' : 'EARNINGS', 'ADX_ECPM'],
    tableMetrics: [
      'AD_REQUESTS',
      'MATCHED_AD_REQUESTS',
      'AD_IMPRESSIONS',
      'CLICKS',
      'ADX_COVERAGE',
      'ADX_SHOW_RATE',
      'ADX_REQUEST_CTR',
      'ADX_ECPM',
      ...(asPublisher
        ? [{ key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('EARNINGS') }]
        : ['EARNINGS', 'ADX_PUBLISHER_EARNINGS']),
    ],
    tableOptionalMetrics: breakdown => !asPublisher
      && (['publisher', 'app', 'tag'].find(b => b === breakdown)
        ? ['_AD_IMPRESSIONS', '_CLICKS', '_EARNINGS']
        : []),
    tableSortDefault: asPublisher ? 'ADX_PUBLISHER_EARNINGS' : 'EARNINGS',
  };

  const fanConfig = {
    MetricClass: FANMetric,
    breakdowns: {
      date: { dims: ['DATE'], title: 'Dates', colName: 'Date' },
      // week: { dims: ['WEEK'], title: 'Weeks', colName: 'Week' },
      month: { dims: ['MONTH'], title: 'Months', colName: 'Month' },
      ...(asPublisher
        ? {}
        : {
          publisher: {
            dims: ['FAN_PUB_ID', 'FAN_PUB_NAME'],
            title: 'Publishers',
            colName: 'Publisher',
          },
        }),
      app: { dims: ['FAN_APP_ID', 'FAN_APP_NAME'], title: 'Apps', colName: 'App' },
      platform: { dims: ['FAN_PLATFORM'], title: 'Platforms', colName: 'Platform' },
      country: { dims: ['COUNTRY_CODE'], title: 'Countries', colName: 'Country' },
    },
    metrics: [
      'FAN_REQUEST',
      'FAN_FILLED_REQUEST',
      'FAN_IMPRESSION',
      'FAN_CLICK',
      'FAN_REVENUE',
      'FAN_PUB_REVENUE',
    ],
    filter: {
      pid: 'FAN_PUB_ID',
      aid: 'FAN_APP_ID',
      platform: 'FAN_PLATFORM',
    },
    chartMetrics: [
      ...(asPublisher
        ? [{ key: 'FAN_PUB_REVENUE', title: getMetricName('FAN_REVENUE') }]
        : ['FAN_REVENUE', 'FAN_PUB_REVENUE']),
      'FAN_CLICK',
      'FAN_REQUEST',
      'FAN_FILLED_REQUEST',
      'FAN_IMPRESSION',
      'FAN_ECPM',
      'FAN_FILL_RATE',
      'FAN_SHOW_RATE',
    ],
    chartDefaultMetrics: [asPublisher ? 'FAN_PUB_REVENUE' : 'FAN_REVENUE', 'FAN_ECPM'],
    tableMetrics: [
      'FAN_REQUEST',
      'FAN_FILLED_REQUEST',
      'FAN_FILL_RATE',
      'FAN_IMPRESSION',
      'FAN_CLICK',
      'FAN_CTR',
      'FAN_ECPM',
      ...(asPublisher
        ? [{ key: 'FAN_PUB_REVENUE', title: getMetricName('FAN_REVENUE') }]
        : ['FAN_REVENUE', 'FAN_PUB_REVENUE']),
    ],
    tableSortDefault: asPublisher ? 'FAN_PUB_REVENUE' : 'FAN_REVENUE',
  };

  return configs.platform === 'ADX' ? adxConfig : fanConfig;
}

const timeOptions = [
  {
    label: 'Date',
    value: 'date',
    format: 'YYYY-MM-DD',
    shorthand: 'd',
  },
  // {
  //   label: 'Week',
  //   value: 'week',
  //   format: 'YYYY-WW',
  //   shorthand: 'w',
  // },
  {
    label: 'Month',
    value: 'month',
    format: 'YYYY-MM',
    shorthand: 'M',
  },
];

const MAX_FILTER = window.innerWidth < 768 ? 1 : 3; // TODO: based on size

/**
 * @param {import('react-router-dom').RouteComponentProps} props
 */
const Report = ({ location, history, currentAccount }) => {
  const conf = getConfig(currentAccount.isPublisher());
  const params = qs.parse(location.search);
  const period = params.period || 'date';
  const { breakdowns } = conf;

  const selectedTimeOption = timeOptions.find(t => t.value === period);
  const { format: timeFormat, shorthand: timeShorthand } = selectedTimeOption;

  if (params.metric && !conf.chartMetrics.find(m => m === params.metric)) {
    params.metric = '';
  }

  let brkKey = params.breakdown || period;
  if (!breakdowns[brkKey]) {
    brkKey = period;
  }

  const timeDimension = get(conf, ['breakdowns', period, 'dims', 0], 'DATE');

  /** @type {typeof breakdowns.date} */
  const breakdown = { ...breakdowns[brkKey], key: brkKey };

  let startDate = moment(params.from, 'YYYY-MM-DD').startOf(timeShorthand);
  let endDate = moment(params.to, 'YYYY-MM-DD').endOf(timeShorthand);
  if (!endDate.isValid()) {
    endDate = moment().endOf(timeShorthand);
    params.to = endDate.format('YYYY-MM-DD');
  }
  if (!startDate.isValid()) {
    startDate = endDate
      .clone()
      .subtract(6, 'd')
      .startOf(timeShorthand);
    params.from = startDate.format('YYYY-MM-DD');
  }
  const range = endDate
    .clone()
    .endOf(timeShorthand)
    .diff(startDate.clone().startOf(timeShorthand), timeShorthand) + 1;
  const prevStartDate = usePrevState(startDate) || startDate;
  const prevEndDate = usePrevState(endDate) || endDate;
  const prevRange = usePrevState(range) || range;

  const reportId = `fan_${brkKey}_report_${qs.stringify(pickBy(params))}`;
  const [{
    reports, loading, error, reportId: prevReportId,
  }, { getReports }] = useModel(
    'report',
    state => ({
      reportId,
      ...(state.getReports[reportId] || {}),
    }),
    [brkKey],
  );
  const [filterTagCode, setFilterTagCode] = useState(params.tcode || '');
  useEffect(() => {
    setFilterTagCode(params.tcode || '');
  }, [params.tcode]);

  let dimensions = [timeDimension];
  const aggGroup = { total: '*', byTime: timeDimension };
  if (!timeOptions.some(t => t.value === brkKey)) {
    dimensions = [...dimensions, ...breakdown.dims];
    // eslint-disable-next-line prefer-destructuring
    aggGroup[brkKey] = breakdown.dims[0];
  }
  useEffect(() => {
    if (range < 1 || reports) {
      return;
    }
    const req = {
      from: startDate
        .clone()
        .subtract(range, timeShorthand)
        .format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      dimensions,
      metrics: conf.metrics,
    };
    if (params.nwid && configs.platform === 'ADX') {
      req['filter.ADX_NETWORK_CODE'] = params.nwid;
    }
    if (params.pid) {
      req[`filter.${conf.filter.pid}`] = params.pid;
    }
    if (params.aid) {
      req[`filter.${conf.filter.aid}`] = params.aid;
    }
    if (params.tcode && conf.filter.tcode) {
      req[`filter.${conf.filter.tcode}`] = params.tcode;
      dimensions.push('ADX_APP_ID');
    }
    if (params.platform && conf.filter.platform) {
      req[`filter.${conf.filter.platform}`] = params.platform;
    }
    if (params.country) {
      req['filter.COUNTRY_CODE'] = params.country;
    }
    if (params['pub.country']) {
      req['filter.pub.country'] = params['pub.country'];
    }
    if (params['pub.type']) {
      req['filter.pub.type'] = params['pub.type'];
    }
    if (params['pub.AM']) {
      req['filter.pub.AM'] = params['pub.AM'];
    }
    if (params['pub.status']) {
      req['filter.pub.status'] = params['pub.status'];
    }
    if (params.bmid) {
      req['filter.FAN_BM_ID'] = params.bmid;
    }
    getReports(reportId, req, rp => asyncAggregateReports(rp, conf.MetricClass, aggGroup, (r) => {
      const d = moment(r[timeDimension], timeFormat);
      if (d.isBetween(startDate, endDate, timeShorthand, '[]')) {
        return 'current';
      }
      return 'prev';
    }));
  }, [prevReportId]);

  const { total, byTime, tableData } = useMemo(() => {
    const out = {
      byTime: [],
      tableData: [],
      total: {
        current: new conf.MetricClass(),
        prev: new conf.MetricClass(),
      },
    };

    if (reports && reports.byTime) {
      out.total.prev = reports.total.prev || out.total.prev;
      out.total.current = reports.total.current || out.total.current;
      Object.keys(reports.byTime)
        .sort((d, dd) => (d > dd ? 1 : -1))
        .forEach((d) => {
          const dd = moment(d, timeFormat);
          if (!dd.isBetween(prevStartDate, prevEndDate, 'd', '[]')) {
            return;
          }

          const dtemp = out.byTime.length > 0
            ? moment(out.byTime[out.byTime.length - 1][0], timeFormat).add(1, timeShorthand)
            : prevStartDate.clone();

          while (!dtemp.isAfter(dd)) {
            const dstr = dtemp.format(timeFormat);
            const prevdstr = dtemp
              .clone()
              .subtract(prevRange, timeShorthand)
              .format(timeFormat);

            const ddata = {
              ...(reports.byTime[dstr] || {
                [timeDimension]: dstr,
                current: new conf.MetricClass(),
              }),
              [`PREV_${timeDimension}`]: prevdstr,
              prev: reports.byTime[prevdstr]
                ? reports.byTime[prevdstr].prev
                : new conf.MetricClass(),
            };

            out.byTime.push([dstr, ddata]);
            if (timeOptions.some(t => t.value === brkKey)) {
              out.tableData.push(ddata);
            }
            dtemp.add(1, timeShorthand);
          }
        });

      if (!timeOptions.some(t => t.value === brkKey)) {
        out.tableData = Object.values(reports[brkKey] || {}).map((a) => {
          a.prev = a.prev || new conf.MetricClass();
          a.current = a.current || new conf.MetricClass();
          return a;
        });
      }
    }
    return out;
  }, [reports, params.from, params.to, brkKey, period]);

  const onDateRangeChange = (start, end) => {
    history.push({
      ...location,
      search: qs.stringify({
        ...params,
        from: start.format(timeFormat),
        to: end.format(timeFormat),
      }),
    });
  };

  const dimension = {
    key: breakdown.dims[0],
    name: breakdown.colName,
    valueKey: breakdown.dims[0],
  };
  if (breakdowns[brkKey].dims.length > 1) {
    // eslint-disable-next-line prefer-destructuring
    dimension.valueKey = breakdowns[brkKey].dims[1];
  }
  dimension.render = (id, row) => {
    let value = id === '_empty_'
      ? '_unknown_'
      : row[breakdown.dims[breakdown.dims.length - 1]] || row[breakdown.dims[0]] || '_unknown_';
    const filterKey = Object.keys(conf.filter).find(k => conf.filter[k] === breakdown.dims[0]);

    if (filterKey && (id !== '_empty_' || (id === '_empty_' && dimension.key === 'ADX_APP_ID'))) {
      if (filterKey === 'pid') {
        params['pub.country'] = null;
        params['pub.type'] = null;
        params['pub.AM'] = null;
        params['pub.status'] = null;
      }
      const locParams = { ...params, breakdown: null, [filterKey]: id === '_empty_' ? '0' : id };
      if (filterKey === 'tcode' && row.ADX_APP_ID) {
        locParams.aid = row.ADX_APP_ID;
      }
      const loc = {
        ...location,
        search: qs.stringify(pickBy(locParams)),
      };
      let infoLoc;
      if (
        (dimension.key === 'ADX_PUBLISHER_ID' || dimension.key === 'FAN_PUB_ID')
        && currentAccount.isAdmin()
      ) {
        infoLoc = `/publishers/${row[dimension.key]}`;
      } else if (
        id !== '_empty_'
        && (dimension.key === 'ADX_APP_ID' || dimension.key === 'FAN_APP_ID')
      ) {
        infoLoc = `/apps/${row[dimension.key]}`;
      } else if (dimension.key === 'AD_TAG_CODE' && row.ADX_APP_ID) {
        infoLoc = `/apps/${row.ADX_APP_ID}`;
      }
      value = (
        <>
          {infoLoc && (
            <Link className="mr-2" to={infoLoc} title="View publisher info">
              <Icon size={18} name="info" />
            </Link>
          )}
          <Link to={loc}>{value}</Link>
        </>
      );
    }

    return { children: value };
  };

  const dateRangePicker = (
    <div className="mt-3 d-flex">
      <ButtonGroup size="sm" className="mr-2">
        <Button
          variant={timeShorthand === 'd' ? 'primary' : 'secondary'}
          onClick={() => onQueryParamsChange({
            period: 'date',
            breakdown: brkKey === 'month' ? 'date' : brkKey,
            from: moment()
              .subtract(6, 'd')
              .format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
          })
          }
        >
          Daily
        </Button>
        <Button
          variant={timeShorthand === 'M' ? 'primary' : 'secondary'}
          onClick={() => onQueryParamsChange({
            period: 'month',
            breakdown: brkKey === 'date' ? 'month' : brkKey,
            from: moment()
              .subtract(3, 'M')
              .format('YYYY-MM'),
            to: moment().format('YYYY-MM'),
          })
          }
        >
          Monthly
        </Button>
      </ButtonGroup>
      {timeShorthand === 'd' ? (
        <DateRangePicker
          className="align-self-end align-self-sm-center"
          anchorDirection="right"
          startDate={startDate}
          endDate={endDate}
          onChange={onDateRangeChange}
          maxDate={moment()}
          maxRange={90}
        />
      ) : (
        <MonthRangePicker
          startMonth={startDate}
          endMonth={endDate}
          onChange={onDateRangeChange}
          pastScrollRange={24}
        />
      )}
    </div>
  );

  const onQueryParamsChange = p => history.push({
    ...location,
    search: qs.stringify(pickBy({ ...params, ...p })),
  });

  const nav = [
    {
      key: 'label',
      title: 'Breakdown',
      disabled: true,
    },
    ...Object.keys(breakdowns)
      .filter((b) => {
        if (timeOptions.some(t => t.value === b) && b !== period) {
          return false;
        }
        return !breakdowns[b].only || breakdowns[b].only === currentAccount.type;
      })
      .map(d => ({
        key: d,
        title: breakdowns[d].title,
      })),
  ];

  const filterOptions = [
    {
      key: 'networkId',
      hidden: configs.platform === 'FAN' || currentAccount.isPublisher(),
      title: 'DFP Account',
      hasValue: params.nwid,
      render: () => (
        <NetworkSelect
          isClearable
          placeholder="DFP Account"
          value={params.nwid}
          onChange={nwid => onQueryParamsChange({ nwid })}
        />
      ),
    },
    {
      key: 'publisher',
      hidden: currentAccount.isPublisher(),
      title: 'Publisher Segment',
      hasValue:
        params.pid
        || params['pub.country']
        || params['pub.type']
        || params['pub.AM']
        || params['pub.status'],
      render: () => (
        <PublisherSegment
          onChange={onQueryParamsChange}
          value={{
            pid: params.pid,
            'pub.country': params['pub.country'],
            'pub.type': params['pub.type'],
            'pub.AM': params['pub.AM'],
            'pub.status': params['pub.status'],
          }}
        />
      ),
    },
    {
      key: 'bm',
      title: 'Business Account',
      hidden: configs.platform === 'ADX' || currentAccount.isPublisher(),
      hasValue: params.bmid,
      render: () => (
        <BMSelect
          placeholder="All business accounts"
          value={params.bmid}
          onChange={bmid => onQueryParamsChange({ bmid })}
        />
      ),
    },
    {
      key: 'app',
      title: 'App',
      hasValue: !!params.aid,
      render: () => (
        <AppInput
          placeholder="All apps"
          onChange={a => onQueryParamsChange({ aid: a ? a.id : '' })}
          value={params.aid}
        />
      ),
    },
    {
      key: 'tag',
      title: 'Tag',
      hasValue: !!params.tcode,
      hidden: configs.platform !== 'ADX',
      render: () => (
        <FormControl
          placeholder="Tag code"
          value={filterTagCode}
          onChange={e => setFilterTagCode(e.target.value)}
          onKeyPress={e => e.charCode === 13 && onQueryParamsChange({ tcode: filterTagCode })}
        />
      ),
    },
    {
      key: 'platform',
      title: 'Platform',
      hasValue: !!params.platform,
      hidden: configs.platform === 'ADX',
      render: () => (
        <Form.Control as="select" onChange={e => onQueryParamsChange({ platform: e.target.value })}>
          <option value="">All platforms</option>
          <option value="ios">iOS</option>
          <option value="android">Android</option>
          <option value="desktop">Desktop</option>
          <option value="mobile_web">Mobile Web</option>
          <option value="instant_articles">Instant Articles</option>
          <option value="unknown">Unknown</option>
        </Form.Control>
      ),
    },
    {
      key: 'country',
      title: 'Country',
      hasValue: params.country,
      render: () => (
        <CountryInput
          placeholder="All countries"
          onChange={c => onQueryParamsChange({ country: c ? c.code : '' })}
          value={params.country}
        />
      ),
    },
  ].filter(fo => !fo.hidden);

  const filtersHaveValue = filterOptions
    .filter(fo => fo.hasValue)
    // .slice(0, MAX_FILTER)
    .map(fo => fo.key);
  const defaultFilters = filterOptions.slice(0, MAX_FILTER).map(fo => fo.key);

  const onMetricChange = params.metric
    ? m => history.push({ ...location, search: qs.stringify({ ...params, metric: m }) })
    : null;

  return (
    <MainLayout>
      <PageHeader title="Reports" subTitle="Performance report" extraContent={dateRangePicker} />
      {error && (
        <Alert variant="danger" className="mt-3">
          {error.message || error.error}
        </Alert>
      )}
      <FilterGroup
        className="mt-3"
        showAll={filterOptions.length < 4}
        filters={filterOptions}
        initFilters={filtersHaveValue.length === 0 ? defaultFilters : filtersHaveValue}
      />
      <Card className="mt-3">
        <Card.Header>
          <Card.Title>Chart</Card.Title>
        </Card.Header>
        <Card.Body>
          <Dimmer active={loading} loader>
            <Row>
              <Col lg={params.metric ? 12 : 6} className="mb-4 mb-md-0">
                <MetricChart
                  showHeader
                  total={total}
                  data={byTime}
                  metrics={conf.chartMetrics}
                  metric={params.metric || conf.chartDefaultMetrics[0]}
                  onChangeMetric={onMetricChange}
                  showPrev
                />
              </Col>
              {!params.metric && (
                <Col lg={6}>
                  <MetricChart
                    showHeader
                    total={total}
                    data={byTime}
                    metrics={conf.chartMetrics}
                    metric={conf.chartDefaultMetrics[1]}
                    showPrev
                  />
                </Col>
              )}
            </Row>
          </Dimmer>
        </Card.Body>
      </Card>
      <ReportTableCard
        activeKey={brkKey}
        nav={nav}
        metrics={conf.tableMetrics}
        optionalMetrics={
          typeof conf.tableOptionalMetrics === 'function'
            ? conf.tableOptionalMetrics(brkKey)
            : conf.tableOptionalMetrics
        }
        dimension={dimension}
        data={tableData}
        loading={loading}
        sortDefault={conf.tableSortDefault}
        onNavigate={b => onQueryParamsChange({ breakdown: b })}
      />
    </MainLayout>
  );
};

export default Report;
