import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import { Dimmer } from 'tabler-react';
import { ListGroup, Media, Button } from 'react-bootstrap';
import classNames from 'classnames';

import EmptyState from 'components/EmptyState';
import Icon from 'components/Icon';

import { useModel } from 'models';

import defaultIcon from 'static/images/app-icon-default.png';
import styles from './PublisherApps.module.scss';

const appsSelector = ({ listApps, apps }) => ({
  apps: listApps.ids.map(i => apps[i]),
  total: listApps.total,
  loading: listApps.loading,
});

const PublisherApps = ({ account }) => {
  const [{ total, apps, loading }, { getListApps }] = useModel('app', appsSelector);

  useEffect(() => {
    getListApps({ limit: 30, skip: 0, aid: account.id });
  }, []);

  /* eslint-disable jsx-a11y/anchor-is-valid */
  const renderApp = app => (
    <ListGroup.Item key={app.id}>
      <Media className={classNames(styles.rowItem, 'align-items-center')}>
        <img className="icon mr-2 rounded" src={app.icon || defaultIcon} alt="" />
        <Media.Body>
          <div>
            <Link className="text-decoration-none" title={app.name} to={`/apps/${app.id}`}>
              {app.name}
            </Link>
            <div>{app.getStatusBadge()}</div>
          </div>
          <Link to={`/app-reports?breakdown=app&aid=${app.id}`}>
            <Button size="sm">
              <Icon name="chart-bar" type="fa" size={16} />
            </Button>
          </Link>
        </Media.Body>
      </Media>
    </ListGroup.Item>
  );

  return (
    <div className={styles.wrapper} data-slideout-ignore>
      <Scrollbar
        noScrollX
        style={{ width: '100%', height: '100%' }}
        onScroll={({ scrollTop, scrollHeight, clientHeight }) => {
          const bottomReached = scrollTop >= scrollHeight - clientHeight - 200;
          if (bottomReached && total > apps.length && !loading) {
            getListApps({ limit: 30, skip: apps.length, aid: account.id });
          }
        }}
      >
        <Dimmer active={loading} loader>
          <ListGroup variant="flush">
            <>
              {apps.map(renderApp)}
              {apps.length === 0 && (
                <EmptyState className="mt-5" icon="search" title="No data" desc="No app to show" />
              )}
            </>
          </ListGroup>
        </Dimmer>
      </Scrollbar>
    </div>
  );
};

export default PublisherApps;
