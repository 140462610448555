import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { StatsCard, Card, Dimmer } from 'tabler-react';
import { Row, Col, Alert } from 'react-bootstrap';
import qs from 'query-string';
import moment from 'moment';
import Chart from 'react-google-charts';
import elementResizeEvent from 'element-resize-event';
import debounce from 'lodash.debounce';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import { useModel } from 'models';
import DateRangePicker from 'components/DateRangePicker';
import {
  FANMetric,
  ADXMetric,
  FANPageMetric,
  formatMetric,
  getMetricName,
  asyncAggregateReports,
  AdmobMetric,
} from 'entities/Report';
import { formatNumber } from 'utils/intl';
import ReportTableCard from 'components/ReportTableCard';
import configs from 'configs/configs';
import MetricChart from 'components/MetricChart';
import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import Icon from 'components/Icon';
import NavCard from 'components/NavCard';

import styles from './Home.module.scss';

function getConfig(asPublisher = false) {
  const adxConfig = {
    MetricClass: ADXMetric,
    dimensions: ['ADX_APP_ID', 'ADX_APP_NAME'],
    metrics: [
      'AD_REQUESTS',
      'MATCHED_AD_REQUESTS',
      'AD_IMPRESSIONS',
      'CLICKS',
      'EARNINGS',
      'ADX_PUBLISHER_EARNINGS',
    ],
    aggregate: { byApp: 'ADX_APP_ID' },
    statCards: [
      {
        metric: 'ADX_ECPM',
        name: 'eCPM',
        color: '#467fcf',
      },
      {
        metric: 'AD_IMPRESSIONS',
        name: 'Impressions',
        color: '#e74c3c',
        format: { round: true, maximumFractionDigits: 2 },
      },
      {
        metric: 'MATCHED_AD_REQUESTS',
        name: 'Matched Requests',
        color: '#f1c40f',
        format: { round: true, maximumFractionDigits: 2 },
      },
      {
        metric: 'CLICKS',
        name: 'Clicks',
        color: '#5eba00',
        format: { round: true, maximumFractionDigits: 2 },
      },
    ],
    table: {
      sort: 'EARNINGS',
      dimension: {
        key: 'ADX_APP_ID',
        name: 'App',
        valueKey: 'ADX_APP_NAME',
        className: 'top-rev-dimension-col',
        render: (id, row) => ({
          children:
            row.ADX_APP_ID !== '_empty_' ? (
              <Link className={styles.itemLink} to={`/app-reports?aid=${row.ADX_APP_ID}`}>
                <div className="item-name">{row.ADX_APP_NAME || row.ADX_APP_ID}</div>
              </Link>
            ) : (
              row.ADX_APP_ID
            ),
        }),
      },
      metrics: [
        'AD_REQUESTS',
        'MATCHED_AD_REQUESTS',
        'AD_IMPRESSIONS',
        'CLICKS',
        'ADX_COVERAGE',
        'ADX_SHOW_RATE',
        'ADX_REQUEST_CTR',
        'ADX_ECPM',
        ...(asPublisher
          ? [{ key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('EARNINGS') }]
          : ['EARNINGS', 'ADX_PUBLISHER_EARNINGS']),
      ],
    },
    chartMetrics: [
      'AD_REQUESTS',
      'MATCHED_AD_REQUESTS',
      'AD_IMPRESSIONS',
      'CLICKS',
      'ADX_ECPM',
      ...(asPublisher
        ? [{ key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('EARNINGS') }]
        : ['EARNINGS', 'ADX_PUBLISHER_EARNINGS']),
    ],
    overviewMetrics: asPublisher
      ? [{ key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('EARNINGS') }, 'ADX_ECPM']
      : ['EARNINGS', 'PROFIT'],
    revMetric: asPublisher ? 'ADX_PUBLISHER_EARNINGS' : 'EARNINGS',
    admob: {
      dimensions: ['APP_ID', 'APP_NAME'],
      metrics: AdmobMetric.METRIC_TYPES,
      aggregate: { byApp: 'APP_ID' },
      chartMetrics: asPublisher
        ? [
          ...AdmobMetric.METRIC_TYPES.filter(
            t => !['ESTIMATED_EARNINGS', 'ADX_PUBLISHER_EARNINGS'].includes(t),
          ),
          { key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('ESTIMATED_EARNINGS') },
        ]
        : AdmobMetric.METRIC_TYPES,
      overviewMetrics: asPublisher
        ? [
          { key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('ESTIMATED_EARNINGS') },
          'IMPRESSION_RPM',
        ]
        : ['ESTIMATED_EARNINGS', 'PROFIT'],
      revMetric: asPublisher ? 'ADX_PUBLISHER_EARNINGS' : 'ESTIMATED_EARNINGS',
      table: {
        sort: 'ESTIMATED_EARNINGS',
        dimension: {
          key: 'APP_ID',
          name: 'App',
          valueKey: 'APP_NAME',
          className: 'top-rev-dimension-col',
          render: (id, row) => ({
            children: (
              <Link className={styles.itemLink} to={`/admob-reports?aid=${row.APP_ID}`}>
                <div className="item-name">{row.APP_NAME || row.APP_ID}</div>
              </Link>
            ),
          }),
        },
        metrics: [
          'AD_REQUESTS',
          'MATCHED_REQUESTS',
          'IMPRESSIONS',
          'CLICKS',
          'IMPRESSION_CTR',
          'IMPRESSION_RPM',
          ...(asPublisher
            ? [{ key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('ESTIMATED_EARNINGS') }]
            : ['ESTIMATED_EARNINGS', 'ADX_PUBLISHER_EARNINGS']),
        ],
      },
    },
  };

  const fanConfig = {
    MetricClass: FANMetric,
    dimensions: ['FAN_APP_ID', 'FAN_APP_NAME'],
    metrics: [
      'FAN_REQUEST',
      'FAN_FILLED_REQUEST',
      'FAN_IMPRESSION',
      'FAN_CLICK',
      'FAN_REVENUE',
      'FAN_PUB_REVENUE',
    ],
    aggregate: { byApp: 'FAN_APP_ID' },
    statCards: [
      { metric: 'FAN_FILL_RATE', name: 'Fill Rate', color: '#467fcf' },
      { metric: 'FAN_IMPRESSION', name: 'Impressions', color: '#e74c3c' },
      { metric: 'FAN_ECPM', name: 'eCPM', color: '#f1c40f' },
      {
        metric: 'FAN_REVENUE',
        name: 'Estimated Revenue',
        color: '#5eba00',
        format: {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        },
      },
    ],
    table: {
      sort: 'FAN_REVENUE',
      dimension: {
        key: 'FAN_APP_ID',
        name: 'App',
        valueKey: 'FAN_APP_NAME',
        className: 'top-rev-dimension-col',
        render: (id, row) => ({
          children:
            row.FAN_APP_ID !== '_empty_' ? (
              <Link className={styles.itemLink} to={`/app-reports?aid=${row.FAN_APP_ID}`}>
                <img
                  alt=" "
                  className="rounded icon"
                  src={`https://graph.facebook.com/${id}/picture`}
                />
                <div className="item-name">{row.FAN_APP_NAME || row.FAN_APP_ID}</div>
              </Link>
            ) : (
              row.FAN_APP_ID
            ),
        }),
      },
      metrics: [
        'FAN_REQUEST',
        'FAN_FILLED_REQUEST',
        'FAN_FILL_RATE',
        'FAN_IMPRESSION',
        'FAN_CLICK',
        'FAN_CTR',
        'FAN_ECPM',
        ...(asPublisher
          ? [{ key: 'FAN_PUB_REVENUE', title: getMetricName('FAN_REVENUE') }]
          : ['FAN_REVENUE', 'FAN_PUB_REVENUE']),
      ],
    },
    chartMetrics: [
      ...(asPublisher
        ? [{ key: 'FAN_PUB_REVENUE', title: getMetricName('FAN_REVENUE') }]
        : ['FAN_REVENUE', 'FAN_PUB_REVENUE']),
      'FAN_CLICK',
      'FAN_REQUEST',
      'FAN_FILLED_REQUEST',
      'FAN_IMPRESSION',
      'FAN_ECPM',
      'FAN_FILL_RATE',
      'FAN_SHOW_RATE',
    ],
    overviewMetrics: asPublisher
      ? [{ key: 'FAN_PUB_REVENUE', title: getMetricName('FAN_REVENUE') }, 'FAN_ECPM']
      : ['FAN_REVENUE', 'PROFIT'],
    revMetric: asPublisher ? 'FAN_PUB_REVENUE' : 'FAN_REVENUE',
    adbreak: {
      dimensions: ['FAN_PAGE_ID', 'FAN_PAGE_NAME'],
      metrics: FANPageMetric.METRIC_TYPES,
      aggregate: { byPage: 'FAN_PAGE_ID' },
      chartMetrics: asPublisher
        ? [
          ...FANPageMetric.METRIC_TYPES.filter(t => !['EARNINGS', 'PUB_EARNINGS'].includes(t)),
          { key: 'PUB_EARNINGS', title: getMetricName('EARNINGS') },
        ]
        : FANPageMetric.METRIC_TYPES,
      overviewMetrics: asPublisher
        ? [{ key: 'PUB_EARNINGS', title: getMetricName('EARNINGS') }, 'GROSS_CPM']
        : ['EARNINGS', 'PROFIT'],
      revMetric: asPublisher ? 'PUB_EARNINGS' : 'EARNINGS',
      table: {
        sort: 'EARNINGS',
        dimension: {
          key: 'FAN_PAGE_ID',
          name: 'Page',
          valueKey: 'FAN_PAGE_NAME',
          className: 'top-rev-dimension-col',
          render: (id, row) => ({
            children:
              row.FAN_PAGE_ID !== '_empty_' ? (
                <Link className={styles.itemLink} to={`/page-reports?pgid=${row.FAN_PAGE_ID}`}>
                  <img
                    alt=" "
                    className="rounded icon"
                    src={`https://graph.facebook.com/${id}/picture`}
                  />
                  <div className="item-name">{row.FAN_PAGE_NAME || row.FAN_PAGE_ID}</div>
                </Link>
              ) : (
                row.FAN_PAGE_ID
              ),
          }),
        },
        metrics: [
          'IMPRESSIONS',
          'IMPRESSIONS_CROSSPOSTED',
          'GROSS_CPM_CROSSPOSTED',
          'GROSS_CPM',
          ...(asPublisher
            ? [{ key: 'PUB_EARNINGS', title: getMetricName('EARNINGS') }]
            : ['EARNINGS', 'PUB_EARNINGS']),
        ],
      },
    },
  };

  return configs.platform === 'FAN' ? fanConfig : adxConfig;
}

/**
 * @param {import('react-router-dom').RouteComponentProps} props
 */
const Home = ({ location, history, currentAccount }) => {
  const cfg = getConfig(currentAccount.isPublisher());
  const [activeKey, setActiveKey] = useState('app-overview');
  const params = qs.parse(location.search);
  let startDate = moment(params.from, 'YYYY-MM-DD');
  let endDate = moment(params.to, 'YYYY-MM-DD');
  if (!endDate.isValid()) {
    endDate = moment();
    params.to = endDate.format('YYYY-MM-DD');
  }
  if (!startDate.isValid()) {
    startDate = endDate.clone().subtract(6, 'd');
    params.from = startDate.format('YYYY-MM-DD');
  }
  const range = endDate.diff(startDate, 'd') + 1;
  const reportId = `overview_${startDate.format('YYMMDD')}_${endDate.format('YYMMDD')}`;
  const [
    {
      reports, pageReports, admobReports, loading, error, reportId: prevReportId,
    },
    { getReports, getPageReports, getAdmobReports },
  ] = useModel(
    'report',
    state => ({
      reportId,
      pageReports: { ...(state.getPageReports[reportId] || {}) },
      admobReports: { ...(state.getAdmobReports[reportId] || {}) },
      ...(state.getReports[reportId] || {}),
    }),
    [reportId],
  );

  useEffect(() => {
    if (range < 1 || reports) {
      return;
    }
    getReports(
      reportId,
      {
        from: startDate
          .clone()
          .subtract(range, 'd')
          .format('YYYY-MM-DD'),
        to: endDate.format('YYYY-MM-DD'),
        dimensions: ['DATE', ...cfg.dimensions],
        metrics: cfg.metrics,
      },
      rreports => asyncAggregateReports(
        rreports,
        cfg.MetricClass,
        { total: '*', byDate: 'DATE', ...cfg.aggregate },
        r => (r.DATE >= params.from && r.DATE <= params.to ? 'current' : 'prev'),
      ),
    );
    if (configs.platform === 'FAN' && (currentAccount.isAdmin() || currentAccount.adbreakEnabled)) {
      getPageReports(
        reportId,
        {
          from: startDate
            .clone()
            .subtract(range, 'd')
            .format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD'),
          dimensions: ['DATE', ...cfg.adbreak.dimensions],
          metrics: cfg.adbreak.metrics,
        },
        rreports => asyncAggregateReports(
          rreports,
          FANPageMetric,
          { total: '*', byDate: 'DATE', ...cfg.adbreak.aggregate },
          r => (r.DATE >= params.from && r.DATE <= params.to ? 'current' : 'prev'),
        ),
      );
    }
    if (configs.platform === 'ADX' && (currentAccount.isAdmin() || (currentAccount.admobConfig || {}).enabled)) {
      getAdmobReports(
        reportId,
        {
          from: startDate
            .clone()
            .subtract(range, 'd')
            .format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD'),
          dimensions: ['DATE', ...cfg.admob.dimensions],
          metrics: cfg.admob.metrics,
        },
        rreports => asyncAggregateReports(
          rreports,
          AdmobMetric,
          { total: '*', byDate: 'DATE', ...cfg.admob.aggregate },
          r => (r.DATE >= params.from && r.DATE <= params.to ? 'current' : 'prev'),
        ),
      );
    }
  }, [prevReportId]);
  const rowRef = useRef();
  const resizeDebounce = useRef(null);
  const [resized, setResized] = useState(0);
  useEffect(() => {
    // eslint-disable-next-line react/no-find-dom-node
    const node = findDOMNode(rowRef.current);
    if (!node) return;

    elementResizeEvent(node, () => {
      if (resizeDebounce.current) {
        resizeDebounce.current.cancel();
      }
      resizeDebounce.current = debounce(() => setResized(resized + 1), 100);
      resizeDebounce.current();
    });

    // eslint-disable-next-line consistent-return
    return () => {
      elementResizeEvent.unbind(node);
      if (resizeDebounce.current) {
        resizeDebounce.current.cancel();
      }
    };
  }, []);

  const {
    byApp, changes, total, byDate, topApp,
  } = useMemo(() => {
    const out = {
      byApp: [],
      changes: {},
      total: {
        current: new cfg.MetricClass(),
        prev: new cfg.MetricClass(),
      },
      byDate: [],
      topApp: [],
    };

    if (reports) {
      Object.keys(reports.byDate)
        .sort((d, dd) => (d > dd ? 1 : -1))
        .forEach(d => {
          if (d < params.from) {
            return;
          }
          // fill the gap
          const dtemp = out.byDate.length > 0
            ? moment(out.byDate[out.byDate.length - 1].DATE, 'YYYY-MM-DD').add(1, 'd')
            : startDate.clone();
          while (dtemp.format('YYYY-MM-DD') <= d) {
            const dprev = dtemp
              .clone()
              .subtract(range, 'd')
              .format('YYYY-MM-DD');
            const dstr = dtemp.format('YYYY-MM-DD');

            out.byDate.push({
              ...(reports.byDate[dstr] || {
                DATE: dstr,
                current: new cfg.MetricClass(),
              }),
              PREV_DATE: dprev,
              prev: reports.byDate[dprev] ? reports.byDate[dprev].prev : new cfg.MetricClass(),
            });
            dtemp.add(1, 'd');
          }
        });

      out.total.current = reports.total.current || out.total.current;
      out.total.prev = reports.total.prev || out.total.prev;
      out.changes = out.total.current.getChange(out.total.prev);
      out.byApp = Object.values(reports.byApp).map(a => {
        a.prev = a.prev || new cfg.MetricClass();
        a.current = a.current || new cfg.MetricClass();
        return a;
      });
      out.topApp = out.byApp
        .sort((a, aa) => aa.current[cfg.revMetric] - a.current[cfg.revMetric])
        .slice(0, 6)
        .filter(a => (a.ADX_APP_ID || a.FAN_APP_ID) !== '_empty_' && a.current[cfg.revMetric] > 0)
        .slice(0, 5);
    }
    return out;
  }, [reports]);

  useEffect(() => {
    if (
      loading === false
      && byApp.length === 0
      && overviewNav.find(o => o.key === 'page-overview')
    ) {
      setActiveKey('page-overview');
    }
  }, [loading, byApp]);

  const pageReportData = useMemo(() => {
    const out = {
      byPage: [],
      changes: {},
      total: {
        current: new FANPageMetric(),
        prev: new FANPageMetric(),
      },
      byDate: [],
      topPage: [],
    };

    if (!pageReports.reports) {
      return out;
    }

    if (pageReports.reports) {
      Object.keys(pageReports.reports.byDate)
        .sort((d, dd) => (d > dd ? 1 : -1))
        .forEach(d => {
          if (d < params.from) {
            return;
          }
          // fill the gap
          const dtemp = out.byDate.length > 0
            ? moment(out.byDate[out.byDate.length - 1].DATE, 'YYYY-MM-DD').add(1, 'd')
            : startDate.clone();
          while (dtemp.format('YYYY-MM-DD') <= d) {
            const dprev = dtemp
              .clone()
              .subtract(range, 'd')
              .format('YYYY-MM-DD');
            const dstr = dtemp.format('YYYY-MM-DD');

            out.byDate.push({
              ...(pageReports.reports.byDate[dstr] || {
                DATE: dstr,
                current: new FANPageMetric(),
              }),
              PREV_DATE: dprev,
              prev: pageReports.reports.byDate[dprev]
                ? pageReports.reports.byDate[dprev].prev
                : new FANPageMetric(),
            });
            dtemp.add(1, 'd');
          }
        });

      out.total.current = pageReports.reports.total.current || out.total.current;
      out.total.prev = pageReports.reports.total.prev || out.total.prev;
      out.changes = out.total.current.getChange(out.total.prev);
      out.byPage = Object.values(pageReports.reports.byPage).map(a => {
        a.prev = a.prev || new FANPageMetric();
        a.current = a.current || new FANPageMetric();
        return a;
      });
      out.topPage = out.byPage
        .sort((a, aa) => aa.current[cfg.adbreak.revMetric] - a.current[cfg.adbreak.revMetric])
        .slice(0, 6)
        .filter(a => a.FAN_PAGE_ID !== '_empty_' && a.current[cfg.adbreak.revMetric] > 0)
        .slice(0, 5);
    }

    return out;
  }, [pageReports.reports]);

  const admobReportData = useMemo(() => {
    const out = {
      byApp: [],
      changes: {},
      total: {
        current: new AdmobMetric(),
        prev: new AdmobMetric(),
      },
      byDate: [],
      topApp: [],
    };

    if (!admobReports.reports) {
      return out;
    }

    if (admobReports.reports) {
      Object.keys(admobReports.reports.byDate)
        .sort((d, dd) => (d > dd ? 1 : -1))
        .forEach(d => {
          if (d < params.from) {
            return;
          }
          // fill the gap
          const dtemp = out.byDate.length > 0
            ? moment(out.byDate[out.byDate.length - 1].DATE, 'YYYY-MM-DD').add(1, 'd')
            : startDate.clone();
          while (dtemp.format('YYYY-MM-DD') <= d) {
            const dprev = dtemp
              .clone()
              .subtract(range, 'd')
              .format('YYYY-MM-DD');
            const dstr = dtemp.format('YYYY-MM-DD');

            out.byDate.push({
              ...(admobReports.reports.byDate[dstr] || {
                DATE: dstr,
                current: new AdmobMetric(),
              }),
              PREV_DATE: dprev,
              prev: admobReports.reports.byDate[dprev]
                ? admobReports.reports.byDate[dprev].prev
                : new AdmobMetric(),
            });
            dtemp.add(1, 'd');
          }
        });

      out.total.current = admobReports.reports.total.current || out.total.current;
      out.total.prev = admobReports.reports.total.prev || out.total.prev;
      out.changes = out.total.current.getChange(out.total.prev);
      out.byApp = Object.values(admobReports.reports.byApp).map(a => {
        a.prev = a.prev || new AdmobMetric();
        a.current = a.current || new AdmobMetric();
        return a;
      });
      out.topApp = out.byApp
        .sort((a, aa) => aa.current[cfg.admob.revMetric] - a.current[cfg.admob.revMetric])
        .filter(a => a.current[cfg.admob.revMetric] > 1)
        .slice(0, 5);
    }

    return out;
  }, [admobReports.reports]);

  const onDateRangeChange = (start, end) => {
    history.push({
      ...location,
      search: qs.stringify({
        ...params,
        from: start.format('YYYY-MM-DD'),
        to: end.format('YYYY-MM-DD'),
      }),
    });
  };

  const colProps = {
    sm: 12,
    md: 6,
    lg: 3,
  };
  const dateRangePicker = (
    <DateRangePicker
      className="align-self-end align-self-sm-center"
      startDate={startDate}
      endDate={endDate}
      onChange={onDateRangeChange}
      maxDate={moment()}
      anchorDirection="right"
      maxRange={90}
    />
  );

  const makeMetricRender = metric => (v, r) => {
    const c = r.prev ? r.current.getChange(r.prev) : null;
    return (
      <>
        <div>{(r.current || r).format(metric)}</div>
        {c && (
          <small
            className={classNames({
              'text-success': c[metric] > 0,
              'text-danger': c[metric] < 0,
            })}
          >
            <Icon name={`arrow-${c[metric] > 0 ? 'up' : 'down'}`}>
              {formatMetric('FAN_CTR', Math.abs(c[metric]))}
            </Icon>
          </small>
        )}
      </>
    );
  };

  const topAppColumns = [
    cfg.table.dimension,
    { key: cfg.revMetric, name: 'Rev', render: makeMetricRender(cfg.revMetric) },
  ];

  const overviewNav = [
    {
      key: 'app-overview',
      title: 'App Performance',
      render: () => (
        <Dimmer active={loading || pageReports.loading} loader>
          <Row className={classNames('mt-3', styles.chartRow)}>
            <Col lg={8}>
              <MetricOverview
                metrics={cfg.overviewMetrics.map(m => {
                  m = typeof m === 'object' ? m : { key: m };
                  return {
                    ...m,
                    value: total.current[m.key],
                    change: changes[m.key],
                  };
                })}
              />
              <MetricChart
                key="app-chart"
                total={total}
                data={byDate.map(d => [d.DATE, d])}
                metrics={cfg.chartMetrics}
                metric={cfg.revMetric}
                showPrev
                fixedMetric
              />
            </Col>
            <Col lg={4} className="top-rev">
              <Card.Title className="mb-2 ml-3">Top Apps</Card.Title>
              <DataTable
                rowKey={cfg.table.dimension.key}
                scroll={{ x: false }}
                showHeader={false}
                emptyText={<EmptyState desc="No data" />}
                columns={topAppColumns}
                rows={topApp}
              />
            </Col>
          </Row>
        </Dimmer>
      ),
      viewMore: {
        pathname: '/app-reports',
        search: qs.stringify({ from: params.from, to: params.to, metric: cfg.revMetric }),
      },
    },
  ];
  if (configs.platform === 'FAN' && (currentAccount.isAdmin() || currentAccount.adbreakEnabled)) {
    overviewNav.push({
      key: 'page-overview',
      title: 'Adbreak Performance',
      render: () => {
        const topPageColumns = [
          cfg.adbreak.table.dimension,
          {
            key: cfg.adbreak.revMetric,
            name: 'Rev',
            render: makeMetricRender(cfg.adbreak.revMetric),
          },
        ];
        return (
          <Dimmer active={loading || pageReports.loading} loader>
            <Row className={classNames('mt-3', styles.chartRow)}>
              <Col lg={8}>
                <MetricOverview
                  metrics={cfg.adbreak.overviewMetrics.map(m => {
                    m = typeof m === 'object' ? m : { key: m };
                    return {
                      ...m,
                      value: pageReportData.total.current[m.key],
                      change: pageReportData.changes[m.key],
                    };
                  })}
                />
                <MetricChart
                  key="fan-page-chart"
                  total={pageReportData.total}
                  data={pageReportData.byDate.map(d => [d.DATE, d])}
                  metrics={cfg.adbreak.chartMetrics}
                  metric={cfg.adbreak.revMetric}
                  showPrev
                  fixedMetric
                />
              </Col>
              <Col lg={4} className="top-rev">
                <Card.Title className="mb-2 ml-3">Top Pages</Card.Title>
                <DataTable
                  scroll={{ x: false }}
                  rowKey={cfg.adbreak.table.dimension.key}
                  showHeader={false}
                  emptyText={<EmptyState desc="No data" />}
                  columns={topPageColumns}
                  rows={pageReportData.topPage}
                />
              </Col>
            </Row>
          </Dimmer>
        );
      },
      viewMore: {
        pathname: '/page-reports',
        search: qs.stringify({ from: params.from, to: params.to, metric: cfg.adbreak.revMetric }),
      },
    });
  }
  if (
    configs.platform === 'ADX'
    && (currentAccount.isAdmin() || (currentAccount.admobConfig || {}).enabled)
  ) {
    overviewNav.push({
      key: 'admob-overview',
      title: 'Admob Performance',
      render: () => {
        const topAdmobAppColumns = [
          cfg.admob.table.dimension,
          {
            key: cfg.admob.revMetric,
            name: 'Rev',
            render: makeMetricRender(cfg.admob.revMetric),
          },
        ];
        return (
          <Dimmer active={loading || pageReports.loading} loader>
            <Row className={classNames('mt-3', styles.chartRow)}>
              <Col lg={8}>
                <MetricOverview
                  metrics={cfg.admob.overviewMetrics.map(m => {
                    m = typeof m === 'object' ? m : { key: m };
                    return {
                      ...m,
                      value: admobReportData.total.current[m.key],
                      change: admobReportData.changes[m.key],
                    };
                  })}
                />
                <MetricChart
                  key="fan-page-chart"
                  total={admobReportData.total}
                  data={admobReportData.byDate.map(d => [d.DATE, d])}
                  metrics={cfg.admob.chartMetrics}
                  metric={cfg.admob.revMetric}
                  showPrev
                  fixedMetric
                />
              </Col>
              <Col lg={4} className="top-rev">
                <Card.Title className="mb-2 ml-3">Top Apps</Card.Title>
                <DataTable
                  scroll={{ x: false }}
                  rowKey={cfg.admob.table.dimension.key}
                  showHeader={false}
                  emptyText={<EmptyState desc="No data" />}
                  columns={topAdmobAppColumns}
                  rows={admobReportData.topApp}
                />
              </Col>
            </Row>
          </Dimmer>
        );
      },
      viewMore: {
        pathname: '/admob-reports',
        search: qs.stringify({ from: params.from, to: params.to, metric: cfg.admob.revMetric }),
      },
    });
  }

  const getViewmoreLocation = () => overviewNav.find(n => n.key === activeKey).viewMore;

  return (
    <MainLayout>
      <PageHeader title="Dashboard" subTitle="Overview" extraContent={dateRangePicker} />
      {error && (
        <Alert className="mt-3" variant="danger">
          {error.message || error.error || 'Something went wrong.'}
        </Alert>
      )}
      <NavCard
        className="mt-3"
        activeKey={activeKey}
        nav={overviewNav.length > 1 ? overviewNav.map(({ key, title }) => ({ key, title })) : []}
        onNavigate={setActiveKey}
        footer={(
          <Link className="float-right" to={getViewmoreLocation()}>
            View more
            <Icon name="chevron-right" />
          </Link>
)}
      >
        {overviewNav.find(({ key }) => key === activeKey).render()}
      </NavCard>
      {activeKey === 'app-overview' && (
        <Row className={classNames(styles.infoBoxes, { loading })} ref={rowRef}>
          {cfg.statCards.map(c => (
            <Col {...colProps} key={c.metric}>
              <Link className="text-decoration-none" to={`/app-reports?metric=${c.metric}`}>
                <StatsCard
                  layout={2}
                  total={total.current.format(c.metric, c.format)}
                  movement={formatNumber(changes[c.metric] * 100, { maximumFractionDigits: 0 })}
                  label={c.name}
                  chart={<MiniChart data={byDate} metric={c.metric} color={c.color} />}
                />
              </Link>
            </Col>
          ))}
        </Row>
      )}
      {!loading && activeKey === 'page-overview' && (
        <ReportTableCard
          loading={pageReports.loading}
          metrics={cfg.adbreak.table.metrics}
          data={Object.values(pageReportData.byPage)}
          dimension={cfg.adbreak.table.dimension}
          sortDefault={cfg.adbreak.table.sort}
        />
      )}
      {activeKey === 'app-overview' && (
        <ReportTableCard
          loading={loading}
          metrics={cfg.table.metrics}
          data={Object.values(byApp)}
          dimension={cfg.table.dimension}
          sortDefault={cfg.table.sort}
        />
      )}
      { activeKey === 'admob-overview' && (
        <ReportTableCard
          loading={admobReports.loading}
          metrics={cfg.admob.table.metrics}
          data={Object.values(admobReportData.byApp)}
          dimension={cfg.admob.table.dimension}
          sortDefault={cfg.admob.table.sort}
        />
      )}
    </MainLayout>
  );
};

const MiniChart = ({ data = [], metric, color }) => {
  const chart = useRef(null);
  const container = useRef(null);
  /** @type {{current: import("lodash").Cancelable}} */
  const resizeDebounce = useRef(null);

  useEffect(() => {
    if (!container.current) {
      return;
    }

    elementResizeEvent(container.current, () => {
      if (resizeDebounce.current) {
        resizeDebounce.current.cancel();
      }
      resizeDebounce.current = debounce(() => chart.current && chart.current.draw(), 200);
      resizeDebounce.current();
    });

    // eslint-disable-next-line consistent-return
    return () => {
      elementResizeEvent.unbind(container.current);
      if (resizeDebounce.current) {
        resizeDebounce.current.cancel();
      }
      if (chart.current) {
        chart.current.clear();
      }
    };
  }, []);

  const chartEvents = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper }) => {
        chart.current = chartWrapper;
      },
    },
  ];

  const chartOpts = {
    theme: 'material',
    legend: { position: 'none' },
    chartArea: {
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
    },
    hAxis: {
      textPosition: 'none',
      title: '',
    },
    vAxis: {
      title: '',
      textPosition: 'none',
      ticks: [],
      baselineColor: 'none',
    },
    tooltip: { trigger: 'none' },
    pointsVisible: false,
    enableInteractivity: false,
    series: [{ color }],
  };

  const chartData = useMemo(
    () => [['DATE', metric], ...(data || []).map(d => [d.DATE, d.current[metric] || 0])],
    [data, metric],
  );

  return (
    <div ref={container} className="w-100 h-100">
      {data && data.length > 0 && (
        <Chart
          chartType="AreaChart"
          width="100%"
          height="100%"
          options={chartOpts}
          data={chartData}
          chartEvents={chartEvents}
        />
      )}
    </div>
  );
};

/**
 *
 * @param {Object} props
 * @param {[{key: string, title:string, value: number, change: number}]} props.metrics
 */
const MetricOverview = ({ metrics = [] }) => (
  <Row>
    {metrics.map(m => (
      <Col key={m.key} xs={metrics.length > 1 ? 6 : 12} className="d-flex flex-column pl-5 mb-5">
        <h3 className="card-title mb-3">{m.title || getMetricName(m.key)}</h3>
        <div>
          <h2 className="d-inline-block m-0">{formatMetric(m.key, Math.abs(m.value))}</h2>
          <h4
            className={classNames('d-inline-block m-0 ml-3', {
              'text-success': m.change > 0,
              'text-danger': m.change < 0,
            })}
          >
            <Icon name={`arrow-${m.change > 0 ? 'up' : 'down'}`}>
              {formatMetric('FAN_CTR', Math.abs(m.change))}
            </Icon>
          </h4>
        </div>
      </Col>
    ))}
  </Row>
);

export default Home;
