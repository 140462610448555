import alertService from 'services/alert';

export default {
  name: 'alert',
  state: {
    alerts: {},
    getAlerts: {
      total: {
        success: 0,
        warning: 0,
        urgent: 0,
      },
    },
    resolveAlert: {},
  },
  reducers: {
    getAlerts: (state, {
      skip, total, alerts, error,
    }, status) => {
      switch (status) {
        case 'success':
          const ids = (skip === 0 || !state.getAlerts.ids ? [] : state.getAlerts.ids).concat(
            (alerts || []).map(p => p.id),
          );
          return {
            alerts: (alerts || []).reduce((v, i) => ({ ...v, [i.id]: { ...v[i.id], ...i } }), {
              ...state.alerts,
            }),
            getAlerts: {
              total,
              ids,
              hasMore: ids.length < total.success + total.warning + total.urgent,
              loading: false,
              error: null,
            },
          };
        case 'error':
          return {
            getAlerts: {
              ...state.getAlerts,
              loading: false,
              error,
            },
          };
        default:
          return {
            getAlerts: {
              ...state.getAlerts,
              loading: true,
              error: null,
            },
          };
      }
    },
    resolveAlert: (state, { id, error }, status) => {
      switch (status) {
        case 'success':
          return {
            getAlerts: {
              ...state.getAlerts,
              ids: (state.getAlerts.ids || []).filter(alertId => alertId !== id),
            },
            resolveAlert: {
              ...state.resolveAlert,
              [id]: {
                loading: false,
                error: null,
              },
            },
          };
        case 'error':
          return {
            resolveAlert: {
              ...state.resolveAlert,
              [id]: {
                loading: false,
                error,
              },
            },
          };
        default:
          return {
            resolveAlert: {
              ...state.resolveAlert,
              [id]: {
                loading: true,
                error: null,
              },
            },
          };
      }
    },
  },
  effects: {
    async getAlerts(payload, onSuccess, onError) {
      try {
        const response = await alertService.getAlerts(payload);
        onSuccess({ ...response, skip: payload.skip });
      } catch (e) {
        onError(e);
      }
    },
    async resolveAlert({ id, data }, onSuccess, onError) {
      try {
        const { resolved } = await alertService.updateAlert(id, data);
        if (resolved === 1) {
          onSuccess({ id });
        } else {
          throw new Error('Something went wrong.');
        }
      } catch (e) {
        onError({ id, error: e });
      }
    },
  },
  actions: {
    getAlerts: params => params,
    resolveAlert: (id, data) => ({ id, data }),
  },
};
