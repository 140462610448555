import React, { useState, useEffect } from 'react';
import { Form, Col, DropdownButton } from 'react-bootstrap';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import Icon from 'components/Icon';

import styles from './FilterGroup.module.scss';

const FilterGroup = ({
  className, showAll, filters = [], initFilters = [],
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [visibleFilters, setVisibleFilters] = useState(initFilters);
  const prevNumFilter = usePrevious(initFilters.length);
  useEffect(() => {
    if (initFilters.length > prevNumFilter) {
      setVisibleFilters(initFilters);
    }
  });
  const colProps = {
    xs: 12,
    sm: 6,
    md: 4,
    xl: 3,
    className: 'mb-2',
  };

  const dropdownButtonTitle = (
    <>
      <Icon name="plus" className="mr-1" />
      More Filters
      {` (${visibleFilters.length}/${filters.length})`}
    </>
  );

  const renderFilters = () => {
    if (showAll) {
      return filters.map(f => (
        <Col key={f.key} {...{ ...colProps, ...f.colProps }}>
          {f.render()}
        </Col>
      ));
    }
    return visibleFilters.map((filterKey) => {
      const ff = filters.find(f => f.key === filterKey);
      return (
        <Col key={filterKey} {...{ ...colProps, ...ff.colProps }}>
          {ff.render()}
        </Col>
      );
    });
  };

  return (
    <div className={classNames(className, styles.filterGroup)}>
      <Form.Row>
        {!showAll && (
          <Col {...colProps}>
            <DropdownButton
              className="more"
              show={showDropdown}
              onToggle={() => setShowDropdown(!showDropdown)}
              title={dropdownButtonTitle}
              alignRight
            >
              {filters.map(({ key, title }) => {
                const isSelected = visibleFilters.includes(key);
                const onChange = () => {
                  setVisibleFilters(
                    isSelected
                      ? visibleFilters.filter(kk => kk !== key)
                      : visibleFilters.concat(key),
                  );
                };

                /* eslint-disable jsx-a11y/no-static-element-interactions */
                return (
                  <Form.Check
                    className="filter-option"
                    key={key}
                    id={key}
                    inline
                    type="checkbox"
                    label={title}
                    checked={isSelected}
                    onChange={onChange}
                  />
                );
              })}
            </DropdownButton>
          </Col>
        )}
        {renderFilters()}
      </Form.Row>
    </div>
  );
};

export default FilterGroup;
