import React, { useState, useEffect, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import classNames from 'classnames';

import { useModel } from 'models';
// import styles from './PublisherInput.module.scss';

/** @type {import("lodash").Cancelable} */
let searchDebounced = null;

const pubsSelector = ({ listPublishers, publishers }) => ({
  publishers: listPublishers.ids.map(i => publishers[i]),
  total: listPublishers.total,
  loading: listPublishers.loading,
});

const PublisherInput = ({
  filter = {}, onChange, value, fixedOptions = [], emptyOption, error, className, ...rest
}) => {
  const [keyword, setKeyword] = useState(value);
  const [{ loading, publishers }, { getPublishers }] = useModel('publisher', pubsSelector);

  useEffect(() => {
    setKeyword(value);
  }, [value]);

  useEffect(() => {
    if (searchDebounced) {
      searchDebounced.cancel();
    }
    if (keyword) {
      searchDebounced = debounce(
        () => getPublishers({
          ...filter,
          keyword,
        }),
        300,
      );
      searchDebounced();
    }
  }, [keyword]);

  let options = emptyOption ? [...fixedOptions, emptyOption] : fixedOptions;
  if (keyword && !loading) {
    options = [...options, ...publishers];
  }

  if (emptyOption || fixedOptions.length > 0) {
    rest.openMenuOnFocus = true;
  } else {
    rest.menuIsOpen = !!keyword && keyword !== value;
  }

  return (
    <Fragment>
      <Select
        isClearable
        isLoading={loading}
        className={classNames('Select', { 'is-invalid': error }, className)}
        classNamePrefix="Select"
        getOptionValue={v => v.id}
        getOptionLabel={v => v.name}
        options={options}
        onInputChange={kw => setKeyword(kw)}
        onChange={onChange}
        value={value ? options.find(p => p.id === value) : null}
        filterOption={opts => opts} // show all search results
        noOptionsMessage={() => 'No result'}
      // menuIsOpen={!!keyword && keyword !== value}
        {...rest}
      />
      {error && <Form.Control.Feedback type="invalid" className="d-block">{error}</Form.Control.Feedback>}
    </Fragment>
  );
};

export default PublisherInput;
