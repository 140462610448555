import api from './api';

export default {
  getAlerts(params) {
    return api.GET('/alerts', params);
  },
  updateAlert(id, params) {
    return api.POST(`/alerts/${id}`, params);
  },
};
