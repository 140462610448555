import React, { useState } from 'react';
import {
  Button, Modal, InputGroup, FormControl,
} from 'react-bootstrap';
import moment from 'moment';

import Icon from 'components/Icon';
import CopyableText from '../CopyableText';

const APIKey = ({ apiKey = {}, onGenerate, generateState: { loading } }) => {
  const [showDoc, setShowDoc] = useState(false);
  // TODO
  return (
    <div>
      <p>API key can be used to get reports from API.</p>
      <Button className="m-0 p-0" variant="link" onClick={() => setShowDoc(true)}>
        View API document
      </Button>
      <APIDocumentModal show={showDoc} onHide={() => setShowDoc(false)} />
      {apiKey.token && (
      <div className="mt-3">
        <InputGroup className="mb-1">
          <FormControl type="text" value={apiKey.token} readOnly />
          <InputGroup.Append>
            <CopyableText text={apiKey.token}>
              <Button variant="secondary"><Icon name="copy" /></Button>
            </CopyableText>
          </InputGroup.Append>
        </InputGroup>
        <small>{`Your API key will be expired at ${moment(apiKey.exp).format('lll')}`}</small>
      </div>
      )}
      {onGenerate && (
      <div className="mt-3 text-center">
        <Button disabled={loading} onClick={onGenerate}>
          <Icon size={16} className="mr-1" name="refresh-cw">Regenerate</Icon>
        </Button>
      </div>
      )}

    </div>
  );
};

export default APIKey;

const APIDocumentModal = props => (
  <Modal size="lg" {...props}>
    <Modal.Header closeButton>
      <Modal.Title>API Document</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>1. Authorization</h4>
      <p>
        You need a valid API key to send request. API key can be sent to sever via query string or
        HTTP header:
      </p>
      <ul>
        <li>
          Via query string:
          <code>
            ?token=
            {'{YOUR_API_KEY}'}
          </code>
        </li>
        <li>
          Via HTTP header:
          <code>
Authorization: Bearer
            {'{YOUR_API_KEY}'}
          </code>
        </li>
      </ul>
      <h4>2. Endpoints</h4>
      <ul>
        <li>
          Endpoint:
          <code>https://adx.appota.com/api/v1.1/query/report</code>
        </li>
        <li>
          Method:
          <code>GET</code>
        </li>
        <li>Query params:</li>
        <ul>
          <li>
            <code>from</code>
: Required, start date, format
            <code>YYYY-MM-DD</code>
          </li>
          <li>
            <code>to</code>
: Required, end date, format
            <code>YYYY-MM-DD</code>
          </li>
          <li>
            <code>dimensions</code>
: Required, comma separated list of query dimensions. Valid
            dimensions:
          </li>
          <ul>
            <li>
              <code>DATE</code>
: Format
              <code>YYYY-MM-DD</code>
            </li>
            <li>
              <code>WEEK</code>
: Format
              <code>YYYY-WW</code>
            </li>
            <li>
              <code>MONTH</code>
Format
              <code>YYYY-MM</code>
            </li>
            <li>
              <code>ADX_APP_ID</code>
            </li>
            <li>
              <code>ADX_APP_NAME</code>
            </li>
            <li>
              <code>ADX_AD_TAG_NAME</code>
            </li>
          </ul>
          <li>
            <code>metrics</code>
: Required, comma separated list of query metrics. Valid metrics:
          </li>
          <ul>
            <li>
              <code>AD_REQUESTS</code>
            </li>
            <li>
              <code>MATCHED_AD_REQUESTS</code>
            </li>
            <li>
              <code>AD_IMPRESSIONS</code>
            </li>
            <li>
              <code>CLICKS</code>
            </li>
            <li>
              <code>ADX_PUBLISHER_EARNINGS</code>
: Estimated revenue($)
            </li>
          </ul>
          <li>
            <code>filter.ADX_APP_ID</code>
: Optional, filter by app ID
          </li>
        </ul>
        <li>
          Response:
          <div>
Content type:
            <code>JSON</code>
          </div>
        </li>
        <pre>
          <code>
            {`
              {
                "headers": [], // Array of dimensions and metrics as columns of a table
                "rows": [] // Array of rows, each row is an array of values correspond with header values
              }
            `}
          </code>
        </pre>
        <li>
          Example:
          <pre>
            <code>
              https://adx.appota.com/api/v1.1/query/report?from=2018-05-01&to=2018-05-31&dimensions=DATE,ADX_APP_NAME,ADX_AD_TAG_NAME&metrics=AD_IMPRESSIONS,CLICKS,ADX_PUBLISHER_EARNINGS&token=
              {'{YOUR_API_KEY}'}
            </code>
          </pre>
        </li>
      </ul>
      <h4>3. Error Handling</h4>
      Check HTTP response code:
      <ul>
        <li>
          <strong>200</strong>
: OK
        </li>
        <li>
          <strong>400</strong>
: Bad request, missing or invalid params
        </li>
        <li>
          <strong>401</strong>
: Unauthorized
        </li>
        <li>
          <strong>403</strong>
: Permission denied
        </li>
        <li>
          <strong>500</strong>
: System error, something went wrong
        </li>
      </ul>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={props.onHide}>Close</Button>
    </Modal.Footer>
  </Modal>
);
