import React, { useEffect } from 'react';
import {
  Form, Col, Button, Row, Badge,
} from 'react-bootstrap';
import Select from 'react-select';
import { stringify, parse } from 'query-string';
import pickBy from 'lodash.pickby';
import moment from 'moment';
import { Card } from 'tabler-react';
import classNames from 'classnames';

import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import Icon from 'components/Icon';
import DateRangePicker from 'components/DateRangePicker';
import MonitorInput from 'components/MonitorInput';
import EmptyState from 'components/EmptyState';
import MonitorAlert from 'components/MonitorAlert';
import User from 'entities/User';

import { useModel } from 'models';

import styles from './ListAlerts.module.scss';

const perpage = 30;

const alertSelector = ({ getAlerts, alerts }) => ({
  alerts: (getAlerts.ids || []).map(i => alerts[i]),
  total: getAlerts.total || {},
  loading: getAlerts.loading,
  hasMore: getAlerts.hasMore,
});

const ListAlerts = ({ history, location, currentUser }) => {
  const [{
    total, alerts, loading, hasMore,
  }, { getAlerts, resolveAlert }] = useModel('alert', alertSelector);
  const params = parse(location.search);

  useEffect(() => {
    getAlerts({
      skip: 0,
      limit: perpage,
      type: params.type,
      order: params.order,
      monitorId: params.mid,
      from: params.from ? moment(params.from).format('YYYY-MM-DD') : null,
      to: params.to ? moment(params.to).format('YYYY-MM-DD') : null,
    });
  }, [params.type, params.order, params.mid, params.from, params.to]);

  const onLoadmore = () => {
    getAlerts({
      limit: perpage,
      skip: alerts.length,
      type: params.type,
      order: params.order,
      monitorId: params.mid,
      from: params.from ? moment(params.from).format('YYYY-MM-DD') : null,
      to: params.to ? moment(params.to).format('YYYY-MM-DD') : null,
    });
  };

  const orderOptions = [
    { value: '', label: 'Newest' },
    { value: 'lstUpdate', label: 'Oldest' },
    { value: '-type', label: 'High Priority' },
    { value: 'type', label: 'Low Priority' },
  ];

  const typeOptions = [
    { value: 1, label: 'SUCCESS' },
    { value: 2, label: 'WARNING' },
    { value: 3, label: 'DANGER' },
  ];

  const onQueryParamsChange = p => history.push({
    ...location,
    search: stringify(pickBy({ ...params, ...p })),
  });

  const action = currentUser.hasPermission(User.PermMonitorView) && (
    <Button className="d-flex align-items-center" onClick={() => history.push('/monitors')}>
      <Icon name="tachometer-alt" type="fa" className="mr-2" size={16} />
      List Monitors
    </Button>
  );

  return (
    <MainLayout>
      <PageHeader title="List Alerts" extraContent={action} />
      <Form autoComplete="off" className="mt-3">
        <Form.Row>
          <Col sm={4} md={2} className="mb-3">
            <Select
              className="Select"
              classNamePrefix="Select"
              options={orderOptions}
              value={orderOptions.find(o => o.value === (params.order || ''))}
              onChange={o => onQueryParamsChange({ order: o ? o.value : '' })}
            />
          </Col>
          <Col sm={4} md={3} className="mb-3">
            <MonitorInput
              placeholder="Monitor"
              onChange={m => onQueryParamsChange({ mid: m ? m.id : '' })}
              value={params.mid}
            />
          </Col>
          <Col sm={4} md={2} className="mb-3">
            <Select
              className="Select"
              classNamePrefix="Select"
              isClearable
              placeholder="Status"
              options={typeOptions}
              value={typeOptions.find(o => `${o.value}` === params.type)}
              onChange={o => onQueryParamsChange({ type: o ? o.value : '' })}
            />
          </Col>
          <Col md={5} lg={4} xl={3} className="mb-3">
            <DateRangePicker
              className="DateRangeFilter"
              showClearDates
              anchorDirection="right"
              isOutsideRange={d => false}
              startDate={params.from ? moment(params.from, 'YYYY-MM-DD') : null}
              endDate={params.to ? moment(params.to, 'YYYY-MM-DD') : null}
              maxDate={moment()}
              onChange={(start, end) => onQueryParamsChange({
                from: start.format('YYYY-MM-DD'),
                to: end.format('YYYY-MM-DD'),
              })}
              onClearDate={() => onQueryParamsChange({ from: '', to: '' })}
            />
          </Col>
        </Form.Row>
      </Form>
      <Card className={classNames(styles.listAlerts, 'mt-3')}>
        <Card.Header>
          <Card.Title>
            Total:
            <Badge className="mx-1" variant="success">{`Success: ${total.success}`}</Badge>
            <Badge className="mx-1" variant="warning">{`Warning: ${total.warning}`}</Badge>
            <Badge className="mx-1" variant="danger">{`Urgent: ${total.urgent}`}</Badge>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {!alerts || alerts.length === 0 ? (
            <EmptyState className="mt-5" icon="bell-off" title="No data" desc="No alert to show" />
          ) : (
            <Row>
              {alerts.map(a => (
                <Col key={a.id} lg={6} className="animated slideInUp">
                  <MonitorAlert
                    alert={a}
                    showActions={currentUser.hasPermission(User.PermMonitorAlertEdit)}
                    showMonitorLink={currentUser.hasPermission(User.PermMonitorView)}
                    showSimilarLink={!params.mid}
                    onResolve={status => resolveAlert(a.id, { status })}
                  />
                </Col>
              ))}
            </Row>
          )}
          <div className="d-flex justify-content-center mt-3">
            {hasMore && (
              <Button variant="secondary" disabled={loading} onClick={onLoadmore}>
                Load more
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </MainLayout>
  );
};

export default ListAlerts;
