import api from './api';

export default {
  getReferrals(accountId, data) {
    return api.GET(`/accounts/${accountId}/referrals`, data);
  },
  getRefferalReport(data) {
    return api.GET('/query/report/referrals', data);
  },
};
