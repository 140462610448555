import React from 'react';
import { Button } from 'tabler-react';
import { Modal } from 'react-bootstrap';

import Icon from './Icon';

const styles = {
  warning: { icon: 'alert-triangle' },
  info: { icon: 'info' },
  error: { icon: 'x-circle' },
  success: { icon: 'check' },
};

const Dialog = ({
  type = 'warning',
  title,
  content,
  okText = 'OK',
  cancelText = 'Cancel',
  onOK = () => {},
  onCancel = () => {},
  closable = true,
  cancelable = true,
  okProps,
  cancelProps,
  show,
  onExited,
  ...rest
}) => (
  <Modal className="alert-dialog" show={show} centered onHide={onCancel} onExited={onExited} {...rest}>
    <Modal.Header closeButton={closable}>
      <Modal.Title><Icon className="mr-1" size={23} name={styles[type].icon}>{title}</Icon></Modal.Title>
    </Modal.Header>
    <Modal.Body>{content}</Modal.Body>
    <Modal.Footer>
      {cancelable && (
        <Button size="sm" onClick={onCancel} {...cancelProps}>
          {cancelText}
        </Button>
      )}
      <Button size="sm" color="primary" onClick={onOK} {...okProps}>
        {okText}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Dialog;
