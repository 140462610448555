import React, { useEffect, useRef } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import useTempState from 'utils/useTempState';
import MonitorForm from 'components/MonitorForm';

import styles from './UpdateMonitorModal.module.scss';

const UpdateMonitorModal = ({
  show, monitor = {}, onSuccess, onHide,
}) => {
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);
  const form = useRef(null);

  const [{ loading, error }, { updateMonitor }] = useModel('monitor', state => ({
    ...state.updateMonitor,
  }));

  useEffect(() => {
    setSubmitStatus({ loading, error });
  }, [loading, error]);

  useActionEffect({ loading, error, onSuccess });

  return (
    <Modal dialogClassName={styles.modal} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create new monitor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MonitorForm
          ref={form}
          initialValues={monitor}
          onSubmit={values => updateMonitor(monitor.id, values)}
          submitError={submitStatus.error}
          submitting={submitStatus.loading}
        />
        {submitStatus.error && (
          <Alert variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => form.current.submitForm()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateMonitorModal;
