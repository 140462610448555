import React, { useContext } from 'react';
import Select from 'react-select';

import { AppContext } from 'App';

const AccountTypeSelect = ({
  onChange, value, className, ...rest
}) => {
  const { currentAccount } = useContext(AppContext);
  const options = (currentAccount.team || []).map(am => ({
    label: am.name,
    value: am.id,
  }));

  return (
    <Select
      isClearable
      className={`${className || ''} Select`}
      classNamePrefix="Select"
      options={options}
      onChange={o => onChange(o && o.value)}
      value={value ? options.find(s => s.value === value) : null}
      noOptionsMessage={() => 'No result'}
      {...rest}
    />
  );
};

export default AccountTypeSelect;
