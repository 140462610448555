import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'tabler-react';
import { Button, Badge } from 'react-bootstrap';
import classNames from 'classnames';

import Icon from 'components/Icon';
import EmptyState from 'components/EmptyState';
import DataTable from 'components/DataTable';
import UpdateMonitorModal from 'components/UpdateMonitorModal';

import capitalize from 'utils/capitalize';

import styles from './ListMonitorCard.module.scss';

const ListMonitorCard = ({
  history,
  className,
  viewOnly,
  total = 0,
  monitors = [],
  pagination = {
    current: 0,
    perpage: 30,
  },
  onPlay,
  onPause,
}) => {
  const [selectedMonitor, setSelectedMonitor] = useState(null);
  const totalPage = Math.ceil(total / pagination.perpage);

  const alertIcon = {
    1: (
      <Icon
        name="calendar-check"
        type="fa"
        style={{ color: '#13A10C', marginRight: '12px' }}
        size={20}
      />
    ),
    2: (
      <Icon
        name="exclamation-triangle"
        type="fa"
        style={{ color: '#F7A24F', marginRight: '12px' }}
        size={20}
      />
    ),
    3: (
      <Icon
        name="exclamation-triangle"
        type="fa"
        style={{ color: '#EA4B5A', marginRight: '12px' }}
        size={20}
      />
    ),
  };

  const renderTarget = (m) => {
    let tag = null;
    if (m.tagId === '*') {
      tag = <div>Every tags</div>;
    } else if (m.tagId && m.tagName) {
      tag = <Link to={`/apps/${m.appId}`}>{`Tag: ${m.tagName}`}</Link>;
    }

    let app = null;
    if (m.appId === '*' && m.tagId !== '*') {
      app = <div>Every applications</div>;
    } else if (m.appId && m.appName) {
      app = <Link to={`/apps/${m.appId}`}>{`App: ${m.appName}`}</Link>;
    }

    let pub = null;
    if (m.pubId === '*' && m.tagId !== '*' && m.appId !== '*') {
      pub = <div>Every publishers</div>;
    } else if (m.pubId && m.pubName) {
      pub = <Link to={`/publishers/${m.pubId}`}>{`Pub: ${m.pubName}`}</Link>;
    }
    return (
      <div>
        <div>{pub}</div>
        <div>{app}</div>
        <div>{tag}</div>
      </div>
    );
  };

  /** @type {[DataTableColumProps]} */
  const columns = [
    { key: 'name', title: 'Name' },
    { key: 'target', title: 'Target' },
    { key: 'period', title: 'Period' },
    { key: 'status', title: 'Status' },
    { key: 'action', title: 'Actions' },
  ];

  const rows = monitors.map((m) => {
    const actions = [
      {
        visible: m.status === 'stopped' && !viewOnly,
        render: () => (
          <Button className="py-0 px-1" key="start" size="small" onClick={() => onPlay(m.id)}>
            <Icon name="play" className="mr-1" />
            Start
          </Button>
        ),
      },
      {
        visible: m.status === 'running' && !viewOnly,
        render: () => (
          <Button className="py-0 px-1" key="pause" size="small" variant="danger" onClick={() => onPause(m.id)}>
            <Icon name="pause" className="mr-1" />
            Stop
          </Button>
        ),
      },
      {
        visible: true,
        render: () => (
          <Button
            className="py-0 px-1 ml-2"
            key="list-alert"
            size="small"
            variant="info"
            onClick={() => history.push(`/alerts?mid=${m.id}`)}
          >
            <Icon name="bell" className="mr-1" />
            List Alerts
          </Button>
        ),
      },
    ];
    return {
      id: m.id,
      name: (
        <a href="#edit" onClick={() => setSelectedMonitor(m)}>
          {alertIcon[m.alertType]}
          {m.name}
        </a>
      ),
      target: renderTarget(m),
      period: (
        <Fragment>
          <div>{`${m.period} day(s)`}</div>
          {m.oteval && <div>One time</div>}
        </Fragment>
      ),
      status: (
        <Badge pill variant={m.status === 'stopped' ? 'danger' : 'success'}>
          {capitalize(m.status)}
        </Badge>
      ),
      action: actions.map(a => a.visible && a.render()),
    };
  });

  const onPageChange = p => pagination.onChange
    && pagination.onChange({
      page: p,
      perpage: pagination.perpage,
    });

  return (
    <Card className={classNames('mt-3', styles.card, className)}>
      <Card.Header>
        <Card.Title>{`Total: ${total}`}</Card.Title>
      </Card.Header>
      <Card.Body>
        <DataTable
          rowKey="id"
          columns={columns}
          rows={rows}
          scroll={{ x: true }}
          emptyText={<EmptyState desc="No data" />}
          pagination={{
            total: totalPage,
            current: Math.min(totalPage, pagination.current),
            onChange: onPageChange,
          }}
        />
      </Card.Body>
      <UpdateMonitorModal
        show={!!selectedMonitor}
        monitor={selectedMonitor}
        onSuccess={() => setSelectedMonitor(null)}
        onHide={() => setSelectedMonitor(null)}
      />
    </Card>
  );
};

export default ListMonitorCard;
