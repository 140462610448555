import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import qs from 'query-string';
import {
  Alert, Form, Col, ButtonGroup, Button,
} from 'react-bootstrap';
import { Card, Dimmer } from 'tabler-react';
import pickBy from 'lodash.pickby';
import { Link } from 'react-router-dom';
import get from 'lodash.get';

import { useModel } from 'models';
import usePrevState from 'utils/usePrevState';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import DateRangePicker from 'components/DateRangePicker';
import MetricChart from 'components/MetricChart';
import { asyncAggregateReports, AdmobMetric, getMetricName } from 'entities/Report';
import ReportTableCard from 'components/ReportTableCard';
import MonthRangePicker from 'components/MonthRangePicker';
import CountryInput from 'components/CountryInput';

import Account from 'entities/Account';
import Icon from 'components/Icon';
import PublisherInput from 'components/PublisherInput';
import SearchInput from 'components/SearchInput';

const getConfig = (asPublisher = false) => {
  const metrics = asPublisher
    ? [
      ...AdmobMetric.METRIC_TYPES.filter(
        t => !['ESTIMATED_EARNINGS', 'ADX_PUBLISHER_EARNINGS'].includes(t),
      ),
      { key: 'ADX_PUBLISHER_EARNINGS', title: getMetricName('ESTIMATED_EARNINGS') },
    ]
    : AdmobMetric.METRIC_TYPES;

  return {
    MetricClass: AdmobMetric,
    breakdowns: {
      date: { dims: ['DATE'], title: 'Dates', colName: 'Date' },
      // week: { dims: ['WEEK'], title: 'Weeks', colName: 'Week' },
      month: { dims: ['MONTH'], title: 'Months', colName: 'Month' },
      publisher: {
        only: Account.TYPE_ADMIN,
        dims: ['ADX_PUBLISHER_ID', 'ADX_PUBLISHER_NAME'],
        title: 'Publishers',
        colName: 'Publisher',
      },
      app: { dims: ['APP_ID', 'APP_NAME'], title: 'Apps', colName: 'App' },
      country: { dims: ['COUNTRY_CODE'], title: 'Countries', colName: 'Country' },
    },
    revMetric: asPublisher ? 'ADX_PUBLISHER_EARNINGS' : 'ESTIMATED_EARNINGS',
    filter: {
      pid: 'ADX_PUBLISHER_ID',
      aid: 'APP_ID',
      country: 'COUNTRY_CODE',
      platform: 'PLATFORM',
    },
    chartMetrics: metrics,
    tableMetrics: metrics,
  };
};

const timeOptions = [
  {
    label: 'Date',
    value: 'date',
    format: 'YYYY-MM-DD',
    shorthand: 'd',
  },
  {
    label: 'Month',
    value: 'month',
    format: 'YYYY-MM',
    shorthand: 'M',
  },
];

/**
 * @param {import('react-router-dom').RouteComponentProps} props
 */
const AdmobReport = ({ location, history, currentAccount }) => {
  const conf = getConfig(currentAccount.isPublisher());
  const params = qs.parse(location.search);
  const period = params.period || 'date';
  const selectedTimeOption = timeOptions.find(t => t.value === period);

  const { format: timeFormat, shorthand: timeShorthand } = selectedTimeOption;

  const { breakdowns } = conf;
  if (params.metric && !conf.chartMetrics.find(m => m === params.metric)) {
    params.metric = '';
  }

  let brkKey = params.breakdown || period;
  if (!breakdowns[brkKey]) {
    brkKey = period;
  }

  const timeDimension = get(conf, ['breakdowns', period, 'dims', 0], 'DATE');

  /** @type {typeof breakdowns.date} */
  const breakdown = { ...breakdowns[brkKey], key: brkKey };

  let startDate = moment(params.from, timeFormat);
  let endDate = moment(params.to, timeFormat);
  if (!endDate.isValid()) {
    endDate = moment().endOf(timeShorthand);
  }
  if (!startDate.isValid()) {
    startDate = endDate
      .clone()
      .subtract(6, 'd')
      .startOf(timeShorthand);
  }
  const range = endDate
    .clone()
    .endOf(timeShorthand)
    .diff(startDate.clone().startOf(timeShorthand), timeShorthand) + 1;
  const reportId = `admob_${brkKey}_report_${qs.stringify(pickBy(params))}`;
  const [{
    reports, loading, error, reportId: prevReportId,
  }, { getAdmobReports }] = useModel(
    'report',
    state => ({
      reportId,
      ...(state.getAdmobReports[reportId] || {}),
    }),
    [brkKey],
  );
  const prevStartDate = usePrevState(startDate) || startDate;
  const prevEndDate = usePrevState(endDate) || endDate;
  const prevRange = usePrevState(range) || range;

  let dimensions = [timeDimension];
  const aggGroup = { total: '*', byTime: timeDimension };

  if (!timeOptions.some(t => t.value === brkKey)) {
    dimensions = [...dimensions, ...breakdown.dims];
    // eslint-disable-next-line prefer-destructuring
    aggGroup[brkKey] = breakdown.dims[0];
  }

  useEffect(() => {
    if (range < 1 || reports) {
      return;
    }
    const req = {
      from: startDate
        .clone()
        .subtract(range, timeShorthand)
        .format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      dimensions,
    };
    if (params.pid) {
      req[`filter.${conf.filter.pid}`] = params.pid;
    }
    if (params.aid) {
      req[`filter.${conf.filter.aid}`] = params.aid;
    }
    if (params.country) {
      req[`filter.${conf.filter.country}`] = params.country;
    }
    getAdmobReports(reportId, req, rpts => asyncAggregateReports(rpts, conf.MetricClass, aggGroup, r => {
      const d = moment(r[timeDimension], timeFormat);
      if (d.isBetween(startDate, endDate, timeShorthand, '[]')) {
        return 'current';
      }
      return 'prev';
    }));
  }, [prevReportId]);

  const { total, byTime, tableData } = useMemo(() => {
    const out = {
      byTime: [],
      tableData: [],
      total: {
        current: new conf.MetricClass(),
        prev: new conf.MetricClass(),
      },
    };

    if (reports && reports.byTime) {
      out.total.prev = reports.total.prev || out.total.prev;
      out.total.current = reports.total.current || out.total.current;
      Object.keys(reports.byTime)
        .sort((d, dd) => (d > dd ? 1 : -1))
        .forEach(d => {
          const dd = moment(d, timeFormat);
          if (!dd.isBetween(prevStartDate, prevEndDate, 'd', '[]')) {
            return;
          }

          const dtemp = out.byTime.length > 0
            ? moment(out.byTime[out.byTime.length - 1][0], timeFormat).add(1, timeShorthand)
            : prevStartDate.clone();

          while (!dtemp.isAfter(dd)) {
            const dstr = dtemp.format(timeFormat);
            const prevdstr = dtemp
              .clone()
              .subtract(prevRange, timeShorthand)
              .format(timeFormat);

            const ddata = {
              ...(reports.byTime[dstr] || {
                [timeDimension]: dstr,
                current: new conf.MetricClass(),
              }),
              [`PREV_${timeDimension}`]: prevdstr,
              prev: reports.byTime[prevdstr]
                ? reports.byTime[prevdstr].prev
                : new conf.MetricClass(),
            };

            out.byTime.push([dstr, ddata]);
            if (timeOptions.some(t => t.value === brkKey)) {
              out.tableData.push(ddata);
            }
            dtemp.add(1, timeShorthand);
          }
        });

      if (!timeOptions.some(t => t.value === brkKey)) {
        out.tableData = Object.values(reports[brkKey] || {}).map(a => {
          a.prev = a.prev || new conf.MetricClass();
          a.current = a.current || new conf.MetricClass();
          return a;
        });
      }
    }
    return out;
  }, [reports, params.from, params.to, brkKey, period]);

  const onDateRangeChange = (start, end) => {
    history.push({
      ...location,
      search: qs.stringify({
        ...params,
        from: start.format(timeFormat),
        to: end.format(timeFormat),
      }),
    });
  };

  const dimension = {
    key: breakdown.dims[0],
    name: breakdown.colName,
    valueKey: breakdown.dims[0],
  };
  if (breakdowns[brkKey].dims.length > 1) {
    // eslint-disable-next-line prefer-destructuring
    dimension.valueKey = breakdowns[brkKey].dims[1];
  }
  dimension.render = (id, row) => {
    let value = row[breakdown.dims[breakdown.dims.length - 1]] || row[breakdown.dims[0]] || '_unknown_';
    const filterKey = Object.keys(conf.filter).find(k => conf.filter[k] === breakdown.dims[0]);

    if (filterKey && id !== '_empty_' && id !== '_unknown_') {
      const loc = {
        ...location,
        search: qs.stringify(pickBy({ ...params, breakdown: null, [filterKey]: id })),
      };
      value = (
        <>
          {dimension.key === 'ADX_PUBLISHER_ID' && currentAccount.isAdmin() && (
            <Link
              className="mr-2"
              to={`/publishers/${row[dimension.key]}`}
              title="View publisher info"
            >
              <Icon size={18} name="info" />
            </Link>
          )}
          <Link to={loc}>{value}</Link>
        </>
      );
    }
    return { children: value };
  };

  const dateRangePicker = (
    <div className="mt-3 d-flex">
      <ButtonGroup size="sm" className="mr-2">
        <Button
          variant={timeShorthand === 'd' ? 'primary' : 'secondary'}
          onClick={() => onQueryParamsChange({
            period: 'date',
            from: moment()
              .subtract(6, 'd')
              .format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
          })}
        >
          Daily
        </Button>
        <Button
          variant={timeShorthand === 'M' ? 'primary' : 'secondary'}
          onClick={() => onQueryParamsChange({
            period: 'month',
            from: moment()
              .subtract(3, 'M')
              .format('YYYY-MM'),
            to: moment().format('YYYY-MM'),
          })}
        >
          Monthly
        </Button>
      </ButtonGroup>
      {timeShorthand === 'd' ? (
        <DateRangePicker
          className="align-self-end align-self-sm-center"
          anchorDirection="right"
          startDate={startDate}
          endDate={endDate}
          onChange={onDateRangeChange}
          maxDate={moment()}
          maxRange={90}
        />
      ) : (
        <MonthRangePicker
          startMonth={startDate}
          endMonth={endDate}
          onChange={onDateRangeChange}
          pastScrollRange={24}
        />
      )}
    </div>
  );

  const onQueryParamsChange = p => history.push({
    ...location,
    search: qs.stringify(pickBy({ ...params, ...p })),
  });

  const nav = [
    {
      key: 'label',
      title: 'Breakdown',
      disabled: true,
    },
    ...Object.keys(breakdowns)
      .filter(b => {
        if (timeOptions.some(t => t.value === b) && b !== period) {
          return false;
        }
        return !breakdowns[b].only || breakdowns[b].only === currentAccount.type;
      })
      .map(d => ({
        key: d,
        title: breakdowns[d].title,
      })),
  ];

  const colProps = {
    xs: 6,
    sm: 4,
    md: 3,
    className: 'mb-1',
  };

  const onMetricChange = params.metric
    ? m => history.push({ ...location, search: qs.stringify({ ...params, metric: m }) })
    : null;

  return (
    <MainLayout>
      <PageHeader title="Reports" subTitle="Admob reports" extraContent={dateRangePicker} />
      {error && (
        <Alert variant="danger" className="mt-3">
          {error.message || error.error || 'Something went wrong.'}
        </Alert>
      )}
      <div className="mt-3">
        <Form.Row>
          {currentAccount.isAdmin() && (
            <Col {...colProps}>
              <PublisherInput
                placeholder="All publishers"
                onChange={p => onQueryParamsChange({ pid: p ? p.id : '' })}
                value={params.pid}
              />
            </Col>
          )}
          <Col {...colProps}>
            <SearchInput
              searchOnEnter
              onClear={() => onQueryParamsChange({ aid: '' })}
              onSearch={kw => onQueryParamsChange({ aid: kw })}
              value={params.aid || ''}
              placeholder="App ID"
            />
          </Col>
          <Col {...colProps}>
            <CountryInput
              placeholder="All countries"
              onChange={c => onQueryParamsChange({ country: c ? c.code : '' })}
              value={params.country}
            />
          </Col>
        </Form.Row>
      </div>
      <Card className="mt-3">
        <Card.Header>
          <Card.Title>Chart</Card.Title>
        </Card.Header>
        <Card.Body>
          <Dimmer active={loading} loader>
            <MetricChart
              showHeader
              showPrev
              total={total}
              data={byTime}
              metrics={conf.chartMetrics}
              metric={params.metric || conf.revMetric}
              onChangeMetric={onMetricChange}
            />
          </Dimmer>
        </Card.Body>
      </Card>
      <ReportTableCard
        activeKey={brkKey}
        nav={nav}
        sortDefault="ESTIMATED_EARNINGS"
        loading={loading}
        metrics={conf.tableMetrics}
        dimension={dimension}
        data={tableData}
        onNavigate={b => onQueryParamsChange({ breakdown: b })}
      />
    </MainLayout>
  );
};

export default AdmobReport;
