import React, { useMemo, forwardRef } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import moment from 'moment';

import styles from './MonthPicker.module.scss';

const format = 'MM/YYYY';

const MonthPicker = forwardRef(({
  onChange, value, className, pastScrollRange = 12, ...rest
}, ref) => {
  const options = useMemo(() => {
    const opts = [];
    const monthTemp = moment();
    while (moment().diff(monthTemp, 'month') < pastScrollRange) {
      opts.push({ value: monthTemp.clone(), label: monthTemp.format(format) });
      monthTemp.subtract(1, 'month');
    }
    return opts;
  }, [pastScrollRange]);

  return (
    <Select
      ref={ref}
      className={classNames('Select', styles.monthPicker, className)}
      classNamePrefix="Select"
      options={options}
      onChange={o => onChange(o && o.value)}
      value={value ? options.find(s => s.value.isSame(value, 'month')) : null}
      {...rest}
    />
  );
});

export default MonthPicker;
