import React from 'react';
import { Button } from 'tabler-react';
import { withRouter } from 'react-router-dom';

import CenteredLayout from 'layouts/Centered';
import ErrorState from 'components/ErrorState';
import Icon from 'components/Icon';

const messages = {
  403: 'You have no permission to view this page',
  404: 'The page you are looking for could not be found',
  500: 'Something went wrong, please try again later',
};

const Error = ({ code = '404', history }) => (
  <CenteredLayout>
    <div className="text-center">
      <ErrorState title={code} detail={messages[code]}>
        <Button onClick={() => history.goBack()} className="btn-primary">
          <Icon className="mr-2" name="arrow-left">Go back</Icon>
        </Button>
      </ErrorState>
    </div>
  </CenteredLayout>
);

export default withRouter(Error);
