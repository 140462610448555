import React, { useEffect, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Loader } from 'tabler-react';
import qs from 'query-string';
import ReactGA from 'react-ga';

import CenteredLayout from 'layouts/Centered';
import { useModel } from 'models';
import Error from 'pages/Error';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Dialog from 'components/Dialog';
import ListApps from 'pages/ListApps';
import ListPublishers from 'pages/ListPublishers';
import ListInvoices from 'pages/ListInvoices';
import InvoiceDetail from 'pages/InvoiceDetail';
import AddInvoice from 'pages/AddInvoice';
import configs from 'configs/configs';
import Report from './pages/Report';
import PageReport from './pages/PageReport';
import AccountDetail from './pages/AccountDetail';
import Settings from './pages/Settings';
import Register from './pages/Login/Register';
import Invitation from './pages/Invitation';
import ListAlerts from './pages/ListAlerts';
import ListMonitors from './pages/ListMonitors';
import ListReferrals from './pages/ListReferrals';
import ReferralsReport from './pages/ReferralsReport';
import ListPages from './pages/ListPages';
import Account from './entities/Account';
import Integration from './pages/Integration';
import User from './entities/User';
import AdmobReport from './pages/AdmobReport';

/**
 * @typedef {Object} DialogManager
 * @property {function(Parameters<Dialog>[0]):any} info
 * @property {function(Parameters<Dialog>[0]):any} warning
 * @property {function(Parameters<Dialog>[0]):any} success
 * @property {function(Parameters<Dialog>[0]):any} error
 */

/** @type {React.Context<{currentAccount: Account, currentUser: User, dialog: DialogManager}>} */
export const AppContext = React.createContext({});

let count = 0;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogs: [],
    };

    this.dialogManager = {
      info: opts => this.showDialog({ ...opts, type: 'info' }),
      warning: opts => this.showDialog({ ...opts, type: 'warning' }),
      success: opts => this.showDialog({ ...opts, type: 'success' }),
      error: opts => this.showDialog({ ...opts, type: 'error' }),
    };
  }

  upsertDialog = (key, opts) => {
    this.setState(({ dialogs }) => {
      let idx = dialogs.findIndex(d => d.key === key);
      if (idx === -1) {
        idx = dialogs.length;
      }
      return {
        dialogs: [
          ...dialogs.slice(0, idx),
          { ...dialogs[idx], ...opts, key },
          ...dialogs.slice(idx + 1),
        ],
      };
    });
  };

  removeDialog = key => {
    this.setState(({ dialogs }) => {
      dialogs.filter(d => d.key !== key);
    });
  };

  showDialog = ({
    onCancel, onOK, onExisted, ...rest
  }) => {
    const key = `dialog-${++count}`;
    if (!onCancel) {
      onCancel = i => i.dismiss();
    }
    if (!onOK) {
      onOK = i => i.dismiss();
    }
    const instance = {
      state: {},
      update: ({
        /* eslint-disable no-shadow */
        onCancel,
        onExisted,
        onOK,
        show,
        ...updateable
      }) => this.upsertDialog(key, updateable),
      destroy: () => this.removeDialog(key),
      dismiss: () => this.upsertDialog(key, { show: false }),
      setState(state = {}) {
        // eslint-disable-next-line
        this.state = {
          ...this.state,
          ...state,
        };
      },
    };
    this.upsertDialog(key, {
      onCancel: () => onCancel(instance),
      onOK: () => onOK(instance),
      onExited: () => instance.destroy(),
      ...rest,
      show: true,
    });
    return instance;
  };

  render() {
    const { dialogs } = this.state;

    return (
      <AppContext.Provider value={{ dialog: this.dialogManager }}>
        <Switch>
          <R path="/login" component={Login} />
          <R path="/register" component={Register} />
          <R path="/invitations/:code" component={Invitation} />
          <R authorize path="/monitors" component={ListMonitors} />
          {configs.platform === 'FAN' && (
            <R authorize path="/pages/:pageId" component={ListPages} />
          )}
          {configs.platform === 'FAN' && <R authorize path="/pages" component={ListPages} />}
          {configs.platform === 'ADX' && (
            <R
              authorize
              only={Account.TYPE_ADMIN}
              path="/referrals-report/:accountId/:refereeId"
              component={ReferralsReport}
            />
          )}
          {configs.platform === 'ADX' && (
            <R
              authorize
              only={Account.TYPE_ADMIN}
              path="/referrals-report/:accountId"
              component={ReferralsReport}
            />
          )}
          {configs.platform === 'ADX' && (
            <R
              authorize
              only={Account.TYPE_ADMIN}
              path="/referrals-report"
              component={ReferralsReport}
            />
          )}
          {configs.platform === 'ADX' && (
            <R
              authorize
              only={Account.TYPE_PUBLISHER}
              path="/referrals"
              component={ListReferrals}
            />
          )}
          {configs.platform === 'ADX' && (
            <R authorize path="/integration" component={Integration} />
          )}
          {configs.platform === 'ADX' && <R authorize path="/alerts" component={ListAlerts} />}
          <R authorize path="/apps/:appId" component={ListApps} />
          <R authorize path="/apps" component={ListApps} />
          <R
            authorize
            only={Account.TYPE_ADMIN}
            path="/publishers/:accountId/invoices"
            component={AddInvoice}
          />
          <R
            authorize
            only={Account.TYPE_ADMIN}
            path="/publishers/:accountId"
            component={AccountDetail}
          />
          <R authorize only={Account.TYPE_ADMIN} path="/publishers" component={ListPublishers} />
          <R authorize path="/app-reports" component={Report} />
          {configs.platform === 'FAN' && (
            <R authorize path="/page-reports" component={PageReport} />
          )}
          {configs.platform === 'ADX' && <R authorize path="/admob-reports" component={AdmobReport} />}
          <R
            authorize
            path="/invoices/:invoiceId"
            perms={[User.PermPubInvoiceView, User.PermPubInvoiceEdit]}
            component={InvoiceDetail}
          />
          <R
            authorize
            path="/invoices"
            perms={[User.PermPubInvoiceView, User.PermPubInvoiceEdit]}
            component={ListInvoices}
          />
          <R authorize path="/settings" component={Settings} />
          <R exact authorize path="/" component={Home} />

          {/* backward compatible */}
          <R
            path="/account/invoices/:id"
            component={Rewrite}
            componentProps={{ path: '/invoices/:id' }}
          />
          <R path="/account/invoices" component={Rewrite} componentProps={{ path: '/invoices' }} />
          <R
            path="/admin/invoices/:id"
            component={Rewrite}
            componentProps={{ path: '/invoices/:id' }}
          />
          <R path="/admin/invoices" component={Rewrite} componentProps={{ path: '/invoices' }} />

          <R component={Error} componentProps={{ code: '404' }} />
        </Switch>
        {dialogs.map(d => (
          <Dialog {...d} />
        ))}
      </AppContext.Provider>
    );
  }
}

export default App;

const Rewrite = ({ match: { params }, path }) => {
  let to = path;
  Object.keys(params).forEach(k => {
    to = to.replace(`:${k}`, encodeURIComponent(params[k]));
  });
  return <Redirect to={to} />;
};

const R = ({
  authorize = false,
  only,
  path = '',
  component: Component,
  componentProps = null,
  location,
  computeMatch,
  perms,
  ...rest
}) => {
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);
  if (authorize) {
    return (
      <Route
        {...rest}
        path={path}
        render={props => (
          <Authorized
            component={Component}
            perms={perms}
            only={only}
            {...props}
            {...componentProps}
          />
        )}
      />
    );
  }
  return (
    <Route {...rest} path={path} render={props => <Component {...props} {...componentProps} />} />
  );
};

const Authorized = ({
  component: Component, perms, only, ...props
}) => {
  const [{
    user, account, unauthorized, error,
  }, { getCurrentUser }] = useModel('auth');
  useEffect(() => {
    getCurrentUser();
  }, []);
  useEffect(() => {
    if (account) {
      ReactGA.set({ accountId: account.id, userId: user.id });
    }
  }, [(account || {}).id]);
  const ctx = useContext(AppContext);
  if (user) {
    if ((only && account.type !== only) || (perms && !user.hasPermission(...perms))) {
      return <Error code="404" />;
    }
    return (
      <>
        <AppContext.Provider value={{ ...ctx, currentAccount: account, currentUser: user }}>
          <Component {...props} currentUser={user} currentAccount={account} />
        </AppContext.Provider>
        {unauthorized && (
          <Dialog
            closable={false}
            cancelable={false}
            title="Session expired"
            content="You must login again to continue"
            onOK={() => window.location.reload()}
          />
        )}
      </>
    );
  }

  if (error) {
    if (error.code === 3) {
      const {
        location: { pathname, search },
      } = props;
      const query = { next: pathname + search };
      return <Redirect to={{ pathname: '/login', search: `?${qs.stringify(query)}` }} />;
    }
    return <div>{error.message || error.error || error}</div>;
  }

  return (
    <CenteredLayout>
      <Loader />
    </CenteredLayout>
  );
};
