import React, { useState, useEffect, useContext } from 'react';

import { useModel } from 'models';
import ListInvoiceCard from 'components/ListInvoiceCard';
import { AppContext } from 'App';

import useActionEffect from 'utils/useActionEffect';

import styles from './PublisherInvoices.module.scss';

const invoiceSelector = ({
  getInvoices, payInvoice, deleteInvoice, invoices,
}) => ({
  invoices: (getInvoices.ids || []).map(i => invoices[i]),
  total: getInvoices.total,
  totalAmount: getInvoices.totalAmount,
  payState: payInvoice,
  deleteState: deleteInvoice,
});

const PublisherInvoices = ({ account }) => {
  const perpage = 5;
  const [page, setPage] = useState(1);

  const { dialog } = useContext(AppContext);

  const [{
    total, totalAmount, invoices, payState, deleteState,
  },
  { getInvoices, payInvoice, deleteInvoice },
  ] = useModel('invoice', invoiceSelector);

  useEffect(() => {
    getInvoices({
      limit: perpage,
      skip: `${(page - 1) * perpage}`,
      accountId: account.id,
    });
  }, [page]);

  useActionEffect({
    loading: deleteState.loading,
    error: deleteState.error,
    onError: () => Notification.error(deleteState.error.message || deleteState.error.error || 'Something went wrong.', 1),
  });

  useActionEffect({
    loading: payState.loading,
    error: payState.error,
    onError: () => Notification.error(payState.error.message || payState.error.error || 'Something went wrong.', 1),
  });

  const onPay = (id) => {
    dialog.warning({
      title: 'Pay invoice',
      content: 'Are you sure want to pay this invoice?',
      onOK: (d) => {
        payInvoice(id);
        d.dismiss();
      },
    });
  };

  const onDelete = (id) => {
    dialog.warning({
      title: 'Delete invoice',
      content: 'Are you sure want to delete this invoice?',
      onOK: (d) => {
        deleteInvoice(id);
        d.dismiss();
      },
    });
  };


  const onPaginate = (e) => {
    if (e.page !== page) {
      setPage(e.page);
    }
  };


  return (
    <ListInvoiceCard
      className={styles.publisherInvoices}
      total={total}
      totalAmount={totalAmount}
      invoices={invoices}
      pagination={{ current: page, perpage, onChange: onPaginate }}
      onPay={onPay}
      onDelete={onDelete}
    />
  );
};

export default PublisherInvoices;
