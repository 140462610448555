import React from 'react';
import Select from 'react-select';

const AlertTypeSelect = ({
  onChange, value, className, ...rest
}) => {
  const options = [
    { label: 'SUCCESS', value: 1 },
    { label: 'WARNING', value: 2 },
    { label: 'URGENT', value: 3 },
  ];

  return (
    <Select
      isClearable
      className={`${className || ''} Select`}
      classNamePrefix="Select"
      options={options}
      onChange={o => onChange(o && o.value)}
      value={value ? options.find(s => s.value === value) : null}
      {...rest}
    />
  );
};

export default AlertTypeSelect;
