import React from 'react';
import Select from 'react-select';

const AccountTypeSelect = ({
  onChange, value, className, ...rest
}) => {
  const options = [
    { label: 'Company', value: 'company' },
    { label: 'Personal', value: 'personal' },
  ];

  return (
    <Select
      isClearable
      className={`${className || ''} Select`}
      classNamePrefix="Select"
      options={options}
      onChange={o => onChange(o && o.value)}
      value={value ? options.find(s => s.value === value) : null}
      {...rest}
    />
  );
};

export default AccountTypeSelect;
