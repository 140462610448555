import React from 'react';
import { Badge } from 'react-bootstrap';

import smart from 'static/images/smart.png';
import banner from 'static/images/banner.png';
import inter from 'static/images/inter.png';
import native from 'static/images/native.png';
import reward from 'static/images/reward.png';

const smartBannerSizes = [
  {
    label: 'Auto',
    value: 'auto',
  },
];

const interSizes = [
  {
    value: 'i-1024x768',
    label: '1024x768 (Interstitial)',
  },
  {
    value: 'i-768x1024',
    label: '768x1024 (Interstitial)',
  },
  {
    value: 'i-480x320',
    label: '480x320 (Interstitial)',
  },
  {
    value: 'i-320x480',
    label: '320x480 (Interstitial)',
  },
];

const bannerSizes = [
  {
    value: '728x90',
    label: '728x90 (Leaderboard)',
  },
  {
    value: '468x60',
    label: '468x60 (Full banner)',
  },
  {
    value: '336x280',
    label: '336x280 (Large rectangle)',
  },
  {
    value: '320x50',
    label: '320x50 (Mobile leaderboard)',
  },
  {
    value: '300x250',
    label: '300x250 (Medium rectangle)',
  },
];

const nativeSizes = [
  {
    label: 'Fluid',
    value: 'fluid',
  },
];

const tagTypes = [
  { name: 'Custom' },
  { name: 'Smart Banner' },
  { name: 'Banner' },
  { name: 'Interstitial' },
  { name: 'Native' },
  { name: 'Reward' },
];

const tagTypeIcons = [null, smart, banner, inter, native, reward];

const tagTypeDesc = [
  '',
  'Smart Banners allow SDK to choose the best sized ad according to the size and orientation of the mobile app.',
  'Banner ads are rectangular image or text ads that occupy a spot within an app layout',
  'Interstitial ads are full-screen ads that cover the interface of their host app.',
  'Native ads are flexible and can provide a beautiful, relevant, effective ad in any situation and on any device.',
  'Allowing users to voluntarily watch an ad in exchange for an in-app reward.',
];

export default class App {
  static TAG_CUSTOM = 0;
  static TAG_SMART_BANNER = 1;
  static TAG_BANNER = 2;
  static TAG_INTERSTITIAL = 3;
  static TAG_NATIVE = 4;
  static TAG_REWARD = 5;

  static STATUS_PENDING = 'pending';
  static STATUS_BLOCKED = 'blocked';
  static STATUS_APPROVED = 'approved';
  static STATUS_ARCHIVED = 'archived';

  static STORE_STATUS_PUBLISHED = 'published';
  static STORE_STATUS_UNPUBLISHED = 'unpublished';

  constructor(app = {}) {
    Object.assign(this, app);
  }

  static getTagSizesOptions = (type) => {
    switch (type) {
      case App.TAG_CUSTOM:
        return [...bannerSizes, ...interSizes];
      case App.TAG_BANNER:
        return [...bannerSizes];
      case App.TAG_INTERSTITIAL:
        return [...interSizes];
      default:
        return [];
    }
  };

  static getSizesStr = sizes => [...bannerSizes, ...nativeSizes, ...smartBannerSizes, ...interSizes]
    .reduce(
      (names, s) => ((sizes || []).indexOf(s.value) > -1 ? [...names, s.label] : names),
      [],
    )
    .join(', ');

  static getTagTypeStr = type => (tagTypes[type] || {}).name;

  static getTagOptions = () => tagTypes.map((t, index) => ({
    value: index,
    label: t.name,
  }));

  static getTypeIcon = type => tagTypeIcons[type];

  static getTypeDesc = type => tagTypeDesc[type];

  get storeUrl() {
    if (this.store === 'gp') {
      return `https://play.google.com/store/apps/details?id=${this.storeAppId}`;
    }
    if (this.store === 'itunes') {
      const id = this.storeAppId.split(':', 2);
      if (id.length === 2) {
        return `https://apps.apple.com/${id[0]}/app/${id[1]}`;
      }
      return `https://apps.apple.com/app/${id[0]}`;
    }
    return '';
  }

  static getStatusOptions() {
    return [
      { value: this.STATUS_PENDING, label: 'Pending' },
      { value: this.STATUS_BLOCKED, label: 'Blocked' },
      { value: this.STATUS_APPROVED, label: 'Approved' },
      { value: this.STATUS_ARCHIVED, label: 'Archived' },
    ];
  }

  static getStoreStatusOptions() {
    return [
      { value: this.STORE_STATUS_PUBLISHED, label: 'Published' },
      { value: this.STORE_STATUS_UNPUBLISHED, label: 'Unpublished' },
    ];
  }

  getStatusBadge() {
    let style = '';
    let badge = '';
    switch (this.status) {
      case 'blocked':
        style = 'warning';
        badge = 'BLOCKED';
        break;
      case 'approved':
        style = 'success';
        badge = 'APPROVED';
        break;
      case 'archived':
        style = 'default';
        badge = 'ARCHIVED';
        break;
      default:
        style = 'default';
        badge = 'PENDING';
    }

    /* eslint react/react-in-jsx-scope: "off" */
    /* eslint react/jsx-filename-extension: "off" */
    return (
      <Badge pill variant={style}>
        {badge}
      </Badge>
    );
  }

  getStoreURL() {
    if (!this.storeAppId) return '';

    if (this.store === 'gp') {
      return `https://play.google.com/store/apps/details?id=${
        this.storeAppId
      }`;
    }

    if (this.store === 'itunes') {
      const id = this.storeAppId.split(':', 2);
      if (id.length === 2) {
        return `https://apps.apple.com/${id[0]}/app/${id[1]}`;
      }
      return `https://apps.apple.com/app/${id[0]}`;
    }

    return '';
  }

  getViolationStatus() {
    return violationStatuses[this.violationStatus];
  }
}

const violationStatuses = {
  DEMAND_RESTRICT: 'Status: Restricted ad serving',
  BLACKLIST: 'Status: Disabled ad serving',
  WARNING: 'Status: Warning',
};
