import api from './api';

export default {
  getListPages(params) {
    return api.GET('/fbpages', params);
  },
  getPageDetail(id, params) {
    return api.GET(`/fbpages/${id}`, params);
  },
  getListImportPages(params) {
    return api.GET('/import_fbpages', params);
  },
  importPages(params) {
    return api.POST('/import_fbpages', params);
  },
  updatePage(id, data) {
    return api.POST(`/fbpages/${id}`, data);
  },
};
