import get from 'lodash.get';
import User from './User';

const statuses = ['', 'Pending', 'Activated', 'Deactivated'];

export default class Account {
  static ADX_NETWORK_1 = '194427432';
  static ADX_NETWORK_2 = '21617015150';

  static TYPE_UNREGISTERED = 0;
  static TYPE_ADMIN = 1;
  static TYPE_PUBLISHER = 2;

  static STATUS_PENDING = 1;
  static STATUS_ACTIVATED = 2;
  static STATUS_DEACTIVATED = 3;
  constructor(acc = {}) {
    if (acc.adxId) {
      if (!acc.adxConfigs) {
        acc.adxConfigs = {};
      }
      acc.adxConfigs[Account.ADX_NETWORK_2] = {
        ...(acc.adxConfigs[Account.ADX_NETWORK_2] || {}),
        adxId: acc.adxId,
        dfpAdUnitID: acc.dfpAdUnitID,
      };
    }
    Object.assign(this, acc);
  }

  clone(props = {}) {
    return new Account({ ...this, ...props });
  }

  statusString() {
    return statuses[this.status] || '';
  }

  isPublisher() {
    return this.type === Account.TYPE_PUBLISHER;
  }

  isUnregistered() {
    return !this.id || this.type === Account.TYPE_UNREGISTERED;
  }

  isAdmin() {
    return this.type === Account.TYPE_ADMIN;
  }

  isPending() {
    return this.status === Account.STATUS_PENDING;
  }

  isActivated() {
    return this.status === Account.STATUS_ACTIVATED;
  }

  isDeactivated() {
    return this.status === Account.STATUS_DEACTIVATED;
  }
  isOpenReferral() {
    return this.referralAllowed;
  }

  hasPaymentMethod = () => get(this, 'payment.bankTransfers.length') > 0 || get(this, 'payment.paypals.length') > 0 || get(this, 'payment.payoneer')

  getUserById(id) {
    if (!this.team) return null;
    for (let i = 0; i < this.team.length; i++) {
      if (this.team[i].id === id) {
        return new User(this.team[i]);
      }
    }
    return null;
  }

  needUpdateInfo() {
    return (this.isPersonal() && !this.PIN) || !this.payment || this.payment === {};
  }

  needGenerateContract() {
    return (
      this.sharingConfig
      && this.sharingConfig > 0
      && this.contractInfoChanged
      && this.isPersonal()
      && !this.needUpdateInfo()
    );
  }

  isPersonal() {
    return !this.company || this.company === {};
  }

  getAMID() {
    if (this.AM && this.AM.length > 0) {
      return this.AM[0];
    }

    return null;
  }

  getMember(id) {
    for (let i = 0; i < this.team.length; i++) {
      if (this.team[i].id === id) {
        return this.team[i];
      }
    }

    return null;
  }

  static getAvatar = (name) => {
    const n = encodeURIComponent(name);
    return `https://ui-avatars.com/api/?name=${n}&background=0D8ABC&color=fff`;
  };

  static getStatusOptions() {
    return [
      { label: 'PENDING', value: Account.STATUS_PENDING },
      { label: 'ACTIVATED', value: Account.STATUS_ACTIVATED },
      { label: 'DEACTIVATED', value: Account.STATUS_DEACTIVATED },
    ];
  }

  listAMs() {
    if (this.type !== Account.TYPE_ADMIN) {
      return [];
    }
    return this.team.filter(u => !new User({ ...u, account: this }).hasPermission(User.PermPubAll));
  }

  isOwner(id) {
    if (this.id === id) return true;
    if (this.team.length && this.team[0].id === id) return true;

    return false;
  }

  getSystemSettings() {
    return this.sysSettings || {};
  }

  getCountry() {
    return get(this, 'contactInfo.country');
  }

  getEmail() {
    return get(this, 'contactInfo.email');
  }

  getFormData() {
    return {
      pubType: this.isPersonal() ? 'personal' : 'company',
      referralAllowed: this.referralAllowed,
      adbreakEnabled: this.adbreakEnabled,
      name: this.name,
      contactInfo: this.contactInfo,
      company: this.company,
      PIN: this.PIN,
      enableADX: this.platformStatus.ADX,
      enableFAN: this.platformStatus.FAN,
      lockInventory: this.lockInventory,
    };
  }

  get paymentThreshold() {
    return get(this, 'payment.threshold');
  }

  canGenerateContract() {
    return this.PIN && this.contactInfo && this.sharingConfig && this.payment;
  }
}
