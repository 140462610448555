import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import {
  Modal, Form, Alert, Badge, Button, Row, Col,
} from 'react-bootstrap';
import { Media, Avatar, Form as TRForm } from 'tabler-react';

import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import { useModel } from 'models';
import User from 'entities/User';
import Icon from 'components/Icon';
import UpdateMemberModal from 'components/UpdateMemberModal';
import { AppContext } from 'App';
import styles from './Settings.module.scss';

/**
 * @param {object} props
 * @param {import('entities/Account').default} props.account
 */
const TeamMember = ({ account, members = [], viewOnly }) => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [{ loading, error }, { sendInvite, updateMember }] = useModel(
    'auth',
    state => state.sendInvite,
  );
  const prevLoading = useRef(loading);
  const [showModal, setShowModal] = useState();
  const [showDeactivated, setShowDeactivated] = useState(false);
  const { currentUser } = useContext(AppContext);
  let roles = { ...account.roles, [User.ROLE_ADMIN]: { name: 'Admin', perms: [] } };

  useEffect(() => {
    if (prevLoading.current && !loading && !error) {
      setShowModal(false);
    }
    prevLoading.current = loading;
  }, [loading]);

  const columns = [
    {
      key: 'id',
      title: 'Name',
      render: (v, r) => ({
        children: (
          <Media>
            <Avatar className="mr-2" color="blue" imageURL={r.avatar} size="md">
              {!r.avatar && new User(r).getAbbrName()}
            </Avatar>
            <Media.Body className="align-self-center">
              <div>{r.name}</div>
              <small className="text-muted">{r.role}</small>
            </Media.Body>
          </Media>
        ),
      }),
    },
    {
      key: 'contact',
      title: 'Contact',
      render: (v, r) => ({
        children: (
          <>
            <div><Icon className="mr-1" name="envelope" type="fa">{r.email}</Icon></div>
            {r.phone && <div><Icon className="mr-1" name="phone" type="fa">{r.phone}</Icon></div>}
            {r.skype && <div><Icon className="mr-1" name="skype" type="fa">{r.skype}</Icon></div>}
          </>
        ),
      }),
    },
    { key: 'status', title: 'Status', render: s => <StatusBadge status={s} /> },
    {
      key: 'action',
      title: 'Actions',
      render: (_, m) => {
        if (currentUser.id === m.id) {
          return (
            <Button className="p-0 text-left" variant="link" onClick={() => setSelectedMember(m)}>
              Edit
            </Button>
          );
        }
        if (!currentUser.hasPermission(User.PermAccountTeamEdit)) {
          return null;
        }
        return (
          <>
            <Button className="p-0 text-left" variant="link" onClick={() => setSelectedMember(m)}>
              Edit
            </Button>
            <br />
            {m.status === User.STATUS_ACTIVATED && (
              <Button
                variant="link"
                className="p-0 text-left"
                onClick={() => updateMember(account.id, m.id, { status: User.STATUS_DEACTIVATED })}
              >
                Deactivate
              </Button>
            )}
            {m.status === User.STATUS_DEACTIVATED && (
              <Button
                variant="link"
                className="p-0 text-left"
                onClick={() => updateMember(account.id, m.id, { status: User.STATUS_ACTIVATED })}
              >
                Activate
              </Button>
            )}
          </>
        );
      },
    },
  ];

  if (account.isAdmin()) {
    columns.splice(2, 0, {
      key: 'platforms',
      title: 'Platforms',
      render: (v, u) => ({
        children: (
          <>
            {(!u.disabledPlatform || !u.disabledPlatform.ADX) && (
              <Badge className="mr-1" variant="primary">
                ADX
              </Badge>
            )}
            {(!u.disabledPlatform || !u.disabledPlatform.FAN) && (
              <Badge variant="primary">FAN</Badge>
            )}
          </>
        ),
      }),
    });
  }

  const rows = members.filter(m => (showDeactivated ? m : m.status !== User.STATUS_DEACTIVATED));
  const onInvite = data => sendInvite(data);

  if (account.isPublisher()) {
    roles = {
      admin: { name: 'Admin', desc: 'Full permissions.' },
      developer: {
        name: 'Developer',
        desc:
          'Can create/edit applications and tags, view information of account, invoices, reports.',
      },
      viewer: {
        name: 'Viewer',
        desc: 'Can view information of account, applications, invoices, and reports.',
      },
    };
  }

  return (
    <div className={styles.teamMembers}>
      <Row>
        <Col x2={8}>
          <TRForm.Group>
            <TRForm.Switch
              className="p-0 mt-2"
              name="toggler"
              type="checkbox"
              label="Show deactivated members"
              onChange={e => setShowDeactivated(e.target.checked)}
              value="1"
            />
          </TRForm.Group>
        </Col>
        {!viewOnly && (
          <Col>
            <Button className="ml-auto d-block" onClick={() => setShowModal(true)}>
              <Icon name="mail" className="mr-1">
                Invite
              </Icon>
            </Button>
          </Col>
        )}
      </Row>
      <DataTable
        rowKey="id"
        className="mt-3"
        columns={columns}
        rows={rows}
        scroll={{ x: true }}
        emptyText={<EmptyState desc="No data" />}
      />
      {!viewOnly && (
        <InviteModal
          roles={roles}
          loading={loading}
          error={error}
          show={showModal}
          onSubmit={onInvite}
          onHide={() => setShowModal(false)}
        />
      )}
      <UpdateMemberModal
        show={!!selectedMember}
        infoOnly={selectedMember && selectedMember.id === currentUser.id}
        account={account}
        member={selectedMember}
        onSuccess={() => setSelectedMember(null)}
        onHide={() => setSelectedMember(null)}
      />
    </div>
  );
};

export default TeamMember;

export const StatusBadge = ({ status, ...rest }) => {
  const statuses = ['', 'Pending', 'Activated', 'Deactivated'];
  const variants = ['', 'secondary', 'success', 'danger'];

  return (
    <Badge pill variant={variants[status]} {...rest}>
      {statuses[status]}
    </Badge>
  );
};

const InviteModal = ({
  error, roles, loading, onSubmit: submitCallback, ...rest
}) => {
  const [state, setState] = useState({
    email: '',
    role: '',
    message: '',
  });
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const onSubmit = () => {
    setValidated(true);
    if (form.current.checkValidity()) {
      submitCallback(state);
    }
  };

  return (
    <Modal {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Icon size={23} className="mr-2" name="user-plus">
            Invite new member
          </Icon>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={form} noValidate validated={validated}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              size="sm"
              value={state.email}
              onChange={e => setState({ ...state, email: e.target.value })}
              required
            />
            <Form.Control.Feedback type="invalid">Invalid email address</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              value={state.role}
              onChange={e => setState({ ...state, role: e.target.value })}
            >
              <option value="" disabled />
              <option value="admin">admin</option>
              {Object.keys(roles).map(r => (
                <option value={r} key={r}>
                  {roles[r].name}
                </option>
              ))}
            </Form.Control>
            {state.role && roles[state.role] && roles[state.role].desc && (
              <Form.Control.Feedback className="d-block text-muted">
                {roles[state.role].desc}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              value={state.message}
              onChange={e => setState({ ...state, message: e.target.value })}
              rows="3"
            />
          </Form.Group>
        </Form>
        {error && (
          <Alert variant="danger">{error.message || error.error || 'Something went wrong'}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button loading={loading} disabled={loading} onClick={onSubmit} color="primary">
          <Icon className="mr-1" name="send">
            Send
          </Icon>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
