import React, { useState, useEffect, useContext } from 'react';
import { Card, Dimmer } from 'tabler-react';
import {
  Button, Alert, Row, Col,
} from 'react-bootstrap';
import moment from 'moment';

import { useModel } from 'models';
import { AppContext } from 'App';
import Icon from 'components/Icon';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import DataTable from 'components/DataTable';
import EmptyState from 'components/EmptyState';
import ReferralUrlModal from 'components/ReferralUrlModal';
import { formatNumber } from 'utils/intl';

const ListReferrals = ({ currentAccount }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [{
    referrals, report, loading, error,
  }, { getReferrals, getReferralReport }] = useModel(
    'referral',
    (state) => ({
      ...state.getReferrals[currentAccount.id],
      referrals: (state.getReferrals[currentAccount.id] || {}).referrals || [],
      report: state.getReferralReport,
    }),
  );

  const { dialog } = useContext(AppContext);

  useEffect(() => {
    getReferrals(currentAccount.id);
    getReferralReport();
  }, []);

  const columns = [
    { key: 'name', title: 'Referee Account' },
    { key: 'src', title: 'Referer' },
    { key: 'ts', title: 'Timestamp' },
  ];

  const rows = referrals.map((r, i) => ({
    key: i,
    name: r.name,
    src: r.src || '-',
    ts: moment(r.ts).format('lll'),
  }));

  const action = <Button onClick={() => setModalVisible(true)}>Get Referral URL</Button>;

  const showLearnMoreDialog = () => {
    dialog.info({
      title: 'Referral Policy',
      cancelable: false,
      content: (
        <div>
          Refer your friends and receive 3-months bonus from AppotaX. Starting from February,
          successfully refer a friend to join AppotaX system and both of you can earn money.
          <h5 className="mt-2">Terms and conditions:</h5>
          <ul>
            <li>Referee must be a new member signing-up for the first time.</li>
            <li>Referrer must be the AppotaX’s publisher.</li>
            <li>
              Referral bonus will be applied for 3 months starting from the sign-up date with 3%
              from AppotaX’s revenue.
            </li>
            <li>Referrer must achieved minimum $300 revenue on the scheduled monthly payment.</li>
            <li>No limitation on number of referees per a referrer.</li>
          </ul>
        </div>
      ),
    });
  };

  return (
    <MainLayout>
      <PageHeader title="List Referrals" extraContent={action} />
      <Alert variant="info" className="mt-3">
        <Icon name="info" size={20} className="mr-1" />
        Referral Policy: Referrer must achieve minimum $300 revenue on the scheduled monthly payment.
        <a className="ml-1" href="#learnmore" onClick={showLearnMoreDialog}>
            Learn more
        </a>
      </Alert>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Header>
              <h4 className="mb-0">Estimasted Revenue This Month</h4>
            </Card.Header>
            <Card.Body>
              <h3>
                {formatNumber(report.rows && report.rows.length > 0 ? report.rows[0][1] : 0, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>
              <h4 className="mb-0">Referrals Revenue This Month</h4>
            </Card.Header>
            <Card.Body>
              <h3>
                {formatNumber(report.rows && report.rows.length > 0 ? report.rows[0][2] : 0, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className="mt-3">
        <Card.Header>
          <Card.Title>{`Total: ${referrals.length}`}</Card.Title>
        </Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger">
              {error.message || error.error || 'Something went wrong.'}
            </Alert>
          )}
          <Dimmer active={loading} loader>
            <DataTable
              columns={columns}
              rows={rows}
              scroll={{ x: true }}
              emptyText={<EmptyState desc="No data" />}
            />
          </Dimmer>
        </Card.Body>
      </Card>
      <ReferralUrlModal
        show={modalVisible}
        accountId={currentAccount.id}
        onHide={() => setModalVisible(false)}
      />
    </MainLayout>
  );
};

export default ListReferrals;
