import React, { useEffect, useRef } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

import { useModel } from 'models';
import useActionEffect from 'utils/useActionEffect';
import useTempState from 'utils/useTempState';
import MemberForm from 'components/MemberForm';

/**
 * @param {object} props
 * @param {import('entities/Account').default} props.account
 */
const UpdateMemberModal = ({
  show, infoOnly, account, member, onSuccess, onHide, ...rest
}) => {
  const [submitStatus, setSubmitStatus] = useTempState({ loading: false, error: null }, [show]);
  const form = useRef(null);
  const memeberId = member && member.id;
  const [{ loading, error }, { updateMember }] = useModel('auth', state => ({
    ...(state.updateMember[memeberId] || {}),
  }), memeberId);

  useEffect(() => {
    setSubmitStatus({ loading, error });
  }, [loading, error]);

  useActionEffect({ loading, error, onSuccess });
  let platforms = {};
  if (member) {
    platforms = {
      ADX: !member.disabledPlatform || !member.disabledPlatform.ADX,
      FAN: !member.disabledPlatform || !member.disabledPlatform.FAN,
    };
  }

  return (
    <Modal show={show} onHide={onHide} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>Update member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MemberForm
          ref={form}
          infoOnly={infoOnly}
          account={account}
          initialValues={{ ...member, platforms }}
          onSubmit={values => updateMember(account.id, memeberId, values)}
          submitError={submitStatus.error}
          submitting={submitStatus.loading}
        />
        {submitStatus.error && (
          <Alert variant="danger">
            {submitStatus.error.message || submitStatus.error.error || 'Something went wrong.'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => form.current.submitForm()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateMemberModal;
