import React, { useEffect, useContext } from 'react';
import { Card } from 'tabler-react';
import classNames from 'classnames';
import get from 'lodash.get';

import { AppContext } from 'App';
import MainLayout from 'layouts/Main';
import PageHeader from 'components/PageHeader';
import NavCard from 'components/NavCard';
import ListBusinessAccount from 'components/Settings/ListBusinessAccount';
import User from 'entities/User';
import AccountInfo from 'components/AccountInfo/AccountInfo';
import TeamMember from 'components/Settings/TeamMember';
import PaymentSetting from 'components/PaymentSetting';
import ListContract from 'components/Settings/ListContract';
// import CompanyProfile from 'components/Settings/CompanyProfile';
import AccountRole from 'components/Settings/AccountRole';
import ListContractTemplates from 'components/Settings/ListContractTemplates';
import SystemSetting from 'components/Settings/SystemSetting';
import AlertSetting from 'components/Settings/AlertSetting';
import APIKey from 'components/AccountDetailCard/APIKey';
import AdmobSettings from 'components/AdmobSettings';

import { useModel } from 'models';
import configs from 'configs/configs';

/**
 * @param {Object} props
 * @param {User} props.currentUser
 * @param {import('entities/Account').default} props.currentAccount
 */
const Settings = ({
  location, history, currentUser, currentAccount,
}) => {
  const [{ invitations, generateApiKeyState }, { getInvitations, generateApiKey }] = useModel(
    'auth',
    state => ({
      invitations: state.invitations,
      generateApiKeyState: { ...state.generateApiKey },
    }),
  );
  useEffect(() => {
    getInvitations();
  }, []);

  const [publisher] = useModel('publisher', state => state.publishers[currentAccount.id]);
  const { dialog } = useContext(AppContext);
  const payoneerHash = localStorage.getItem('payoneer_hash');

  useEffect(() => {
    if (
      location.hash.replace('#', '') === payoneerHash
      && get(currentAccount, 'payment.payoneer')
    ) {
      localStorage.removeItem('payoneer_hash');
      dialog.success({
        title: 'Congratulation',
        content: 'Please wait for activation and then you can use Payoneer as payment method',
        cancelable: false,
      });
    }
  }, []);

  const onGenerateApiKey = () => {
    dialog.warning({
      title: 'Regenerate API Key',
      content: 'Are you sure want to regenerate new API key, current key will be invalid?',
      onOK: d => {
        generateApiKey(currentAccount.id);
        d.dismiss();
      },
    });
  };

  const members = [
    ...(currentAccount.team || []),
    ...invitations.map(i => ({
      ...i,
      id: i.code,
      name: i.email,
      status: User.STATUS_PENDING,
    })),
  ];

  const sysSettings = currentAccount.getSystemSettings();
  const nav = [
    {
      key: 'account',
      title: 'Account Info',
      show: currentAccount.isPublisher(),
      content: <AccountInfo account={currentAccount} />,
    },
    {
      key: 'member',
      title: 'Team Members',
      show:
        (currentAccount.isPublisher() && !currentAccount.isPersonal())
        || currentUser.hasPermission(User.PermAccountTeamView),
      content: (
        <TeamMember
          account={currentAccount}
          members={members}
          viewOnly={!currentUser.hasPermission(User.PermAccountTeamEdit)}
        />
      ),
    },
    {
      key: 'business',
      title: 'Business Account',
      show: currentAccount.isAdmin() && configs.platform === 'FAN',
      content: (
        <ListBusinessAccount
          businesses={sysSettings.fanBusinesses || []}
          viewOnly={!currentUser.hasPermission(User.PermSystemSettingEdit)}
        />
      ),
    },
    {
      key: 'payment',
      alsoMatch: payoneerHash,
      title: 'Payment',
      show: currentAccount.isPublisher(),
      content: <PaymentSetting account={publisher || currentAccount} />,
    },
    {
      key: 'contract',
      title: 'Contracts',
      show:
        configs.platform === 'ADX' && currentAccount.isPublisher() && currentAccount.isPersonal(),
      content: <ListContract account={currentAccount} />,
    },
    // {
    //   key: 'company-profile',
    //   title: 'Company Profile',
    //   show: currentAccount.isAdmin(),
    //   content: <CompanyProfile />,
    // },
    {
      key: 'contract-templates',
      title: 'Contract Templates',
      show: currentAccount.isAdmin(),
      content: <ListContractTemplates templates={sysSettings.contractTpls || []} />,
    },
    {
      key: 'role',
      title: 'Roles',
      show: currentAccount.isAdmin() && currentUser.hasPermission(User.PermAccountRoleView),
      content: <AccountRole account={currentAccount} />,
    },
    {
      key: 'system',
      title: 'System',
      show: currentAccount.isAdmin(),
      content: <SystemSetting account={currentAccount} />,
    },
    {
      key: 'alert',
      title: 'Alert',
      show: currentAccount.isAdmin(),
      content: <AlertSetting currentUser={currentUser} />,
    },
    {
      key: 'apiKey',
      title: 'API Key',
      show: configs.platform === 'ADX' && currentAccount.isPublisher(),
      content: (
        <APIKey
          generateState={generateApiKeyState}
          apiKey={currentAccount.apiKey || {}}
          onGenerate={
            currentAccount.isActivated() && currentUser.isAdmin() ? onGenerateApiKey : null
          }
        />
      ),
    },
    {
      key: 'admob',
      title: 'Admob',
      show:
        configs.platform === 'ADX'
        && currentAccount.isPublisher()
        && !!(currentAccount.admobConfig || {}).enabled,
      content: (
        <AdmobSettings accountId={currentAccount.id} config={currentAccount.admobConfig || {}} />
      ),
    },
  ];

  const showNav = nav.filter(n => n.show === undefined || n.show);
  const activeKey = location.hash.replace('#', '');
  let activeNav = showNav.find(n => n.key === activeKey || n.alsoMatch === activeKey);
  if (!activeNav) {
    [activeNav] = showNav;
  }

  const header = (
    <Card.Header>
      <Card.Title>{activeNav.title}</Card.Title>
    </Card.Header>
  );
  return (
    <MainLayout>
      <PageHeader title="Settings" />
      <NavCard
        vertical
        header={header}
        nav={showNav.map(({ content, ...rest }) => rest)}
        className={classNames('mt-3', 'flex-grow-1')}
        activeKey={activeNav.key}
        onNavigate={key => history.push({ ...location, hash: `#${key}` })}
      >
        {activeNav.content}
      </NavCard>
    </MainLayout>
  );
};

export default Settings;
